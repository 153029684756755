import React from "react";
import { Link as RouterLink, LinkProps, useMatch } from "react-router-dom";

type LinkRouteProps = LinkProps & {
  to: string;
  children: React.ReactNode;
};

export const LinkRoute: React.FC<LinkRouteProps> = ({
  to,
  children,
  className,
  ...rest
}) => {
  const match = useMatch(to);
  const activeClassName = match ? `${className} active` : className;
  return (
    <RouterLink to={to} {...rest} className={activeClassName}>
      {children}
    </RouterLink>
  );
};
