import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function findMaxValue(data: any[]): number {
  if (!Array.isArray(data) || data.length === 0) {
    return 0; // Return 0 for invalid input or empty array
  }

  let max: number = -Infinity;

  for (const item of data) {
    const numberValue: number = parseFloat(item); // Parse the string to a number
    if (!isNaN(numberValue) && numberValue > max) {
      max = numberValue;
    }
  }

  return max;
}

export const formatNumber = (number: number): string => {
  // if (number >= 10000000) {
  //   return (number / 10000000).toFixed(2) + ' Cr';
  // } else
  if (number >= 100000 || number >= 10000 || number >= 1000) {
    return (number / 100000).toFixed(2) + " Lakh";
  } else if (number < 1000) {
    return Math.round(Number(number)).toString();
  } else {
    return number.toString();
  }
};

export function indian_number_system_converter(
  input: string | undefined
): string {
  if (!input) return ""; // Handling undefined or empty input

  // Remove all the delimiters from the inputString
  const str: string = input.replace(/[\s,]+/g, "");
  // empty array
  const arr: string[] = [];
  let i: number = str.length - 1;
  // loop through the characters of `str`and pushing each character to the array
  for (let j: number = str.length - 1; j > i; j--) arr.push(str[j]);
  // loop through the characters of `str`
  for (let j: number = 0; i >= 0; i--, j++) {
    // add comma after every second character (starting from the third)
    if (j > 2 && j % 2 === 1) arr.push(",");
    // Pushing each character to the array
    arr.push(str[i]);
  }
  // Reverse the array, join it and return
  return [...arr].reverse().join("");
}

export const toTitleCase = (str: string) => {
  if (str === "NCDRC") {
    return str;
  } else {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
};

export const downloadPDF = async (chartId: string, filename: string) => {
  const chartElement: HTMLElement | null = document.getElementById(chartId);
  try {
    // Capture the chart as a canvas using html2canvas
    const canvas = await html2canvas(chartElement as HTMLElement, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const doc = new jsPDF('p', 'mm', 'a4');

    // Get image properties to maintain aspect ratio
    const imgProps = doc.getImageProperties(imgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    // Save the PDF document with the specified filename
    doc.save(`${filename}_graph.pdf`);
  } catch (error) {
    console.error('Error generating PDF', error);
  }
};

//transform Timeperiod data into a certain required format to show in Data table
export const transformTimeSpanData = (data: any) => {
  if (!data || Object.keys(data).length === 0) {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    timePeriod: key,
    count: value,
  }));
};