import { calculatingTotalData } from "../../ReportFunction/ReportBodyFunction";

export const reportFooter = (data: any, reportType: string) => {
  switch (reportType) {
    //@ type: commission_wise
    //@ Report no: 1
    case "commission_wise":
      return {
        commission_type: "All India",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5)
      };

    //@ type: categoryWiseReport
    //@ Report no: 2
    case "categoryWiseReport":
      return {
        category_name: "All India",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5),
      };

    //@ type: categoryWiseReport
    //@ Report no: 3
    case "StateWisePendencyReport":
      return {
        state_name: "All India",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5),
      };

    //@ type: StatePendencyForOneYearReport
    //@ Report no: 4
    case "StatePendencyForOneYearReport":
      return {
        state_name: "All India",
        district_name: "",
        casesfiled: calculatingTotalData(data, "casesfiled"),
        pending1to3mths: calculatingTotalData(data, "pending1to3mths"),
        pending3to6mths: calculatingTotalData(data, "pending3to6mths"),
        pending6to12mths: calculatingTotalData(data, "pending6to12mths"),
      };

    //@ type: StateDistrictReport
    //@ Report no: 5
    case "StateDistrictReport":
      return {
        state_name: "All India",
        district_name: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5),
      };

    //@ type: RegionWiseReport
    //@ Report no: 6
    case "RegionWiseReport":
      return {
        region: "All India",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5),
      };

    //@ type: DisposalMoreThanOneYearReport
    //@ Report no: 7
    case "DisposalMoreThanOneYearReport":
      return {
        state_name: "All India",
        district_name: "",
        disposalwithinayear: calculatingTotalData(
          data,
          "disposalwithinayear"
        ),
        disposedwithin1to2years: calculatingTotalData(
          data,
          "disposedwithin1to2years"
        ),
        disposedwithin2to5years: calculatingTotalData(
          data,
          "disposedwithin2to5years"
        ),
        disposedwithin5to10years: calculatingTotalData(
          data,
          "disposedwithin5to10years"
        ),
        disposedwithin10to20years: calculatingTotalData(
          data,
          "disposedwithin10to20years"
        ),
        disposedwithin20to30years: calculatingTotalData(
          data,
          "disposedwithin20to30years"
        ),
        morethan30years: calculatingTotalData(data, "morethan30years"),
      };

    //@ type: disposalWithinOneYearReport
    //@ Report no: 8
    case "disposalWithinOneYearReport":
      return {
        state_name: "All India",
        district_name: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        disposedwithin1to3months: calculatingTotalData(
          data,
          "disposedwithin1to3months"
        ),
        disposedwithin3to6months: calculatingTotalData(
          data,
          "disposedwithin3to6months"
        ),
        disposedwithin6to12months: calculatingTotalData(
          data,
          "disposedwithin6to12months"
        ),
      };

    //@ type: StateCommissionDurationWisePendency
    //@ Report no: 9
    case "StateCommissionDurationWisePendency":
      return {
        state_commission: "All India",
        casesfiled: calculatingTotalData(data, "casesfiled"),
        pend1to2yrs: calculatingTotalData(data, "pend1to2yrs"),
        pend2to5yrs: calculatingTotalData(data, "pend2to5yrs"),
        pend5to10yrs: calculatingTotalData(data, "pend5to10yrs"),
        pend10to20yrs: calculatingTotalData(data, "pend10to20yrs"),
        pend20to30yrs: calculatingTotalData(data, "pend20to30yrs"),
        pendmorethan30yrs: calculatingTotalData(data, "pendmorethan30yrs"),
      };

    //@ type: CaseType_Wise_Report
    //@ Report no: 10
    case "CaseType_Wise_Report":
      return {
        category_name: "All India",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "totalcasesfiled")) *
            100).toString().slice(0,5),
      };

    //@ type: Registered_Users_Report
    //@ Report no: 11
    case "Registered_Users_Report":
      return {
        state_name: "All India",
        district_name: "",
        registered_users: calculatingTotalData(data, "registered_users"),
      };

    //@ type: e-Jagriti_Case_Detail_Report
    //@ Report no: 12
    case "e-Jagriti_Case_Detail_Report":
      return {
        commission_type: "All India",
        online_filed: calculatingTotalData(data, "online_filed"),
        reverted: calculatingTotalData(data, "reverted"),
        admitted: calculatingTotalData(data, "admitted"),
        disposed: calculatingTotalData(data, "disposed"),
        pending: calculatingTotalData(data, "pending"),
      };

    //@ type: StageWiseReport
    //@ Report no: 13
    case "StageWiseReport":
      return {
        stage_name: "All India",
        case_count: calculatingTotalData(data, "case_count"),
      };

    //@ type: StateStageWiseReport
    //@ Report no: 14
    case "StateStageWiseReport":
      return {
        state_name: "All India",
        stage_name: "",
        case_count: calculatingTotalData(data, "case_count"),
      };

    //@ type: State_District_Stage_wise_report
    //@ Report no: 15
    case "State_District_Stage_wise_report":
      return {
        state_name: "All India:",
        district_name: "",
        stage_name: "",
        case_count: calculatingTotalData(data, "case_count"),
      };

    //@ type: YearlyWiseCategoryReport
    //@ Report no: 16
    case "YearlyWiseCategoryReport":
      return {
        applicable_year: "All",
        cases_filed: calculatingTotalData(data, "cases_filed"),
        cases_disposed: calculatingTotalData(data, "cases_disposed"),
        cases_pending: calculatingTotalData(data, "cases_pending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "disposal_rate") /
            calculatingTotalData(data, "cases_filed")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "cases_disposed") /
              calculatingTotalData(data, "cases_filed")) *
            100).toString().slice(0,4),
      };

    //@ type: RegionWiseEjagritiReport
    //@ Report no: 17
    case "RegionWiseEjagritiReport":
      return {
        region: "All India",
        online_filed: calculatingTotalData(data, "online_filed"),
        reverted: calculatingTotalData(data, "reverted"),
        payment_pending: calculatingTotalData(data, "payment_pending"),
        admitted: calculatingTotalData(data, "admitted"),
        disposed: calculatingTotalData(data, "disposed"),
        pending: calculatingTotalData(data, "pending"),
      };
    default:
  }
};
