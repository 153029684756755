export const headerLables: IHeaderLabels = {
    dashboard: "DASHBOARD",
    aboutEdakhil: "ABOUT EDAAKHIL",
    causeList: "CAUSELIST",
    judgement: "JUDGEMENT",
    caseStatus: "CASE STATUS",
    displayBoard: "DISPLAY BOARD",
    tutorials: "TUTORIALS",
    login: "LOGIN",
    signUp: "SIGN UP",
    reports: "REPORTS",

};

export interface IHeaderLabels {
    dashboard: string;
    aboutEdakhil: string;
    causeList: string;
    judgement: string;
    caseStatus: string;
    displayBoard: string;
    tutorials: string;
    login: string;
    signUp: string;
    reports: string;
}