import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkRoute } from "../components";
import {
	LogoIcon,
	UserEditIcon,
	UserLogoutIcon,
} from "../components/controls/BasicIcons";
import { COMPLETE_PROFILE, ROOT } from "../navigation/CONSTANTS";
import { useAuth } from "../navigation/auth/ProvideAuth";
import { RootState } from "../redux";
import LanguageSelection from "../utils/LanguageSelection";
import { SidebarView } from "./Sidebar/SidebarView";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	}),
}));

export const ResponsiveHeaderView = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const { signOut } = useAuth();
	const { userDetailsData } = useSelector((state: RootState) => state.user);

	// handle dropdown click
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// handle the closing of dropdown
	const handleClose = () => {
		setAnchorEl(null);
	};

	// handle logout
	const logout = async () => {
		const userLogout = await signOut();
		if (userLogout) {
			navigate(ROOT);
		}
	};

	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	return (
		<>
			<AppBar
				sx={{ top: "0" }}
				className="header-responsive d-xl-none d-md-block"
				open={drawerOpen}
			>
				<div className="container-fluid h-100">
					<div className="row h-100 align-items-center">
						<div className="col-10">
							<LogoIcon className="img-fluid header-logo" alt="" width={180} />
						</div>
						<div className="col-2 text-end ps-0">
							<div className="text-end ps-0">
								<IconButton
									disableRipple={true}
									color="primary"
									aria-label="open drawer"
									edge="end"
									onClick={handleDrawerOpen}
									sx={{ ...(drawerOpen && { display: "none" }) }}
								>
									<MenuIcon />
								</IconButton>
								<IconButton
									disableRipple={true}
									className="p-0"
									sx={{ ...(!drawerOpen && { display: "none" }) }}
									onClick={handleDrawerClose}
								>
									<CloseIcon />
								</IconButton>
							</div>
						</div>
					</div>
				</div>
				<Drawer
					className="d-xl-none d-md-block"
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": { width: drawerWidth },
					}}
					variant="persistent"
					anchor="right"
					open={drawerOpen}
				>
					<nav className="h-100 position-relative">
						<div className="pos-end d-flex align-items-center padding-top-15 padding-left-5">
							<div className="dropdown-language-selection">
								<LanguageSelection />
							</div>
							<Avatar className="user-image rounded-circle" />
							{/* <UserIcon
                width={40}
                height={40}
                className="user-image rounded-circle margin-left-20"
                alt="User"
              /> */}
							<Tooltip title="">
								<IconButton
									disableRipple
									onClick={handleClick}
									size="small"
									aria-controls={open ? "account-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									className="btn-dropdown-toggle ps-sm-0"
								>
									<div className="float-start text-start">
										<div className="btn-dropdown-toggle-user-name">
											{userDetailsData.userName}
										</div>
										<div className="btn-dropdown-toggle-user-role">
											{userDetailsData.roleId}
										</div>
									</div>
									<div className="d-flex align-items-center margin-left-5">
										<KeyboardArrowDownIcon />
									</div>
								</IconButton>
							</Tooltip>

							<Menu
								anchorEl={anchorEl}
								disableScrollLock={true}
								id="account-menu"
								className="dropdown-account-menu"
								open={open}
								onClose={handleClose}
								onClick={handleClose}
								transformOrigin={{ horizontal: "right", vertical: "top" }}
								anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
							>
								<MenuItem disableRipple={true}>
									<ListItemIcon>
										<UserEditIcon className="img-fluid" alt="User Edit" />
									</ListItemIcon>
									<LinkRoute to={COMPLETE_PROFILE}>Edit Profile</LinkRoute>
								</MenuItem>

								<MenuItem disableRipple={true} onClick={logout}>
									<ListItemIcon>
										<UserLogoutIcon className="img-fluid" alt="User Logout" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Menu>
						</div>
					</nav>
					<SidebarView open={true} />
				</Drawer>
			</AppBar>
		</>
	);
};
