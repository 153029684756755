import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { t } from "i18next";
import * as React from "react";
import { labels } from "../Provider.text";
import { BasicButton, LinkRoute } from "../components";
import { LogoIcon } from "../components/controls/BasicIcons";
import {
	ABOUT_EDAAKHIL,
	CASE_STATUS,
	CAUSE_LIST,
	DISPLAY_BOARD,
	JUDGEMENT,
	LOGIN,
	REGISTER,
	REPORTS,
	ROOT,
	TUTORIALS,
} from "../navigation/CONSTANTS";
import LanguageSelection from "../utils/LanguageSelection";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	}),
}));

export const ResponsiveMainHeaderView = () => {
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	return (
		<>
			<AppBar className="header-responsive d-xl-none d-md-block" open={open}>
				<div className="container-fluid h-100">
					<div className="row h-100 align-items-center">
						<div className="col-10">
							<LogoIcon
								className="img-fluid header-logo "
								alt="logo"
								width={180}
							/>
						</div>
						<div className="col-2 text-end ps-0">
							<IconButton
								disableRipple={true}
								color="primary"
								aria-label="open drawer"
								edge="end"
								onClick={handleDrawerOpen}
								sx={{ ...(open && { display: "none" }) }}
							>
								<MenuIcon />
							</IconButton>
							<IconButton
								disableRipple={true}
								className="p-0"
								sx={{ ...(!open && { display: "none" }) }}
								onClick={handleDrawerClose}
							>
								<CloseIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AppBar>
			<Drawer
				className="d-xl-none d-md-block"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": { width: drawerWidth },
				}}
				variant="persistent"
				anchor="right"
				open={open}
			>
				<ul className="header-responsive-navbar-nav navbar-nav align-items-start">
					<li className="nav-item">
						<LinkRoute className="nav-link" to={ROOT}>
							{t(labels.dashboard)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={ABOUT_EDAAKHIL}>
							{t("ABOUTEDAAKHIL")}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={CAUSE_LIST}>
							{t(labels.causeList)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={JUDGEMENT}>
							{t(labels.judgement)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={CASE_STATUS}>
							{t(labels.caseStatus)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={DISPLAY_BOARD}>
							{t(labels.displayBoard)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={REPORTS}>
							{t(labels.reports)}
						</LinkRoute>
					</li>
					<li className="nav-item">
						<LinkRoute className="nav-link" to={TUTORIALS}>
							{t(labels.tutorials)}
						</LinkRoute>
					</li>
					<li className="nav-item margin-bottom-15">
						<LanguageSelection />
					</li>
					<li className="nav-item margin-bottom-15">
						<LinkRoute className="text-decoration-none" to={LOGIN}>
							<BasicButton
								className="btn-custom width-100 btn-custom-primary btn-custom-small"
								text={t(labels.login)}
								variant="contained"
								color="success"
							/>
						</LinkRoute>
					</li>
					<li className="nav-item margin-bottom-15">
						<LinkRoute className="text-decoration-none" to={REGISTER}>
							<BasicButton
								className="btn-custom  width-100 btn-custom-secondary btn-custom-small"
								text={t(labels.signUp)}
								variant="outlined"
							/>
						</LinkRoute>
					</li>
				</ul>
			</Drawer>
		</>
	);
};
