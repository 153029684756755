import { useTranslation } from "react-i18next";
import SidebarCommissionDashboardActiveIcon from "../assets/images/sidebar_commission_dashboard_active_icon.svg";
import SidebarCommissionDashboardIcon from "../assets/images/sidebar_commission_dashboard_icon.svg";
import { ISidebarMenu } from "../models/common/ISidebarMenu";

import {
    ADD_ADVOCATE,
    ADD_RESPONDENTADVOCATE_LIST,
    ADVOCATE_CASEFILING,
    ADVOCATE_DASHBOARD,
    ADVOCATE_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
    ADVOCATE_KNOW_YOUR_CASE,
    ADVOCATE_LIST,
    ADVOCATE_PAYMENT_HISTORY,
    ADVOCATE_REJOINDER,
    ADVOCATE_REPLY,
    ADVOCATE_SUBSEQUENT_FILING,
    COMMISSION_ADD_REMOVE_BUNCH_CASES,
    COMMISSION_CAUSELIST,
    COMMISSION_CONSOLIDATED_FILES,
    COMMISSION_COURTROOM_HEARING,
    COMMISSION_COURTROOM_MODIFICATION,
    COMMISSION_DAILY_ORDER_ENTRY,
    COMMISSION_DAILY_ORDER_VIEW,
    COMMISSION_DASHBOARD,
    COMMISSION_ISSUE_PRINT_NOTICE,
    COMMISSION_JUDGMENT_PROCEEDING,
    COMMISSION_JUDGMENT_UPLOAD,
    COMMISSION_PENDING_FOR_SCRUTINY,
    COMMISSION_REJOINDER,
    COMMISSION_REPLY,
    COMMISSION_SUBSEQUENT_FILING,
    CONSUMER_CASEFILING,
    CONSUMER_DASHBOARD,
    CONSUMER_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
    CONSUMER_KNOW_YOUR_CASE,
    CONSUMER_PAYMENT_HISTORY,
    CONSUMER_REJOINDER,
    CONSUMER_REPLY,
    CONSUMER_SUBSEQUENT_FILING,
    FIND_CASEDETAILS,
    JUDGE_CONSOLIDATED_FILES,
    JUDGE_DASHBOARD,
    JUDGE_FIND_CASEDETAILS,
    JUDGE_IMPORTANT_NOTES,
    COURT_MASTER_DASHBOARD,
    DOCA_USER_DASHBOARD,
    DEALING_ASSISTANT_DASHBOARD,
    DEALING_ASSISTANT_CASETRANSFER,
    DEALING_ASSISTANT_ADDREMOVEBUNCH,
    DEALING_ASSISTANT_ADDMODIFYCOMPLAINT,
    DEALING_ASSISTANT_SHIFTADJOURN,
    DEALING_ASSISTANT_ISSUEPRINT,
    COURT_COURTROOM_HEARING,
    COURT_DAILY_ORDER_ENTRY,
    COURT_COUSELIST,
    COURT_JUDGEMENT_UPLOAD,
    COURT_RESTORE_CASE,
    COURT_SUBSEQUENT_FILING,
    COMMISSION_SETTING_FILES,
    COMMISSION_SETTING_USER,
    COMMISSION_SETTING_ROLES,
    COMMISSION_SETTING_MODULES,
    COMMISSION_SETTING_USER_ROLE_ALLOCATION,
    COURT_DAILY_ORDER_LIST,
    COMMISSION_CASE_FINALIZE,
    COMMISSION_CASE_DEFINALIZE,
    JUDGE_CAUSELIST,
    JUDGE_CASE_HISTORY
} from "../navigation/CONSTANTS";
import { ROLE } from "../utils/Enum";
import { useSelector } from "react-redux";
import SidebarCommissionBasilInvoiceSolidActiveIcon from "../assets/images/sidebar_commission_basil_invoice_solid_active_icon.svg";
import SidebarCommissionBasilInvoiceSolidIcon from "../assets/images/sidebar_commission_basil_invoice_solid_icon.svg";
import SidebarCommissionDocumentSolidActiveIcon from "../assets/images/sidebar_commission_document_solid_active_icon.svg";
import SidebarCommissionDocumentSolidIcon from "../assets/images/sidebar_commission_document_solid_icon.svg";
import SidebarCommissionFileCheckFillActiveIcon from "../assets/images/sidebar_commission_file_check_fill_active_icon.svg";
import SidebarCommissionFileCheckFillIcon from "../assets/images/sidebar_commission_file_check_fill_icon.svg";
import SidebarCommissionInventoryActiveIcon from "../assets/images/sidebar_commission_inventory_active_icon.svg";
import SidebarCommissionInventoryIcon from "../assets/images/sidebar_commission_inventory_icon.svg";
import SidebarCommissionNoteAltActiveIcon from "../assets/images/sidebar_commission_note_alt_active_icon.svg";
import SidebarCommissionNoteAltIcon from "../assets/images/sidebar_commission_note_alt_icon.svg";
import SidebarCommissionPrintActiveIcon from "../assets/images/sidebar_commission_print_active_icon.svg";
import SidebarCommissionPrintIcon from "../assets/images/sidebar_commission_print_icon.svg";
import SidebarCommissionRiFileInfoFillActiveIcon from "../assets/images/sidebar_commission_ri_file_info_fill_active_icon.svg";
import SidebarCommissionRiFileInfoFillIcon from "../assets/images/sidebar_commission_ri_file_info_fill_icon.svg";
import SidebarConsumerAccountBalanceWalletIcon from "../assets/images/sidebar_consumer_account_balance_wallet.svg";
import SidebarConsumerAccountBalanceWalletActiveIcon from "../assets/images/sidebar_consumer_account_balance_wallet_active.svg";
import SidebarConsumerBasilDocumentSolidIcon from "../assets/images/sidebar_consumer_basil_document_solid.svg";
import SidebarConsumerBasilDocumentSolidActiveIcon from "../assets/images/sidebar_consumer_basil_document_solid_active.svg";
import SidebarConsumerMaterialSymbolsNoteAltIcon from "../assets/images/sidebar_consumer_material_symbols_note_alt.svg";
import SidebarConsumerMaterialSymbolsNoteAltActiveIcon from "../assets/images/sidebar_consumer_material_symbols_note_alt_active.svg";
import SidebarConsumerOrAdvocateDashboard from "../assets/images/sidebar_consumer_or_advocate_dashboard.svg";
import SidebarConsumerOrAdvocateDashboard_active from "../assets/images/sidebar_consumer_or_advocate_dashboard_active.svg";
import SidebarConsumerOrAdvocateFileCheckFillIcon from "../assets/images/sidebar_consumer_or_advocate_file_check_fill.svg";
import SidebarConsumerOrAdvocateFileCheckFillActiveIcon from "../assets/images/sidebar_consumer_or_advocate_file_check_fill_active.svg";
import SidebarConsumerOrAdvocateFile_info_fill from "../assets/images/sidebar_consumer_or_advocate_file_info_fill.svg";
import SidebarConsumerOrAdvocateFile_info_fill_active from "../assets/images/sidebar_consumer_or_advocate_file_info_fill_active.svg";
import SidebarConsumerOrAdvocateInvoice_solid from "../assets/images/sidebar_consumer_or_advocate_invoice_solid.svg";
import SidebarConsumerOrAdvocateInvoice_solid_active from "../assets/images/sidebar_consumer_or_advocate_invoice_solid_active.svg";
import SidebarConsumerVoiceEnabledIcon from "../assets/images/sidebar_consumer_voice_enabled_icon.svg";
import SidebarConsumerVoiceEnabledIconActive from "../assets/images/sidebar_consumer_voice_enabled_icon_active.svg";
import SidebarCommissionArrowUpCircleActiveIcon from "../assets/images/sidebar_commission_arrow_up_circle_active_icon.svg";
import SidebarCommissionArrowUpCircleIcon from "../assets/images/sidebar_commission_arrow_up_circle_icon.svg";
import { RootState } from "../redux";
/**
 * Component for List of menu in Sidebar
 * @returns List of sidebar Data
 */
export const useSidebarData = () => {
    const { t } = useTranslation();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const complainantMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: CONSUMER_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateDashboard,
            activeIcon: SidebarConsumerOrAdvocateDashboard_active,
        },
        {
            id: 2,
            path: CONSUMER_CASEFILING,
            text: t("Sidebar.FileNewCase"),
            icon: SidebarConsumerOrAdvocateFile_info_fill,
            activeIcon: SidebarConsumerOrAdvocateFile_info_fill_active,
        },
        {
            id: 3,
            path: CONSUMER_PAYMENT_HISTORY,
            text: t("Sidebar.PaymentHistory"),
            icon: SidebarConsumerAccountBalanceWalletIcon,
            activeIcon: SidebarConsumerAccountBalanceWalletActiveIcon,
        },
        {
            id: 5,
            path: CONSUMER_REPLY,
            text: t("Sidebar.Reply"),
            icon: SidebarConsumerMaterialSymbolsNoteAltIcon,
            activeIcon: SidebarConsumerMaterialSymbolsNoteAltActiveIcon,
        },
        {
            id: 6,
            path: CONSUMER_REJOINDER,
            text: t("Sidebar.Rejoinder"),
            icon: SidebarConsumerBasilDocumentSolidIcon,
            activeIcon: SidebarConsumerBasilDocumentSolidActiveIcon,
        },
        {
            id: 7,
            path: CONSUMER_SUBSEQUENT_FILING,
            text: t("Sidebar.SubsequentFiling"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 8,
            path: CONSUMER_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
            text: t("Sidebar.VoiceEnabledCaseFiling"),
            icon: SidebarConsumerVoiceEnabledIcon,
            activeIcon: SidebarConsumerVoiceEnabledIconActive,
        },
        {
            id: 9,
            path: CONSUMER_KNOW_YOUR_CASE,
            text: t("Sidebar.KnowYourCaseNumber"),
            icon: SidebarConsumerMaterialSymbolsNoteAltIcon,
            activeIcon: SidebarConsumerMaterialSymbolsNoteAltActiveIcon,
        },
    ];

    const forumAdminMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: COMMISSION_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarCommissionDashboardIcon,
            activeIcon: SidebarCommissionDashboardActiveIcon,
        },
        {
            id: 2,
            path: COMMISSION_PENDING_FOR_SCRUTINY,
            text: t("Sidebar.Scrutiny"),
            icon: SidebarCommissionDashboardIcon,
            activeIcon: SidebarCommissionDashboardActiveIcon,
        },
        {
            id: 5,
            path: COMMISSION_COURTROOM_HEARING,
            text: t("Sidebar.CourtroomHearing"),
            icon: SidebarCommissionRiFileInfoFillIcon,
            activeIcon: SidebarCommissionRiFileInfoFillActiveIcon,
        },
        {
            id: 6,
            path: COMMISSION_COURTROOM_MODIFICATION,
            text: t("Sidebar.CourtroomModification"),
            icon: SidebarCommissionArrowUpCircleIcon,
            activeIcon: SidebarCommissionRiFileInfoFillActiveIcon,
        },
        {
            id: 7,
            path: COMMISSION_DAILY_ORDER_ENTRY,
            text: t("Label.DailyOrderEntry"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 8,
            path: COMMISSION_DAILY_ORDER_VIEW,
            text: t("Label.DailyOrderList"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon
        },
        {
            id: 9,
            path: COMMISSION_JUDGMENT_UPLOAD,
            text: t("Label.JudgmentUpload"),
            icon: SidebarCommissionFileCheckFillIcon,
            activeIcon: SidebarCommissionFileCheckFillActiveIcon,
        },
        {
            id: 10,
            path: COMMISSION_JUDGMENT_PROCEEDING,
            text: t("Sidebar.JudgementSearch"),
            icon: SidebarCommissionFileCheckFillIcon,
            activeIcon: SidebarCommissionFileCheckFillActiveIcon,
        },
        {
            id: 11,
            path: COMMISSION_REPLY,
            text: t("Sidebar.Reply"),
            icon: SidebarCommissionNoteAltIcon,
            activeIcon: SidebarCommissionNoteAltActiveIcon,
        },
        {
            id: 12,
            path: COMMISSION_REJOINDER,
            text: t("Sidebar.Rejoinder"),
            icon: SidebarCommissionDocumentSolidIcon,
            activeIcon: SidebarCommissionDocumentSolidActiveIcon,
        },
        {
            id: 14,
            path: COMMISSION_SUBSEQUENT_FILING,
            text: t("Sidebar.SubsequentFiling"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 15,
            path: COMMISSION_ISSUE_PRINT_NOTICE,
            text: t("Sidebar.IssuePrintNotice"),
            icon: SidebarCommissionPrintIcon,
            activeIcon: SidebarCommissionPrintActiveIcon,
        },
        {
            id: 22,
            path: COMMISSION_ADD_REMOVE_BUNCH_CASES,
            text: t("Sidebar.AddRemoveBunchCases"),
            icon: SidebarCommissionInventoryIcon,
            activeIcon: SidebarCommissionInventoryActiveIcon,
        },
        {
            id: 26,
            path: COMMISSION_CAUSELIST,
            text: t("Sidebar.Causelist"),
            icon: SidebarCommissionArrowUpCircleIcon,
            activeIcon: SidebarCommissionArrowUpCircleActiveIcon,
        },
        {
            id: 27,
            path: ADD_ADVOCATE,
            text: t("Sidebar.AddAdvocate"),
            icon: SidebarCommissionDashboardIcon,
            activeIcon: SidebarCommissionDashboardActiveIcon,
        },
        {
            id: 28,
            path: ADVOCATE_LIST,
            text: t("Sidebar.AdvocateList"),
            icon: SidebarCommissionDashboardIcon,
            activeIcon: SidebarCommissionDashboardActiveIcon,
        },
        {
            id: 29,
            path: ADD_RESPONDENTADVOCATE_LIST,
            text: t("Sidebar.AddRespondentAdvocate"),
            icon: SidebarCommissionDashboardIcon,
            activeIcon: SidebarCommissionDashboardActiveIcon,
        },
        {
            id: 30,
            path: COMMISSION_CASE_FINALIZE,
            text: t("Sidebar.CaseFinalize"),
            icon: SidebarCommissionPrintIcon,
            activeIcon: SidebarCommissionPrintActiveIcon,
        },
        {
            id: 31,
            path: COMMISSION_CASE_DEFINALIZE,
            text: t("Sidebar.CaseDefinalize"),
            icon: SidebarCommissionPrintIcon,
            activeIcon: SidebarCommissionPrintActiveIcon,
        },
        {
            id: 32,
            path: FIND_CASEDETAILS,
            text: t("Sidebar.findCaseDetails"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 33,
            path: COMMISSION_CONSOLIDATED_FILES,
            text: t("Sidebar.ConsolidatedFiles"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 34,
            path: COMMISSION_SETTING_FILES,
            text: t("Sidebar.Settings"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
            children: [
                {
                  id: 1,
                  path: COMMISSION_SETTING_USER,
                  text: t("Sidebar.Users"),
                  icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
                  activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
                },
                {
                  id: 2,
                  path: COMMISSION_SETTING_ROLES,
                  text: t("Sidebar.Roles"),
                  icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
                  activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
                },
                {
                  id: 3,
                  path: COMMISSION_SETTING_MODULES,
                  text: t("Sidebar.Modules"),
                  icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
                  activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
                  
                },
                {
                  id: 4,
                  path: COMMISSION_SETTING_USER_ROLE_ALLOCATION,
                  text: t("Sidebar.UserRoleAllocation"),
                  icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
                  activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
                },
              ],
        },
    ];

    const judgeMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: JUDGE_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateInvoice_solid,
            activeIcon: SidebarConsumerOrAdvocateInvoice_solid_active,
        },
        {
            id: 2,
            path: JUDGE_FIND_CASEDETAILS,
            text: t("Sidebar.findCaseDetails"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 3,
            path: JUDGE_CONSOLIDATED_FILES,
            text: t("Sidebar.ConsolidatedFiles"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 4,
            path: JUDGE_IMPORTANT_NOTES,
            text: t("Sidebar.importantnotes"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
            disabled: true,
        },
        {
            id: 5,
            path: JUDGE_CAUSELIST,
            text: t("Sidebar.Causelist"),
            icon: SidebarCommissionArrowUpCircleIcon,
            activeIcon: SidebarCommissionArrowUpCircleActiveIcon,
        },
        {
            id: 6,
            path: JUDGE_CASE_HISTORY,
            text: t("Sidebar.CaseHistory"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
    ];

    const CourtmasterMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: COURT_MASTER_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateInvoice_solid,
            activeIcon: SidebarConsumerOrAdvocateInvoice_solid_active,
        },
        {
            id: 2,
            path: COURT_COURTROOM_HEARING,
            text: t("Sidebar.Courtroomhearing"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 3,
            path: COURT_DAILY_ORDER_ENTRY,
            text: t("Sidebar.Dailyorderentry"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 4,
            path: COURT_DAILY_ORDER_LIST,
            text: t("Sidebar.DailyOrderList"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 5,
            path: COURT_COUSELIST,
            text: t("Sidebar.Causelist"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
            disabled: true,
        },
        {
            id: 6,
            path: COURT_JUDGEMENT_UPLOAD,
            text: t("Sidebar.Judgementupload"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
            disabled: true,
        },
        {
            id: 7,
            path: COURT_RESTORE_CASE,
            text: t("Sidebar.restorecase"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
            disabled: true,
        },
        {
            id: 8,
            path: COURT_SUBSEQUENT_FILING,
            text: t("Sidebar.sebsequentfiling"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
    ];

    const DealingAssistantMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: DEALING_ASSISTANT_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateInvoice_solid,
            activeIcon: SidebarConsumerOrAdvocateInvoice_solid_active,
        },
        {
            id: 2,
            path: DEALING_ASSISTANT_CASETRANSFER,
            text: t("Sidebar.CaseTransfer"),
            icon: SidebarCommissionBasilInvoiceSolidIcon,
            activeIcon: SidebarCommissionBasilInvoiceSolidActiveIcon,
        },
        {
            id: 3,
            path: DEALING_ASSISTANT_ADDREMOVEBUNCH,
            text: t("Sidebar.AddRemoveBunchCases"),
            icon: SidebarCommissionInventoryIcon,
            activeIcon: SidebarCommissionInventoryActiveIcon,
        },
        {
            id: 4,
            path: DEALING_ASSISTANT_ADDMODIFYCOMPLAINT,
            text: t("Sidebar.AddModifyComplainantRespondentAndDocuments"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 5,
            path: DEALING_ASSISTANT_SHIFTADJOURN,
            text: t("Sidebar.ShiftAdjournCase"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 6,
            path: DEALING_ASSISTANT_ISSUEPRINT,
            text: t("Sidebar.IssuePrintNotice"),
            icon: SidebarCommissionPrintIcon,
            activeIcon: SidebarCommissionPrintActiveIcon,
        },
    ];

    const DocaUserMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: DOCA_USER_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateInvoice_solid,
            activeIcon: SidebarConsumerOrAdvocateInvoice_solid_active,
        }
    ];

    const advocateMenuList: ISidebarMenu[] = [
        {
            id: 1,
            path: ADVOCATE_DASHBOARD,
            text: t("Sidebar.Dashboard"),
            icon: SidebarConsumerOrAdvocateDashboard,
            activeIcon: SidebarConsumerOrAdvocateDashboard_active,
        },
        {
            id: 2,
            path: ADVOCATE_CASEFILING,
            text: t("Sidebar.FileNewCase"),
            icon: SidebarConsumerOrAdvocateFile_info_fill,
            activeIcon: SidebarConsumerOrAdvocateFile_info_fill_active,
        },
        {
            id: 3,
            path: ADVOCATE_PAYMENT_HISTORY,
            text: t("Sidebar.PaymentHistory"),
            icon: SidebarConsumerAccountBalanceWalletIcon,
            activeIcon: SidebarConsumerAccountBalanceWalletActiveIcon,
        },
        {
            id: 5,
            path: ADVOCATE_REPLY,
            text: t("Sidebar.Reply"),
            icon: SidebarConsumerMaterialSymbolsNoteAltIcon,
            activeIcon: SidebarConsumerMaterialSymbolsNoteAltActiveIcon,
        },
        {
            id: 6,
            path: ADVOCATE_REJOINDER,
            text: t("Sidebar.Rejoinder"),
            icon: SidebarConsumerBasilDocumentSolidIcon,
            activeIcon: SidebarConsumerBasilDocumentSolidActiveIcon,
        },
        {
            id: 7,
            path: ADVOCATE_SUBSEQUENT_FILING,
            text: t("Sidebar.SubsequentFiling"),
            icon: SidebarConsumerOrAdvocateFileCheckFillIcon,
            activeIcon: SidebarConsumerOrAdvocateFileCheckFillActiveIcon,
        },
        {
            id: 8,
            path: ADVOCATE_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
            text: t("Sidebar.VoiceEnabledCaseFiling"),
            icon: SidebarConsumerVoiceEnabledIcon,
            activeIcon: SidebarConsumerVoiceEnabledIconActive,
        },
        {
            id: 9,
            path: ADVOCATE_KNOW_YOUR_CASE,
            text: t("Sidebar.KnowYourCaseNumber"),
            icon: SidebarConsumerMaterialSymbolsNoteAltIcon,
            activeIcon: SidebarConsumerMaterialSymbolsNoteAltActiveIcon,
        },
    ];

    const sideBarData: () => ISidebarMenu[] = () => {
        switch (userDetailsData.roleId) {
            case (ROLE.Consumer):
                return [...complainantMenuList]

            case (ROLE.NcdrcAdmin):
                return [...forumAdminMenuList]

            case (ROLE.ScdrcAdmin):
                return [...forumAdminMenuList]

            case (ROLE.DcdrcAdmin):
                return [...forumAdminMenuList]

            case (ROLE.Advocate):
                return [...advocateMenuList]

            case (ROLE.President):
                return [...judgeMenuList]

            case (ROLE.CourtMaster):
                return [...CourtmasterMenuList]

            case (ROLE.DealingAssistant):
                return [...DealingAssistantMenuList]

            case (ROLE.DocaUser):
                return [...DocaUserMenuList]

            default:
                return [...complainantMenuList]
        }
    }

    return sideBarData();
};
