import {
	Avatar,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	LogoIcon,
	UserEditIcon,
	UserLogoutIcon,
} from "../components/controls/BasicIcons";
import { LinkRoute } from "../components/controls/LinkRoutes";
import {
	ADVOCATE_DASHBOARD,
	ADVOCATE_EDITPROFILE,
	ANALYTICS_DASHBOARD,
	ANALYTICS_EDITPROFILE,
	COMMISSION_DASHBOARD,
	COMMISSION_EDITPROFILE,
	COMPLETE_PROFILE,
	CONSUMER_DASHBOARD,
	CONSUMER_EDITPROFILE,
	JUDGE_DASHBOARD,
	JUDGE_EDITPROFILE,
	DEALING_ASSISTANT_DASHBOARD,
	DEALING_ASSISTANT_EDITPROFILE,
	COURT_MASTER_DASHBOARD,
	COURT_EDITPROFILE,
	DOCA_USER_DASHBOARD,
	DOCA_EDITPROFILE,
	ROOT,
} from "../navigation/CONSTANTS";
import { useAuth } from "../navigation/auth/ProvideAuth";
import { RootState } from "../redux";
import { ROLE } from "../utils/Enum";
import { b64toBlob } from "../utils/Helper";
import LanguageSelection from "../utils/LanguageSelection";

interface IHeaderProps {
	onSidebarClick?: () => void;
	openSidebar?: boolean;
}

interface PathProps {
	homePagePath: string;
	editProfilePath: string;
}

/** Renders the header after authentication
 * @param @IHeaderProps
 */
export const HeaderView = (props: IHeaderProps) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const location = useLocation();
	const [profileImage, setProfileImage] = useState<string>("");
	const [paths, setPaths] = useState<PathProps>({
		homePagePath: ROOT,
		editProfilePath: CONSUMER_EDITPROFILE,
	});
	const { userDetailsData } = useSelector((state: RootState) => state.user);
	const { signOut } = useAuth()

	// handle dropdown click
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	//get the role name according to roleId

	const roleName = (id: number) => {
		const rolesList = sessionStorage.getItem('rolesList');
		if (rolesList) {
			const rolesArray = JSON.parse(rolesList);
			const role = rolesArray.find((role: any) => role.value === id);
			if (role) {
				if (role.label === "President") {
					return role.label = "Judge"
				} else {
					return role.label;
				}
			} else {
				console.warn(`Role with value ${id} not found`);
				return null;
			}
		} else {
			console.warn('No rolesList found in session storage');
			return null;
		}
	};

	// handle the closing of dropdown
	const handleClose = () => {
		setAnchorEl(null);
	};

	// handle logout
	const logout = async () => {
		const userLogedout = await signOut();
		if (userLogedout) {
			navigate(ROOT);
		}
	};

	// set the paths for edit and home page
	const handleSetPath = useCallback(() => {
		const roleId = userDetailsData?.roleId;
		if (roleId === ROLE.Consumer) {
			setPaths({
				homePagePath: CONSUMER_DASHBOARD,
				editProfilePath: CONSUMER_EDITPROFILE,
			});
		} else if (
			roleId === ROLE.DcdrcAdmin ||
			roleId === ROLE.ScdrcAdmin ||
			roleId === ROLE.NcdrcAdmin
		) {
			setPaths({
				homePagePath: COMMISSION_DASHBOARD,
				editProfilePath: COMMISSION_EDITPROFILE,
			});
		} else if (roleId === ROLE.Advocate) {
			setPaths({
				homePagePath: ADVOCATE_DASHBOARD,
				editProfilePath: ADVOCATE_EDITPROFILE,
			});
		} else if (roleId === ROLE.President) {
			setPaths({
				homePagePath: JUDGE_DASHBOARD,
				editProfilePath: JUDGE_EDITPROFILE,
			});
		} else if (roleId === ROLE.AnalyticsAdmin) {
			setPaths({
				homePagePath: ANALYTICS_DASHBOARD,
				editProfilePath: ANALYTICS_EDITPROFILE,
			})
		}else if (roleId === ROLE.DealingAssistant) {
			setPaths({
				homePagePath: DEALING_ASSISTANT_DASHBOARD,
				editProfilePath: DEALING_ASSISTANT_EDITPROFILE,
			})
		}else if (roleId === ROLE.CourtMaster) {
			setPaths({
				homePagePath: COURT_MASTER_DASHBOARD,
				editProfilePath: COURT_EDITPROFILE,
			})
		}else if (roleId === ROLE.DocaAdmin) {
			setPaths({
				homePagePath: DOCA_USER_DASHBOARD,
				editProfilePath: DOCA_EDITPROFILE,
			})
		}
	}, [userDetailsData]);


	

	
	// checking if user is in any auth page
	useEffect(() => {
		if (
			!location.pathname.includes("edit-profile") ||
			!location.pathname.includes("complete-profile")
		) {
			handleSetPath();
			if (
				userDetailsData?.fileContentBase64 &&
				userDetailsData?.fileContentBase64 !== ""
			) {
				const profilePictureFile = b64toBlob(
					userDetailsData?.fileContentBase64,
					"image/png",
					"profilePicture"
				);
				const profilePicture = URL.createObjectURL(profilePictureFile);
				setProfileImage(profilePicture);
			}
		}
	}, [handleSetPath, location, userDetailsData?.fileContentBase64]);

	return (
		<>
			<header
				className={`header fixed-top ${location.pathname !== COMPLETE_PROFILE &&
					"d-none d-md-none d-xl-block"
					}`}
			>
				<div className="container-fluid h-100">
					<nav className="d-flex align-items-center h-100 position-relative">
						<LogoIcon
							className="img-fluid header-logo pointer"
							alt=""
							onClick={() => navigate(paths.homePagePath)}
							width={150}
						/>
						<div className="pos-end d-flex align-items-center">
							<div className="dropdown-language-selection">
								<LanguageSelection />
							</div>
							{profileImage ? (
								<img
									width={40}
									height={40}
									src={profileImage}
									className="user-image rounded-circle margin-left-20"
									alt="User"
								/>
							) : (
								<Avatar className="user-image rounded-circle margin-left-20" />
							)}

							{/* <img
                width={40}
                height={40}
                className="user-image rounded-circle margin-left-20"
                alt="User"
              /> */}
							<Tooltip title="">
								<IconButton
									disableRipple
									onClick={handleClick}
									size="small"
									aria-controls={open ? "account-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									className="btn-dropdown-toggle"
								>
									<div className="float-start text-start">
										<div className="btn-dropdown-toggle-user-name">
											{userDetailsData.userName}
										</div>
										<div className="btn-dropdown-toggle-user-role">
											{roleName(userDetailsData?.roleId)}
										</div>
									</div>
									<div className="d-flex align-items-center ms-lg-2 ms-0">
										<KeyboardArrowDownIcon className="arrow-down-icon" />
									</div>
								</IconButton>
							</Tooltip>

							<Menu
								anchorEl={anchorEl}
								disableScrollLock={true}
								id="account-menu"
								className="dropdown-account-menu"
								open={open}
								onClose={handleClose}
								onClick={handleClose}
								transformOrigin={{ horizontal: "right", vertical: "top" }}
								anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
							>
								<MenuItem disableRipple={true}>
									<ListItemIcon>
										<UserEditIcon className="img-fluid" alt="User Edit" />
									</ListItemIcon>
									<LinkRoute to={paths.editProfilePath}>Edit Profile</LinkRoute>
								</MenuItem>

								<MenuItem disableRipple={true} onClick={logout}>
									<ListItemIcon>
										<UserLogoutIcon className="img-fluid" alt="User Logout" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Menu>
						</div>
					</nav>
				</div>
			</header>
		</>
	);
};
