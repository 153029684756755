import { calculatingTotalData } from "../../ReportFunction/ReportBodyFunction";

export const reportFooter = (data, reportType) => {
  switch (reportType) {
    case "Commission_wise_case_type_reports":
      return {
        case_type_name_en: "Total",
        commission_type_en: "",
        total_cases_filed: calculatingTotalData(data, "total_cases_filed"),
        total_cases_disposed: calculatingTotalData(
          data,
          "total_cases_disposed"
        ),
        total_cases_pending: calculatingTotalData(data, "total_cases_pending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "total_cases_disposed") /
            calculatingTotalData(data, "total_cases_filed")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "total_cases_disposed") /
                calculatingTotalData(data, "total_cases_filed")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Commission_wise_Category_reports":
      return {
        commissionType: "All India:",
        categoryName: " ",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "commission_wise_State_with_category_Report":
      return {
        commissionType: "All India:",
        categoryName: " ",
        stateName: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "State_Category_wise_report":
      return {
        categoryName: "All:",
        stateName: " ",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "NCDRC_or_SCDRC_or_DCDRC":
      return {
        commission_type_en: "All India",
        total_cases_filed: calculatingTotalData(data, "total_cases_filed"),
        total_cases_disposed: calculatingTotalData(
          data,
          "total_cases_disposed"
        ),
        total_cases_pending: calculatingTotalData(data, "total_cases_pending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "total_cases_disposed") /
            calculatingTotalData(data, "total_cases_filed")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "total_cases_disposed") /
                calculatingTotalData(data, "total_cases_filed")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Ad-hoc_Report_SCDRC":
      return {
        commissionType: "All India",
        stateName: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Ad-hoc_Report_DCDRC":
      return {
        commission_type_en: "All India",
        state_name_en: "",
        commission_name_en: "",
        total_cases_filed: calculatingTotalData(data, "total_cases_filed"),
        total_cases_disposed: calculatingTotalData(
          data,
          "total_cases_disposed"
        ),
        total_cases_pending: calculatingTotalData(data, "total_cases_pending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "total_cases_disposed") /
            calculatingTotalData(data, "total_cases_filed")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "total_cases_disposed") /
                calculatingTotalData(data, "total_cases_filed")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Ad-hoc_Report_Category":
      return {
        // state_name_en: "Total",
        // commission_name_en: "",
        case_category_name_en: "Total",
        total_cases_filed: calculatingTotalData(data, "total_cases_filed"),
        total_cases_disposed: calculatingTotalData(
          data,
          "total_cases_disposed"
        ),
        total_cases_pending: calculatingTotalData(data, "total_cases_pending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "total_cases_disposed") /
            calculatingTotalData(data, "total_cases_filed")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "total_cases_disposed") /
                calculatingTotalData(data, "total_cases_filed")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Filter_District_Category_wise_case_type_report":
      // case_type_name_en,
      // commission_type_en,
      // case_category_name_en,
      // state_name_en,
      // commission_name_en,
      // total_cases_filed,
      // total_cases_disposed,
      // total_cases_pending,
      // disposal_rate,
      return {
        // commission_type_en:"",
        // commission_name_en: "",
        case_type_name_en: "Total",
        case_category_name_en: "",
        // state_name_en: "",
        total_cases_filed: calculatingTotalData(data, "total_cases_filed"),
        total_cases_disposed: calculatingTotalData(
          data,
          "total_cases_disposed"
        ),
        total_cases_pending: calculatingTotalData(data, "total_cases_pending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "total_cases_disposed") /
            calculatingTotalData(data, "total_cases_filed")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "total_cases_disposed") /
                calculatingTotalData(data, "total_cases_filed")) *
              100
            )
              .toString()
              .slice(0, 5),
      };
    case "Bench_Wise_Report":
      Object.keys(data[0]).map((curEle) => {
        return `${curEle}: ""`;
      });
      break;
    case "JudgesWiseReport":
      return {
        state_name_en: "Total",
        commission_name_en: "",
        judges_name_en: "",
        no_of_cases: calculatingTotalData(data, "no_of_cases"),
        no_of_hearings: calculatingTotalData(data, "no_of_hearings"),
        no_of_judgement: calculatingTotalData(data, "no_of_judgement"),
        no_of_final_order: calculatingTotalData(data, "no_of_final_order"),
        no_of_daily_Order: calculatingTotalData(data, "no_of_daily_Order"),
        no_of_finalJudgement: calculatingTotalData(
          data,
          "no_of_finalJudgement"
        ),
      };
    case "stateDistrictDuration":
      return {
        applicableYear: "Total",
        stateName: "",
        districtName: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicSelectedStateDistrictDuration":
      return {
        state_name_en: "Total",
        commission_name_en: "",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicSelectedStateDistrictDurationCategoryCaseType":
      return {
        commissionNameEn: "Total",
        caseCategoryNameEn: "",
        caseTypeNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rates: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicDcdrcStateDiscrictDurationCategory":
      return {
        commissionNameEn: "Total",
        caseCategoryNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "scdrcStateDurationCategoryCaseType":
      return {
        caseCategoryNameEn: "Total",
        caseTypeNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicDcdrcStateDiscrictDurationCaseType":
      return {
        stateNameEn: "Total",
        commissionNameEn: "",
        caseTypeNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DcdrcStateDistrictDurationCaseType":
      return {
        commissionNameEn: "Total",
        caseTypeNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicScdrcStateDurationCategory":
      return {
        caseCategoryNameEn: "Total",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicScdrcStateDuration":
      return {
        state_name_en: "Total",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicNcdrcDuration":
      return {
        commission_type_en: "Total",
        totalcasesfiled: calculatingTotalData(data, "totalcasesfiled"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposalrate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "totalcasesfiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalcasesdisposed") /
                calculatingTotalData(data, "totalcasesfiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicNcdrcDurationCategory":
      return {
        caseCategoryNameEn: "Total",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicNcdrcDurationCaseType":
      return {
        caseTypeNameEn: "Total",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "DynamicNcdrcDurationCaseTypeCategory":
      return {
        caseCategoryNameEn: "Total",
        caseTypeNameEn: "",
        totalCasesFiled: calculatingTotalData(data, "totalCasesFiled"),
        totalCasesDisposed: calculatingTotalData(data, "totalCasesDisposed"),
        totalCasesPending: calculatingTotalData(data, "totalCasesPending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalCasesDisposed") /
            calculatingTotalData(data, "totalCasesFiled")) *
            100
        )
          ? 0
          : (
              (calculatingTotalData(data, "totalCasesDisposed") /
                calculatingTotalData(data, "totalCasesFiled")) *
              100
            )
              .toString()
              .slice(0, 4),
      };
    case "NcdrcjudgewiseReport":
      return {
        judges_name_en: "",
        case_number: "",
        old_case_number: "",
        complainant_name: "",
        respondent_name: "",
        dateofhearing: "",
        nextdateofhearing: "",
        hearingsequencenumber: "",
        court_name: "",
        stage_name: "",
      }
    case "details_Of_Judges_Cases_Details":
      return{
        judges_name_en: "Total",
        casesheared: calculatingTotalData(data, "casesheared"),
        totalcasesdisposed: calculatingTotalData(data, "totalcasesdisposed"),
        totalcasespending: calculatingTotalData(data, "totalcasespending"),
        disposal_rate: isNaN(
          (calculatingTotalData(data, "totalcasesdisposed") /
            calculatingTotalData(data, "casesheared")) *
            100
        )
          ? 0
          : ((calculatingTotalData(data, "totalcasesdisposed") /
              calculatingTotalData(data, "casesheared")) *
            100).toString().slice(0,4)
      }

    default:
  }
};



