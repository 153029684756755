export const ROOT = "/";
// Common urls
export const REGISTER = "/register";
export const LOGIN = "/login";
export const FORGOTPASSWORD = "/forgot-password";
export const ABOUT_EDAAKHIL = "/about-ejagriti";
export const CASE_HISTORY_CASE_STATUS = "/case-history-case-status";
export const JUDGEMENT = "/judgement";
export const CASE_STATUS = "/case-status";
export const CAUSE_LIST = "/cause-list";
export const DISPLAY_BOARD = "/display-board";
export const TUTORIALS = "/tutorials";
export const JUDGESPUBLIC = "/judges";
export const TERMSOFSERVICE = "/terms-of-service";
export const PRIVACYPOLICY = "/privacy-policy";
export const COMPLETE_PROFILE = "/complete-profile";
export const REPORTS = "/reports";
export const UNDERPROGRESS = "/under-progress";
export const CONTROL_VIEW = "/controls";
export const UNAUTHORIZED = "/unauthorized";
export const NOT_FOUND = "/404";
export const EDITPROFILE = "/edit-profile";
export const DOWNLOAD_REPORTS_WITH_QR = "/download-reports-pdf";
export const WEBSITE_POLICIES = "/website-policies";
export const HELP = "/help";
export const FAQ = "/faq";
export const DISCLAIMER = "/disclaimer";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const FEEDBACK = "/feedback";

// Consumer Routes
export const CONSUMER = "/consumer";
export const CONSUMER_EDITPROFILE = "/consumer/edit-profile";
export const CONSUMER_DASHBOARD = "/consumer/dashboard";
export const CONSUMER_FILED_CASES = "/consumer/filed-cases";
export const CONSUMER_DISPOSED_CASES = "/consumer/disposed-cases";
export const CONSUMER_PENDING_CASES = "/consumer/pending-cases";
export const CONSUMER_PENDINGFORAPPROVAL_CASES =
	"/consumer/pending-for-approval";
export const CONSUMER_DRAFT_CASES = "/consumer/draft-cases";
export const CONSUMER_PENDINGFORSCRUTINY_CASES =
	"/consumer/pending-for-scrutiny-cases";
export const CONSUMER_REVERTEDINSCRUTINY_CASES =
	"/consumer/reverted-in-scrutiny-cases";
export const CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW =
	"/consumer/reverted-case-preview";
export const CONSUMER_PAYMENTPENDING_CASES = "/consumer/payment-pending-cases";
export const CONSUMER_CASELIST_PREVIEW = "/consumer/case-preview";
export const CONSUMER_CASEFILING = "/consumer/case-filing";
export const CONSUMER_FILENEWCASE_STEPPERFORM =
	"/consumer/case-filing/new-case-form";
export const CONSUMER_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM =
	"/consumer/case-filing/new-case-form-speech";
export const CONSUMER_FILENEWCASE_PREVIEW = "/consumer/case-filing/preview";
export const CONSUMER_APPEAL_TYPE_CASE_STEPPERFORM =
	"/consumer/case-filing/appeal-type-case-form";
export const CONSUMER_APPEAL_TYPE_CASE_PREVIEW =
	"/consumer/case-filing/appeal-type-preview";
export const CONSUMER_APPEAL_TYPE_CASE_REVERTED_PREVIEW =
	"/consumer/case-filing/appeal-type-reverted-preview";
export const CONSUMER_INTERLOCUTORY_TYPE_CASE_STEPPERFORM =
	"/consumer/case-filing/interlocutory-type-case-form";
export const CONSUMER_INTERLOCUTORY_TYPE_CASE_PREVIEW =
	"/consumer/case-filing/interlocutory-type-preview";
export const CONSUMER_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW =
	"/consumer/case-filing/interlocutory-type-reverted-preview";
export const CONSUMER_MISCELLANEOUS_TYPE_CASE_STEPPERFORM =
	"/consumer/case-filing/miscellaneous-type-case-form";
export const CONSUMER_MISCELLANEOUS_TYPE_CASE_PREVIEW =
	"/consumer/case-filing/miscellaneous-type-preview";
export const CONSUMER_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW =
	"/consumer/case-filing/miscellaneous-type-reverted-preview";
export const CONSUMER_TRANSFER_TYPE_CASE_STEPPERFORM =
	"/consumer/case-filing/transfer-type-case-form";
export const CONSUMER_PAYMENT_HISTORY = "/consumer/payment-history";
export const CONSUMER_PAYMENT_SUCCESS = "/consumer/payment-history/paymentSuccess";
export const CONSUMER_PAYMENT_FAILURE = "/consumer/payment-history/paymentFailure";
export const CONSUMER_CASE_HISTORY = "/consumer/case-history";
export const CONSUMER_REPLY = "/consumer/reply";
export const CONSUMER_UPLOADREPLYDOCUMENTS =
	"/consumer/reply/upload-reply-documents";
export const CONSUMER_REPLYDOCUMENTS = "/consumer/reply/reply-documents";

export const CONSUMER_REJOINDER = "/consumer/rejoinder";
export const CONSUMER_UPLOADREJOINDERDOCUMENTS =
	"/consumer/rejoinder/upload-rejoinder-documents";
export const CONSUMER_REJOINDERDOCUMENTS =
	"/consumer/rejoinder/rejoinder-documents";

export const CONSUMER_SUBSEQUENT_FILING = "/consumer/subsequent-filing";
export const CONSUMER_UPLOADSUBSEQUENTDOCUMENTS =
	"/consumer/subsequent-filing/upload-subsequent-documents";
export const CONSUMER_SUBSEQUENTDOCUMENTS =
	"/consumer/subsequent-filing/subsequent-documents";

export const CONSUMER_ADD_AS_PARTY = "/consumer/add-as-party";
export const CONSUMER_KNOW_YOUR_CASE = "/consumer/know-your-case";
export const CONSUMER_AGAINST_ME_FILED_CASES = "/consumer/against-me-filed-cases";
export const CONSUMER_AGAINST_ME_DISPOSED_CASES = "/consumer/against-me-disposed-cases";
export const CONSUMER_AGAINST_ME_PENDING_CASES = "/consumer/against-me-pending-cases";

// Commision Routes
export const COMMISSION = "/commission";
export const COMMISSION_EDITPROFILE = "/commission/edit-profile";
export const COMMISSION_DASHBOARD = "/commission/dashboard";
export const COMMISSION_PENDING_FOR_SCRUTINY = "/commission/scrutiny";
export const ADD_ADVOCATE = "/commission/add-advocate";
export const ADVOCATE_LIST = "/commission/advocate-list";
export const ADD_RESPONDENTADVOCATE_LIST = "/commission/RespondentAdvocate";
export const COMMISSION_PENDING_FOR_SCRUTINY_STEPPERFORM =
	"/commission/scrutiny/case-form";
export const COMMISSION_PENDING_FOR_APPEAL_TYPE_SCRUTINY_STEPPERFORM =
	"/commission/scrutiny/appeal-type-case-form";
export const COMMISSION_PENDING_FOR_INTERLOCUTORY_TYPE_SCRUTINY_STEPPERFORM =
	"/commission/scrutiny/interlocutory-type-case-form";
export const COMMISSION_PENDING_FOR_MISCELLANEOUS_TYPE_SCRUTINY_STEPPERFORM =
	"/commission/scrutiny/miscellaneous-type-case-form";
export const COMMISSION_PENDING_FOR_TRANSFER_APPLICATION_TYPE_SCRUTINY_STEPPERFORM =
	"/commission/scrutiny/transfer-application-type-case-form";
export const COMMISSION_REVERTEDINSCRUTINY_CASES =
	"/commission/reverted-in-scrutiny-cases";
export const COMMISSION_PENDING_PAYMENT_CASES =
	"/commission/pending-payment-cases";
export const COMMISSION_CASE_PREVIEW = "/commission/case-preview";
export const COMMISSION_COURTROOM_HEARING = "/commission/courtroom-hearing";
export const COMMISSION_COURTROOM_MODIFICATION =
	"/commission/courtroom-modification";
export const COMMISSION_COURTROOM_HEARING_DETAILS =
	"/courtroom-hearing/details";
export const COMMISSION_PENDING_FOR_APPROVAL =
	"/commission/pending-for-approval";
export const COMMISSION_PENDING_FOR_APPROVAL_VIEW =
	"/commission/pending-for-approval/case-detail";
export const COMMISSION_ADD_PRESIDENT_MEMBER =
	"/commission/add-president-member";
export const COMMISSION_ADD_NEW_COMMISSION_USER =
	"/commission/add-new-commission-user";
export const COMMISSION_ADD_NEW_MEDIATOR = "/commission/add-new-mediator";
export const COMMISSION_PRESIDENT_MEMBER_LIST =
	"/commission/president-member-list";
export const COMMISSION_COMMISSION_USERS_LIST =
	"/commission/commission-users-list";
export const COMMISSION_MEDIATORS_LIST = "/commission/mediators-list";

export const COMMISSION_DAILY_ORDER_ENTRY = "/commission/daily-order-entry";
export const COMMISSION_DAILY_ORDER_VIEW = "/commission/daily-order-view";
export const COMMISSION_UPLOAD_TYPE_ORDER =
	"/commission/daily-order-entry/upload-type-order";
export const COMMISSION_JUDGMENT_UPLOAD = "/commission/judgment-upload";
export const COMMISSION_JUDGMENT_PROCEEDING = "/commission/judgment-proceeding";
export const COMMISSION_UPLOAD_TYPE_JUDGMENT =
	"/commission/judgment-upload/upload-type-judgment";
export const COMMISSION_ISSUE_PRINT_NOTICE = "/commission/issue-print-notice";
export const COMMISSION_GENERATE_NOTICE = "/commission/generate-notice";
export const COMMISSION_EDIT_NOTICE = "/commission/edit-notice";
export const COMMISSION_FINALIZE_DE_FINALIZE_ORDER_JUDGEMENT =
	"/commission/finalize-de-finalize-order-judgement";
export const COMMISSION_MEDIATION_CENTER = "/commission/mediation-center";
export const COMMISSION_ASSIGN_CASE =
	"/commission/mediation-center/assign-case-to-mediator-center";
export const COMMISSION_CASE_TRANSFER = "/commission/case-transfer";
export const COMMISSION_SHIFT_ADJOURN_CASE = "/commission/shift-adjourn-case";
export const COMMISSION_ADD_NOTICE_TEMPLATE = "/commission/notice-template";
export const COMMISSION_HISTORY_AND_RECORDS = "/commission/history-and-records";
export const COMMISSION_ADD_REMOVE_BUNCH_CASES =
	"/commission/add-remove-bunch-cases";
export const COMMISSION_SET_CAUSELIST_TYPE_ORDER =
	"/commission/set-causelist-type-order";
export const COMMISSION_ADD_MODIFY_COMPLAINANT_RESPONDENT_DOCUMENTS =
	"/commission/add-modify-complainant-respondent-documents";
export const COMMISSION_ADD_MODIFY_COMPLAINANT_RESPONDENT_DOCUMENTS_CASE_DETAIL =
	"/commission/add-modify-complainant-respondent-documents/case-detail";
export const COMMISSION_ADD_COMPLAINANT_DETAILS =
	"/commission/add-modify-complainant-respondent-documents/case-detail/add-complainant-details";
export const COMMISSION_SHOW_HIDE_CASE = "/commission/show-hide-case";
export const COMMISSION_RESTORE_CASE = "/commission/restore-case";
export const COMMISSION_CAUSELIST = "/commission/causelist";

export const COMMISSION_REPLY = "/commission/reply";
export const COMMISSION_UPLOADREPLYDOCUMENTS =
	"/commission/reply/upload-reply-documents";
export const COMMISSION_REPLYDOCUMENTS = "/commission/reply/reply-documents";

export const COMMISSION_REJOINDER = "/commission/rejoinder";
export const COMMISSION_UPLOADREJOINDERDOCUMENTS =
	"/commission/rejoinder/upload-rejoinder-documents";
export const COMMISSION_REJOINDERDOCUMENTS =
	"/commission/rejoinder/rejoinder-documents";

export const COMMISSION_SUBSEQUENT_FILING = "/commission/subsequent-filing";
export const COMMISSION_UPLOADSUBSEQUENTDOCUMENTS =
	"/commission/subsequent-filing/upload-subsequent-documents";
export const COMMISSION_SUBSEQUENTDOCUMENTS =
	"/commission/subsequent-filing/subsequent-documents";

export const COMMISSION_DISPLAYBOARD = "/commission/display-board";
export const FIND_CASEDETAILS = "/commission/find-Case-Details"
export const CASEDETAILS = "/commission/Case-Details"
export const PAYMENT_RECONCILIATION = "/commission/payment-reconciliation";

export const COMMISSION_CONSOLIDATED_FILES = "/commission/consolidated-files";
export const COMMISSION_CASE_FINALIZE = "/commission/case-finalize";
export const COMMISSION_CASE_DEFINALIZE = "/commission/case-definalize";
export const COMMISSION_DAILY_ORDER_FINALIZE = "/commission/daily-order-finalize";
export const COMMISSION_DAILY_ORDER_DEFINALIZE = "/commission/daily-order-definalize";
export const COMMISSION_JUDGEMENT_FINALIZE = "/commission/judgement-finalize";
export const COMMISSION_JUDGEMENT_DEFINALIZE = "/commission/judgement-definalize";

export const COMMISSION_SETTING_FILES = "/commission/settings-files"
export const COMMISSION_SETTING_USER = "/commission/settings/user"
export const COMMISSION_SETTING_ROLES = "/commission/settings/roles"
export const COMMISSION_SETTING_MODULES = "/commission/settings/modules"
export const COMMISSION_SETTING_USER_ROLE_ALLOCATION = "/commission/settings/user-role-allocation"


// Judge Routes
export const JUDGE = "/judge";
export const JUDGE_EDITPROFILE = "/judge/edit-profile";
export const JUDGE_DASHBOARD = "/judge/dashboard";
export const JUDGE_COURTROOM_HEARING = "/judge/courtroom-hearing-list-judge";
export const JUDGE_COURTROOM_MODIFICATION =
	"/judge/courtroom-modification";
export const JUDGE_COURTROOM_HEARING_DETAILS =
	"/courtroom-hearing-list-judge/details";
export const JUDGE_DAILY_ORDER_ENTRY = "/judge/daily-order-entry";
export const JUDGE_DAILY_ORDER_VIEW = "/judge/daily-order-view";
export const JUDGE_UPLOAD_TYPE_ORDER =
	"/judge/daily-order-entry/upload-type-order";
export const JUDGE_JUDGMENT_UPLOAD = "/judge/judgment-upload";
export const JUDGE_JUDGMENT_PROCEEDING = "/judge/judgment-proceeding";
export const JUDGE_UPLOAD_TYPE_JUDGMENT =
	"/judge/judgment-upload/upload-type-judgment";
export const JUDGE_FIND_CASEDETAILS = "/judge/find-Case-Details"
export const JUDGE_CASEDETAILS = "/judge/Case-Details";
export const JUDGE_CONSOLIDATED_FILES = "/judge/consolidated-files";
export const JUDGE_IMPORTANT_NOTES = "judge/important-notes";
export const JUDGE_CAUSELIST = "/judge/causelist";
export const JUDGE_CASE_HISTORY = "/judge/case-history"

//Dealing Assistant
export const DEALINGASSISTANT = "/dealingAssistant";
export const DEALING_ASSISTANT_EDITPROFILE = "/dealingAssistant/edit-profile";
export const DEALING_ASSISTANT_DASHBOARD = "/dealingAssistant/dashboard";
export const DEALING_ASSISTANT_CASETRANSFER = "/dealingAssistant/case-trasnfer";
export const DEALING_ASSISTANT_ADDREMOVEBUNCH = "/dealingAssistant/add-remove- bunch";
export const DEALING_ASSISTANT_ADDMODIFYCOMPLAINT = "/dealingAssistant/add-modify-complaint";
export const DEALING_ASSISTANT_SHIFTADJOURN = "/dealingAssistant/shift-Adjourn";
export const DEALING_ASSISTANT_ISSUEPRINT = "/dealingAssistant/issue-print-notice";
export const DEALING_ASSISTANT_GENERATE_NOTICE = "/dealingAssistant/generate-notice";
export const DEALING_ASSISTANT_EDIT_NOTICE = "/dealingAssistant/edit-notice";

//CourtMaster
export const COURT = "/courtMaster";
export const COURT_EDITPROFILE = "/courtMaster/edit-profile";
export const COURT_MASTER_DASHBOARD = "/courtMaster/dashboard";
export const COURT_COURTROOM_HEARING = "/courtMaster/courtroom-hearing-list-courtMaster";
export const COURT_COURTROOM_HEARING_DETAILS =
	"/courtroom-hearing-list-courtMaster/details";
export const COURT_DAILY_ORDER_ENTRY = "/courtMaster/daily-order-entry";
export const COURT_DAILY_ORDER_ENTRY_UPLOAD = "/courtMaster/daily-order-entry/upload-type-order"
export const COURT_DAILY_ORDER_LIST = "/courtMaster/daily-order-view"
export const COURT_COUSELIST = "/courtMaster/causelist";
export const COURT_JUDGEMENT_UPLOAD = "/courtMaster/judgement-upload";
export const COURT_RESTORE_CASE = "/courtMaster/restore-Case";
export const COURT_SUBSEQUENT_FILING = "/courtMaster/subsequent-filing";
export const COURT__SUBSEQUENTDOCUMENTS = "/courtMaster/subsequent-filing/subsequent-documents";
export const COURT__UPLOADSUBSEQUENTDOCUMENTS = "/courtMaster/subsequent-filing/upload-subsequent-documents";

//DocaUser
export const DOCA = "/docaUser";
export const DOCA_EDITPROFILE = "/docaUser/edit-profile";
export const DOCA_USER_DASHBOARD = "/docaUser/dashboard";

// Advocate Routes
export const ADVOCATE = "/advocate";
export const ADVOCATE_DASHBOARD = "/advocate/dashboard";
export const ADVOCATE_EDITPROFILE = "/advocate/edit-profile";
export const ADVOCATE_FILED_CASES = "/advocate/filed-cases";
export const ADVOCATE_DISPOSED_CASES = "/advocate/disposed-cases";
export const ADVOCATE_PENDING_CASES = "/advocate/pending-cases";
export const ADVOCATE_PENDINGFORAPPROVAL_CASES =
	"/advocate/pending-for-approval";
export const ADVOCATE_DRAFT_CASES = "/advocate/draft-cases";
export const ADVOCATE_PENDINGFORSCRUTINY_CASES =
	"/advocate/pending-for-scrutiny-cases";
export const ADVOCATE_REVERTEDINSCRUTINY_CASES =
	"/advocate/reverted-in-scrutiny-cases";
export const ADVOCATE_FILENEWCASE_REVERTED_CASE_PREVIEW =
	"/advocate/reverted-case-preview";
export const ADVOCATE_PAYMENTPENDING_CASES = "/advocate/payment-pending-cases";
export const ADVOCATE_CASELIST_PREVIEW = "/advocate/case-preview";
export const ADVOCATE_CASEFILING = "/advocate/case-filing";
export const ADVOCATE_FILENEWCASE_STEPPERFORM =
	"/advocate/case-filing/new-case-form";
export const ADVOCATE_FILENEWCASE_PREVIEW = "/advocate/case-filing/preview";
export const ADVOCATE_APPEAL_TYPE_CASE_STEPPERFORM =
	"/advocate/case-filing/appeal-type-case-form";
export const ADVOCATE_APPEAL_TYPE_CASE_PREVIEW =
	"/advocate/case-filing/appeal-type-preview";
export const ADVOCATE_APPEAL_TYPE_CASE_REVERTED_PREVIEW =
	"/advocate/case-filing/appeal-type-reverted-preview";
export const ADVOCATE_INTERLOCUTORY_TYPE_CASE_STEPPERFORM =
	"/advocate/case-filing/interlocutory-type-case-form";
export const ADVOCATE_INTERLOCUTORY_TYPE_CASE_PREVIEW =
	"/advocate/case-filing/interlocutory-type-preview";
export const ADVOCATE_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW =
	"/advocate/case-filing/interlocutory-type-reverted-preview";
export const ADVOCATE_MISCELLANEOUS_TYPE_CASE_STEPPERFORM =
	"/advocate/case-filing/miscellaneous-type-case-form";
export const ADVOCATE_MISCELLANEOUS_TYPE_CASE_PREVIEW =
	"/advocate/case-filing/miscellaneous-type-preview";
export const ADVOCATE_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW =
	"/advocate/case-filing/miscellaneous-type-reverted-preview";
export const ADVOCATE_TRANSFER_TYPE_CASE_STEPPERFORM =
	"/advocate/case-filing/transfer-type-case-form";
export const ADVOCATE_PAYMENT_HISTORY = "/advocate/payment-history";
export const ADVOCATE_PAYMENT_SUCCESS = "/advocate/paymentSuccess";
export const ADVOCATE_PAYMENT_FAILURE = "/advocate/paymentFailure";
export const ADVOCATE_CASE_HISTORY = "/advocate/case-history";
export const ADVOCATE_REPLY = "/advocate/reply";
export const ADVOCATE_UPLOADREPLYDOCUMENTS =
	"/advocate/reply/upload-reply-documents";
export const ADVOCATE_REPLYDOCUMENTS = "/advocate/reply/reply-documents";

export const ADVOCATE_REJOINDER = "/advocate/rejoinder";
export const ADVOCATE_UPLOADREJOINDERDOCUMENTS =
	"/advocate/rejoinder/upload-rejoinder-documents";
export const ADVOCATE_REJOINDERDOCUMENTS =
	"/advocate/rejoinder/rejoinder-documents";

export const ADVOCATE_SUBSEQUENT_FILING = "/advocate/subsequent-filing";
export const ADVOCATE_UPLOADSUBSEQUENTDOCUMENTS =
	"/advocate/subsequent-filing/upload-subsequent-documents";
export const ADVOCATE_SUBSEQUENTDOCUMENTS =
	"/advocate/subsequent-filing/subsequent-documents";

export const ADVOCATE_ADD_AS_PARTY = "/advocate/add-as-party";
export const ADVOCATE_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM =
	"/advocate/case-filing/new-case-form-speech";
export const ADVOCATE_KNOW_YOUR_CASE = "/advocate/know-your-case";
export const ADVOCATE_AGAINST_ME_FILED_CASES = "/advocate/against-me-filed-cases";
export const ADVOCATE_AGAINST_ME_DISPOSED_CASES = "/advocate/against-me-disposed-cases";
export const ADVOCATE_AGAINST_ME_PENDING_CASES = "/advocate/against-me-pending-cases";


// Analytics Routes

export const ANALYTICS = "/analytics";
export const ANALYTICS_DASHBOARD = "/analytics/dashboard";
export const ANALYTICS_EDITPROFILE = "/analytics/edit-profile";
