import NewsPressRelease from "./news-pressrelease";
import "./news-and-spolight.scss"
import CarouselSpotlight from "./carousel.spotlight";
export default function NewsAndSpotlight() {
    return (
        <section className="news-spotlight-section cardBackgroundLeanear">
            <img className="backGroundImageBannerBottom" src="/images/lineSquire1.svg" alt="background Image" />
            <div className="news-spotlight-grid">
                <div className="news-spotlight-subgrid  section-padding">
                    {/* <h5 className="text-white">Stay Informed, Stay Empowered!</h5> */}
                    <h2 className="fw-bold text-white">Spotlight / <span className="theme-secondary">Highlight</span></h2>
                    <CarouselSpotlight />
                </div>
                <NewsPressRelease />
            </div>
        </section>
    )
}