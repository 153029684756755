import { DateTime } from "luxon";
import { IDropdown } from "../../common/IDropdown";

export class ICCPACaseDetailViewModel {
  constructor(
    public authority: IDropdown,
    public authorityId: number | null,
    public dateOfOrder: DateTime,
    public caseCategory: IDropdown,
    public subCategory: IDropdown,
    public subSubCategory: IDropdown,
    public caseDetails: string,
    public fillingReferenceNumber?: string,
    public caseTypeId?: number
  ) {
    this.authority = authority;
    this.authorityId = authorityId;
    this.dateOfOrder = dateOfOrder;
    this.caseCategory = caseCategory;
    this.subCategory = subCategory;
    this.subSubCategory = subSubCategory;
    this.caseDetails = caseDetails;
    this.fillingReferenceNumber = fillingReferenceNumber;
    this.caseTypeId = caseTypeId;
  }
  static init = () =>
    new ICCPACaseDetailViewModel(
      { label: "", value: "" },
      null,
      DateTime.now(),
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      ""
    );
}

export interface ICCPACaseDetailsModel {
  caseDetails: {
    caseFiledBy: number;
    caseCategoryId: number;
    caseTypeId: number;
    filingReferenceNumber?: number;
  };
  otherLegalBodyId: number;
  otherLegalBodyOrderDate: string;
  remarks: string;
  authorityId: number | null;
}

export interface ICCPACaseDetailsResponseModel {
  caseDetails: {
    filingReferenceNumber: number
    fileApplicationNumber: number
    caseNumber: any
    caseTypeId: number
    filedInCommissionId: number
    dateOfNextHearing: string
    claimAmount: number
    paidAmount: number
    compensationAmount: number
    stateIdOfPlaceOfCause: number
    districtIdOfPlaceOfCause: number
    dateOfCause: string
    previousFilingReferenceNumber: number
    destinationCommissionId: number
    admissionStatusId: number
    admissionDate: string
    caseStageId: number
    caseFilingFeePaymentDetailsId: number
    refundStatus: string
    caseFiledBy: number
    caseFilingDate: string
    caseCategoryId: number
    firstOriginalFilingReferenceNumber: number
    firstOriginalCaseNumber: number
    bunchCasesDetails: string
    sourceApplicationTypeId: number
    caseReasonTypeId: number
    updatedBy: number
    updatedAt: string
    remarks: string
    clientIpAddress: string
    createdAt: string
    activeStatus: boolean
    caseCategoryIdLevel02: number
    caseCategoryIdLevel01: number
    defectTypeId: number
  };
  otherLegalBodyId: number;
  otherLegalBodyOrderDate: string;
  authorityId: number;
  remarks: string;
}

export interface IGetCCPACaseDetailsResponse {
  filingReferenceNumber: number;
  caseCategoryIdLvl01: number;
  caseCategoryNameEnLvl01: string;
  caseCategoryIdLvl02: number;
  caseCategoryNameEnLvl02: string;
  caseCategoryIdLvl03: number;
  caseCategoryNameEnLvl03: string;
  filedInComissionId: any;
  remark: string;
  otherLegalBodyId: number;
  otherLegalBodyNameEn: string;
  otherLegalBodyOrderDate: string;
  authorityId: number;
}
