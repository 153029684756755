import { AxiosResponse } from "axios";
import { getCall } from "../api";
import { ANALYTICS_SERVICE } from "../CONSTANTS";
import {
  ICaseTypePendencyResponse,
  ICategoryListResponse,
  ICommissionWisePendencyResponse,
  IHighestDisposalStateResponse,
  IHighestFillingStateResponse,
  IStackedAreaChart,
  IStateDataResponse,
  ITotalDataResponse,
  IStackedBarChartGroup,
  OriginalDataItem,
  IDataEntry,
  IRadarChartData,
  IPendencyOverviewResponse,
  IDistrictDataResponse,
  IHighestFilingResponse,
  IStackedBarChartGroup2,
  ICaseTypes,
  DataPoint,
  MonthlyDataPoint,
  IncreaseDecreaseData,
  QuickestDisposalByCaseTypeData,
  RegionalTimeline,
  IPercentageChangeType,
  IStateDistrict,
  ICategories,
  ICaseStageFiled,
  IMapData,
  IPendingHeatmap,
  IGetCommisions,
  LongestPendency,
  IYearWise1to2y,
  IYearWise2to5y,
  IYearWise5to10y,
  IYearWise10to20y,
  IYearWise20to30y,
  IYearWise30plus,
  IYearWise1to2yPending,
  IYearWise2to5yPending,
  IYearWise10to20yPending,
  IYearWise20to30yPending,
  IYearWiseAbove30yPending,
  IDistrictMapData,
  IHighestFillingResponseTop10,
  IYearWise0to1y,
  IYearWise0to1yPending,
} from "../../models/anaylticsDashboard/reponseData";
import {
  IAPIPayload,
  IAPIWithCaseTypePayload,
  IDisposedPending,
  IStateDistrictListPayload,
  IStateDistrictListPayload2,
  ITopTenCommisionsBubbleChart,
  IHeatMapData,
  IMultiplePayloadData,
  IJudgesPayloadData,
} from "../../models/anaylticsDashboard/payload";

export class AnalyticsService {
  /**
   * Call an API to get the list of states
   */
  public static getTotalData = async (): Promise<ITotalDataResponse[]> => {
    const response: AxiosResponse<ITotalDataResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/total_filedDisposedPending_forAll`
    );
    return response?.data;
  };
  public static getTotalDataNCDRC = async (): Promise<ITotalDataResponse[]> => {
    const response: AxiosResponse<ITotalDataResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/fetchTotalData`
    );
    return response?.data;
  };

  public static getPercentageChangeData = async (): Promise<
    IPercentageChangeType[]
  > => {
    const response: AxiosResponse<IPercentageChangeType[]> = await getCall(
      `${ANALYTICS_SERVICE}/percentageChangeComparedToLastYear`
    );
    return response?.data;
  };

  public static getComparisonData = async (): Promise<MonthlyDataPoint[]> => {
    const response: AxiosResponse<MonthlyDataPoint[]> = await getCall(
      `${ANALYTICS_SERVICE}/yoyMonthlyComparison`
    );
    return response?.data;
  };
  /**
   * Call an API to get the list of states
   @param StateDistrictListPayload: data
   */
  public static getStateList = async (): Promise<IStateDataResponse[]> => {
    const response: AxiosResponse<IStateDataResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/stateDropdown_NCDRClogin`
    );
    return response?.data;
  };

  /**
   * Call an API to get the list of district
   @param StateDistrictListPayload : data
   */
  public static getDistrictList = async (
    data: IStateDistrictListPayload
  ): Promise<IDistrictDataResponse[]> => {
    const response: AxiosResponse<IDistrictDataResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/district_dropdown_query_NCDRClogin`,
      data
    );
    return response?.data;
  };
  /**
   * Call an API to get the list of categories
   */
  public static getCategory = async (): Promise<ICategoryListResponse[]> => {
    const response: AxiosResponse<ICategoryListResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/category_dropdown_query_forAll`
    );

    return response?.data;
  };

  public static getTotalBasedOnSelection = async (
    data: IAPIPayload
  ): Promise<ITotalDataResponse[]> => {
    const response: AxiosResponse<ITotalDataResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/total_consolidatedData`,
      data
    );
    return response?.data;
  };
  /**
   * Call an API to get the highest fillings in State, Commission, Sector
   @param data
   */
  public static getHighestFillingState = async (
    data: IAPIPayload
  ): Promise<IHighestFillingStateResponse[]> => {
    const response: AxiosResponse<IHighestFillingStateResponse[]> =
      await getCall(`${ANALYTICS_SERVICE}/highest_filling_state`, data);

    return response?.data;
  };

  public static getHighestFillingCommission = async (
    data: IAPIPayload
  ): Promise<IHighestFillingResponseTop10[]> => {
    const response: AxiosResponse<IHighestFillingResponseTop10[]> =
      await getCall(
        `${ANALYTICS_SERVICE}/commissions_with_highestFilingCounts?region=NULL`,
        data
      );
    if (response?.data && response.data.length > 0) {
      const first = [response.data[0]];
      return first;
    } else {
      return [];
    }
  };

  public static getHighestFillingSector = async (
    data: IAPIPayload
  ): Promise<IHighestFillingStateResponse[]> => {
    const response: AxiosResponse<IHighestFillingStateResponse[]> =
      await getCall(`${ANALYTICS_SERVICE}/top_caseFilling_sector`, data);

    return response?.data;
  };
  /**
   * Call an API to get the highest disposal rate in State, Commission, Sector
   @param data
   */
  public static getDisposalRateState = async (
    data: IAPIPayload
  ): Promise<IHighestDisposalStateResponse[]> => {
    const response: AxiosResponse<IHighestDisposalStateResponse[]> =
      await getCall(`${ANALYTICS_SERVICE}/highest_disposal_rate_state`, data);
    return response?.data;
  };

  public static getDisposalRateCommission = async (
    data: IAPIPayload
  ): Promise<IHighestDisposalStateResponse[]> => {
    const response: AxiosResponse<IHighestDisposalStateResponse[]> =
      await getCall(`${ANALYTICS_SERVICE}/top_DisposalRate_commission`, data);
    return response?.data;
  };

  public static getDisposalRateSector = async (
    data: IAPIPayload
  ): Promise<IHighestFillingStateResponse[]> => {
    const response: AxiosResponse<IHighestFillingStateResponse[]> =
      await getCall(
        `${ANALYTICS_SERVICE}/highest_disposalRate_with_filledDisposedCount_sector`,
        data
      );
    return response?.data;
  };

  /**
   * Call an API to get the casetype pendency data
   */
  public static getCommissionWisePendency = async (
    data?: IAPIPayload
  ): Promise<ICommissionWisePendencyResponse[]> => {
    const response: AxiosResponse<ICommissionWisePendencyResponse[]> =
      await getCall(`${ANALYTICS_SERVICE}/commissionwise_pendency?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`, data);
    return response?.data;
  };

  /**
   * Call an API to get the casetype pendency data
   */
  public static getCaseTypePendency = async (
    data: IAPIWithCaseTypePayload
  ): Promise<ICaseTypePendencyResponse[]> => {
    const response: AxiosResponse<ICaseTypePendencyResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/casetype_filledDisposedPending?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );
    return response?.data;
  };

  /**
   * Call an API to get top 10 highest filing commissions
   */
  public static getTop10HighestFilingCommissions = async (
    data: ITopTenCommisionsBubbleChart
  ): Promise<IHighestFillingResponseTop10[]> => {
    const response: AxiosResponse<IHighestFillingResponseTop10[]> =
      await getCall(
        `${ANALYTICS_SERVICE}/commissions_with_highestFilingCounts`,
        data
      );
    return response?.data;
  };

  /**
   * Call an API to get top 10 highest disposal rate commissions
   */
  public static getTop10HighestDisposalRateCommissions = async (
    data: IAPIPayload
  ): Promise<IHighestDisposalStateResponse[]> => {
    const response: AxiosResponse<IHighestDisposalStateResponse[]> =
      await getCall(
        `${ANALYTICS_SERVICE}/highestDisposalRate_commission`,
        data
      );
    return response?.data;
  };

  /**
   * Call an API to get the list of categories
   */

  public static getTopTenHighestFiledSectors = async (
    data: IAPIPayload
  ): Promise<IStackedAreaChart[]> => {
    const response: AxiosResponse<IStackedAreaChart[]> = await getCall(
      `${ANALYTICS_SERVICE}/highestFilling_inCommissions_acrossSectors?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );
    return response?.data;
  };

  public static getHighestFilingSectors = async (
    data: IAPIPayload
  ): Promise<IHighestFilingResponse[]> => {
    const response: AxiosResponse<IHighestFilingResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/highestFilling_inCommissions_acrossSectors?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );
    return response?.data.slice(0, 5);
  };
  /**
   * Call an API to get the list of categories
   */

  public static getPendencyByAgeGroup = async (
    data: IAPIWithCaseTypePayload
  ): Promise<IStackedBarChartGroup[]> => {
    const response: AxiosResponse<IStackedBarChartGroup[]> = await getCall(
      `${ANALYTICS_SERVICE}/agewise_pendency_onCasetype`,
      data
    );
    return response?.data;
  };

  public static getDisposalByAgeGroup = async (
    data: IAPIWithCaseTypePayload
  ): Promise<IStackedBarChartGroup2[]> => {
    const response: AxiosResponse<IStackedBarChartGroup2[]> = await getCall(
      `${ANALYTICS_SERVICE}/agewise_disposal_onCasetype`,
      data
    );
    return response?.data;
  };
  /**
   * Call an API to get the list of categories
   */

  public static getPendingPercentageModel = async (
    data: IAPIPayload
  ): Promise<OriginalDataItem[]> => {
    const response: AxiosResponse<OriginalDataItem[]> = await getCall(
      `${ANALYTICS_SERVICE}/pendingPercentage_severityLevel`,
      data
    );
    return response?.data;
  };

  /**
   * Call an API to get the list of categories
   */

  /**
   * Call an API to get the list of categories
   */
  public static getHighestFillingSectors = async (): Promise<IDataEntry[]> => {
    const response: AxiosResponse<IDataEntry[]> = await getCall(
      `${ANALYTICS_SERVICE}/highestCaseFilling_sectors?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`
    );
    return response?.data;
  };

  public static getPendencyOverview = async (
    data: IAPIPayload
  ): Promise<IPendencyOverviewResponse[]> => {
    const response: AxiosResponse<IPendencyOverviewResponse[]> = await getCall(
      `${ANALYTICS_SERVICE}/statewisePendencyOverview`,
      data
    );

    return response?.data;
  };

  public static getRadarData = async (
    data: IAPIPayload
  ): Promise<IRadarChartData[]> => {
    const response: AxiosResponse<IRadarChartData[]> = await getCall(
      `${ANALYTICS_SERVICE}/regionAndState_selection?fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );

    return response?.data;
  };

  public static getSunburstData = async (
    data?: IAPIPayload
  ): Promise<IDataEntry[]> => {
    const response: AxiosResponse<IDataEntry[]> = await getCall(
      `${ANALYTICS_SERVICE}/sectoral_statewiseDistributin?parentcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );

    return response?.data;
  };

  public static getCaseTypes = async (): Promise<ICaseTypes[]> => {
    const response: AxiosResponse<ICaseTypes[]> = await getCall(
      `${ANALYTICS_SERVICE}/caseTypeDropdown`
    );
    return response?.data;
  };

  public static getYearlyDisposed = async (
    data?: IAPIPayload
  ): Promise<DataPoint[]> => {
    const response: AxiosResponse<DataPoint[]> = await getCall(
      `${ANALYTICS_SERVICE}/pendencyWithinPeriodYearflag?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );
    return response?.data;
  };

  public static getMonthlyDisposed = async (
    data?: IAPIPayload
  ): Promise<MonthlyDataPoint[]> => {
    const response: AxiosResponse<MonthlyDataPoint[]> = await getCall(
      `${ANALYTICS_SERVICE}/pendencyWithinPeriodMonthflag?parentcommissionid=0&childcommissionid=0&fromdate=NULL&todate=NULL&categoryid=NULL`,
      data
    );
    return response?.data;
  };

  public static getIncreaseDecreaseValues = async (): Promise<
    IncreaseDecreaseData[]
  > => {
    const response: AxiosResponse<IncreaseDecreaseData[]> = await getCall(
      `${ANALYTICS_SERVICE}/comparisonGraphFor5Years?state_id=NULL&district_id=NULL&category_id=NULL`
    );
    return response?.data;
  };

  public static getQuickestDisposalByCaseType = async (
    data: IAPIPayload
  ): Promise<QuickestDisposalByCaseTypeData[]> => {
    const response: AxiosResponse<QuickestDisposalByCaseTypeData[]> =
      await getCall(
        `${ANALYTICS_SERVICE}/quickestDisposals_casetypeNamewise_new`,
        data
      );
    return response?.data;
  };

  public static getLongestPendencyByCaseType = async (
    data: IAPIPayload
  ): Promise<LongestPendency[]> => {
    const response: AxiosResponse<LongestPendency[]> = await getCall(
      `${ANALYTICS_SERVICE}/PendencyProprtion_10years_byCaseType`,
      data
    );
    return response?.data;
  };

  public static getRegionalTimelineYearlyStats = async (): Promise<
    RegionalTimeline[]
  > => {
    const response: AxiosResponse<RegionalTimeline[]> = await getCall(
      `${ANALYTICS_SERVICE}/regionalTimeline_yearlyStats`
    );
    return response?.data;
  };

  public static getMapData = async (): Promise<IMapData[]> => {
    const response: AxiosResponse<IMapData[]> = await getCall(
      `${ANALYTICS_SERVICE}/filedDisposedPendingCount_forStates_onMaps`
    );
    return response?.data;
  };

  public static getDistrictMapData = async (
    data: number
  ): Promise<IDistrictMapData[]> => {
    const response: AxiosResponse<IDistrictMapData[]> = await getCall(
      `${ANALYTICS_SERVICE}/filedDisposedPendingCount_forDistricts_onMaps?state_id=${data}`
    );
    return response?.data;
  };

  public static getStates = async (): Promise<IStateDistrict[]> => {
    const response: AxiosResponse<IStateDistrict[]> = await getCall(
      `${ANALYTICS_SERVICE}/stateDistrictSelection?commissionflag=S&parentcommissionid=0`
    );
    return response?.data;
  };

  public static getDistrict = async (
    data: IStateDistrictListPayload2
  ): Promise<IStateDistrict[]> => {
    const response: AxiosResponse<IStateDistrict[]> = await getCall(
      `${ANALYTICS_SERVICE}/stateDistrictSelection?commissionflag=D&`,
      data
    );
    return response?.data;
  };

  public static getCategoryList = async (): Promise<
    AxiosResponse<ICategories[]>
  > => {
    const response: AxiosResponse<ICategories[]> = await getCall(
      `${ANALYTICS_SERVICE}/categoryIdDropdown`
    );

    return response;
  };

  public static getCaseStagePending = async (
    data: IMultiplePayloadData
  ): Promise<ICaseStageFiled[]> => {
    const response: AxiosResponse<ICaseStageFiled[]> = await getCall(
      `${ANALYTICS_SERVICE}/casestage_pendingProportion`,
      data
    );
    return response?.data;
  };

  public static getCaseStageDisposed = async (
    data: IMultiplePayloadData
  ): Promise<ICaseStageFiled[]> => {
    const response: AxiosResponse<ICaseStageFiled[]> = await getCall(
      `${ANALYTICS_SERVICE}/casestage_disposalProportion`,
      data
    );
    return response?.data;
  };

  public static getPendingHeatMapData = async (
    data: IHeatMapData
  ): Promise<IPendingHeatmap[]> => {
    const response: AxiosResponse<IPendingHeatmap[]> = await getCall(
      `${ANALYTICS_SERVICE}/categoryYearPeriodwisePending`,
      data
    );
    return response?.data;
  };

  public static getDisposedHeatMapData = async (
    data: IHeatMapData
  ): Promise<IPendingHeatmap[]> => {
    const response: AxiosResponse<IPendingHeatmap[]> = await getCall(
      `${ANALYTICS_SERVICE}/categoryDisposalPeriodwise`,
      data
    );
    return response?.data;
  };

  public static getNoOfCommisions = async (): Promise<IGetCommisions[]> => {
    const response: AxiosResponse<IGetCommisions[]> = await getCall(
      `${ANALYTICS_SERVICE}/number_of_commissions`
    );
    return response?.data;
  };

  public static getYearWise0to1yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise0to1y[]> => {
    const response: AxiosResponse<IYearWise0to1y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal0to1y`,
      data
    );
    return response?.data;
  };

  public static getYearWise1to2yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise1to2y[]> => {
    const response: AxiosResponse<IYearWise1to2y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal1to2y`,
      data
    );
    return response?.data;
  };

  public static getYearWise2to5yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise2to5y[]> => {
    const response: AxiosResponse<IYearWise2to5y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal2to5y`,
      data
    );
    return response?.data;
  };

  public static getYearWise5to10yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise5to10y[]> => {
    const response: AxiosResponse<IYearWise5to10y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal5to10y`,
      data
    );
    return response?.data;
  };

  public static getYearWise10to20yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise10to20y[]> => {
    const response: AxiosResponse<IYearWise10to20y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal10to20y`,
      data
    );
    return response?.data;
  };

  public static getYearWise20to30yDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise20to30y[]> => {
    const response: AxiosResponse<IYearWise20to30y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposal20to30y`,
      data
    );
    return response?.data;
  };

  public static getYearWise30yPlusDisposal = async (
    data: IDisposedPending
  ): Promise<IYearWise30plus[]> => {
    const response: AxiosResponse<IYearWise30plus[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyDisposalAbove30y`,
      data
    );
    return response?.data;
  };

  //

  public static getYearWise0to1yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise0to1yPending[]> => {
    const response: AxiosResponse<IYearWise0to1yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency0to1y`,
      data
    );
    return response?.data;
  };

  public static getYearWise1to2yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise1to2yPending[]> => {
    const response: AxiosResponse<IYearWise1to2yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency1to2y`,
      data
    );
    return response?.data;
  };

  public static getYearWise2to5yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise2to5yPending[]> => {
    const response: AxiosResponse<IYearWise2to5yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency2to5y?`,
      data
    );
    return response?.data;
  };

  public static getYearWise5to10yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise1to2y[]> => {
    const response: AxiosResponse<IYearWise1to2y[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency5to10y`,
      data
    );
    return response?.data;
  };

  public static getYearWise10to20yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise10to20yPending[]> => {
    const response: AxiosResponse<IYearWise10to20yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency10to20y`,
      data
    );
    return response?.data;
  };

  public static getYearWise20to30yPending = async (
    data: IDisposedPending
  ): Promise<IYearWise20to30yPending[]> => {
    const response: AxiosResponse<IYearWise20to30yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendency20to30y`,
      data
    );
    return response?.data;
  };

  public static getYearWise30yPlusPending = async (
    data: IDisposedPending
  ): Promise<IYearWiseAbove30yPending[]> => {
    const response: AxiosResponse<IYearWiseAbove30yPending[]> = await getCall(
      `${ANALYTICS_SERVICE}/getYearlyPendencyAbove30y`,
      data
    );
    return response?.data;
  };

  public static getRadarChartDisposed = async (
    data: IHeatMapData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/periodwiseDisposalTotalCountandPercent`,
      data
    );
    return response?.data;
  };

  public static getRadarChartPending = async (
    data: IHeatMapData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/periodWisePendingTotalCountandPercent`,
      data
    );
    return response?.data;
  };

  public static getCumulativeData = async (
    data: IMultiplePayloadData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/fdpCountsByCommissionDateCategory`,
      data
    );
    return response?.data;
  };

  public static getDisposedYearlyData = async (
    data: IMultiplePayloadData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/pendencyWithinPeriodYearflag`,
      data
    );
    return response?.data;
  };

  public static getDisposedPendingMonthlyData = async (
    data: IMultiplePayloadData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/pendencyWithinPeriodMonthflag`,
      data
    );
    return response?.data;
  };

  public static getJudgePerformanceData = async (
    data: IJudgesPayloadData
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/judgesPerformanceTracker?`, data);
    return response?.data;
  };

  public static getCourtroomData = async (): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/inCourtroomHearings`);
    return response?.data;
  };

  public static getJudgeData = async (): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/judgesPerformance?judgeid=NULL`);
    return response?.data;
  };
  public static getJudgeDataBySector = async (id: string): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/judgesBySectorPerformance?`, { judgeid: id });
    return response?.data;
  };
  public static getJudgeSectorwiseCases = async (data: any): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/judgesBySectorCases?`, data);
    return response?.data;
  };

  public static getRegionWiseFiledDisposedPending = async (
    data: ITopTenCommisionsBubbleChart
  ): Promise<any> => {
    const response: AxiosResponse<any> = await getCall(
      `${ANALYTICS_SERVICE}/statesDisposedPendingCountBasedOnRegion`,
      data
    );
    return response?.data;
  };
}
