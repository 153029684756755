import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicButton } from '../../../components';
import { WorkInPrpgress } from '../../../components/controls/BasicIcons';
import { ROOT } from '../../../navigation/CONSTANTS';
import { useAuth } from '../../../navigation/auth/ProvideAuth';

/**
 * Under progress page for all the progress pages
 * @returns 
 */
const UnderProgress = () => {
    const { t } = useTranslation();
    const { signOut } = useAuth();
    const navigate = useNavigate();

    // logout the user
    const logout = async () => {
        const userLogout = await signOut();
        if (userLogout) {
            navigate(ROOT);
        }
    };

    return (
        <div className="page-wrapper">
            <section className="section margin-top-35">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 text-center">
                            <WorkInPrpgress className="img-fluid w-50" />
                        </div>
                        <div className="col-lg-12 text-center margin-top-20">
                            <BasicButton
                                size="medium"
                                variant="contained"
                                className="btn-custom btn-custom-primary btn-custom-small ms-3"
                                text={t('BtnText.LogOut')}
                                onClick={logout}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default UnderProgress;