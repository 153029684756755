import { FC, useCallback, useEffect, useState } from "react";

// Define the interface for the props
interface TreemapProps {
  rows: number; // number of rows
  columns: number; // number of columns
  data: { text: string; value: number }[]; // array of objects with text and value properties
  colors: string[]; // array of colors for the boxes
  height: number; // height of the svg element
  width: number; // width of the svg element
  gap: number; // gap between boxes
  fontSize: number; // font size of the text
  className?: string; // optional class name for the svg element
}

// Define the interface for the box object
interface Box {
  x: number; // x coordinate of the box
  y: number; // y coordinate of the box
  width: number; // width of the box
  height: number; // height of the box
  text: string; // text of the box
  value: number; // value of the box
  color: string; // color of the box
}

// Define the TreeMapChart component as a function that takes props as an argument and returns JSX elements
const TreeMapChart: FC<TreemapProps> = (props) => {
  // Get the props from props
  const {
    rows,
    columns,
    data,
    colors,
    height,
    width,
    gap,
    fontSize,
    className,
  } = props;

  // Initialize a state variable to store the boxes array and a setter function to update it
  const [boxes, setBoxes] = useState<Box[]>([]);

  // Define a function to calculate the boxes based on the props
  const calculateBoxes = useCallback(() => {
    // Initialize an empty array of boxes
    let boxes: Box[] = [];

    // Calculate the height of each box based on the rows, height, and gap
    const boxHeight = (height - (rows - 1) * gap) / rows;

    // Calculate the available width for the boxes after accounting for gaps
    const availableWidth = width - (columns - 1) * gap;

    // Loop through the rows
    for (let i = 0; i < rows; i++) {
      // Calculate the total value of the data in the current row
      const rowTotalValue = data
        .slice(i * columns, i * columns + columns)
        .reduce((total, item) => total + item.value, 0);

      // Calculate the width of each box in the row relative to the available width
      const rowBoxWidths = data
        .slice(i * columns, i * columns + columns)
        .map((item) => (item.value / rowTotalValue) * availableWidth);

      // Initialize a variable to store the y coordinate of the current row
      const y = i * (boxHeight + gap);

      // Loop through the columns again and create a box object for each data item
      for (let j = 0; j < columns; j++) {
        // Get the text and value of the data at the corresponding index
        const { text, value } = data[i * columns + j];

        // Calculate the x coordinate of the box based on the column index and box width
        const x = j * (rowBoxWidths[j] + gap);

        // Pick a random color from the colors array using Math.random and Math.floor functions
        const color = colors[Math.floor(Math.random() * colors.length)];

        // Create a box object with x, y, width, height, text, value, and color properties
        const box = {
          x,
          y,
          width: rowBoxWidths[j],
          height: boxHeight,
          text,
          value,
          color,
        };

        // Push the box object to the boxes array
        boxes.push(box);
      }
    }

    // Return the boxes array
    return boxes;
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // Use an effect hook to run after the component is mounted or updated
  useEffect(() => {
    // Call the calculateBoxes function and set the state with the result
    const totalBoxes = calculateBoxes();
    setBoxes(totalBoxes);
  }, [rows, columns, data, height, width,calculateBoxes]); // Add dependencies to trigger re-render when any of them changes

  return (
    <div className="text-center">
      <svg height={height} width={width} className={className}>
        {/* Loop through the boxes array and render a rect element for each box */}
        {boxes.map((box, index) => (
          <g key={index}>
            <rect
              x={box.x}
              y={box.y}
              width={box.width}
              height={box.height}
              fill={box.color}
            />
            {/* Render a text element inside the g element with adjusted positioning */}
            <text
              x={box.x + box.width / 2} // Adjusted x position
              y={box.y + box.height / 2} // Adjusted y position
              fill="white"
              textAnchor="middle"
              dominantBaseline="middle" // Use "middle" instead of "central" for better compatibility
              fontSize={fontSize}
            >
              <tspan>{box.text}</tspan>
              <tspan dy="1.2em">{box.value}</tspan>
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

// Export the TreeMapChart component
export { TreeMapChart };
