import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDropdown } from "../../models/common/IDropdown";

type IMasterStoreData = {
    state: IDropdown[];
    country: IDropdown[];
    allCaseCategory: IDropdown[];
    handiCapType: IDropdown[];
    addressType: IDropdown[];
    iaMaReasonList: IDropdown[];
    otherLegalBodyList: IDropdown[];
}

const initialState: IMasterStoreData = {
    state: [],
    country: [],
    allCaseCategory: [],
    handiCapType: [],
    addressType: [],
    iaMaReasonList: [],
    otherLegalBodyList: []
}


export const masterSlice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        addMasterState: (state, action: PayloadAction<IDropdown[]>) => {
            state.state = action.payload
        },
        addMasterCountry: (state, action: PayloadAction<IDropdown[]>) => {
            state.country = action.payload
        },
        addCaseCategory: (state, action: PayloadAction<IDropdown[]>) => {
            state.allCaseCategory = action.payload
        },
        addHandiCapType: (state, action: PayloadAction<IDropdown[]>) => {
            state.handiCapType = action.payload
        },
        addAddressType: (state, action: PayloadAction<IDropdown[]>) => {
            state.addressType = action.payload
        },
        addIaMaReasonList: (state, action: PayloadAction<IDropdown[]>) => {
            state.iaMaReasonList = action.payload
        },
        addOtherLegalBodyList: (state, action: PayloadAction<IDropdown[]>) => {
            state.otherLegalBodyList = action.payload
        },
        clearMaster: (state) => {
            state.state = [];
            state.addressType = [];
            state.allCaseCategory = [];
            state.country = [];
            state.handiCapType = [];
            state.iaMaReasonList = [];
            state.otherLegalBodyList = [];
        },
        clearIaMaReasonList: (state) => {
            state.iaMaReasonList = []
        },
        clearOtherLegalBodyList: (state) => {
            state.otherLegalBodyList = []
        },
    }
});

export const { addMasterState, addMasterCountry, addCaseCategory, addHandiCapType, addAddressType, addIaMaReasonList, clearIaMaReasonList, clearOtherLegalBodyList, addOtherLegalBodyList, clearMaster } = masterSlice.actions;
export default masterSlice.reducer