import { Typography } from "@mui/material";
import { Link as RouterLink, useMatch } from "react-router-dom";

export interface SidebarMenuLinkRouteProps {
  to: string;
  label: string;
  className?: string;
  menuIcon?: string;
  menuActiveIcon?: string;
  disabled?: boolean;
  style?: React.CSSProperties; // Add this line to accept custom styles
}

export function SidebarMenuLinkRoute({
  to,
  label,
  className,
  menuIcon,
  menuActiveIcon,
  disabled,
  style, // Add this line to accept custom styles
  ...rest
}: SidebarMenuLinkRouteProps) {
  const match = useMatch(to);
  const activeClassName = match ? `${className} active` : className;

  if (disabled) {
    return (
      <Typography
        component={'span'}
        sx={{
          backgroundImage: `url('${menuIcon}')`,
          cursor: 'not-allowed',
          color: 'grey',
          '&:hover': {
            backgroundImage: `url('${menuIcon}')`,
          },
        }}
        style={style} // Apply custom styles here
        {...rest} // Spread the rest of the props
      >
        {label}
      </Typography>
    );
  }

  return (
    <RouterLink to={to} className={activeClassName} style={style} {...rest}>
      <Typography
        component={'span'}
        sx={{
          backgroundImage: match ? `url('${menuActiveIcon}')` : `url('${menuIcon}')`,
          color: "inherit",
          transition: "color 0.3s ease",
          '&:hover': {
            color: "white", // Change text color on hover
            backgroundImage: `url('${menuActiveIcon}')`,
          },
        }}
      >
        {label}
      </Typography>
    </RouterLink>
  );
}
