import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import { ADVOCATE, ADVOCATE_ADD_AS_PARTY, ADVOCATE_AGAINST_ME_DISPOSED_CASES, ADVOCATE_AGAINST_ME_FILED_CASES, ADVOCATE_AGAINST_ME_PENDING_CASES, ADVOCATE_APPEAL_TYPE_CASE_PREVIEW, ADVOCATE_APPEAL_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_APPEAL_TYPE_CASE_STEPPERFORM, ADVOCATE_CASEFILING, ADVOCATE_CASELIST_PREVIEW, ADVOCATE_DASHBOARD, ADVOCATE_DISPOSED_CASES, ADVOCATE_DRAFT_CASES, ADVOCATE_EDITPROFILE, ADVOCATE_FILED_CASES, ADVOCATE_FILENEWCASE_PREVIEW, ADVOCATE_FILENEWCASE_REVERTED_CASE_PREVIEW, ADVOCATE_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM, ADVOCATE_FILENEWCASE_STEPPERFORM, ADVOCATE_INTERLOCUTORY_TYPE_CASE_PREVIEW, ADVOCATE_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_INTERLOCUTORY_TYPE_CASE_STEPPERFORM, ADVOCATE_KNOW_YOUR_CASE, ADVOCATE_MISCELLANEOUS_TYPE_CASE_PREVIEW, ADVOCATE_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW, ADVOCATE_MISCELLANEOUS_TYPE_CASE_STEPPERFORM, ADVOCATE_PAYMENT_HISTORY, ADVOCATE_PAYMENTPENDING_CASES, ADVOCATE_PENDING_CASES, ADVOCATE_PENDINGFORAPPROVAL_CASES, ADVOCATE_PENDINGFORSCRUTINY_CASES, ADVOCATE_REJOINDER, ADVOCATE_REJOINDERDOCUMENTS, ADVOCATE_REPLY, ADVOCATE_REPLYDOCUMENTS, ADVOCATE_REVERTEDINSCRUTINY_CASES, ADVOCATE_SUBSEQUENT_FILING, ADVOCATE_SUBSEQUENTDOCUMENTS, ADVOCATE_TRANSFER_TYPE_CASE_STEPPERFORM, ADVOCATE_UPLOADREJOINDERDOCUMENTS, ADVOCATE_UPLOADREPLYDOCUMENTS, ADVOCATE_UPLOADSUBSEQUENTDOCUMENTS } from "../CONSTANTS";

// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const AdvocateDashboardView = lazy(() => import('../../pages/dashboard/components/AdvocateDashboard/AdvocateDashboardView'));
const FiledCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerCasesInCommission/FiledCases').then((module) => ({ default: module.FiledCaseList })));
const DisposedCaseView = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/DisposedCases/DisposedCaseView'));
const PendingCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerCasesInCommission/PendingCases').then((module) => ({ default: module.PendingCaseList })));
const DraftCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.DraftCaseList })));
const PendingForScrutinyCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.PendingForScrutinyCaseList })));
const PendingForApprovalCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerRegisteredCases/PendingForApprovalCaseList').then((module) => ({ default: module.PendingForApprovalCaseList })));
const PaymentPendingCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.PaymentPendingCaseList })));
const RevertedInScrutinyCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.RevertedInScrutinyCaseList })));
const CasePreview = lazy(() => import('../../pages/dashboard/components/CasePreview').then((module) => ({ default: module.CasePreview })));
const Preview = lazy(() => import('../../pages/Consumer/FileNewCase/components/Preview'));
const FileNewCase = lazy(() => import('../../pages/Consumer/FileNewCase'));
const CaseType = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseType'));
const CaseFileStepperForm = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseFileStepperForm'));
const CaseFileStepperFormSpeechEnabled = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseFileStepperFormSpeechEnabled'));
const AppealTypeContainer = lazy(() => import('../../pages/Consumer/AppealTypeCases'));
const InterlocutoryApplicationContainer = lazy(() => import('../../pages/Consumer/InterlocutoryApplicationCase'));
const AppealTypePreview = lazy(() => import('../../pages/Consumer/AppealTypeCases/components/AppealTypePreview'));
const PaymentHistory = lazy(() => import('../../pages/Consumer/PaymentHistory'));
const Reply = lazy(() => import('../../pages/Consumer/Reply'));
const UploadReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/UploadReplyDocuments'));
const ReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/ReplyDocuments'));
const SubsequentFiling = lazy(() => import('../../pages/Consumer/SubsequentFiling'));
const UploadSubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/UploadSubsequentDocuments'));
const SubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/SubsequentDocuments'));
const AddAsParty = lazy(() => import('../../pages/Consumer/AddAsParty'));
const KnowYourCase = lazy(() => import('../../pages/Consumer/KnowYourCase'));

// list of consumer Routes
export const advocateRoutes: IRoute[] = [
    {
        path: "",
        element: <PrivateRoute requiredRoles={[ROLE.Advocate]} />,
        children: [
            {
                path: ADVOCATE_EDITPROFILE,
                element: <EditProfile />
            },
            {
                path: ADVOCATE,
                element: <Dashboard />,
                children: [
                    {
                        path: ADVOCATE_DASHBOARD,
                        element: <AdvocateDashboardView />
                    }
                ]
            },
            {
                path: ADVOCATE_FILED_CASES,
                element: <FiledCaseList />
            },
            {
                path: ADVOCATE_DISPOSED_CASES,
                element: <DisposedCaseView />
            },
            {
                path: ADVOCATE_PENDING_CASES,
                element: <PendingCaseList />
            },
            {
                path: ADVOCATE_DRAFT_CASES,
                element: <DraftCaseList />
            },
            {
                path: ADVOCATE_PENDINGFORSCRUTINY_CASES,
                element: <PendingForScrutinyCaseList />
            },
            {
                path: ADVOCATE_PENDINGFORAPPROVAL_CASES,
                element: <PendingForApprovalCaseList />
            },
            {
                path: ADVOCATE_PAYMENTPENDING_CASES,
                element: <PaymentPendingCaseList />
            },
            {
                path: ADVOCATE_REVERTEDINSCRUTINY_CASES,
                element: <RevertedInScrutinyCaseList />
            },
            {
                path: ADVOCATE_FILENEWCASE_REVERTED_CASE_PREVIEW,
                element: <Preview />
            },
            {
                path: ADVOCATE_CASELIST_PREVIEW,
                element: <CasePreview />
            },
            {
                path: ADVOCATE_AGAINST_ME_FILED_CASES,
                element: <FiledCaseList />
            },
            {
                path: ADVOCATE_AGAINST_ME_DISPOSED_CASES,
                element: <DisposedCaseView />
            },
            {
                path: ADVOCATE_AGAINST_ME_PENDING_CASES,
                element: <PendingCaseList />
            },
            {
                path: ADVOCATE_CASEFILING,
                element: <FileNewCase />,
                children: [
                    {
                        path: ADVOCATE_CASEFILING,
                        element: <CaseType />
                    },
                    {
                        path: ADVOCATE_FILENEWCASE_STEPPERFORM,
                        element: <CaseFileStepperForm />
                    },
                    {
                        path: ADVOCATE_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
                        element: <CaseFileStepperFormSpeechEnabled />
                    },
                    {
                        path: ADVOCATE_APPEAL_TYPE_CASE_STEPPERFORM,
                        element: <AppealTypeContainer />
                    },
                    {
                        path: ADVOCATE_INTERLOCUTORY_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: ADVOCATE_TRANSFER_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: ADVOCATE_MISCELLANEOUS_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: ADVOCATE_FILENEWCASE_PREVIEW,
                        element: <Preview />
                    },
                    {
                        path: ADVOCATE_APPEAL_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: ADVOCATE_INTERLOCUTORY_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview  />
                    },
                    {
                        path: ADVOCATE_MISCELLANEOUS_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: ADVOCATE_APPEAL_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: ADVOCATE_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: ADVOCATE_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    }
                ]
            },
            {
                path: ADVOCATE_PAYMENT_HISTORY,
                element: <PaymentHistory />
            },
            {
                path: ADVOCATE_REPLY,
                element: <Reply />
            },
            {
                path: ADVOCATE_UPLOADREPLYDOCUMENTS,
                element: <UploadReplyDocuments />
            },
            {
                path: ADVOCATE_REPLYDOCUMENTS,
                element: <ReplyDocuments />
            },
            {
                path: ADVOCATE_REJOINDER,
                element: <Reply />
            },
            {
                path: ADVOCATE_UPLOADREJOINDERDOCUMENTS,
                element: <UploadReplyDocuments />
            },
            {
                path: ADVOCATE_REJOINDERDOCUMENTS,
                element: <ReplyDocuments />
            },
            {
                path: ADVOCATE_SUBSEQUENT_FILING,
                element: <SubsequentFiling />
            },
            {
                path: ADVOCATE_UPLOADSUBSEQUENTDOCUMENTS,
                element: <UploadSubsequentDocuments />
            },
            {
                path: ADVOCATE_SUBSEQUENTDOCUMENTS,
                element: <SubsequentDocuments />
            },
            {
                path: ADVOCATE_ADD_AS_PARTY,
                element: <AddAsParty />
            },
            {
                path: ADVOCATE_KNOW_YOUR_CASE,
                element: <KnowYourCase />
            }
        ]
    }
];
