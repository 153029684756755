import { IDropdown } from "../common/IDropdown";

/**
 * Stores adress details
 */
export class AddressDetailsModel {
    public addressType: IDropdown;
    public houseNumber: string;
    public street: string;
    public landmark: string;
    public postOffice: IDropdown;
    public state: IDropdown;
    public district: IDropdown;
    public pinCode: string;
    public isNRI: boolean;
    public country: IDropdown;  // Optional property
    public addressId?: number;
    public stateOfNri?: string
    public districtOfNri?: string

    constructor(
        addressType: IDropdown,
        houseNumber: string,
        street: string,
        landmark: string,
        postOffice: IDropdown,
        state: IDropdown,
        district: IDropdown,
        pinCode: string,
        isNRI: boolean,
        country: IDropdown,  // Optional parameter
        addressId?: number,
        stateOfNri?: string,
        districtOfNri?: string
    ) {
        this.addressType = addressType;
        this.houseNumber = houseNumber;
        this.street = street;
        this.landmark = landmark;
        this.postOffice = postOffice;
        this.state = state;
        this.district = district;
        this.pinCode = pinCode;
        this.isNRI = isNRI;
        this.addressId = addressId;
        this.country = country;  // Set country only if isNRI is true
        this.stateOfNri = stateOfNri
        this.districtOfNri = districtOfNri
    }

    static readonly init = () => new AddressDetailsModel(
        { label: "", value: "" },
        "",
        "",
        "",
        { label: "", value: "" },
        { label: "", value: "" },
        { label: "", value: "" },
        "",
        false,
        { label: "", value: "" }
    );
};