import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { supportedLanguages } from './utils/CONSTANTS';

/** detect the browser language
 * implement according to browser language
@param 
**/

i18n
.use(Backend)
// detect user language
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
  interpolation: { escapeValue: false},
  supportedLngs: supportedLanguages,
  fallbackLng: "en",
  // debug: true,
  load: 'languageOnly',
  ns: ["translation", "message"],
  defaultNS: ["translation"],
  detection: {
    order: ["localStorage", "path", "htmlTag"],
    caches: ["localStorage"],
  },
  backend: {
    loadPath: `${window.location.origin}/assets/locales/{{lng}}/{{ns}}.json`,
  }
});

export default i18n;