import "./Loader.css";
import loader from './../../../assets/images/home2/loader.gif'

function Loader() {
  return (
    <div className="analytics-graph-plotlybars-wrapper margin-bottom-80">
      <div className="analytics-graph-plotlybars">
        {/* <div className="analytics-graph-plotlybars-bar analytics-graph-b1"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b2"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b3"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b4"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b5"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b6"></div>
        <div className="analytics-graph-plotlybars-bar analytics-graph-b7"></div> */}
        <img className="ejagritiLoader" src={loader} alt="loader" width={100} height={100} />
      </div>
      <div className="analytics-graph-plotlybars-text">Loading...</div>
    </div>
  );
}

export default Loader;
