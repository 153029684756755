import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileNewCaseViewModel } from "../../models/cases/fileNewCase/FileNewCaseModel";

const initialState: FileNewCaseViewModel = {}

export const fileNewCaseSlice = createSlice({
    name: 'fileNewCase',
    initialState,
    reducers: {
        addCaseDetail: (state, action: PayloadAction<FileNewCaseViewModel>) => {
            state.caseDetail = action.payload.caseDetail
        },
        addComplainantRespondant: (state, action: PayloadAction<FileNewCaseViewModel>) => {
            state.complainantRespondent = action.payload.complainantRespondent
        },
        clearFileCase: (state) => {
            state =  {};
            localStorage.removeItem('persist:root');
        }
    }
});

export const { addCaseDetail, addComplainantRespondant, clearFileCase } = fileNewCaseSlice.actions;
export default fileNewCaseSlice.reducer