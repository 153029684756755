export const states = [
  { id: "Delhi", title: "NCDRC", state_id: "DL", cases: 120, color: '#FF6384' },
  // Add other states similarly
];
// export const states: any = [
//   {
//     id: "Delhi",
//     title: "Delhi",
//     state_id: "DL",
//     lgd_code: "7",
//   },
//   {
//     id: "Andhra_Pradesh",
//     title: "Andhra Pradesh",
//     state_id: "AP",
//     lgd_code: "28",
//   },
//   { id: "Bihar", title: "Bihar", state_id: "BR", lgd_code: "10" },
//   {
//     id: "Dadra_and_Nagar_Haveli_and_Daman_and_Diu",
//     title: "Dadra and Nagar Haveli and Daman and Diu",
//     state_id: "ND",
//     lgd_code: "38",
//   },
//   {
//     id: "Andaman_and_Nicobar_Islands",
//     title: "Andaman and Nicobar Islands",
//     state_id: "AN",
//     lgd_code: "35",
//   },
//   {
//     id: "Arunachal_Pradesh",
//     title: "Arunachal Pradesh",
//     state_id: "AR",
//     lgd_code: "12",
//   },

//   {
//     id: "Chhattisgarh",
//     title: "Chhattisgarh",
//     state_id: "CG",
//     lgd_code: "22",
//   },
//   { id: "Chandigarh", title: "Chandigarh", state_id: "CH", lgd_code: "4" },
//   { id: "Assam", title: "Assam", state_id: "AS", lgd_code: "18" },

//   { id: "Goa", title: "Goa", state_id: "GA", lgd_code: "30" },
//   {
//     id: "Himachal_Pradesh",
//     title: "Himachal Pradesh",
//     state_id: "HP",
//     lgd_code: "2",
//   },
//   { id: "Haryana", title: "Haryana", state_id: "HR", lgd_code: "6" },
//   {
//     id: "Jammu_and_Kashmir",
//     title: "Jammu and Kashmir",
//     state_id: "JK",
//     lgd_code: "1",
//   },
//   {
//     id: "Jharkhand",
//     title: "Jharkhand",
//     state_id: "JH",
//     lgd_code: "20",
//   },

//   { id: "Karnataka", title: "Karnataka", state_id: "KA", lgd_code: "29" },
//   { id: "Kerala", title: "Kerala", state_id: "KL", lgd_code: "32" },
//   { id: "Gujarat", title: "Gujarat", state_id: "GJ", lgd_code: "24" },
//   { id: "Ladakh", title: "Ladakh", state_id: "LA", lgd_code: "37" },

//   {
//     id: "Madhya_Pradesh",
//     title: "Madhya Pradesh",
//     state_id: "MP",
//     lgd_code: "23",
//   },
//   {
//     id: "Lakshadweep",
//     title: "Lakshadweep",
//     state_id: "LD",
//     lgd_code: "31",
//   },
//   {
//     id: "Maharashtra",
//     title: "Maharashtra",
//     state_id: "MH",
//     lgd_code: "27",
//   },
//   { id: "Manipur", title: "Manipur", state_id: "MN", lgd_code: "14" },
//   { id: "Meghalaya", title: "Meghalaya", state_id: "ML", lgd_code: "17" },
//   { id: "Punjab", title: "Punjab", state_id: "PB", lgd_code: "3" },
//   { id: "Odisha", title: "Odisha", state_id: "OD", lgd_code: "21" },
//   { id: "Sikkim", title: "Sikkim", state_id: "SK", lgd_code: "11" },
//   { id: "Nagaland", title: "Nagaland", state_id: "NL", lgd_code: "13" },
//   { id: "Mizoram", title: "Mizoram", state_id: "MZ", lgd_code: "15" },
//   { id: "Rajasthan", title: "Rajasthan", state_id: "RJ", lgd_code: "8" },
//   {
//     id: "Puducherry",
//     title: "Puducherry",
//     state_id: "PY",
//     lgd_code: "34",
//   },
//   {
//     id: "Tamil_Nadu",
//     title: "Tamil Nadu",
//     state_id: "TN",
//     lgd_code: "33",
//   },
//   {
//     id: "Uttar_Pradesh",
//     title: "Uttar Pradesh",
//     state_id: "UP",
//     lgd_code: "9",
//   },
//   {
//     id: "Telangana",
//     title: "Telangana",
//     state_id: "TS",
//     lgd_code: "36",
//   },
//   { id: "Tripura", title: "Tripura", state_id: "TR", lgd_code: "16" },
//   {
//     id: "Uttarakhand",
//     title: "Uttarakhand",
//     state_id: "UK",
//     lgd_code: "5",
//   },
//   {
//     id: "West_Bengal",
//     title: "West Bengal",
//     state_id: "WB",
//     lgd_code: "19",
//   },
// ];
