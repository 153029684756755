import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkRoute } from "../components";
import { RootState } from "../redux";
import { navigateToDashboardPath } from "../utils/Helper";
import { useAuth } from "./auth/AuthProvider";
import notFound from "../assets/images/notfound.jpg";


export const NotFound = () => {
  const { isLogin } = useAuth();
  const navigate = useNavigate();
  const { userDetailsData } = useSelector((state: RootState) => state.user);
  React.useEffect(() => {
    if (isLogin()) {
      navigate("/");
    }
  }, [isLogin]);
  return (
    <section className="w-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <img className="w-100 overflow-hidden" src={notFound} alt="Not Found" />
            <div className="return-home">Return to <LinkRoute to={navigateToDashboardPath(userDetailsData?.roleId)}>Home</LinkRoute></div>
          </div>
        </div>
      </div>
    </section>
  )
}
