const qr = require('qrcode');

// -------function for Qr code-------------------
const generateQRCode = async (link: string) => {
    try {
      // Generate QR code as a data URI
      const qrCodeDataUri = await qr.toDataURL(link);
      return qrCodeDataUri;
    } catch (error) {
      console.error('Error generating QR code:', error);
      return null;
    }
  };
  
  const root_link = `${window.location.origin}`
  const Download_Link = "/download-reports-pdf?"
  


// --------------ALL QR CODE EXPORTING FROM HERE--------------------
export const QrCode_image=async(Report_type:any)=>{

    if(Report_type[2] === "Filtered_reports"){
      return await generateQRCode(root_link+"/reports");
    }
    return await generateQRCode(root_link+Download_Link+Report_type);
}

