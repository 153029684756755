import { AxiosError, AxiosResponse, ResponseType } from "axios";

import { instance } from "./instance";
import { loaderService } from "./CommonService/loaderService";

/**
 * Make a server call for POST type
 * @param data: Generic type of request data
 * @param url: An endpoint
 */
export const postCall = <T extends unknown>(
	url: string,
	data: T,
	params: any = {}
) => {
	try {
		const response = instance
			.post(url, data, { params })
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};

/**
 * Make a server call for POST type for file
 * @param data: Generic type of request data
 * @param url: An endpoint
 */
export const postCallFile = <T extends unknown>(url: string, data: T) => {
	try {
		const response = instance
			.post(url, data, {
				headers: {
					"Content-Type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};

/**
 * Make a server call for PATCH type
 * @param data: Generic type of request data
 * @param url: An endpoint
 */
export const patchCall = <T extends unknown>(url: string, data: T) => {
	try {
		const response = instance
			.patch(url, data)
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};

/**
 * Make a server call for HTTP Get
 * @param url: An endpoint
 * @param responseType: Response type of the response
 * @param showNotificationOnNotFoundError: Show notification error message for StatusCode other that 404(NotFound)
 */
export const getCall = (
	url: string,
	params: any = {},
	responseType: ResponseType = "json",
	showNotificationOnNotFoundError: boolean = true
) => {
	try {
		const response = instance
			.get(url, { params, responseType })
			.then((response: AxiosResponse) => {
				if (response?.data) {
					loaderService.setLoading(false);
				}
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		loaderService.clearLoading();
		throw error;
	}
};

/**
 * Make a server call for PUT type
 * @param data: Request data
 * @param url: An endpoint
 */
export const putCall = <T extends unknown>(url: string, data: T) => {
	try {
		const response = instance
			.put(url, data)
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};

/**
 * Make a server call for put with file data
 * @param data: Response data
 */
export const putCallFile = <T extends unknown>(url: string, data: T) => {
	try {
		const response = instance
			.put(url, data, {
				headers: {
					"Content-Type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};

/**
 * Make a server call for DELETE type
 * @param data: Request data
 * @param url: An endpoint
 */
export const deleteCall = <T extends unknown>(
	url: string,
	data: T,
	params: any = {}
) => {
	try {
		const response = instance
			.delete(url, { data, params })
			.then((response: AxiosResponse) => {
				loaderService.setLoading(false);
				return response.data;
			})
			.catch((error: AxiosError) => {
				loaderService.clearLoading();
				// TODO: do proper error handling based on the error object and status code returned from the server
			});

		return response;
	} catch (error) {
		throw error;
	}
};
