/**
 * Context API used for Auth related information and methods.
 * I am keeping everything related to the authentication and PrivateRoute in this one file.
 */
import React, { createContext, useContext } from "react";
import useAuthentication from "../../hooks/useAuthentication";
import { UserModel } from "../../models/user/UserModel";
import { Login, LoginWithOtp } from "../../models/user/LoginModel";

export interface IContextProps {
  isAuthenticated: () => boolean;
  signin(login: Login): Promise<UserModel>;
  signinWithOtp(login: LoginWithOtp): Promise<UserModel>;
  signOut: () => Promise<boolean>;
  user: UserModel;
}

// Context API used for Auth related information and methods.
const AuthContext = createContext<IContextProps>({
  isAuthenticated: () => false,
  signin: () => Promise.resolve(UserModel.init()),
  signinWithOtp: () => Promise.resolve(UserModel.init()),
  signOut: () => Promise.resolve(false),
  user: UserModel.init(),
});

/** Context Provider to wrap the whole app within and make auth information available.  */
export function ProvideAuth({ children }: Readonly<{ children: React.ReactNode }>) {
  const auth: IContextProps = useAuthentication();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth() {
  return useContext<IContextProps>(AuthContext);
}
