import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import {
    DOCA,
    DOCA_EDITPROFILE,
    DOCA_USER_DASHBOARD
} from "../CONSTANTS";


// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const DocaDashboard = lazy(() => import('../../pages/dashboard/components/DocaDashboard/DocaDashboard'))

const DocaChildrenRoute: IRoute[] = [
    {
        path: DOCA_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: DOCA,
        element: <Dashboard />,
        children: [
            {
                path: DOCA_USER_DASHBOARD,
                element: <DocaDashboard />
            }
        ]
    },
];

// list of commission Routes
export const DocaRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute
            requiredRoles={[ROLE.DocaUser]}
        />,
        children: DocaChildrenRoute
    }
];