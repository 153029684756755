import { outlinedInputClasses } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';

const cssVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: cssVar('--primary-blue'),
            light: cssVar('--primary-blue'),
            dark: cssVar('--primary-blue'),
            contrastText: cssVar('--white'),
        },
        secondary: {
            main: cssVar('--secondary-coffee'),
            light: cssVar('--secondary-coffee'),
            dark: cssVar('--secondary-coffee'),
            contrastText: cssVar('--white'),
        },
        success: {
            main: cssVar('--success'),
            light: cssVar('--success'),
            dark: cssVar('--success'),
            contrastText: cssVar('--white'),
        },
        info: {
            main: cssVar('--gray-dark'),
            light: cssVar('--gray-dark'),
            dark: cssVar('--gray-dark'),
            contrastText: cssVar('--white'),
        },
        error: {
            main: cssVar('--danger'),
            light: cssVar('--danger'),
            dark: cssVar('--danger'),
            contrastText: cssVar('--white'),
        },
        text: {
            primary: cssVar('--primary-blue'),
            secondary: cssVar('--secondary-coffee'),
            disabled: cssVar('--gray-dark'),
        }
    },
    typography: {
        fontFamily: "'Inter', sans-serif",
        h6: {
            color: 'var(--heading)',
            fontSize: 'var(--size-14)',
            fontWeight: 'var(--weight-400)',
            lineHeight: 'var(--size-21)',
        },
        h5: {
            color: 'var(--heading)',
            fontSize: 'var(--size-16)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-22)',
        },
        h4: {
            color: 'var(--heading)',
            fontSize: 'var(--size-18)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-24)',
        },
        h3: {
            color: 'var(--heading)',
            fontSize: 'var(--size-24)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-30)',
        },
        h2: {
            color: 'var(--heading)',
            fontSize: 'var(--size-32)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-40)',
        },
        h1: {
            color: 'var(--heading)',
            fontSize: 'var(--size-34)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-42)',
        },
        body1: {
            color: 'var(--body-text)',
            fontSize: 'var(--size-14)',
            fontWeight: 'var(--weight-400)',
            lineHeight: 'var(--size-21)',
        },
        body2: {
            color: 'var(--heading)',
            fontSize: 'var(--size-14)',
            fontWeight: 'var(--weight-700)',
            lineHeight: 'var(--size-21)',
        }
    },
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fontSize: 'var(--size-32)',
                    color: 'var(--gray-divider)',
                    '&.Mui-active': {
                        color: 'var(--primary-blue)',
                        '.MuiStepIcon-text': {
                            fill: 'var(--white)'
                        }
                    },
                    '&.Mui-completed': {
                        color: 'var(--success)',
                        '.MuiStepIcon-text': {
                            fill: 'var(--success)',
                        }
                    },
                    '.MuiStepIcon-text': {
                        fill: 'var(--body-text)',
                        fontSize: 'var(--size-11)',
                        lineHeight: 'var(--size-21)',
                        fontWeight: 'var(--weight-700)',
                    }
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    '.MuiStepLabel-label': {
                        fontSize: 'var(--size-14)',
                        lineHeight: 'var(--size-21)',
                        fontWeight: 'var(--weight-700)',
                        color: 'var(--heading-color)',
                        '&.Mui-active': {
                            color: 'var(--heading-color)',
                            fontWeight: 'var(--weight-700)',
                        },
                        '&.Mui-completed': {
                            color: 'var(--heading-color)',
                            fontWeight: 'var(--weight-700)',
                            '.MuiStepIcon-text': {
                                color: 'var(--heading-color)',
                                fontWeight: 'var(--weight-700)',
                            }

                        },
                    },
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    '.MuiStepConnector-line': {
                        borderTopWidth: "var(--size-2)"
                    },
                    '&.Mui-completed .MuiStepConnector-line, &.Mui-active .MuiStepConnector-line': {
                        borderColor: 'var(--success)'
                    }
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: 'var(--primary-blue)',
                    },
                    '& label.Mui-error': {
                        color: 'rgba(233, 57, 79, 0.54)',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    background: 'var(--white)',
                    padding: '12.45px var(--size-14) 12.45px var(--size-14)',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    fontSize: 'var(--size-14)',
                    padding: '12.45px var(--size-14) 12.45px var(--size-14)',
                    color: 'var(--heading)'
                },
                notchedOutline: {
                    borderColor: 'var(--gray-divider)',
                    borderWidth: 'var(--size-2)',
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--primary-blue)',
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--primary-blue)',
                    },
                    [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--error)',
                    },
                    "&.Mui-disabled": {
                        pointerEvents: 'none'
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 'var(--size-14)',
                    lineHeight: 'var(--size-14)',
                    color: 'rgba(0, 18, 51, 0.54)',
                    padding: '0px 0 1px 0',
                    overflow: 'inherit'
                },
                outlined: {
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(15px, -5px) scale(0.75)',
                        color: 'var(--primary-blue)',
                    },
                    '&.MuiInputLabel-shrink.Mui-error': {
                        color: 'var(--error)',
                    },
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: 'var(--size-2) solid var(--gray-divider)',
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before,&.Mui-focused:after': {
                        borderBottom: 'var(--size-2) solid var(--primary-blue)',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 'var(--size-12)',
                    lineHeight: 'var(--size-21)',
                    fontWeight: 'var(--weight-400)',
                    color: 'var(--gray-dark)',
                    marginLeft: 'var(--size-0)',
                    marginTop: 'var(--size-10)',
                    '& .MuiFormHelperText-info': {
                        display: "block",
                        marginBottom: 'var(--size-10)',
                        color: 'var(--gray-dark)',
                    }
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: "var(--gray-dark)",
                    padding: 'var(--size-0)',
                    paddingRight: 'var(--size-5)',
                    '&.Mui-checked': {
                        color: 'var(--primary-blue)',
                    },
                },
            }
        },
        MuiRadio: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: "var(--gray-dark)",
                    '&.Mui-checked': {
                        color: 'var(--primary-blue)',
                    },
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 'var(--size-14)',
                    lineHeight: 'var(--size-21)',
                    fontWeight: 'var(--weight-400)',
                    color: 'var(--body-text)',
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontSize: 'var(--size-14)',
                    "&.Mui-disabled": {
                        background: "var(--gray-divider)",
                        color: "var(--gray-dark)"
                    }
                },
                text: {
                    fontSize: 'var(--size-12)',
                    lineHeight: 'var(--size-18)',
                    fontWeight: 'var(--weight-700)',
                    textDecoration: 'underline',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        textDecoration: 'underline',
                    }
                },
                outlined: {
                    disableRipple: true,
                    color: 'var(--heading)',
                    boxShadow: 'none',
                    fontSize: 'var(--size-14)',
                    fontWeight: 'var(--weight-700)',
                    lineHeight: 'var(--size-21)',
                    padding: 'var(--size-11) var(--size-16)',
                    border: 'var(--size-1) solid var(--heading);',
                    '&:hover,&:active': {
                        backgroundColor: 'var(--white)',
                        boxShadow: 'none',
                    },
                    '&.btn-small': {
                        color: 'var(--primary-blue)',
                        border: 'var(--size-1) solid var(--primary-blue);',
                    }
                },
                contained: {
                    disableRipple: true,
                    backgroundColor: 'var(--primary-blue)',
                    boxShadow: 'none',
                    fontSize: 'var(--size-14)',
                    fontWeight: 'var(--weight-700)',
                    lineHeight: 'var(--size-21)',
                    padding: 'var(--size-12) var(--size-16)',
                    '&:hover,&:active': {
                        backgroundColor: 'var(--primary-blue)',
                        boxShadow: 'none',
                    },
                    '&.btn-shadow': {
                        boxShadow: 'var(--size-0) var(--size-4) var(--size-8) var(--size-0) rgba(0, 0, 0, 0.08)'
                    }
                },
                containedSecondary: {
                    backgroundColor: 'var(--secondary-coffee)',
                    color: 'var(--white)',
                    padding: 'var(--size-7) var(--size-10)',
                    fontSize: 'var(--size-12)',
                    fontWeight: 'var(--weight-700)',
                    lineHeight: 'var(--size-18)',
                    '&:hover': {
                        backgroundColor: 'var(--secondary-coffee)',
                    }
                },
                containedSuccess: {
                    backgroundColor: 'var(--primary-blue)',
                    color: 'var(--white)',
                    '&:hover': {
                        backgroundColor: 'var(--primary-blue)',
                    }
                },
                containedInfo: {
                    backgroundColor: 'var(--heading)',
                    color: 'var(--white)',
                    '&:hover': {
                        backgroundColor: 'var(--heading)',
                    }
                },
                sizeSmall: {
                    fontSize: 'var(--size-12)',
                    fontWeight: 'var(--weight-700)',
                    lineHeight: 'var(--size-18)',
                    padding: 'var(--size-8) var(--size-10)',
                }
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: 'var(--gray-dark)',
                    fontSize: 'var(--size-12)',
                    fontWeight: 'var(--weight-400)',
                    lineHeight: 'var(--size-18)',
                    '& .MuiLink-root': {
                        color: 'var(--gray-dark)'
                    },
                    "& p.MuiTypography-root": {
                        color: 'var(--heading)',
                        fontSize: 'var(--size-12)',
                        fontWeight: 'var(--weight-700)',
                        lineHeight: 'var(--size-18)',
                    },
                    "& .MuiBreadcrumbs-separator": {
                        marginLeft: 'var(--size-5)',
                        marginRight: 'var(--size-5)',
                    },
                    "& .MuiBreadcrumbs-separator svg": {
                        fill: "var(--gray-dark)",
                        fontSize: 'var(--size-18)',
                    }
                },
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: 'var(--size-70)',
                    background: 'var(--white)',
                    boxShadow: 'none',
                    borderBottom: 'var(--size-1) solid var(--gray-divider)',
                    top: 'var(--size-25)'
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    ".MuiPaper-elevation": {
                        paddingRight: 'var(--size-0)!important',
                        boxShadow: 'none',
                    },
                    ".MuiToolbar-gutters": {
                        minHeight: 'var(--size-55)'
                    },
                    "header .MuiTypography-root": {
                        color: 'var(--heading)',
                        fontSize: 'var(--size-20)',
                        fontWeight: 'var(--weight-700)',
                        lineHeight: 'var(--size-28)',
                        margin: 'var(--size-0)'
                    },
                    "header .MuiTypography-root:after": {
                        content: '""',
                        width: 'var(--size-60)',
                        height: 'var(--size-3)',
                        position: 'absolute',
                        left: 'var(--size-24)',
                        bottom: 'var(--size-0)',
                        borderRadius: 'var(--size-50) var(--size-50) var(--size-0) var(--size-0)',
                        background: 'var(--secondary-coffee)',
                    },
                    "& .dialog-text": {
                        color: 'var(--heading)',
                        fontSize: 'var(--size-20)',
                        lineHeight: 'var(--size-30)',
                    }
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    "&.MuiDialogContent-dividers": {
                        borderTop: 'var(--size-1) solid var(--gray-divider)',
                        borderBottom: 'var(--size-1) solid var(--gray-divider)'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: 'var(--body-text)',
                    fontSize: 'var(--size-12)',
                    fontWeight: 'var(--weight-700)',
                    lineHeight: 'var(--size-18)',
                    padding: 'var(--size-10) var(--size-12)',
                    opacity: 'var(--size-1)',
                    minHeight: 'var(--size-35)',
                    "&.Mui-selected": {
                        color: 'var(--primary-blue)',
                        borderRadius: "var(--size-25)"
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'var(--size-35)',
                    borderBottom: 'var(--size-1) solid var(--gray-divider)'
                },
                indicator: {
                    backgroundColor: 'var(--primary-blue)',
                    height: 'var(--size-3)',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    "&.MuiDataGrid-menuList .MuiTypography-root": {
                        color: 'var(--gray-dark)',
                        fontSize: 'var(--size-12)',
                        fontWeight: 'var(--weight-400)',
                        lineHeight: 'var(--size-18)',
                    }
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'var(--gray-divider)',
                    borderBottomWidth: 'var(--size-1)',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    fontSize: 'var(--size-14)',
                    padding: '3.45px 4px 3.45px 5px!important',
                    color: 'var(--heading)'
                },
                option: {
                    minHeight: 'auto!important'
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderColor: 'var(--gray-divider)',
                    borderWidth: 'var(--size-1)',
                    borderStyle: 'solid',
                    color: 'var(--heading)',
                    fontWeight: 'var(--weight-700)',
                    boxShadow: 'none',
                    borderRadius: 'var(--size-4)',
                    marginBottom: 'var(--size-15)',
                    marginTop: 'var(--size-0)!important',
                    ':before': {
                        display: 'none'
                    }
                },
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--primary-lighter)',
                    minHeight: 'var(--size-56)!important',
                    "& .MuiAccordionSummary-content": {
                        margin: 'var(--size-17) var(--size-0)'
                    }
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 'var(--size-17)'
                },
            }
        }
    }
}