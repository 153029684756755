import React, { useCallback, useEffect, useState } from "react";
import Dropzone, { Accept, DropzoneProps, FileRejection } from "react-dropzone";
import { Chip, Alert, Typography } from "@mui/material";
import { UploadIcon } from "./BasicIcons";
import { convertBytes } from "../../utils/Helper";

interface IFileUploaderProps extends DropzoneProps {
	text?: string; // Text to be aaded in Component
	dropzoneClassName?: string; //Styles to be added in component
	onFileDrop: (acceptedFiles: File[], filetoDelete?: string) => void; //It will handles the onChange
	maxFiles?: number; //Handles Files limit
	maxSize?: number; //Handles max file size
	accept?: Accept; //Handles which type of files to be added
	isMultipleFile?: boolean; //check if multiple files need to be uploaded
	fileList?: File[];
}

//This is the custom icon of Drag and drop component
const CustomIcon = () => <UploadIcon alt="Upload" />;

/**
 * File Uploader Component
 *
 * Renders a upload file  component.
 *
 * @param {IFileUploaderProps} props - The props for the File Uploader component.
 * @returns {JSX.Element} - The rendered FileUploader component.
 */
export const FileUploader: React.FC<IFileUploaderProps> = ({
	text = "Drag and Drop Files here or Click to upload (jpg, pdf, or png files only)",
	dropzoneClassName,
	onFileDrop,
	maxFiles = 1,
	maxSize = 20971520,
	accept = {
		"application/pdf": [".pdf"],
	},
	isMultipleFile = false,
	fileList = [],
	...rest
}) => {
	//This state is to see list of files in chips
	const [filePreviews, setFilePreviews] = useState<File[]>(fileList);
	const [alert, setAlert] = React.useState<string | null>(null); //Alert on Rejection
	//OnDrop() func tion will handle the acceptedfiles, deletedfiles and each file on drag and drop
	const onDrop = useCallback(
		(acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (fileRejections.length > 0) {
				// Show alert message for rejected files
				setAlert(fileRejections[0].errors[0].code);
			}
			else {
				setAlert(null);
			}

			if (onFileDrop) {
				onFileDrop([...acceptedFiles]);
			}

			if (isMultipleFile) {
				//Updating File Previews
				setFilePreviews((prevFilePreviews) => [
					...prevFilePreviews,
					...acceptedFiles,
				]);
			} else {
				setFilePreviews(acceptedFiles);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onFileDrop, filePreviews, isMultipleFile]
	);

	//Handles the files deleted and updating filePreviews
	const handleDeleteFile = (fileToDelete: File) => {
		setFilePreviews((prevFilePreviews) =>
			prevFilePreviews.filter((file) => file.name !== fileToDelete.name)
		);

		// Pass the updated file list to the parent component

		if (onFileDrop) {
			const updatedFiles = filePreviews.filter(
				(file) => file.name !== fileToDelete.name

			);
			onFileDrop(updatedFiles);
		}
	};

	//It handles chips to be deleted
	const handleDeleteChip = (fileToDelete: File) => () => {
		handleDeleteFile(fileToDelete);
	};

	useEffect(() => {
		if (fileList.length) {
			setFilePreviews(fileList);
		}
	}, [fileList]);

	// get alert messgage
	const getAlertMessage = (): string => {
		switch (alert) {
			case "file-invalid-type":
				return "File must be a PDF";
			case "file-too-large":
				return "File size must not be larger than 20 MB";
			case "too-many-files":
				return "Only one file is allowed"
			default:
				return "Unknown error"
		}
	};

	return (
		<>
			<Dropzone {...rest} onDrop={onDrop} accept={accept} multiple maxSize={maxSize} maxFiles={1}>
				{({ getRootProps, getInputProps }) => (
					<div className={dropzoneClassName}>
						<div className="dropzone-message" {...getRootProps()}>
							<CustomIcon />
							<input {...getInputProps()} />
							<p>{text}</p>
						</div>
					</div>
				)}
			</Dropzone>
			{filePreviews.length > 0 && (
				<div className="file-previews">
					<Typography variant="body2">
						Added :{" "}
					</Typography>
					<div className="margin-left-20">
						{filePreviews?.map((file, index) => {
							const id = index
							return (
								<Chip
									key={id}
									label={`${file.name} (${convertBytes(file.size)})`}
									onDelete={handleDeleteChip(file)}
									variant="outlined"
									color="primary"
								/>
							)
						})}
					</div>
				</div>
			)}

			{alert ? (
				<div className="mt-1">
					<Alert severity="error">
						{
							getAlertMessage()
						}
					</Alert>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
