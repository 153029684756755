import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import {
  IFindUserResponse,
  ILoginResponse,
  ITokenDecodedData,
  Login,
  LoginWithOtp,
} from "../../models/user/LoginModel";
import { IRegisterResponse, Register, IRegisterNewUserRequest } from "../../models/user/RegisterModel";
import {
  IUserStatus,
  OtpModel,
  UserModel,
  ChangeMobileNumberOrEmailModel,
  ChangeMobileNumberOrEmailOtpModel,
  IChangeMobileNumberOrEmailResponse,
  ChangePasswordModel,
  IPasswordResetResponse,
  ResetPasswordModel,
} from "../../models/user/UserModel";
import { userDetailsData } from "../../redux/AuthReducer/AuthReducer";
import { makeDropdownOptions } from "../../utils/Helper";
import { USER_MANAGEMENT_SERVICE } from "../CONSTANTS";
import { MasterService } from "../MasterService/MasterService";
import { deleteCall, getCall, postCall } from "../api";
import { IAddNewUserResponse } from "../../models/common/INewUserResponse";
import { ICreateNewUserRequest, ICreateNewUserResponse } from "../../pages/Commissions/CreateNewUserDetails/INewUserCreateModel";


export interface IFindUserData {
  user: UserModel;
  userDetails: IFindUserResponse;
}

/** Service which holds all the methods related to user */
export class AuthenticationService {
  /** Call an API to perform login
   * @param username string
   * @param password string
   */
  public static login = async ({ username, password, forcedLogin }: Login) => {
    try {
      let user: IFindUserData = {
        user: UserModel.init(),
        userDetails: userDetailsData,
      };

      const loginBody = {
        emailOrMobile: username,
        userLoginPassword: password,
        passwordLogin: true,
        forcedLogin
      };

      const response: AxiosResponse<ILoginResponse> = await postCall(
        `${USER_MANAGEMENT_SERVICE}/login`,
        loginBody
      );

      if (response?.data?.token) {
        sessionStorage.setItem("token", response?.data?.token);
        const decodedData: ITokenDecodedData = await jwtDecode(
          response.data?.token
        );

        user = await this.findUser(decodedData.userId);

        const rolesList = await MasterService.getAllRoles();
        const rolesOptions = makeDropdownOptions(rolesList, "roleNameEnglish", "roleId");
        sessionStorage.setItem("rolesList", JSON.stringify(rolesOptions));
      } else if (response?.data?.alreadyActiveUser) {
        user = {
          user: {
            ...UserModel.init(),
            alreadyActiveUser: response.data?.alreadyActiveUser,
            userId: response?.data?.userId
          },
          userDetails: userDetailsData
        }
      }

      return user;
    } catch (error) {
      toast.error(error as string);
      throw error;
    }
  };

  /** Call an API to Find User
   * @param userId string
   */
  public static findUser = async (userId: number, alreadyActiveUser: boolean = false): Promise<IFindUserData> => {
    try {
      const response: AxiosResponse<IFindUserResponse> = await postCall(
        `${USER_MANAGEMENT_SERVICE}/findUser`,
        {
          userId: userId,
        }
      );

      //  set the usermodel and return the user data
      const userDetails = response?.data;

      const roles = userDetails?.roleId;
      const user = new UserModel(
        userDetails?.userId,
        userDetails?.userName,
        userDetails?.mobileNumber,
        userDetails?.emailId,
        [roles],
        userDetails?.postalPinCode,
        userDetails?.identificationProofId,
        alreadyActiveUser,
        userDetails?.commissionId,
      );

      return { user, userDetails };
    } catch (error) {
      toast.error(error as string);
      throw error;
    }
  };

  /** Call an API to perform login
   * @param username string
   * @param password string
   */
  public static loginWithOtp = async ({
    emailOrMobile,
    otpText,
    passwordLogin
  }: LoginWithOtp) => {
    try {
      let user: IFindUserData = {
        user: UserModel.init(),
        userDetails: userDetailsData,
      };
      const response: AxiosResponse<ILoginResponse> = await postCall(
        `${USER_MANAGEMENT_SERVICE}/login`,
        {
          emailOrMobile: emailOrMobile,
          otpText: otpText,
          passwordLogin: passwordLogin
        }
      );

      if (response.data?.token) {
        sessionStorage.setItem("token", response?.data?.token);
        const decodedData: ITokenDecodedData = await jwtDecode(
          response.data?.token
        );
        user = await this.findUser(decodedData.userId);

        const rolesList = await MasterService.getAllRoles();
        const rolesOptions = makeDropdownOptions(
          rolesList,
          "roleNameEnglish",
          "roleId"
        );
        sessionStorage.setItem("rolesList", JSON.stringify(rolesOptions));
      }

      return user;
    } catch (error) {
      toast.error(error as string);
      throw error;
    }
  };

  /** Call an API to perform registration
   * @param username string
   * @param password string
   * @param mobilenumber number
   */
  public static register = async ({
    username,
    password,
    mobilenumber,
    clientIpAddress,
  }: Register): Promise<IRegisterResponse> => {
    const response: AxiosResponse<IRegisterResponse> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/addUser`,
      {
        userName: username,
        mobileNumber: parseInt(mobilenumber),
        userLoginPassword: password,
        clientIpAddress,
      }
    );
    return response?.data;
  };

  public static registerNewUser = async (data: IRegisterNewUserRequest): Promise<IRegisterResponse> => {
    const { userName, userLoginPassword, otpText, emailOrMobile } = data
    const response: AxiosResponse<IRegisterResponse> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/addUser`,
      {
        userName: userName,
        userLoginPassword: userLoginPassword,
        otpText: otpText,
        emailOrMobile: emailOrMobile
      }
    );
    return response?.data;
  };

  /** Call an API to perform otp verification
   * @param mobilenumber number
   * @param otp number
   */
  public static readonly otpVerification = async ({ mobilenumber, otp }: any) => {
    const response = await postCall(`${USER_MANAGEMENT_SERVICE}/validate_otp`, {
      emailOrMobile: mobilenumber,
      otpText: otp,
    });
    return response?.data;
  };

  public static userStatus = async (
    mobileNumber: string
  ): Promise<IUserStatus> => {
    const params = { mobileNumber };
    const response: AxiosResponse<IUserStatus> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/checkUserStatus`,
      params
    );
    return response?.data;
  };

  public static deletePresentUser = async (
    mobileNumber: string
  ): Promise<OtpModel> => {
    const data = { mobileNumber: parseInt(mobileNumber) };
    const response: AxiosResponse<OtpModel> = await deleteCall(
      `${USER_MANAGEMENT_SERVICE}/deletePresentUser`,
      data
    );
    return response?.data;
  };

  /** Call an API to perform forget password ith otp
   * @param emailOrMobile  string
   
   */
  public static generateOtpWithMobileNumber = async (
    emailOrMobile: string,
    clientIpAddress: string
  ): Promise<string> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/otp_generated_for_login`,
      {
        emailOrMobile: emailOrMobile,
        forcedLogin: true,
      }
    );
    return response?.data;
  };

  // Generate OTP for v2 
  // @param mobilenumber number
  //    * @param userRegistered boolean
  public static generateOtp = async (
    emailOrMobile: string,
    userRegistered: boolean
  ): Promise<string> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/generateOtp`,
      {
        emailOrMobile: emailOrMobile,
        userRegistration: userRegistered,
      }
    );
    return response?.data;
  };

  /**
   * Call an API to put (update) case details
   * @param data CaseDetailModel
   * @returns Promise<ICaseDetailsResponse>
   */
  public static resetPassword = async (
    data: ResetPasswordModel
  ): Promise<IPasswordResetResponse> => {
    const response: AxiosResponse<IPasswordResetResponse> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/resetPassword`,
      data
    );

    return response?.data;
  };

  /**
   * Call an API to post change password
   * @param data changePassword
   * @returns
   */
  public static ChangePassword = async (
    data: ChangePasswordModel
  ): Promise<string> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/changePassword`,
      data
    );

    return response?.data;
  };
  /**
   * Call an API to post change password
   * @param data changePassword
   * @returns
   */
  public static ChangeMobileOrEmail = async (
    data: ChangeMobileNumberOrEmailModel
  ): Promise<IChangeMobileNumberOrEmailResponse> => {
    const response: AxiosResponse<IChangeMobileNumberOrEmailResponse> =
      await postCall(
        `/confonetUser/auth/changeMobileNumberOrEmail`,
        data
      );

    return response?.data;
  };

  /**
   * Call an API to post change password
   * @param data changePassword
   * @returns
   */
  public static ChangeMobileOrEmailOtp = async (
    data: ChangeMobileNumberOrEmailOtpModel
  ): Promise<string> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/changeMobileNumberOrEmailValidateOtp`,
      data
    );

    return response?.data;
  };

  /**
 * Call an API to get commission case list
 */
  public static readonly generateCaptcha = async (data: string): Promise<string> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/captcha`, {
      emailOrMobile: data
    }

    );
    return response?.data;
  };

  /**
* Call an API to generate Captcha
*/
  public static readonly generateCaptchNew = async (): Promise<string> => {
    const response: AxiosResponse<string> = await getCall(
      `${USER_MANAGEMENT_SERVICE}/generateCaptcha`,
      {}
    );
    return response?.data;
  };

  /**
   * Call an API to get commission case list
   */
  public static verifyCaptcha = async (data: any): Promise<number> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/verify-captcha`,
      data
    );

    return response?.status;
  };

  /**
   * Call an API to verify captcha generated
   */
  public static verifyCaptchaNew = async (data: any): Promise<any> => {
    const response: AxiosResponse<string> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/verifyCaptcha`,
      data
    );

    return response;
  };

  /**
   * Call an API to generate random Number 
   * @returns 
   */
  public static generateRandomNumber = async (): Promise<string> => {
    const response: AxiosResponse<string> = await getCall(
      `${USER_MANAGEMENT_SERVICE}/getRandomNumber`
    );

    return response?.data;
  };

  /**
   * Call an API to Logout user
   */
  public static logoutUser = async (userId: number): Promise<number> => {
    const params = {
      userId
    };

    const response: AxiosResponse<string> = await getCall(
      `${USER_MANAGEMENT_SERVICE}/logout`,
      params
    );

    return response?.status;
  };
  public static readonly getAllNewUser: () => Promise<IAddNewUserResponse[]> = async (): Promise<IAddNewUserResponse[]> => {
    const response: AxiosResponse<IAddNewUserResponse[]> = await getCall(
        `${USER_MANAGEMENT_SERVICE}/getUserList`
    );
    return response?.data;
};

public static CreateNewUser = async (data: ICreateNewUserRequest): Promise<ICreateNewUserResponse> => {
  const { name,mobileNumber,email,address,role,password } = data
  const response: AxiosResponse<ICreateNewUserResponse> = await postCall(
    `${USER_MANAGEMENT_SERVICE}/addUser`,
    {
      name:name,
      mobileNumber:mobileNumber,
      email:email,
      address:address,
      role:role,
      password:password

    }
  );
  return response?.data;
};
}
