import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import { CONSUMER, CONSUMER_ADD_AS_PARTY, CONSUMER_AGAINST_ME_DISPOSED_CASES, CONSUMER_AGAINST_ME_FILED_CASES, CONSUMER_AGAINST_ME_PENDING_CASES, CONSUMER_APPEAL_TYPE_CASE_PREVIEW, CONSUMER_APPEAL_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_APPEAL_TYPE_CASE_STEPPERFORM, CONSUMER_CASEFILING, CONSUMER_CASELIST_PREVIEW, CONSUMER_DASHBOARD, CONSUMER_DISPOSED_CASES, CONSUMER_DRAFT_CASES, CONSUMER_EDITPROFILE, CONSUMER_FILED_CASES, CONSUMER_FILENEWCASE_PREVIEW, CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW, CONSUMER_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM, CONSUMER_FILENEWCASE_STEPPERFORM, CONSUMER_INTERLOCUTORY_TYPE_CASE_PREVIEW, CONSUMER_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_INTERLOCUTORY_TYPE_CASE_STEPPERFORM, CONSUMER_KNOW_YOUR_CASE, CONSUMER_MISCELLANEOUS_TYPE_CASE_PREVIEW, CONSUMER_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW, CONSUMER_MISCELLANEOUS_TYPE_CASE_STEPPERFORM, CONSUMER_PAYMENT_FAILURE, CONSUMER_PAYMENT_HISTORY, CONSUMER_PAYMENT_SUCCESS, CONSUMER_PAYMENTPENDING_CASES, CONSUMER_PENDING_CASES, CONSUMER_PENDINGFORAPPROVAL_CASES, CONSUMER_PENDINGFORSCRUTINY_CASES, CONSUMER_REJOINDER, CONSUMER_REJOINDERDOCUMENTS, CONSUMER_REPLY, CONSUMER_REPLYDOCUMENTS, CONSUMER_REVERTEDINSCRUTINY_CASES, CONSUMER_SUBSEQUENT_FILING, CONSUMER_SUBSEQUENTDOCUMENTS, CONSUMER_TRANSFER_TYPE_CASE_STEPPERFORM, CONSUMER_UPLOADREJOINDERDOCUMENTS, CONSUMER_UPLOADREPLYDOCUMENTS, CONSUMER_UPLOADSUBSEQUENTDOCUMENTS } from "../CONSTANTS";
import PaymentSuccess from "../../pages/Consumer/PaymentHistory/PaymentSuccess";
import PaymentFailure from "../../pages/Consumer/PaymentHistory/PaymentFailure";

// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const ConsumerDashboardView = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/ConsumerDashboardView').then((module) => ({ default: module.ConsumerDashboardView })));
const FiledCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerCasesInCommission/FiledCases').then((module) => ({ default: module.FiledCaseList })));
const DisposedCaseView = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/DisposedCases/DisposedCaseView'));
const PendingCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerCasesInCommission/PendingCases').then((module) => ({ default: module.PendingCaseList })));
const DraftCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.DraftCaseList })));
const PendingForScrutinyCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.PendingForScrutinyCaseList })));
const PendingForApprovalCaseList = lazy(() => import('../../pages/dashboard/components/ConsumerDashboard/components/ConsumerRegisteredCases/PendingForApprovalCaseList').then((module) => ({ default: module.PendingForApprovalCaseList })));
const PaymentPendingCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.PaymentPendingCaseList })));
const RevertedInScrutinyCaseList = lazy(() => import('../../pages/dashboard').then((module) => ({ default: module.RevertedInScrutinyCaseList })));
const CasePreview = lazy(() => import('../../pages/dashboard/components/CasePreview').then((module) => ({ default: module.CasePreview })));
const Preview = lazy(() => import('../../pages/Consumer/FileNewCase/components/Preview'));
const FileNewCase = lazy(() => import('../../pages/Consumer/FileNewCase'));
const CaseType = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseType'));
const CaseFileStepperForm = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseFileStepperForm'));
const CaseFileStepperFormSpeechEnabled = lazy(() => import('../../pages/Consumer/FileNewCase/components/CaseFileStepperFormSpeechEnabled'));
const AppealTypeContainer = lazy(() => import('../../pages/Consumer/AppealTypeCases'));
const InterlocutoryApplicationContainer = lazy(() => import('../../pages/Consumer/InterlocutoryApplicationCase'));
const AppealTypePreview = lazy(() => import('../../pages/Consumer/AppealTypeCases/components/AppealTypePreview'));
const PaymentHistory = lazy(() => import('../../pages/Consumer/PaymentHistory'));
const Reply = lazy(() => import('../../pages/Consumer/Reply'));
const UploadReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/UploadReplyDocuments'));
const ReplyDocuments = lazy(() => import('../../pages/Consumer/Reply/component/ReplyDocuments'));
const SubsequentFiling = lazy(() => import('../../pages/Consumer/SubsequentFiling'));
const UploadSubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/UploadSubsequentDocuments'));
const SubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/SubsequentDocuments'));
const AddAsParty = lazy(() => import('../../pages/Consumer/AddAsParty'));
const KnowYourCase = lazy(() => import('../../pages/Consumer/KnowYourCase'));

// list of consumer Routes
export const consumerRoutes: IRoute[] = [
    {
        path: "",
        element: <PrivateRoute requiredRoles={[ROLE.Consumer]} />,
        children: [
            {
                path: CONSUMER_EDITPROFILE,
                element: <EditProfile />
            },
            {
                path: CONSUMER,
                element: <Dashboard />,
                children: [
                    {
                        path: CONSUMER_DASHBOARD,
                        element: <ConsumerDashboardView />
                    }
                ]
            },
            {
                path: CONSUMER_FILED_CASES,
                element: <FiledCaseList />
            },
            {
                path: CONSUMER_DISPOSED_CASES,
                element: <DisposedCaseView />
            },
            {
                path: CONSUMER_PENDING_CASES,
                element: <PendingCaseList />
            },
            {
                path: CONSUMER_DRAFT_CASES,
                element: <DraftCaseList />
            },
            {
                path: CONSUMER_PENDINGFORSCRUTINY_CASES,
                element: <PendingForScrutinyCaseList />
            },
            {
                path: CONSUMER_PENDINGFORAPPROVAL_CASES,
                element: <PendingForApprovalCaseList />
            },
            {
                path: CONSUMER_PAYMENTPENDING_CASES,
                element: <PaymentPendingCaseList />
            },
            {
                path: CONSUMER_REVERTEDINSCRUTINY_CASES,
                element: <RevertedInScrutinyCaseList />
            },
            {
                path: CONSUMER_FILENEWCASE_REVERTED_CASE_PREVIEW,
                element: <Preview />
            },
            {
                path: CONSUMER_CASELIST_PREVIEW,
                element: <CasePreview />
            },
            {
                path: CONSUMER_AGAINST_ME_FILED_CASES,
                element: <FiledCaseList />
            },
            {
                path: CONSUMER_AGAINST_ME_DISPOSED_CASES,
                element: <DisposedCaseView />
            },
            {
                path: CONSUMER_AGAINST_ME_PENDING_CASES,
                element: <PendingCaseList />
            },
            {
                path: CONSUMER_CASEFILING,
                element: <FileNewCase />,
                children: [
                    {
                        path: CONSUMER_CASEFILING,
                        element: <CaseType />
                    },
                    {
                        path: CONSUMER_FILENEWCASE_STEPPERFORM,
                        element: <CaseFileStepperForm />
                    },
                    {
                        path: CONSUMER_FILENEWCASE_SPEECH_ENABLED_STEPPERFORM,
                        element: <CaseFileStepperFormSpeechEnabled />
                    },
                    {
                        path: CONSUMER_APPEAL_TYPE_CASE_STEPPERFORM,
                        element: <AppealTypeContainer />
                    },
                    {
                        path: CONSUMER_INTERLOCUTORY_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: CONSUMER_TRANSFER_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: CONSUMER_MISCELLANEOUS_TYPE_CASE_STEPPERFORM,
                        element: <InterlocutoryApplicationContainer />
                    },
                    {
                        path: CONSUMER_FILENEWCASE_PREVIEW,
                        element: <Preview />
                    },
                    {
                        path: CONSUMER_APPEAL_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: CONSUMER_INTERLOCUTORY_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: CONSUMER_MISCELLANEOUS_TYPE_CASE_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: CONSUMER_APPEAL_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: CONSUMER_INTERLOCUTORY_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    },
                    {
                        path: CONSUMER_MISCELLANEOUS_TYPE_CASE_REVERTED_PREVIEW,
                        element: <AppealTypePreview />
                    }
                ]
            },
            {
                path: CONSUMER_PAYMENT_HISTORY,
                element: <PaymentHistory />,
            },
            {
                path: CONSUMER_PAYMENT_SUCCESS,
                element: <PaymentSuccess />
            },
            {
                path: CONSUMER_PAYMENT_FAILURE,
                element: <PaymentFailure />
            },
            {
                path: CONSUMER_REPLY,
                element: <Reply />
            },
            {
                path: CONSUMER_UPLOADREPLYDOCUMENTS,
                element: <UploadReplyDocuments />
            },
            {
                path: CONSUMER_REPLYDOCUMENTS,
                element: <ReplyDocuments />
            },
            {
                path: CONSUMER_REJOINDER,
                element: <Reply />
            },
            {
                path: CONSUMER_UPLOADREJOINDERDOCUMENTS,
                element: <UploadReplyDocuments />
            },
            {
                path: CONSUMER_REJOINDERDOCUMENTS,
                element: <ReplyDocuments />
            },
            {
                path: CONSUMER_SUBSEQUENT_FILING,
                element: <SubsequentFiling />
            },
            {
                path: CONSUMER_UPLOADSUBSEQUENTDOCUMENTS,
                element: <UploadSubsequentDocuments />
            },
            {
                path: CONSUMER_SUBSEQUENTDOCUMENTS,
                element: <SubsequentDocuments />
            },
            {
                path: CONSUMER_ADD_AS_PARTY,
                element: <AddAsParty />
            },
            {
                path: CONSUMER_KNOW_YOUR_CASE,
                element: <KnowYourCase />
            }
        ]
    }
];
