import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";

type BasicCheckboxProps = FormControlLabelProps & {
  label: string;
  checked?: boolean;
};
export function BasicCheckbox({ label = "", control = <Checkbox />, ...rest }: BasicCheckboxProps) {
  return <>
    <div className="form-control-group margin-bottom-15 margin-left-10">
      <FormControlLabel
        {...rest}
        control={control}
        label={label}
      />
    </div>
  </>;
}
