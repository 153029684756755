export const reportInfoTable = [
  //@ type: commission_wise
  //@ Report no: 1
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: categoryWiseReport
  //@ Report no: 2
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@ type: StateWisePendencyReport
  //@ Report no: 3
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: StatePendencyForOneYearReport
  //@ Report no: 4
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: StateDistrictReport
  //@ Report no: 5
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: RegionWiseReport
  //@ Report no: 6
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: DisposalMoreThanOneYearReport
  //@ Report no: 7
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: disposalWithinOneYearReport
  //@ Report no: 8
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: StateCommissionDurationWisePendency
  //@ Report no: 9
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: CaseType_Wise_Report
  //@ Report no: 10
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: Registered_Users_Report
  //@ Report no: 11
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: e-Jagriti_Case_Detail_Report
  //@ Report no: 12
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: StageWiseReport
  //@ Report no: 13
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: StateStageWiseReport
  //@ Report no: 14
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: State_District_Stage_wise_report
  //@ Report no: 15
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: YearlyWiseCategoryReport
  //@ Report no: 16
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],

  //@ type: RegionWiseEjagritiReport
  //@ Report no: 17
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
];
