import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

type TabProps = {
  value: number;
  label: string;
  translatedLabel?: string;
  children: React.ReactNode;
};

type BasicTabProps = TabsProps & {
  tabs: Array<TabProps>;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tab-content">{children}</div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

/** Renders the tabs
 * @param BasicTabProps
 */
export function BasicTabs({ tabs, value, ...rest }: BasicTabProps) {
  const theme = useTheme();

  return (
    <div className="tab-panel">
      <div className="tab-panel-header">
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          // variant="fullWidth"
          aria-label="full width tabs"
          {...rest}
        >
          {tabs.map(({ label }: TabProps, index: number) => (
            <Tab disableRipple key={`tab_${index}`} label={label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </div>
      <div className="tab-panel-content padding-top-25 padding-bottom-25">
        {tabs.map(({ children }: TabProps, index: number) => (
          <TabPanel
            key={`tab_panel_${index}`}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {children}
          </TabPanel>
        ))}
      </div>
    </div>
  );
}
