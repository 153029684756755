import React from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from '@mui/icons-material/Language';
import { Dropdown } from "../components";
import { languageDropdown } from "./CONSTANTS";

const LanguageSelection = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState<string>(localStorage.getItem("lng") ?? "en");

  // language change handler
  const handleLanguageChange = React.useCallback(
    (value: string) => {
      setLanguage(value);
      let languageValue;
      if (value === "हिं") {
        languageValue = "hi"  // Hindi
        i18n.changeLanguage(languageValue)
      }
      else if (value === "मराठी") {
        languageValue = "mr"  // Marathi
        i18n.changeLanguage(languageValue)
      }
      else if (value === "ਪੰਜਾਬੀ") {
        languageValue = "pa"  // Punjabi
        i18n.changeLanguage(languageValue)
      }
      else if (value === "ગુજ") {
        languageValue = "gu"  // Gujarati
        i18n.changeLanguage(languageValue)
      }
      else if (value === "ಕನ್ನಡ") {
        languageValue = "kn"  // Kannada
        i18n.changeLanguage(languageValue)
      }
      else if (value === "ଓଡିଆ") {
        languageValue = "or"  // Odia
        i18n.changeLanguage(languageValue)
      }
      else if (value === "বাংলা") {
        languageValue = "bn"  // Bengali
        i18n.changeLanguage(languageValue)
      }
      else if (value === "മലയാളം") {
        languageValue = "ml"  // Malayalam
        i18n.changeLanguage(languageValue)
      }
      else if (value === "తెలుగు") {
        languageValue = "te"  // Telugu
        i18n.changeLanguage(languageValue)
      }
      else {
        i18n.changeLanguage(value);
      }
      localStorage.setItem("lng", value);
    },
    [i18n]
  );

  return (
    <>
      <Dropdown
        size="small"
        id="languageSelector"
        items={languageDropdown}
        label=""
        renderValue={() => {
          return (
            <>
              <LanguageIcon className="translateIcon" />
              <span>{language.toLocaleUpperCase()}</span>
            </>
          );
        }}
        value={language}
        sx={{ marginRight: 2, paddingRight: 2 }}
        onDropDownChange={(e) => handleLanguageChange(e.target.value)}
      />
    </>
  );
};

export default LanguageSelection;
