import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./CONSTANTS";
import { loaderService } from "./CommonService/loaderService";
import { UNAUTHORIZED } from "../navigation/CONSTANTS";
import { ErrorCodeText } from "../utils/Enum";
import { errorMessage } from "../utils/CONSTANTS";
import { userLogout } from "../utils/Helper";

export const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

function setupInterceptors() {
  instance.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      if (!request.url?.includes("/v2/getDashboardFileCount")) {
        loaderService.setLoading(true); // Read token from sessionStorage
      }
      const token = sessionStorage.getItem("token");
      if (token && !request.url?.includes('/logout')) {
        request.headers["Authorization"] = `Bearer ${token}`;
      } else {
        request.headers["Authorization"] = null;
      }
      request.headers["Accept"] = "application/json"
      return request;
    },
    (error: AxiosError<any>) => {
      loaderService.clearLoading();
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      loaderService.clearLoading();
      return response;
    },
    async (error: AxiosError<any>) => {
      loaderService.clearLoading();

      // handling network error
      if (error.code === "ERR_NETWORK") {
        toast.error(error.message);
        return Promise.reject(error);
      }

      if (error?.response?.status === 401) {
        await userLogout();
        localStorage.clear();
      }

      const { data } = error.response!;

      const errorCode = data?.errorCode?.slice(
        data?.errorCode?.indexOf(".") + 1
      );

      switch (errorCode) {
        case ErrorCodeText.USER_ALREADY_EXIST:
          toast.error(errorMessage.USER_ALREADY_EXIST);
          break;
        case ErrorCodeText.ROLE_DOES_NOT_EXIST:
          toast.error(errorMessage.ROLE_DOES_NOT_EXIST);
          break;
        case ErrorCodeText.FIELD_ALREADY_EXIST:
          toast.error(errorMessage.FIELD_ALREADY_EXIST);
          break;
        case ErrorCodeText.INVALID_ARGUMENT:
          toast.error(errorMessage.INVALID_ARGUMENT);
          break;
        case ErrorCodeText.USER_NOT_FOUND:
          toast.error(errorMessage.USER_NOT_FOUND);
          break;
        case ErrorCodeText.ACCED_DENIED:
          toast.error(errorMessage.ACCED_DENIED);
          window.location.assign(UNAUTHORIZED);
          break;
        case ErrorCodeText.EMPTY_REQUEST:
          toast.error(errorMessage.EMPTY_REQUEST);
          break;
        case ErrorCodeText.DATA_NOT_FOUND:
          toast.error(errorMessage.DATA_NOT_FOUND);
          break;
        case ErrorCodeText.UNKNOWN:
          toast.error(errorMessage.UNKNOWN);
          break;
        default:
          toast.error(data.message || errorMessage.SOMETHING_WENT_WRONG);
          break;
      }
      return Promise.reject(error);
    }
  );
}

setupInterceptors();

export default instance;
