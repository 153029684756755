import "./statics.scss"

export default function Statics() {
    return (
        <section className="theme-bg-primary section-padding">
            <div className="">
                <div className="d-flex flex-lg-row flex-column gap-3">
                    <div className="text-center fw-bold max-width-724 my-auto">
                        <h5 className="text-white">Stay Informed, Stay Empowered!</h5>
                        <h2 className="fw-bold text-white">Statistics and <span className="theme-secondary">Guides</span></h2>
                        <h3 className="d-none d-lg-block text-white">
                            A "legal resource hub" typically refers to a centralized platform or website that provides
                            access to a variety of legal resources, information, and tools
                        </h3>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-center gap-4">
                        <div className="max-width-351-square bg-secondary" style={{
                            backgroundImage: "url('/images/legal2.jpeg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}>
                            <div className="overlay">
                                <div className="overlay-text">Tutorial</div>
                            </div>
                        </div>
                        <div className="max-width-351-square bg-secondary" style={{
                            backgroundImage: "url('/images/legal1.jpeg')",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}>
                            <div className="overlay">
                                <div className="overlay-text">Reports</div>
                            </div>
                        </div>
                    </div>
                    <h3 className="d-block d-lg-none text-center text-white">
                        A "legal resource hub" typically refers to a centralized platform or website that provides
                        access to a variety of legal resources, information, and tools
                    </h3>
                </div>
            </div>
        </section>
    )
}