import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { useDispatch } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// import all the reducers here
import FileNewCaseReducer from "./FileNewCaseReducer/FileNewCaseReducer";
import CaseReducer from "./CaseReducer/CaseReducer";
import AuthReducer from "./AuthReducer/AuthReducer";
import SpeechReducer from "./SpeechReducer/SpeechReducer";
import ClientIpAddressReducer from "./ClientIpReducer/ClientIpAddressReducer";
import CommissionCaseReducer from "./CommissionCaseReducer/CommissionCaseReducer";
import MasterReducer from "./MasterReducer/MasterReducer";

// configure the persist in redux
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  fileNewCase: FileNewCaseReducer,
  case: CaseReducer,
  user: AuthReducer,
  speech: SpeechReducer,
  clientIp: ClientIpAddressReducer,
  CommissionCaseReducer: CommissionCaseReducer,
  master: MasterReducer
});

// make persist reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure the redux store
export const store = configureStore({
  // define all the reducers here
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
