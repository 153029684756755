import React from 'react'
import './searchcases.scss'
import { useState } from 'react';

type ButtonProps = {
  text?: string;
  isActive?: boolean;
  handleClick?: () => void;
};


const ButtonComponent: React.FC<ButtonProps> = (
    { text,isActive,handleClick }
) => {
    return (
        <div className={`searchcase-section-custom-btn1 ${isActive ? 'searchcase-section-custom-btn1--active' : 'searchcase-section-custom-btn1--inactive'}`} 
        onClick={handleClick}>
            <div className='searchcase-section-custom-btn1-left'>
                <img
                    src="/images/btn-icon1.png"
                    alt="Icon"
                    className="h-12 w-12 text-black"
                />
            </div>
            <div className="searchcase-section-custom-btn1-right">
                {text}
            </div>
        </div>
    );
};


const Searchcases = () => {
    const [activeButton, setActiveButton] = useState<string | null>(null);

    const handleButtonClick = (buttonText: string) => {
      setActiveButton(buttonText);
    };
  
    const getPlaceholderText = () => {
      if (activeButton === "Case Status") {
        return "Search Case Status";
      } else if (activeButton === "Case History") {
        return "Search Case History";
      } else if (activeButton === "Judgements") {
        return "Search Judgements";
      } else {
        return "Search Case Status/ History/ Judgement";
      }
    };
  
    return (
        <section className='container-fluid searchcase-section'>
            <h1 className='searchcase-section-heading'>Find what you <span className=''>need</span> without any <span>difficulties</span></h1>
            <p className='searchcase-section-descrition'>Get instant access to real-time updates on your case status, case  history, and judgement through our intuitive and user-friendly interface . provides a seamless way to track your case's progress, giving you complete transparency and control over your legal matters.</p>
            <div className='searchcase-section-btn-container'>
                <ButtonComponent
                text="Case Status"
                isActive={activeButton === "Case Status"}
                handleClick={() => handleButtonClick("Case Status")}
                 />
                <ButtonComponent
                text="Case History"
                isActive={activeButton === "Case History"}
                handleClick={() => handleButtonClick("Case History")}
                 />
                <ButtonComponent
                text="Judgements"
                isActive={activeButton === "Judgements"}
                handleClick={() => handleButtonClick("Judgements")}
                 />
            </div>
            <div className='searchcase-section-search'>
            <div className='searchcase-section-search-container'>
                <input
                    tabIndex={0}
                    title="Search Here"
                    id="topbar-input"
                    placeholder={getPlaceholderText()}
                    type="text"
                    maxLength={100}
                />
                <div className="searchcase-section-search-btn-container" >
                    <select>
                    <option className="searchcase-section-search-option" value="">Select</option>
                        <option className="searchcase-section-search-option" value="casestatus">Case Status</option>
                        <option className="searchcase-section-search-option" value="casehistory">Case History</option>
                        <option  className="searchcase-section-search-option" value="Judgements">Judgements</option>
                    </select>
                    <button>
                        Search
                    </button>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Searchcases
