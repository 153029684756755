import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ConfonetAlertDialogProps = DialogProps & {
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
};

/** Renders the alert dialog box  */

export function ConfonetAlertDialog ({
  title,
  children,
  open,
  onClose,
  ...rest
}: ConfonetAlertDialogProps) {

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        {...rest}
      >
        {children}
      </Dialog>
    </>
  );
}