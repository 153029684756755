import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import { ANALYTICS, ANALYTICS_DASHBOARD, ANALYTICS_EDITPROFILE } from "../CONSTANTS";

// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const DashboardView = lazy(() => import('../../pages/dashboard/DashboardView'));

// list of consumer Routes
export const analyticAdminRoutes: IRoute[] = [
    {
        path: "",
        element: <PrivateRoute requiredRoles={[ROLE.AnalyticsAdmin]} />,
        children: [
            {
                path: ANALYTICS_EDITPROFILE,
                element: <EditProfile />
            },
            {
                path: ANALYTICS,
                element: <Dashboard />,
                children: [
                    {
                        path: ANALYTICS_DASHBOARD,
                        element: <DashboardView />
                    }
                ]
            }
        ]
    }
];