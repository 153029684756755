import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

export interface BreadcrumbsProps {
  label: string;
  to: string;
};

type BasicBreadcrumbsProps = {
  items: Array<BreadcrumbsProps>;
  className?: string;
}

/** BasicBreadcrumbs component
@param BasicBreadcrumbsProps
}
**/

export function BasicBreadcrumbs({ items, className, ...rest }: BasicBreadcrumbsProps) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={className}
      {...rest}>
      {items.map(({ to, label }: BreadcrumbsProps, index) => {
        return (
          index === items.length - 1 ?
            <Typography key={`typography_${index}`}>
              {label}
            </Typography>
            :
            <Link key={`link_${index}`} className='text-decoration-none' to={to}>
              {label}
            </Link>
        );
      })}
    </Breadcrumbs >
  )
}

