import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFindUserResponse } from "../../models/user/LoginModel";



export const userDetailsData: IFindUserResponse = {
    userName: "",
    userId: 0,
    mobileNumber: "",
    userLoginPassword: "",
    identificationProofId: 0,
    identificationProofNumber: "",
    identificationProofDocumentPath: "",
    createdOnDateTime: "",
    emailId: "",
    houseAppartmentBuilding: "",
    blockStreetMohalla: "",
    landmarkLocality: "",
    status: false,
    activeStatus: 0,
    roleId: 0,
    userLoginId: "",
    addressTypeId: 0,
    stateId: 0,
    districtId: 0,
    countryId: 0,
    postOffice: "",
    postOfficeRecordId: 0,
    postalPinCode: 0,
    profilePicturePath: "",
    fileContentBase64: "",
    identificationProofContentBase64: ""
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {userDetailsData},
    reducers: {
        saveUser: (state, action: PayloadAction<IFindUserResponse>) => {
            state.userDetailsData = action.payload;
        },
        clearUserData: (state) => {
            state.userDetailsData = {
                userName: "",
                userId: 0,
                mobileNumber: "",
                userLoginPassword: "",
                identificationProofId: 0,
                identificationProofNumber: "",
                identificationProofDocumentPath: "",
                createdOnDateTime: "",
                emailId: "",
                houseAppartmentBuilding: "",
                blockStreetMohalla: "",
                landmarkLocality: "",
                status: false,
                activeStatus: 0,
                roleId: 0,
                userLoginId: "",
                addressTypeId: 0,
                stateId: 0,
                districtId: 0,
                countryId: 0,
                postOffice: "",
                postOfficeRecordId: 0,
                postalPinCode: 0,
                profilePicturePath: "",
                fileContentBase64: "",
                identificationProofContentBase64: ""
            };
            localStorage.removeItem('persist:root');
        },
    }
});

export const { saveUser, clearUserData } = userSlice.actions;
export default userSlice.reducer