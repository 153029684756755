import Carousel from 'react-material-ui-carousel'

type CarouselProps = {
  imgPath: string;
  title?: string;
  description: string;
};


type BasicCarouselProps = {
  items: Array<CarouselProps>;
}

export function BasicCarousel({ items, ...rest }: BasicCarouselProps) {
  const Item = ({ imgPath, title, description }: CarouselProps) => {
    return (
      <>
        <div className="card card-login-info bg-primary">
          <div className="card-body card-login-info-body text-center">
            <img
              src={imgPath}
              className="img-fluid margin-bottom-25"
              alt="logo"
            />
            <h4 className="card-title card-login-info-title">
              {title}
            </h4>
            <p className="card-text card-login-info-text">
              {description}
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <Carousel
        className="carousel"
        interval={4000}
        fullHeightHover={true}
        navButtonsAlwaysInvisible={true}
        navButtonsProps={{ className: "nav-btn" }}
        indicatorContainerProps={{ className: "carousel-indicator" }}
        indicatorIconButtonProps={{ className: "carousel-indicator-btn" }}
        activeIndicatorIconButtonProps={{ className: "carousel-indicator-active-btn" }}
        {...rest}>
        {items.map((item, index) => (
          <Item key={`carousel_item_${index}`} {...item} />
        ))}
      </Carousel>
    </div >
  )
}

