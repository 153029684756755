import React from "react";

const verticalBox: React.CSSProperties = {
  width: "5%",
  height: "100%",
  background: "#00A14B",
  alignSelf: "flex-start",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const ApprovedBox = () => {
  return (
    <div
      style={{
        ...verticalBox,
      }}
    >
      <div className="vertical-text">Approved</div>
    </div>
  );
};

export default ApprovedBox;
