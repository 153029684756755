import React, { FC } from "react";

export interface FunnelDataPoint {
  label: string;
  value: number;
  color: string;
}

interface FunnelChartProps {
  data: FunnelDataPoint[];
  width: number;
  height: number;
  labelFontSize?: number;
  valueFontSize?: number;
}

const FunnelChart: FC<FunnelChartProps> = ({
  data,
  width,
  height,
  labelFontSize = 12,
  valueFontSize = 12,
}) => {
  const renderChart = (): JSX.Element => {
    const boxWidthFactor = 1; // Adjust the width of the boxes
    const verticalSpacing = 24; // Adjust the vertical spacing between value and text

    const pathData = data.map((point, index) => {
      const progress = (index + 1) / data.length; // Calculate the progress as a value between 0 and 1
      const bottomWidth = progress * (width * boxWidthFactor);
      const topWidth =
        index === 0
          ? bottomWidth
          : (progress - 1 / data.length) * (width * boxWidthFactor);

      const topOffset = (width - topWidth) / 2;
      const bottomOffset = (width - bottomWidth) / 2;

      const path = `M ${topOffset} ${index * height} L ${
        topOffset + topWidth
      } ${index * height} L ${bottomOffset + bottomWidth} ${
        (index + 1) * height
      } L ${bottomOffset} ${(index + 1) * height} Z`;

      const labelX = width / 2;
      const labelY = (index + 0.5) * height;

      return {
        path,
        color: point.color,
        label: point.label,
        value: point.value,
        labelX,
        labelY,
        topWidth,
        bottomWidth,
        topOffset,
        bottomOffset,
      };
    });

    return (
      <svg
        width={width}
        height={height * data.length}
        style={{ transform: "rotate(180deg)" }}
      >
        {pathData.map((segment, index) => (
          <g key={index}>
            <path d={segment.path} fill={segment.color} stroke="none" />
            <text
              x={segment.labelX}
              y={segment.labelY - verticalSpacing / 2} // Adjust the offset to position the value higher
              textAnchor="middle"
              fill="white"
              transform={`rotate(180, ${segment.labelX}, ${segment.labelY})`}
              dominantBaseline="middle"
              fontSize={valueFontSize}
            >
              {segment.value}
            </text>
            <text
              x={segment.labelX}
              y={segment.labelY + verticalSpacing / 2} // Adjust the offset to position the text below the value
              textAnchor="middle"
              fill="white"
              transform={`rotate(180, ${segment.labelX}, ${segment.labelY})`}
              dominantBaseline="middle"
              fontSize={labelFontSize}
            >
              {segment.label}
            </text>
            {index < data.length - 1 && (
              <path
                d={`M ${segment.bottomOffset} ${(index + 1) * height} L ${
                  segment.bottomOffset + segment.bottomWidth
                } ${(index + 1) * height}`}
                stroke={segment.color}
                strokeWidth={1}
                fill="none"
              />
            )}
          </g>
        ))}
      </svg>
    );
  };

  return <div className="text-center">{renderChart()}</div>;
};

export { FunnelChart };
