import { TextFieldProps } from "@mui/material";
import { Textbox } from "..";

type TextAreaProps = {shrink?: boolean} & TextFieldProps

/** Renders the TextArea
 * @param TextAreaProps
 */
export function TextArea({ shrink, ...props }: TextAreaProps) {
  return (
    <Textbox
      {...props} InputLabelProps={{shrink}}
    />
  );
}
