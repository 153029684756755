import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1560 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 1560, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const data = [
    {
        image_url: "/images/RealEstate.jpg",
        name: "Real Estate Sector sees maximum fast track cases",
        description: "ALatest Rera amendments triggers fast tracking of majority real estate cases in 2023",
        duration: "12 hours ago"
    },
    {
        image_url: "/images/up-commission1.jpeg",
        name: "Lucknow Commission sets high benchmarks",
        description: "District commission of Lucknow has the lowest pendency among all commissions.",
        duration: "12 hours ago"
    },
    {
        image_url: "/images/highlight3.png",
        name: "District commission of Uttar Pradesh faces staff shortage",
        description: "Major shortage of officials reasons for delayed redressal in many UP district commission",
        duration: "12 hours ago"
    },
    {
        image_url: "/images/highlight4.png",
        name: "Constructive and destructive Industry Scorecards",
        description: "Real Estate Industry sees maximum default and litigation in 2024.",
        duration: "12 hours ago"
    }
]

export default function CarouselSpotlight() {

    const CardItemsTemplate = ({ name, description, imageUrl, duration }: { name: string, description: string, imageUrl: string, duration: string }) => {
        return (
            <div className="card-with-responsive d-flex flex-column bg-white rounded ">
                <div className="content-card-size-responsive rounded-top p-1 pb-0">
                    <img className="object-fit-cover w-100 h-100 rounded" alt={name} src={imageUrl} />
                </div>
                <div className="p-2 d-flex flex-column gap-2">
                    <h4 className="ellipsis-2">{name}</h4>
                    <p className="ellipsis-2 ">{description} </p>
                    <div className="d-flex flex-row justify-content-between text-mute"><small>{duration}</small>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Carousel responsive={responsive} className="">
            {data.map((item, index) => (
                <div className="p-2" key={index}>
                    <CardItemsTemplate description={item.description} name={item.name} duration={item.duration} imageUrl={item.image_url} />
                </div>
            ))}

        </Carousel>
    )
}
