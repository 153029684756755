import { IAllReportsData, ICourtRoomWiseCaseHearingDetailsReport, IDetailsOfJudgesCasesDetails, IreportDataWithSelectedDetails } from "../../../../../models/reports/ReportModel";
import {
  convertNumber,
  transformData,
  pendingMessage,
  updateRowSpans,
  findingWidthOfReportTable2,
  transformDataWithoutTotal,
} from "../../ReportFunction/ReportBodyFunction";
import { reportColumnName } from "./ReportCloumnName";
import { reportFooter } from "./ReportFooter";
import {
  adHocReportCategory,
  adhocReportDcdrc,
  benchWiseReportInfoTable,
  filterDisCatWiseCaseTypeReport,
  onlyStaticDuration,
  reportInfoTable,
  tableFristData,
} from "./ReportInfoTable";

//@Type: Commission_wise_case_type_reports
export const commissionWiseCaseTypeReports = async (data: any) => {
  const reportData = data[0][0]
  const selectedCaseType = data[0][1]
  const reportsHeading = data[3]
  reportData.push(reportFooter(reportData, "Commission_wise_case_type_reports"));
  const tableData2 = updateRowSpans(transformData(convertNumber(reportData)));
  tableData2.unshift(reportColumnName[0]);
  const table1values = {fristColumnKey:"Duration", fristColumnValue:"Since 1st April 1985", secoundColumnKey:"CaseType",secoundColumnValue: selectedCaseType}
  const tableData1 = tableFristData(table1values);
  const tableData1_width = [100, 640];
  const tableData2_width = [31, 111, 111, 111, 110, 110, 110];
  const Page_Margin = [40, 130, 40, 60]; // each page margin top after header
  const MainReportHeader = reportsHeading;
  const reportSubHeading: any = { text: ".", color: "white" };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Commission_wise_Category_reports
export const commissionWiseCategoryReports = async (data: any) => {
  data.push(reportFooter(data, "Commission_wise_Category_reports"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[1]);
  const tableData1 = reportInfoTable[1];
  const tableData1_width = [100, 640];
  const tableData2_width = [31, 111, 111, 111, 110, 110, 110];
  const Page_Margin = [40, 130, 40, 60]; // each page margin top after header
  const MainReportHeader = "Commission Wise Category Reports";
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
  };
};

//@Type: commission_wise_State_with_category_Report
export const commissionWiseStateWithCategoryReport = async (data: any) => {
  data.push(reportFooter(data, "commission_wise_State_with_category_Report"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[2]);
  const tableData1 = reportInfoTable[2];
  const tableData1_width = [100, 640];
  const tableData2_width = [31, 94, 94, 94, 93, 93, 93, 93];
  const Page_Margin = [40, 130, 40, 60]; // each page margin top after header
  const MainReportHeader = "Commission Wise State With Category Reports";
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
  };
};

//@Type: State_Category_wise_report
export const stateCategoryWiseReport = async (data: any) => {
  data.push(reportFooter(data, "State_Category_wise_report"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[3]);
  const tableData1 = reportInfoTable[3];
  const tableData1_width = [100, 640];
  const tableData2_width = [31, 111, 111, 111, 110, 110, 110];
  const Page_Margin = [40, 130, 40, 60]; // each page margin top after header
  const MainReportHeader = "State Wise Category Disposal Status";
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
  };
};

//@Type: NCDRC_or_SCDRC_or_DCDRC
export const ncdrcAndscdrcAnddcdrc = async (table_body: any) => {
  let data = table_body[0];
  data.push(reportFooter(data, "NCDRC_or_SCDRC_or_DCDRC"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[4]);
  const tableData1 = onlyStaticDuration();
  const tableData1_width = [100, 640];
  const tableData2_width = [30, 135, 135, 135, 135, 135];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = table_body[3];
  const reportSubHeading: any = { text: ".", color: "white" };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Ad-hoc_Report_SCDRC
export const stateWiseReport = async (data: any) => {
  data.push(reportFooter(data, "Ad-hoc_Report_SCDRC"));
  const tableData2: any = updateRowSpans(transformData(convertNumber(data)));
  tableData2.unshift(reportColumnName[5]);
  const tableData1 = reportInfoTable[5];
  const tableData1_width = [100, 640];
  const tableData2_width = [45, 131, 110, 110, 100, 100, 100];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader =
    "State Consumer Disputes Redressal Commission Disposal Status";
  const reportSubHeading: any = { text: ".", color: "white" };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Ad-hoc_Report_DCDRC
export const districtWiseReport = async (data: any) => {
  const uniqueStateNames: any[] = [];
  data.forEach((state: { state_name_en: any }) => {
    if (!uniqueStateNames.includes(state.state_name_en)) {
      uniqueStateNames.push(state.state_name_en);
    }
  });
  const uniqueState = uniqueStateNames.join(", ");
  const uniqueDistrictSet: any = new Set(
    data.map(
      (District_Name: { commission_name_en: any }) => District_Name.commission_name_en
    )
  );
  const uniqueDistrictString: string = [...uniqueDistrictSet].join(", ");

  data.push(reportFooter(data, "Ad-hoc_Report_DCDRC"));
  const tableData2: any = updateRowSpans(transformData(convertNumber(data)));
  tableData2.unshift(reportColumnName[6]);
  const tableData1 = adhocReportDcdrc(uniqueState, uniqueDistrictString);
  const tableData1_width = [100, 639, 639, 639, 639];
  const tableData2_width = [40, 131, 110, 100, 80, 75, 70, 80];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader =
    "District Consumer Dispute Redressal Commission Disposal Status";
  const reportSubHeading: any = { text: ".", color: "white" };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Ad-hoc_Report_Category
export const categoryWiseReport = async (item: any) => {
  const reportsData = item[0];
  const reportHeading = item[3];
  const stateData: Set<unknown> = new Set(
    reportsData.map((State_Name: { state_name_en: any }) => State_Name.state_name_en)
  );
  const uniqueStateArray = Array.from(stateData);
  const uniqueState = uniqueStateArray.join(", ");

  const uniqueDistrictSet: Set<unknown> = new Set(
    reportsData.map(
      (District_Name: { commission_name_en: any }) => District_Name.commission_name_en
    )
  );

  const uniqueDistrict: string = Array.from(uniqueDistrictSet).join(", ");

  const uniqueCategorySet: Set<unknown> = new Set(
    reportsData.map(
      (Category_Name: { case_category_name_en: any }) => Category_Name.case_category_name_en
    )
  );

  const uniqueCategory: string = Array.from(uniqueCategorySet).join(", ");

  const data = reportsData.map((curItem: any) => {
    const {
      // state_name_en,
      // commission_name_en,
      case_category_name_en,
      total_cases_filed,
      total_cases_disposed,
      total_cases_pending,
      disposal_rate
    } = curItem;
    return {
      // state_name_en,
      // commission_name_en,
      case_category_name_en,
      total_cases_filed,  
      total_cases_disposed,
      total_cases_pending,
      disposal_rate
    };
  });
  data.push(reportFooter(data, "Ad-hoc_Report_Category"));
  const tableData2: any = updateRowSpans(transformData(convertNumber(data)));
  tableData2.unshift(reportColumnName[7]);
  const tableData1 = adHocReportCategory(
    // uniqueState,
    // uniqueDistrict,
    uniqueCategory
  );
  const tableData1_width = [100, 639, 639];
  const tableData2_width = [39, 135, 135, 130, 130, 130];
  const Page_Margin = [40, 165, 40, 60]; // each page margin top after header
  const MainReportHeader = reportHeading;
  const reportSubHeading: any = {
    // text: "District Commission",
    text: "",
    fontSize: 14,
    alignment: "center",
  };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Filter_District_Category_wise_case_type_report
export const filterDistrictCategoryWiseCaseType = async (item: any) => {
  // const stateNamesArray: any[] = item.map(
  //   (State_Name: { state_name_en: any }) => State_Name.state_name_en
  // );
  // const uniqueStateSet: Set<unknown> = new Set(stateNamesArray);
  // const uniqueState: string = Array.from(uniqueStateSet).join(", ");

  // const districtNamesArray: any[] = item.map(
  //   (District_Name: { commission_name_en: any }) => District_Name.commission_name_en
  // );
  // const uniqueDistrictSet: Set<unknown> = new Set(districtNamesArray);
  // const uniqueDistrict: string = Array.from(uniqueDistrictSet).join(", ");
  const reportsData = item[0];
  const reportHeading = item[3];

  const categoryNamesArray: any[] = reportsData.map(
    (Category_Name: { case_category_name_en: any }) => Category_Name.case_category_name_en
  );
  const uniqueCategorySet: Set<unknown> = new Set(categoryNamesArray);
  const uniqueCategory: string = Array.from(uniqueCategorySet).join(", ");

  const caseTypeNamesArray: any[] = reportsData.map(
    (CaseType_Name: { case_type_name_en: any }) => CaseType_Name.case_type_name_en
  );
  const uniqueCaseTypeNameSet: Set<unknown> = new Set(caseTypeNamesArray);
  const uniqueCaseTypeName: string = Array.from(uniqueCaseTypeNameSet).join(
    ", "
  );
  const data = reportsData.map((curItem: any) => {
    const {
      // commission_name_en,
      case_category_name_en,
      case_type_name_en,
      total_cases_filed,
      total_cases_disposed,
      total_cases_pending,
      disposal_rate,
    } = curItem;

    return {
      // commission_name_en,
      case_category_name_en,
      case_type_name_en,
      total_cases_filed,
      total_cases_disposed,
      total_cases_pending,
      disposal_rate,
    };
  });

  data.push(
    reportFooter(data, "Filter_District_Category_wise_case_type_report")
  );
  const tableData2: any = updateRowSpans(transformData(convertNumber(data)));
  tableData2.unshift(reportColumnName[8]);
  const tableData1 = filterDisCatWiseCaseTypeReport(
    // uniqueState,
    // uniqueDistrict,
    uniqueCategory,
    uniqueCaseTypeName
  );
  const tableData1_width = [100, 639, 639, 639, 639, 639];
  const tableData2_width = [39, 150, 120, 100, 100, 90, 95];
  // const tableData2_width = [39, 85, 85, 85, 82, 75, 75, 75, 75,75];
  const Page_Margin = [40, 140, 40, 60]; // each page margin top after header
  const MainReportHeader = reportHeading;
  const reportSubHeading: any = {
    // text: "District Commission",
    text: "",
    alignment: "center",
    fontSize: 14,
  };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Bench_Wise_Report
export const benchWiseReport = (table_body: any) => {
  let data = table_body[0];
  // Date of hearing
  const dateOfHearing = data[0].dateOfHearing
    ? data[0].dateOfHearing
    : "Select Date";
  // finding unique courtName
  const uniqueCourtNames = new Set(data.map((item: any) => item.courtName));
  const uniqueCourtNamesArray = Array.from(uniqueCourtNames)
    ? Array.from(uniqueCourtNames)
    : "Select Court";
  const newData = data.map((curEle: any) => {
    if (curEle && curEle.fillingReferenceNumber) {
      // Create a new object with modified fillingReferenceNumber
      return {
        ...curEle,
        fillingReferenceNumber: curEle.fillingReferenceNumber.toString(),
      };
    } else {
      // Return the original object if fillingReferenceNumber is not present
      return curEle;
    }
  });

  data.push(reportFooter(data, "Bench_Wise_Report"));
  const tableData2: any = updateRowSpans(transformData(convertNumber(newData)));

  const reportColumnName = [{ text: "S.No", alignment: "center" }];

  const cloumnNames = [
    "S.No",
    "State",
    "Commission",
    "Filling Reference Number",
    "Date Of Hearing",
    "Date Of Next Hearing",
    "Case Number",
    "Complainant",
    "Respondent",
    "Court",
    "Stage",
  ];
  Object.keys(data[0]).forEach((curEle) => {
    switch (curEle) {
      case "stateNameEn":
        reportColumnName.push({
          text: `${cloumnNames[1]}`,
          alignment: "center",
        });
        break;
      case "commissionNameEn":
        reportColumnName.push({
          text: `${cloumnNames[2]}`,
          alignment: "center",
        });
        break;
      case "fillingReferenceNumber":
        reportColumnName.push({
          text: `${cloumnNames[3]}`,
          alignment: "center",
        });
        break;
      case "dateOfHearing":
        reportColumnName.push({
          text: `${cloumnNames[4]}`,
          alignment: "center",
        });
        break;
      case "dateOfNextHearing":
        reportColumnName.push({
          text: `${cloumnNames[5]}`,
          alignment: "center",
        });
        break;
      case "caseNumber":
        reportColumnName.push({
          text: `${cloumnNames[6]}`,
          alignment: "center",
        });
        break;
      case "complainantName":
        reportColumnName.push({
          text: `${cloumnNames[7]}`,
          alignment: "center",
        });
        break;
      case "respondentName":
        reportColumnName.push({
          text: `${cloumnNames[8]}`,
          alignment: "center",
        });
        break;
      case "courtName":
        reportColumnName.push({
          text: `${cloumnNames[9]}`,
          alignment: "center",
        });
        break;
      case "stageName":
        reportColumnName.push({
          text: `${cloumnNames[10]}`,
          alignment: "center",
        });
        break;
      default:
        break; // No need to return for default case
    }
  });

  tableData2.unshift(reportColumnName);
  const tableData1 = benchWiseReportInfoTable(
    dateOfHearing,
    uniqueCourtNamesArray
  );

  // making dynamic report column
  const reportColumn = Object.keys(data[0]).length;
  let totalColumn = [28];
  let i = 0;
  while (i !== reportColumn) {
    totalColumn.push(649 / reportColumn);
    i++;
  }
  const tableData1_width = [100, 640];
  const tableData2_width = totalColumn;
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = "Bench Wise Status";
  const reportSubHeading: any = {
    text: `State: ${table_body[3][0]} \n Commission: ${table_body[3][1]}`,
    fontSize: 14,
    alignment: "center",
  };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: Judges Wise Report
export const judgesWiseReport = async (data: any) => {
  data.push(reportFooter(data, "JudgesWiseReport"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[9]);
  const tableData1 = reportInfoTable[0];

  // making dynamic report column
  const reportColumn = Object.keys(data[0]).length;
  let totalColumn = [28];
  let i = 0;
  while (i !== reportColumn) {
    totalColumn.push(640 / reportColumn);
    i++;
  }
  const tableData1_width = [100, 640];
  const tableData2_width = totalColumn;
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = "Judges Wise Report";
  const reportSubHeading: any = {
    text: `State: ${data[0]?.state_name_en} \n District: ${data[0]?.commission_name_en}`,
    alignment: "center",
  };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    Page_Margin,
    reportSubHeading,
  };
};

//@Type: State District With Duration
export const stateDistrictDuration = async (data: any[]) => {
  const newData = data.map(({ applicableYear, ...rest }) => {
    return { applicableYear: applicableYear.toString(), ...rest };
  });
  newData.push(reportFooter(newData, "stateDistrictDuration"));
  const tableData2: any = updateRowSpans(transformData(convertNumber(newData)));
  tableData2.unshift(reportColumnName[10]);
  const tableData1 = reportInfoTable[0];

  // making dynamic report column
  const reportColumn = Object.keys(newData[0]).length;
  let totalColumn = [28];
  let i = 0;
  while (i !== reportColumn) {
    totalColumn.push(640 / reportColumn);
    i++;
  }
  const tableData1_width = [100, 640];
  const tableData2_width = totalColumn;
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = "State District With Duration";
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    Page_Margin,
  };
};

// Dynamic Report
export const customDynamicReport = (data: any) => {
  let {
    reportData,
    footerTableColumn,
    headerReportColumn,
    ReportTable1Data,
    mainReportHeader,
    reportSubHeading
  } = data[0];

  // Adding footer column data in report
  reportData.push(reportFooter(reportData, footerTableColumn));

  // Update row if same data encounter in verry next row
  const tableData2: any = updateRowSpans(
    transformData(convertNumber(reportData))
  );

  // Adding report header column in report
  tableData2.unshift(reportColumnName[headerReportColumn]);

  // Dynamic width find for report body
  const reportTableData2Width = findingWidthOfReportTable2(reportData[0]);

  return {
    tableData1: ReportTable1Data,
    tableData2,
    tableData1_width: [100, 640], // Table 1 header width
    tableData2_width: reportTableData2Width, // Table 2 body width
    MainReportHeader: mainReportHeader,
    reportSubHeading, // Sub heading no longer require in dynamic report
    Page_Margin: [40, 150, 40, 60], // each page margin top after header,
  };
};

// DynamicReport without UpdaterowSpan
export const DynamicReportWithoutRowSpans = (data: any) => {
  let {
    reportData,
    footerTableColumn,
    headerReportColumn,
    ReportTable1Data,
    mainReportHeader,
    reportSubHeading
  } = data[0];

  // Adding footer column data in report
  reportData.push(reportFooter(reportData, footerTableColumn));

  // Update row if same data encounter in verry next row
  const tableData2: any = transformData(convertNumber(reportData))

  // Adding report header column in report
  tableData2.unshift(reportColumnName[headerReportColumn]);

  // Dynamic width find for report body
  const reportTableData2Width = findingWidthOfReportTable2(reportData[0]);

  return {
    tableData1: ReportTable1Data,
    tableData2,
    tableData1_width: [100, 640], // Table 1 header width
    tableData2_width: reportTableData2Width, // Table 2 body width
    MainReportHeader: mainReportHeader,
    reportSubHeading, // Sub heading no longer require in dynamic report
    Page_Margin: [40, 150, 40, 60], // each page margin top after header,
  };
};


//@Type: Court Room Wise Case Hearing Details
export const courtRoomWiseCaseHearingDetails = (data: any)=> {
  const reportData = data[0];
  const reportHeading = data[3];
  
  // Report table 1 data.
  const tableData1 = [
    [{ text: "Duration", bold: true, fontSize: 12 }, `${reportData.fromDate.toString()} TO ${reportData.toDate.toString()}`],
    [{ text: "Court Number", bold: true, fontSize: 12 }, `${reportData.courtId.toString()}`],
  ]

  const tableData2: any = transformDataWithoutTotal(convertNumber(reportData?.alignReportData));
  tableData2.unshift(reportColumnName[23]);
  const tableData1_width = [100, 640];
  const tableData2_width = [35, 75, 75, 75, 75, 75, 70, 65, 60, 60];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader =reportHeading;
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin
  };
}


//@Type: NCDRC Judge Wise Report
export const ncdrcJudgeWiseReport = (data: any)=> {
  const reportData = data[0];
  const reportHeading = data[3];
  const alignReportData = reportData?.reportData?.map((currentElement: IreportDataWithSelectedDetails)=> {
    const {
      judges_name_en,
      case_number,
      old_case_number,
      complainant_name,
      respondent_name,
      dateofhearing,
      nextdateofhearing,
      hearingsequencenumber,
      court_name,
      stage_name
    } = currentElement;

    return {
      judges_name_en,
      case_number, 
      old_case_number, 
      complainant_name, 
      respondent_name, 
      dateofhearing, 
      nextdateofhearing, 
      hearingsequencenumber: hearingsequencenumber.toString(), 
      court_name,
      stage_name
    }
  })

  if(alignReportData){
    // Report table 1 data.
  const tableData1 = [
    [{ text: "Duration", bold: true, fontSize: 12 }, `${reportData?.fromDate.toString()} TO ${reportData?.toDate.toString()}`],
    // [{ text: "Judge Name", bold: true, fontSize: 12 }, `${data.courtId.toString()}`],
  ]
  const tableData2: any = transformDataWithoutTotal(convertNumber(alignReportData));
  tableData2.unshift(reportColumnName[24]);
  const tableData1_width = [100, 640];
  const tableData2_width = [35, 65, 65, 65, 65, 65, 60, 60, 60, 60, 60];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = reportHeading;
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin
  };
  }
}

export const detailsOfJudgesCasesDetails= (tableBody: any)=> {
  const reportData = tableBody[0];
  const reportHeading = tableBody[3];
  const data = reportData.map((currentElement: IDetailsOfJudgesCasesDetails) =>{
    const {judges_name_en, casesheared, totalcasesdisposed, totalcasespending, disposalrate} = currentElement;
    return {
      judges_name_en,
      casesheared,
      totalcasesdisposed,
      totalcasespending,
      disposalrate
    }
  })
  data.push(reportFooter(data, "details_Of_Judges_Cases_Details"));
  const tableData2: any = transformData(convertNumber(data));
  tableData2.unshift(reportColumnName[25]);
  const tableData1 = reportInfoTable[1];
  const tableData1_width = [100, 640];
  const tableData2_width = [30, 134, 134, 134, 134, 134];
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = reportHeading;
  const reportSubHeading: any = { text: ".", color: "white" };
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    pendingMessage,
    Page_Margin,
    reportSubHeading,
  };
}


//@Type: Judges Hearing Information
export const JudgesHearingInformation = (data: any)=> {
  const reportData = data[0];
  const reportHeading = data[3];
  const newReportData = reportData.map((currentElement:any) =>{
    const {
        salutation,
        judges_name,
        designation,
        noofcases,
        noofhearings,
        proceedings,
        noofjudgement
    } = currentElement;
    return{
      judgeName: `${salutation + " " + judges_name + " " + designation}`,
      noofcases,
      noofhearings,
      proceedings,
      noofjudgement
    }
  })

  const tableData2: any = transformDataWithoutTotal(convertNumber(newReportData));
  tableData2.unshift(reportColumnName[26]);
  const tableData1 = reportInfoTable[0];
  const tableData1_width = [100, 640];
  const tableData2_width = [35, 174, 120, 130, 120, 120]
  const Page_Margin = [40, 150, 40, 60]; // each page margin top after header
  const MainReportHeader = reportHeading;
  return {
    tableData1,
    tableData2,
    tableData1_width,
    tableData2_width,
    MainReportHeader,
    Page_Margin
  };
}