import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

type TextboxProps = {shrink?: boolean} & TextFieldProps

export function Textbox({ id, name, placeholder,  label, shrink, ...rest }: TextboxProps) {
  return (
    <div className="form-control-group margin-bottom-30">
      <FormControl className="input-form-control" fullWidth>
        <TextField
          className="input-form-control-element"  label={label} fullWidth placeholder={placeholder} id={id} name={name} {...rest} InputLabelProps={{shrink}} />
      </FormControl>
    </div>
  );
}
