import { DigitalIndiaLogo, NicLogo } from "../components/controls/BasicIcons";

export const FooterView = () => {
	return (
		<footer className="footer">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-4">
						<a href="https://www.nic.in/" target="_blank" rel="noreferrer">
							<NicLogo alt="nic_logo" className="img-fluid margin-right-20" />
						</a>
						<a href="https://digitalindia.gov.in/" target="_blank" rel="noreferrer">
							<DigitalIndiaLogo
								alt="digital_india_logo"
								className="img-fluid"
							/>
						</a>
					</div>
					<div className="col-sm-8">
						<p className="version-info text-end">Version: 2.0</p>
						<p className="site-info text-end mb-0">
							© e-jagriti Platform is developed, designed and maintained by{" "}
							<a
								href="https://www.nic.in/"
								target="_blank" rel="noreferrer"
								 className="text-decoration-none text-dark"
							>
								{" "}
								National Informatics Centre{" "}
							</a>
							,{" "}
							<a
								href="https://www.meity.gov.in/"
								target="_blank" rel="noreferrer"
							 className="text-decoration-none text-dark"
							>
								Ministry of Electronics & Information Technology
							</a>
							, Government of India
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};
