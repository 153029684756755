import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  clientIpAddress: "" as string,
};

export const clientIpslice = createSlice({
  name: "clientIpAddress",
  initialState,
  reducers: {
    addClientIpAddress: (state, action: PayloadAction<string>) => {
      state.clientIpAddress = action.payload;
    },
  },
});

export const { addClientIpAddress } = clientIpslice.actions;
export default clientIpslice.reducer;
