import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
	COMPLETE_PROFILE,
	FORGOTPASSWORD,
	LOGIN,
	REGISTER,
	UNDERPROGRESS,
} from "../navigation/CONSTANTS";
import { HeaderView } from "./HeaderView";
import { MainHeaderView } from "./MainHeaderView";
import { FooterMainPage } from "./FooterMainPage";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { navigateToDashboardPath } from "../utils/Helper";
import Header from "../LandingPage/Header/Header";
import { LandingPageFooter } from "../LandingPage";

/**
 * Component handling all the public layout
 * @returns
 */
const PublicLayout = () => {
	const location = useLocation();
	const authPaths = useMemo(() => [LOGIN, REGISTER, FORGOTPASSWORD, UNDERPROGRESS], []);
	const [inAuthPath, setInAuthPath] = useState<boolean>(false);
	const navigate = useNavigate();
	const { userDetailsData } = useSelector((state: RootState) => state.user);

	// checking if user is in any auth page
	useEffect(() => {
		if (authPaths.includes(location.pathname)) {
			setInAuthPath(true);
		} else {
			setInAuthPath(false);
		}
	}, [authPaths, location]);

	useEffect(() => {
		if (userDetailsData.userId !== 0) {
			navigate(navigateToDashboardPath(userDetailsData?.roleId))
		}
	}, [navigate, userDetailsData])

	return (
		<div className="page-wrapper">
			<div className="content-wrapper">
				{location.pathname === COMPLETE_PROFILE ? (
					<HeaderView />
				) : (
					<Header/>
					// <MainHeaderView />
				)}
				<main className={`flex-grow-1 ${!inAuthPath}`}>
					<Outlet />
				</main>
				{/* {!inAuthPath && <FooterMainPage />} */}
				{!inAuthPath && <LandingPageFooter/>}
			</div>
		</div>
	);
};

export default PublicLayout;
