import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICourtRoomCaseStageEnumModel } from "../../models/cases/commissionCase/ICommissionCase";

export const courtRoomCaseStage: ICourtRoomCaseStageEnumModel = {
    "ADJOURN": 4,
    "ADJOURN/COMPLIANCE": 27,
    "ADJOURNED_SINE_DIE": 42,
    "ADMISSION": 13,
    "ADMIT": 2,
    "ADMIT_DUE_COURSE": 107,
    "AFFIDAVIT_OF_COMPLAINANT": 16,
    "AFFIDAVIT_OF_RESPONDENT": 17,
    "ALLOWED": 50,
    "APPEAL": 95,
    "APPEARANCE_OF_BOTH_PARTIES": 38,
    "APPEARANCE_OF_COMPLAINANT": 36,
    "APPEARANCE_OF_OPPOSITE_PARTY": 37,
    "AWAIT_BW/NBW": 91,
    "AWAIT_NOTICE": 24,
    "AWAIT_RECOVERY": 21,
    "AWAIT_REPLY": 78,
    "AWAIT_REPORT": 61,
    "CALL_ON": 26,
    "CHIEF/CROSS_EXAMINATION_(EVIDENCE)": 18,
    "CLARIFICATION": 77,
    "CMP_PENDING": 74,
    "CONSIDERATION": 110,
    "CONSIGNED_TO_RECORDROOM": 29,
    "COUNTER_OF_COMPLAINANT": 88,
    "COUNTER_OF_O.P": 87,
    "CROSS_EXAMINATION": 23,
    "DEFAULT": 80,
    "DEFECTIVE": 8,
    "DIRECTION": 96,
    "DISPOSED_OFF": 7,
    "DISPOSED_OFF/COMPLIANCE": 113,
    "ENQUIRY": 106,
    "EVIDENCE": 9,
    "EVIDENCE_OF_COMPLAINANT": 33,
    "EVIDENCE_OF_OPPOSITE_PARTY": 34,
    "EXHIBITS_MARKED": 71,
    "FILING_OF_REPLY": 28,
    "FINAL_HEARING": 5,
    "FOR__FRESH__ADMISSION": 68,
    "FOR_ADMISSION_/IAS": 45,
    "FOR_ADMISSION_HEARING": 98,
    "FOR_APPEARANCE": 57,
    "FOR_ARGUMENTS": 66,
    "FOR_BACK_TRIAL_(SENT_BACK_FOR_REMAND)": 112,
    "FOR_BEING_MENTIONED": 44,
    "FOR_COMPILATION": 58,
    "FOR_COMPLIANCE": 47,
    "FOR_COUNTER": 46,
    "FOR_DISMISSAL": 69,
    "FOR_DOCUMENT": 62,
    "FOR_EXAMINATION_OF_COMP/OPP_PARTY": 40,
    "FOR_FILING_AFFIDAVIT_/_EVIDENCE": 53,
    "FOR_FILING_AFFIDAVITS": 64,
    "FOR_FILING_COUNTERS": 63,
    "FOR_FILING_REPLY": 41,
    "FOR_FURTHER_STEPS": 75,
    "FOR_JUDGMENT": 56,
    "FOR_MAINTAINABILITY": 35,
    "FOR_ORDER": 67,
    "FOR_ORDERS/RESERVED_FOR_ORDERS": 12,
    "FOR_PETITION_HEARING": 99,
    "FOR_RECORDING_EVIDENCE/FOR_ENQUIRY": 65,
    "FOR_REPORT": 49,
    "FOR_SETTLEMENT": 72,
    "FOR_STATEMENTS": 83,
    "FOR_WRITTEN_VERSION": 70,
    "HEARING": 15,
    "INTERLOCATORY_ORDER": 94,
    "INTERROGATORIES": 85,
    "ISSUE_BW": 89,
    "ISSUE_NBW": 90,
    "ISSUE_NOTICE_AGAINST_IA-1/IA-2": 79,
    "ISSUE_NOTICE/DIRECTIONS": 3,
    "ISSUE_OF_COMMISSIONERS_WARRANT": 25,
    "JUDGEMENT": 102,
    "JUDGEMENT_RESERVED": 11,
    "LRC": 84,
    "MISC_MATTERS": 60,
    "OBJECTION": 22,
    "OTHERS": 52,
    "PART_HEARD": 10,
    "PENDING": 51,
    "PPS": 48,
    "PRELIMINARY_HEARING": 43,
    "PRODUCE_DOCUMENT": 73,
    "PRODUCTION_OF_ARTICLE_&_DOCUMENTS": 100,
    "PUBLICATIONS": 86,
    "READY_TO_LIST": 115,
    "RECALLED": 20,
    "REGISTERED": 1,
    "REJOINDER": 32,
    "REMANDED": 81,
    "REMINDED": 19,
    "REPLY": 31,
    "REPLY_TO_INTERROGATORIES": 101,
    "RESTORED": 6,
    "S/R_APPEARANCE": 97,
    "SERVICE": 30,
    "SERVICE/NOTICE": 111,
    "SINE-DIE": 114,
    "STAY": 104,
    "STAY_ORDER": 39,
    "STAYED": 82,
    "UNKNOWN": 999,
    "VERSION": 14,
    "W/S": 109,
    "WARRANT": 59,
    "WRITTEN_ARGUMENTS": 76,
    "WRITTEN_OBJECTION": 93,
    "WRITTEN_STAGE": 92,
    "WRITTEN_VERSION": 103
};

export const commissionCaseSlice = createSlice({
    name: 'courtRoomCaseStage',
    initialState: { courtRoomCaseStage },
    reducers: {
        saveCourtRoomCaseStage: (state, action: PayloadAction<ICourtRoomCaseStageEnumModel>) => {
            state.courtRoomCaseStage = action.payload;
        },
        clearCourtRoomCaseStage: (state) => {
            state.courtRoomCaseStage = {
                "ADJOURN": 4,
                "ADJOURN/COMPLIANCE": 27,
                "ADJOURNED_SINE_DIE": 42,
                "ADMISSION": 13,
                "ADMIT": 2,
                "ADMIT_DUE_COURSE": 107,
                "AFFIDAVIT_OF_COMPLAINANT": 16,
                "AFFIDAVIT_OF_RESPONDENT": 17,
                "ALLOWED": 50,
                "APPEAL": 95,
                "APPEARANCE_OF_BOTH_PARTIES": 38,
                "APPEARANCE_OF_COMPLAINANT": 36,
                "APPEARANCE_OF_OPPOSITE_PARTY": 37,
                "AWAIT_BW/NBW": 91,
                "AWAIT_NOTICE": 24,
                "AWAIT_RECOVERY": 21,
                "AWAIT_REPLY": 78,
                "AWAIT_REPORT": 61,
                "CALL_ON": 26,
                "CHIEF/CROSS_EXAMINATION_(EVIDENCE)": 18,
                "CLARIFICATION": 77,
                "CMP_PENDING": 74,
                "CONSIDERATION": 110,
                "CONSIGNED_TO_RECORDROOM": 29,
                "COUNTER_OF_COMPLAINANT": 88,
                "COUNTER_OF_O.P": 87,
                "CROSS_EXAMINATION": 23,
                "DEFAULT": 80,
                "DEFECTIVE": 8,
                "DIRECTION": 96,
                "DISPOSED_OFF": 7,
                "DISPOSED_OFF/COMPLIANCE": 113,
                "ENQUIRY": 106,
                "EVIDENCE": 9,
                "EVIDENCE_OF_COMPLAINANT": 33,
                "EVIDENCE_OF_OPPOSITE_PARTY": 34,
                "EXHIBITS_MARKED": 71,
                "FILING_OF_REPLY": 28,
                "FINAL_HEARING": 5,
                "FOR__FRESH__ADMISSION": 68,
                "FOR_ADMISSION_/IAS": 45,
                "FOR_ADMISSION_HEARING": 98,
                "FOR_APPEARANCE": 57,
                "FOR_ARGUMENTS": 66,
                "FOR_BACK_TRIAL_(SENT_BACK_FOR_REMAND)": 112,
                "FOR_BEING_MENTIONED": 44,
                "FOR_COMPILATION": 58,
                "FOR_COMPLIANCE": 47,
                "FOR_COUNTER": 46,
                "FOR_DISMISSAL": 69,
                "FOR_DOCUMENT": 62,
                "FOR_EXAMINATION_OF_COMP/OPP_PARTY": 40,
                "FOR_FILING_AFFIDAVIT_/_EVIDENCE": 53,
                "FOR_FILING_AFFIDAVITS": 64,
                "FOR_FILING_COUNTERS": 63,
                "FOR_FILING_REPLY": 41,
                "FOR_FURTHER_STEPS": 75,
                "FOR_JUDGMENT": 56,
                "FOR_MAINTAINABILITY": 35,
                "FOR_ORDER": 67,
                "FOR_ORDERS/RESERVED_FOR_ORDERS": 12,
                "FOR_PETITION_HEARING": 99,
                "FOR_RECORDING_EVIDENCE/FOR_ENQUIRY": 65,
                "FOR_REPORT": 49,
                "FOR_SETTLEMENT": 72,
                "FOR_STATEMENTS": 83,
                "FOR_WRITTEN_VERSION": 70,
                "HEARING": 15,
                "INTERLOCATORY_ORDER": 94,
                "INTERROGATORIES": 85,
                "ISSUE_BW": 89,
                "ISSUE_NBW": 90,
                "ISSUE_NOTICE_AGAINST_IA-1/IA-2": 79,
                "ISSUE_NOTICE/DIRECTIONS": 3,
                "ISSUE_OF_COMMISSIONERS_WARRANT": 25,
                "JUDGEMENT": 102,
                "JUDGEMENT_RESERVED": 11,
                "LRC": 84,
                "MISC_MATTERS": 60,
                "OBJECTION": 22,
                "OTHERS": 52,
                "PART_HEARD": 10,
                "PENDING": 51,
                "PPS": 48,
                "PRELIMINARY_HEARING": 43,
                "PRODUCE_DOCUMENT": 73,
                "PRODUCTION_OF_ARTICLE_&_DOCUMENTS": 100,
                "PUBLICATIONS": 86,
                "READY_TO_LIST": 115,
                "RECALLED": 20,
                "REGISTERED": 1,
                "REJOINDER": 32,
                "REMANDED": 81,
                "REMINDED": 19,
                "REPLY": 31,
                "REPLY_TO_INTERROGATORIES": 101,
                "RESTORED": 6,
                "S/R_APPEARANCE": 97,
                "SERVICE": 30,
                "SERVICE/NOTICE": 111,
                "SINE-DIE": 114,
                "STAY": 104,
                "STAY_ORDER": 39,
                "STAYED": 82,
                "UNKNOWN": 999,
                "VERSION": 14,
                "W/S": 109,
                "WARRANT": 59,
                "WRITTEN_ARGUMENTS": 76,
                "WRITTEN_OBJECTION": 93,
                "WRITTEN_STAGE": 92,
                "WRITTEN_VERSION": 103
            };
            localStorage.removeItem('persist:root');
        },
    }
});

export const { saveCourtRoomCaseStage, clearCourtRoomCaseStage } = commissionCaseSlice.actions;
export default commissionCaseSlice.reducer