export const reportColumnName = [
  //@Type: Commission_wise_case_type_reports 
  //@ 0
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "Case Type", alignment: "center" },
    { text: "Filed\n(a)", alignment: "center" },
    { text: "Disposed\n(b)", alignment: "center" },
    { text: "Pending*", alignment: "center" },
    { text: "Disposal Rate\nf=(b/a)*100", alignment: "center" },
  ],
  //@Type: Commission_wise_Category_reports
  //@ 1
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "Category", alignment: "center" },
    { text: "Filed\n(a)", alignment: "center" },
    { text: "Disposed\n(b)", alignment: "center" },
    { text: "Pending*", alignment: "center" },
    { text: "Disposal Rate\nf=(b/a)*100", alignment: "center" },
  ],
  //@Type:   commission_wise_State_with_category_Report
  //@ 2
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "Category", alignment: "center" },
    { text: "State", alignment: "center" },
    { text: "Filed\n(a)", alignment: "center" },
    { text: "Disposed\n(b)", alignment: "center" },
    { text: "Pending*", alignment: "center" },
    { text: "Disposal Rate\nf=(b/a)*100", alignment: "center" },
  ],
  //@Type: State_Category_wise_report
  //@ 3
  [
    { text: "S.No", alignment: "center" },
    { text: "Category", alignment: "center" },
    { text: "State", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", color: "blue", alignment: "center" },
      ],
    },
  ],
  //@Type: NCDRC_or_SCDRC_or_DCDRC
  //@ 4
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\nf=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],
  //@Ad-hoc_Report_SCDRC
  //@ 5
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "State", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\nf=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],
  //@Type: Ad-hoc_Report_DCDRC
  //@ 6
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "State", alignment: "center" },
    { text: "District", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\nf=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],
  //@Type: Ad-hoc_Report_Category
  //@ 7
  [
    { text: "S.No", alignment: "center" },
    // { text: "State", alignment: "center" },
    // { text: "District", alignment: "center" },
    { text: "Category", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\nf=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],
  //@Type: Filter_District_Category_wise_case_type_report
  //@ 8
  [
    { text: "S.No", alignment: "center" },
    // { text: "State ", alignment: "center" },
    // { text: "District ", alignment: "center" },
    { text: "Category", alignment: "center" },
    { text: "Case Type", alignment: "center" },
    { text: "Filed", alignment: "center" },
    { text: "Disposed", alignment: "center" },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\nf=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],
  //@Type: Judges Wise Report
  //@ 9
  [
    { text: "S.No", alignment: "center" },
    { text: "State ", alignment: "center" },
    { text: "Commission", alignment: "center" },
    { text: "Judges", alignment: "center" },
    { text: "Cases", alignment: "center" },
    { text: "Hearings", alignment: "center" },
    { text: "Judgement", alignment: "center" },
    { text: "Final Order", alignment: "center" },
    { text: "Daily Order", alignment: "center" },
    { text: "Final Judgement", alignment: "center" },
  ],
  //@Type: State District Duration
  //@ 10
  [
    {text: "S.No", alignment: "center"},
    {text: "Year", alignment: "center"},
    {text: "State", alignment: "center"},
    {text: "District", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"}
  ],

  // ====Dynamic Report Header====
  
  //@Type: Dynamically Filter Reports (State District Duration)
  //@ 11
  [
    {text: "S.No", alignment: "center"},
    {text: "State", alignment: "center"},
    {text: "District", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  
  //@Type: Dynamically Filter Reports (State District Duration CaseType Category)
  //@ 12
  [
    {text: "S.No", alignment: "center"},
    {text: "District", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "Case Type", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],

  //@Type: Dynamically Filter Reports (State District Duration Category)
  //@ 13
  [
    {text: "S.No", alignment: "center"},
    {text: "District", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (State District Duration CaseType)
  //@ 14
  [
    {text: "S.No", alignment: "center"},
    {text: "District", alignment: "center"},
    {text: "Case Type", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (SCDRC, State, Duration CaseType, Category)
  //@ 15
  [
    {text: "S.No", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "Case Type", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (SCDRC, State, Duration CaseType, Category)
  //@ 16
  [
    {text: "S.No", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (SCDRC, State, Duration CaseType, Category)
  //@ 17
  [
    {text: "S.No", alignment: "center"},
    {text: "CaseType", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (SCDRC, State, Duration)
  //@ 18
  [
    {text: "S.No", alignment: "center"},
    {text: "State", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (NCDRC, Duration)
  //@ 19
  [
    {text: "S.No", alignment: "center"},
    {text: "Commission", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (NCDRC, Duration, Category)
  //@ 20
  [
    {text: "S.No", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (NCDRC, Duration, Category)
  //@ 21
  [
    {text: "S.No", alignment: "center"},
    {text: "CaseType", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Dynamically Filter Reports (NCDRC, Duration, Category, CaseType)
  //@ 22
  [
    {text: "S.No", alignment: "center"},
    {text: "Category", alignment: "center"},
    {text: "CaseType", alignment: "center"},
    {text: "Filed", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],
  //@Type: Court Room Wise Case Hearing Details
  //@ 23
  [
    {text: "S.No", alignment: "center"},
    {text: "Case Number", alignment: "center"},
    {text: "Old Case Number", alignment: "center"},
    {text: "Complainant Name", alignment: "center"},
    {text: "Respondent Name", alignment: "center"},
    {text: "Date Of Hearing", alignment: "center"},
    {text: "Next Date Of Hearing", alignment: "center"},
    {text: "Hearing Sequence Number", alignment: "center"},
    {text: "Court Name", alignment: "center"},
    {text: "Stage Name", alignment: "center"}
  ],

  //@Type: NCDRC Judgeswise Report
  //@ 24
  [
    {text: "S.No", alignment: "center"},
    {text: "Judge Name", alignment: "center"},
    {text: "Case Number", alignment: "center"},
    {text: "Old Case Number", alignment: "center"},
    {text: "Complainant Name", alignment: "center"},
    {text: "Respondent Name", alignment: "center"},
    {text: "Date Of Hearing", alignment: "center"},
    {text: "Next Date Of Hearing", alignment: "center"},
    {text: "Hearing Sequence Number", alignment: "center"},
    {text: "Court Name", alignment: "center"},
    {text: "Stage Name", alignment: "center"}
  ],
  //@Type: Details Of Judges Cases Details
  //@ 25
  [
    {text: "S.No", alignment: "center"},
    {text: "Judge Name", alignment: "center"},
    {text: "Cases Heared", alignment: "center"},
    {text: "Disposed", alignment: "center"},
    {text: "Pending", alignment: "center"},
    {text: "Disposal Rate", alignment: "center"},
  ],

  //@Type: Details Of Judges Cases Details
  //@ 26
  [
    {text: "S.No", alignment: "center"},
    {text: "Judge Name", alignment: "center"},
    {text: "Cases Heared", alignment: "center"},
    {text: "Hearings Done", alignment: "center"},
    {text: "Proceedings", alignment: "center"},
    {text: "Judgements Given", alignment: "center"},
  ],

];
