import { ISelectedData, table1DataType } from "../../../../../models/reports/ReportModel";

export const reportInfoTable = [
  //@Type: Commission_wise_case_type_reports
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@Type: Commission_wise_Category_reports
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@Type: commission_wise_State_with_category_Report
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@Type: State_Category_wise_report
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@Type: NCDRC_or_SCDRC_or_DCDRC
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
  //@Type: Ad-hoc_Report_SCDRC
  [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ],
];

//@Type: Ad-hoc_Report_DCDRC
export const adhocReportDcdrc = (uniqueState: any, Unique_District: any) => {
  return [
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [{ text: "State(s)", bold: true, fontSize: 12 }, uniqueState],
    [{ text: "District(s)", bold: true, fontSize: 12 }, Unique_District],
    [
      { text: "Case Types", bold: true, fontSize: 12 },
      "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
    ],
  ];
};
//@Type: Ad-hoc_Report_Category
export const adHocReportCategory = (
  // uniqueState: any,
  // uniqueDistrict: any,
  uniqueCategory: any
) => {
  return [
    // [{ text: "State(s)", bold: true, fontSize: 12 }, uniqueState],
    // [{ text: "District(s)", bold: true, fontSize: 12 }, uniqueDistrict],
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [{ text: "Category", bold: true, fontSize: 12 }, uniqueCategory],
  ];
};

export const onlyStaticDuration =()=>{
  return [[{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"]]
}

export const tableFristData = ({fristColumnKey, fristColumnValue, secoundColumnKey, secoundColumnValue}:any) =>{
  console.log(fristColumnKey, fristColumnValue, secoundColumnKey, secoundColumnValue)
  return (
    [
      [{ text: fristColumnKey, bold: true, fontSize: 12 }, fristColumnValue],
      [{ text: secoundColumnKey, bold: true, fontSize: 12 }, secoundColumnValue]
    ]
  )
}

//@Type: Filter_District_Category_wise_case_type_report
export const filterDisCatWiseCaseTypeReport = (
  // uniqueState: any,
  // uniqueDistrict: any,
  uniqueCategory: any,
  uniqueCaseTypeName: any
) => {
  return [
    // [{ text: "State(s)", bold: true, fontSize: 12 }, uniqueState],
    // [{ text: "District(s)", bold: true, fontSize: 12 }, uniqueDistrict],
    [{ text: "Duration", bold: true, fontSize: 12 }, "Since 1st April 1985"],
    [{ text: "Category", bold: true, fontSize: 12 }, uniqueCategory],
    [{ text: "Case Type", bold: true, fontSize: 12 }, uniqueCaseTypeName],
  ];
};

//@Type: Bench_Wise_Report
export const benchWiseReportInfoTable = (dateOfHearing:any,uniqueCourtNamesArray:any) => {
  return [
    [{ text: "Date", bold: true, fontSize: 12 }, dateOfHearing],
    [
      { text: "Court Name", bold: true, fontSize: 12 },
      uniqueCourtNamesArray.toString(),
    ],
  ];
};

//@Type: Dynamic Filter (State District Duration)
export const DynamicReportWithDuration = (selectedData:ISelectedData, table1DataType:table1DataType) => {
  const {selectedCaseTypeName, selectedCategoryName, selectedFromDate, selectedToDate} = selectedData
 
  // Table 1 Data
  let table1Data =[
    [{ text: "Duration", bold: true, fontSize: 12 }, `${selectedFromDate.day}-${selectedFromDate.month}-${selectedFromDate.year} To ${selectedToDate.day}-${selectedToDate.month}-${selectedToDate.year}`],

  ]

  // Category single Table
  const category = [
    { text: "Selected Category", bold: true, fontSize: 12 },
    selectedCategoryName.toString().replaceAll(",",", "),
  ]

  // caseType single Table
  const caseType = [
    { text: "Selected Case Types", bold: true, fontSize: 12 },
    selectedCaseTypeName.toString().replaceAll(",",", "),
  ]

  // All caseType
  const allCaseType = [
        { text: "Selected Case Types", bold: true, fontSize: 12 },
        "Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)",
      ]
  
  switch(table1DataType){
    case "Category":
      table1Data.push(
        category
      )
      return table1Data;
    case "caseType":
        table1Data.push(
         caseType
        )
        return table1Data;
    case "CategoryWithAllCaseType":
      table1Data.push(
        category,
        allCaseType
      )
      return table1Data;
    case "AllCaseType":
      table1Data.push(
        allCaseType
      )
      return table1Data;
    case "SelectedCaseTypeAndSelectedCategory":
      table1Data.push(
        caseType,
        category
      )
      return table1Data;
  }
}
