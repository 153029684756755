import { IconButton, IconButtonProps } from "@mui/material";

interface ConfonetIconButtonProps extends IconButtonProps{
    icon?: JSX.Element;
}

/** Renders an Icon Button
 * 
 * @param ConfonetIconButtonProps
 */
export function ConfonetIconButton({icon,  ...rest }: ConfonetIconButtonProps) {
  return (
    <IconButton disableRipple {...rest}>
        {icon}
    </IconButton>
  );
}