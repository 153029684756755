import { Edit } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capsToTitleCase } from "../../../utils/Helper";
import { BasicButton } from "../BasicButton";
import { ConfonetCard, ConfonetCardBody, ConfonetCardHeader } from "../ConfonetCard";

export interface IPreviewValues {
    label: any;
    value: any;
}

interface IPreviewCardProps {
    heading?: string,
    items?: IPreviewValues[],
    edit?: () => void,
    className?: string,
}

/** Renders a preview card with given items
 * 
 * @param PreviewCardProps
 * 
 * @returns JSX.Element
 */
export const PreviewCard = ({ heading, items, edit, className }: IPreviewCardProps) => {
    const { t } = useTranslation();
    const display = (value: any): any => {
        if (typeof value === "boolean") {
            return value ? "Yes" : "No"
        }
        if (typeof value === "string") {
            return capsToTitleCase(value) || "N/A"
        }
        return value || "N/A"
    }

    return (
        <div className={className}>
            <ConfonetCard>
                {
                    heading &&
                    <ConfonetCardHeader>
                        <div className="card-title card-primary-header-title float-start">{heading}</div>
                        {
                            edit &&
                            <div className="card-primary-header-edit-icon">
                                <BasicButton
                                    text={t("BtnText.Edit")}
                                    startIcon={<Edit />}
                                    variant="text"
                                    color="warning"
                                    onClick={edit}
                                />
                            </div>
                        }
                    </ConfonetCardHeader>
                }

                <ConfonetCardBody>
                    <div className="row" >
                        {
                            items &&
                            items.map((item: IPreviewValues, index: number) => {
                                let width: number = 4;
                                let uppercase: boolean = true;
                                let divide: boolean = false;
                                switch (item.label) {
                                    case "Case Details": width = 12; uppercase = false; break;
                                    case "Address Type": divide = true; break;
                                    case "Advocate": divide = true; break;
                                }

                                return (
                                    <>
                                        {divide && <Divider className="margin-bottom-20" sx={{ opacity: '1' }} />}
                                        <div
                                            className={`margin-bottom-20 col-lg-${width}`}
                                            key={`preview_col_${item.label}`}
                                        >
                                            <Typography className="mb-2" variant="body1">{item.label}</Typography>
                                            <Typography className={`${uppercase && "text-uppercase"}`} variant="body2">{display(item.value)}</Typography>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </ConfonetCardBody>
            </ConfonetCard>
        </div>
    )
};