import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICaseCount, IGetCaseListResponse } from '../../models/cases/consumerCases/IConsumerCaseModel';

const initialState = {
    caseLists: [] as IGetCaseListResponse[][],
    caseCounts: {
        draft: 0,
        pendingForScrutiny: 0,
        revertedInScrutiny: 0,
        paymentPending: 0,
        pendingForApproval: 0,
        filed: 0,
        disposed: 0,
        pending: 0,
        against_filed: 0,
        against_disposed: 0,
        against_pending: 0
    } as ICaseCount
};

export const caseSlice = createSlice({
    name: 'case',
    initialState,
    reducers: {
        addCaseLists: (state, action: PayloadAction<IGetCaseListResponse[][]>) => {
            state.caseLists = action.payload;
        },
        clear: (state) => {
            state.caseLists = [];
            state.caseCounts = {
                draft: 0,
                pendingForScrutiny: 0,
                revertedInScrutiny: 0,
                paymentPending: 0,
                pendingForApproval: 0,
                filed: 0,
                disposed: 0,
                pending: 0,
                against_filed: 0,
                against_disposed: 0,
                against_pending: 0
            }
            localStorage.removeItem('persist:root');
        },
        addCaseCounts: (state, action: PayloadAction<ICaseCount>) => {
            state.caseCounts = action.payload
        }
    }
});

export const { addCaseLists, clear, addCaseCounts } = caseSlice.actions;
export default caseSlice.reducer;