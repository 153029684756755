import { IDropdown } from "../models/common/IDropdown";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

export const Dashboard = "Dashboard";
export const languageDropdown: IDropdown[] = [
	{ label: "English", value: "en" }, // English (en)
	{ label: "हिंदी", value: "हिं" }, // HIndi (hi)
	//   { label: "मराठी", value: "मराठी" },  // Marathi (mr)
	//   { label: "ਪੰਜਾਬੀ", value: "ਪੰਜਾਬੀ" },  // Punjabi (pa)
	//   { label: "ગુજરાતી", value: "ગુજ" },  // Gujarati (gu)
	//   { label: "ಕನ್ನಡ", value: "ಕನ್ನಡ" },  // Kannada (kn)
	//   { label: "ଓଡିଆ", value: "ଓଡିଆ" },  // Odia (or)
	//   { label: "બંગાળી", value: "বাংলা" },  // Bengali (bn)
	//   { label: "മലയാളം", value: "മലയാളം" },  // Malayalam (ml)
	//   { label: "తెలుగు", value: "తెలుగు" },  // Telugu (te)
];

export const supportedLanguages = [
	"en",
	"en-US",
	"hi",
	"mr",
	"pa",
	"gu",
	"kn",
	"or",
	"bn",
	"ml",
	"te",
];

export const errorMessage = {
	USER_ALREADY_EXIST: "User is already exist",
	ROLE_DOES_NOT_EXIST: "This role is not exist",
	FIELD_ALREADY_EXIST: "Given field already exist",
	INVALID_ARGUMENT: "Invalid argument provided",
	USER_NOT_FOUND: "User not found",
	ACCED_DENIED: "You are not allowed to take this action",
	UNKNOWN: "Unknown error occured",
	EMPTY_REQUEST: "The request you are sending is empty",
	DATA_NOT_FOUND: "Data not found",
	SOMETHING_WENT_WRONG: "Something went wrong",
};

export const pdfMakeLibrary = pdfMake;
export const pdfMakeFonts = pdfFonts;
