import { IDropdown } from "../common/IDropdown";
import { AddressDetailsModel } from "./AddressDetailsModel";

/**
 * Stores personal details including multipe addresses
 */
class PersonalDetailsModel {
    constructor(
        public name: string,
        public mobileNumber: string,
        public email: string,
        public isSeniorCitizen: boolean,
        public isWidow: boolean,
        public isDifferentlyAbled: boolean,
        public address: AddressDetailsModel[],
        public advocate: IDropdown,
        public handicapped: IDropdown,
        public partyId?: number,
        public advocateText?: string,
    ) {
        this.name = name;
        this.mobileNumber = mobileNumber;
        this.email = email;
        this.isSeniorCitizen = isSeniorCitizen;
        this.isWidow = isWidow;
        this.isDifferentlyAbled = isDifferentlyAbled;
        this.address = address;
        this.advocate = advocate;
        this.handicapped = handicapped
        this.partyId = partyId
        this.advocateText = advocateText
    }
    static readonly init = (address = [AddressDetailsModel.init()]) => new PersonalDetailsModel("", "", "", false, false, false, address, {label: "", value: ""}, {label: "", value: ""})
}

export default PersonalDetailsModel;

export class RespondantAdvocateModel {
    constructor (
        public advocate: IDropdown,
    ) {
        this.advocate = advocate;
    }

    static init = () => new RespondantAdvocateModel({label: "", value: ""});
}