import React from "react";

interface WaffleChartCellProps {
  x: number;
  y: number;
  size: number;
  gap: number;
  color: string;
}

const WaffleChartCell: React.FC<WaffleChartCellProps> = ({
  x,
  y,
  size,
  gap,
  color,
}) => {
  return (
    <rect
      x={x + gap}
      y={y + gap}
      width={size - 2 * gap}
      height={size - 2 * gap}
      fill={color}
    />
  );
};
export type WaffleLabel = { label: string; percentage: number; color: string };
interface WaffleChartProps {
  rows: number;
  columns: number;
  cellSize: number;
  cellGap: number;
  labels: WaffleLabel[];
  width?: number;
  height?: number;
  labelFontSize?: number;
}

const WaffleChart: React.FC<WaffleChartProps> = ({
  rows,
  columns,
  cellSize,
  cellGap,
  labels,
  width = columns * cellSize,
  height = rows * cellSize,
  labelFontSize = 12,
}) => {
  const labelHeight = labelFontSize + 4;

  const renderChart = () => {
    const cells = [];
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        const x = col * cellSize;
        const y = row * cellSize;

        cells.push(
          <WaffleChartCell
            key={`${row}-${col}`}
            x={x}
            y={y}
            size={cellSize}
            gap={cellGap}
            color="#ccc" // Default color
          />
        );
      }
    }
    return cells;
  };

  const renderLabels = () => {
    const totalLabels = labels.length;
    const columnsPerLabel = Math.floor(columns / totalLabels);
    const remainingColumns = columns % totalLabels;

    let labelColumns = Array(totalLabels).fill(columnsPerLabel);

    // Distribute the remaining columns
    for (let i = 0; i < remainingColumns; i++) {
      labelColumns[i]++;
    }

    let columnIndex = 0;

    const labelElements = labels.map(({ label, percentage, color }, index) => {
      const labelColumnsCount = labelColumns[index];

      const startColumn = columnIndex;
      const endColumn = columnIndex + labelColumnsCount - 1;

      columnIndex += labelColumnsCount;

      const totalCells = labelColumnsCount * rows;
      const fillCells = Math.round((percentage / 100) * totalCells);

      const x =
        ((startColumn + endColumn) / 2) * cellSize +
        (cellSize * labelColumnsCount) / 2 -
        label.length * (labelFontSize / 3.5); // Adjust x-coordinate for center alignment
      const y = height;

      const coloredCells = [];
      for (let i = 0; i < fillCells; i++) {
        const row = rows - Math.floor(i / labelColumnsCount) - 1; // Reverse the row index
        const col = startColumn + (i % labelColumnsCount);

        const x = col * cellSize;
        const y = row * cellSize;

        coloredCells.push(
          <WaffleChartCell
            key={`${label}-${i}`}
            x={x}
            y={y}
            size={cellSize}
            gap={cellGap}
            color={color}
          />
        );
      }

      return (
        <React.Fragment key={`label-${index}`}>
          <text
            x={x}
            y={y}
            textAnchor="middle"
            dominantBaseline="hanging"
            fontSize={labelFontSize}
            fill="#000"
            style={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {label}
          </text>
          {coloredCells}
        </React.Fragment>
      );
    });

    return labelElements;
  };

  const svgStyle = {
    width: `${width}px`,
    height: `${height + labelHeight}px`,
  };

  return (
    <svg style={svgStyle}>
      {renderChart()}
      {renderLabels()}
    </svg>
  );
};

export { WaffleChart };
