import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { SidebarMenuLinkRoute } from "../../components";
import { ISidebarMenu } from "../../models/common/ISidebarMenu";
import { useSidebarData } from "../../hooks/useSidebarData";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type SideViewProps = {
  open: boolean;
};

export const SidebarView = ({ open }: SideViewProps) => {
  const sidebarData = useSidebarData();
  const [openMenu, setOpenMenu] = useState<number | null>(null);

  const toggleSubMenu = (id: number) => {
    setOpenMenu(openMenu === id ? null : id);
  };

  return (
    <>
      <aside className="left-sidebar sidebar-dark sidebar-bg" hidden={!open}>
        <Scrollbars>
          <div id="sidebar" className="sidebar">
            <div className="sidebar-left">
              <ul className="nav sidebar-inner">
                {sidebarData?.map((menu: ISidebarMenu) => (
                  <li key={menu.id} className="nav-item">
                    {menu.children ? (
                      <>
                        <div
                          className="nav-item"
                          onClick={() => toggleSubMenu(Number(menu.id))}
                          style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "100%" }}
                        >
                          <SidebarMenuLinkRoute
                            className="nav-link"
                            menuIcon={menu.icon}
                            menuActiveIcon={menu.activeIcon}
                            to={menu.path}
                            label={menu.text}
                            style={{ flex: "1", color: "inherit", transition: "color 0.3s ease" }} // Flex and color transition
                          />
                          {openMenu === Number(menu.id) ? (
                            <ExpandLessIcon className="expand-icon" />
                          ) : (
                            <ExpandMoreIcon className="expand-icon" />
                          )}
                        </div>
                        {openMenu === Number(menu.id) && (
                          <ul className="nav flex-column sub-menu">
                            {menu.children.map((submenu) => (
                              <li key={submenu.id} className="nav-item">
                                <SidebarMenuLinkRoute
                                  className="nav-link"
                                  to={submenu.path}
                                  label={submenu.text}
                                />
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <SidebarMenuLinkRoute
                        className="nav-link"
                        menuIcon={menu.icon}
                        menuActiveIcon={menu.activeIcon}
                        to={menu.path}
                        label={menu.text}
                        style={{ flex: "1", color: "inherit", transition: "color 0.3s ease" }} // Flex and color transition
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </aside>

      <style>{`
        .nav-item:hover .nav-link {
          color: white; /* Change text to white on hover */
        }

        .expand-icon {
          margin-left: 16px; /* Adjust spacing as needed */
          color: black; /* Ensure the icon is black */
          background-color: white; /* Ensure the icon background is white */
          flex-shrink: 0; /* Prevent icon from shrinking */
        }

        .nav-item:hover .expand-icon {
          color: white; /* Change icon color to white on hover */
        }
      `}</style>
    </>
  );
};
