import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogboxProps = DialogProps & {
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
};

/** Renders the dialog box  */
export function Dialogbox({
  title,
  //fullScreen = false,
  children,
  open,
  onClose,
  ...rest
}: DialogboxProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        TransitionComponent={Transition}
        {...rest}
      >

        {
          title ? <>
            <AppBar sx={{ position: "relative", top: '0px', height: 'auto' }}>
              <Toolbar>
                <Typography sx={{ flex: 1 }} variant="h6" component="div">
                  {title}
                </Typography>
                {onClose && <IconButton
                  disableRipple={true}
                  edge="start"
                  size="small"
                  onClick={onClose}
                  aria-label="close"
                  color="info"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>}
              </Toolbar>
            </AppBar>
          </> : <>
            <Toolbar sx={{ justifyContent: 'end' }}>
              <IconButton
                disableRipple={true}
                edge="start"
                size="small"
                onClick={onClose}
                aria-label="close"
                color="info"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Toolbar>
          </>
        }
        {children}
      </Dialog>
    </div>
  );
}
