export const BASE_URL: string = process.env.REACT_APP_API_BASE_URL;
export const USER_MANAGEMENT_SERVICE: string = process.env.REACT_APP_API_USER_MANAGEMENT_SERVICE + "/v2";
export const MASTER_SERVICE: string = process.env.REACT_APP_API_MASTER_SERVICE + "/v2";
export const CASE_FILING_SERVICE: string = process.env.REACT_APP_API_CASE_FILING_SERVICE + "/caseFilingService/v2";
export const CASE_FILING_DOCUMENT_SERVICE: string = process.env.REACT_APP_API_CASE_FILING_SERVICE + "/caseFillingDocument/v2";
export const COURTROOM_SERVICE: string = process.env.REACT_APP_API_COURTROOM_SERVICE + "/v2";
export const ORDER_SERVICE: string = process.env.REACT_APP_API_COURTROOM_SERVICE + "/judgement/v2";
export const PAYMENT_SERVICE: string = process.env.REACT_APP_API_PAYMENT_SERVICE + "/payments/v2";
export const REPORT_SERVICE: string = process.env.REACT_APP_API_REPORT_SERVICE;
export const SPEECH_SERVICE: string = process.env.REACT_APP_API_SPEECH_SERVICE;
export const ANALYTICS_SERVICE: string = process.env.REACT_APP_API_ANALYTICS_DASHBOARD;

// BHARAT MAP
export const BHARAT_MAP_SERVICE: string = process.env.REACT_APP_API_BHARAT_MAP_SERVICE

// key for local key

// key for staging
export const BHARAT_MAP_API_KEY: string = process.env.REACT_APP_API_BHARAT_MAP_API_KEY

// key for production
// export const BHARAT_MAP_API_KEY =
//   "AYoPi0yUpPCJsWAW5QDg0D3eeCCWV03mFh-8ON_MsFWgbmn0TwFo1tVBSqmmmIKWb5RGCd0WOxi-QQVqUfduug..";

/*
Swagger Links
USER_MANAGEMENT_SERVICE - http://10.246.61.14/confonetUser/swagger-ui/index.html#/
MASTER_SERVICE - http://10.246.61.14/confonetMasterService/swagger-ui/index.html
CASE_FILING_SERVICE - http://10.246.61.14/confonetCaseManagement/swagger-ui/index.html#/
COURTROOM_SERVICE - http://10.246.61.14/courtroomService/swagger-ui/index.html#/
*/