import Carousel from 'react-material-ui-carousel';

type CarouselProps = {
  imgPath: string;
  redirectUrl: string;
};


type ConfonetCarouselProps = {
  items: Array<CarouselProps>;
}

const Item = ({ imgPath, redirectUrl }: CarouselProps) => {
  return (
      <div className="carousel-confonet-item">
        <a href={redirectUrl}>
          <img
            className="carousel-confonet-item-img"
            height={'400'}
            src={imgPath}
            alt="logo"
          />
        </a>
      </div>
  );
};

export function ConfonetCarousel({ items, ...rest }: ConfonetCarouselProps) {

  return (
    <div>
      <Carousel
        className='carousel-confonet'
        //height={400}
        interval={3000}
        fullHeightHover={true}
        navButtonsAlwaysVisible={true}
        navButtonsAlwaysInvisible={false}
        navButtonsProps={{
          className: "nav-btn",
          style: {
            backgroundColor: 'var(--primary-blue)',
            margin: '0 2px'
          }
        }}
        indicatorIconButtonProps={{
          className: "carousel-indicator-btn",
          style: {
            backgroundColor: 'var(--primary-blue)'
          }
        }}
        indicatorContainerProps={{ className: "carousel-indicator" }}
        activeIndicatorIconButtonProps={{ className: "carousel-indicator-active-btn" }}
        {...rest}>
        {items.map((item, index) => (
          <Item key={`confonet_carousel_item_${item.imgPath}`} {...item} />
        ))}
      </Carousel>
    </div >
  )
}

