import { QrCode_image } from './Qrcode_image';
import { BodyTable } from "./BodyTable";
import { pdfMakeFonts, pdfMakeLibrary } from "../../CONSTANTS";

export const reportPdfMaker = async (Report_type: any) => {
  return new Promise(async (resolve) => {
    pdfMakeLibrary.vfs = pdfMakeFonts.pdfMake.vfs;
    const table_data: any = await BodyTable(Report_type);

    table_data.tableData2[0] = table_data.tableData2[0].map((cell: any) => {
      return { text: cell, bold: true, ...cell };
    });
    // table_data.tableData2[table_data.tableData2.length - 1] =
    //   table_data.tableData2[table_data.tableData2.length - 1].map(
    //     (cell: any) => {
    //       return { text: cell, bold: true };
    //     }
    //   );

    const currentTimeString = new Date().toLocaleTimeString().toString();

    const getFooter = function (currentPage: number, pageCount: number) {
      return {
        text: [
          {
            text: `Report generated by (User/Role) from e-Jagriti                                                                                                                                                                                                                                                                                        ${
              "Page " + currentPage.toString() + " of " + pageCount
            } \n\n\n`,
            fontSize: 8,
            marginLeft: 40,
            alignment: "center",
          },
          {
            text: "Designed, Developed By National Informatics Centre",
            fontSize: 8,
            alignment: "center",
          },
        ],
      };
    };
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const year = currentDate.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;

    const tableDefinition = {
      table: {
        headerRows: table_data?.eachPageHeader ? table_data.eachPageHeader : 1, // if custom header, else take header 1st column
        widths: table_data?.tableData2_width,
        body: table_data?.tableData2,
        layout: "headerLineOnly",
      },
    };

    const docDefinition = {
      pageMargins: table_data?.Page_Margin,
      header: function (currentPage: number) {
        if (currentPage > 1) {
          return [
            {
              text: `${table_data?.MainReportHeader[2].text}`,
              alignment: "center",
              absolutePosition: { x: 0, y: 25 },
              fontSize: 8,
            },
            {
              text: `${formattedDate + " " + currentTimeString}`,
              margin: [690, 25, 0, 0],
              fontSize: 10,
            },
            {
              table: {
                widths: table_data?.tableData1_width,
                body: table_data?.tableData1,
              },
              margin: [40, 20, 20, 20],
            },
          ];
        }
        return null;
      },

      footer: getFooter,
      content: [
        {
          text: `${formattedDate + " " + currentTimeString}`,
          style: "date",
          fontSize: 10,
          absolutePosition: { x: 680, y: 118 },
        },
        {
          absolutePosition: { x: 0, y: 25 },
          text: table_data?.MainReportHeader
        },

        {
          table: {
            widths: table_data.tableData1_width,
            body: table_data.tableData1,
          },
          margin: [0, 30, 0, 30], // Margin bottom
        },
        tableDefinition,
        {
          text: table_data?.pendingMessage,
          margin: [40, 20, 0, 0],
        },

        {
          text: "***End of Report***",
          alignment: "center",
          margin: [0, 20, 0, 0],
        },
        {
          text: "Scan here",
          style: 'date',
          marginRight: 10,
          alignment:"right"
        },
        {
          image: await QrCode_image(Report_type),
          width: 60,
          height: 60,
          alignment:"right"
        },
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
        },
        date: {
          fontSize: 10,
        },
        subheaderRed: {
          color: "black",
        },
        subheader: {
          fontSize: 11,
        },
      },
      pageOrientation: "landscape",
    };

    const pdfDocGenerator = pdfMakeLibrary.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob: any) => {
      resolve(blob); // Resolve the promise with the Blob
    });
  });
};
