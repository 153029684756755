import airlines from '../assets/images/categoryIcon/Airlines.svg';
import banking from '../assets/images/categoryIcon/Bank.svg';
import consumer from '../assets/images/categoryIcon/Consumer.svg';
import goods from '../assets/images/categoryIcon/Defective Goods.svg'
import ecom from '../assets/images/categoryIcon/Ecommerce.svg'
import edu from '../assets/images/categoryIcon/education.svg'
import elect from '../assets/images/categoryIcon/electricity.svg'
import finance from '../assets/images/categoryIcon/finance.svg'
import house from '../assets/images/categoryIcon/Housing.svg'
import immigrate from '../assets/images/categoryIcon/imigration.svg'
import industry from '../assets/images/categoryIcon/industries.svg'
import insurance from '../assets/images/categoryIcon/insurance.svg'
import medic from '../assets/images/categoryIcon/medical.svg'
import post from '../assets/images/categoryIcon/postal.svg'
import rail from '../assets/images/categoryIcon/railway.svg'
import service from '../assets/images/categoryIcon/Service Defiency.svg'
import telecom from '../assets/images/categoryIcon/telecom.svg'
import transport from '../assets/images/categoryIcon/transport.svg'
import trade from '../assets/images/categoryIcon/unfair Trades.svg'
import trust from '../assets/images/categoryIcon/trust.svg'
import others from '../assets/images/categoryIcon/more.png'

export const iconMap: Record<string, string> = {
    "AIRLINES": airlines,
    "BANKING": banking,
    "CONSUMER": consumer,
    "DEFECTIVE GOODS": goods,
    "E-COMMERCE": ecom,
    "EDUCATION": edu,
    "ELECTRICITY": elect,
    "FINANCE": finance,
    "HOUSING": house,
    "IMMIGRATION": immigrate,
    "INSURANCE": insurance,
    "INDUSTRIES": industry,
    "MEDICAL": medic,
    "RAILWAYS": rail,
    "POSTAL": post,
    "SERVICE DEFICIENCY": service,
    "TELECOM": telecom,
    "TRANSPORT": transport,
    "TRUST": trust,
    "UNFAIR TRADE": trade,
    "OTHERS": others
};