import * as React from "react";
import { OutlinedInput, OutlinedInputProps, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";

type TextboxWithIconProps = OutlinedInputProps & {
    helpertext?: string | false;
}

/**
 * Component to render an input box with icon
 * @param TextboxWithIconProps 
 * @param helpertext
 */
export function TextboxWithIcon({ ...rest }: TextboxWithIconProps) {
    const { id, label, error, helpertext } = rest;
    return (
        <div className="form-control-group margin-bottom-30">
            <FormControl className="input-form-control" fullWidth>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <OutlinedInput {...rest} />
                {error && (
                    <span className="text-danger">{helpertext}</span>
                )}
            </FormControl>
        </div>
    );
}
