import { AxiosResponse } from "axios";
import { IGetUserComplinantDetailsResponse, UserModel } from "../../models/user/UserModel";
import { USER_MANAGEMENT_SERVICE } from "../CONSTANTS";
import { postCall } from "../api";

/** Service which holds all the methods related to user */
export class UserService {
  /** Get user detail
   * @param userId number
   */
  public static readonly getUserDetail = (userId: number) => {
    return new UserModel(0, "", "", "Amod Vardhan", [1], 0, 0, false, 0);
  };

  public static readonly getUserDetailByUserId = async (userId: number): Promise<IGetUserComplinantDetailsResponse> => {
    const data = { userId }
    const response: AxiosResponse<IGetUserComplinantDetailsResponse> = await postCall(
      `${USER_MANAGEMENT_SERVICE}/getUserDetailsByUserId`,
      data
    )

    return response?.data
  }
}
