import React from "react";

type CardsProps = {
  children: React.ReactNode;
  className?: string;
};

/** Renders the card
 * @param CardsProps
 */
export function Card({
  children,
  className = "card card-primary h-100",
}: CardsProps) {
  return <div className={className}>{children}</div>;
}

type CardHeaderProps = {
  children: React.ReactNode;
  className?: string;
};
export const CardHeader = ({
  children,
  className = "card-header card-primary-header",
}: CardHeaderProps) => <div className={className}>{children}</div>;

type CardBodyProps = {
  value?: string;
  children: React.ReactNode;
  className?: string;
};
export const CardBody = ({
  value,
  children,
  className = "card-body card-primary-body",
}: CardBodyProps) => <div className={className}>{children}</div>;
