import React, { useEffect, useState } from "react";
import Loader from "../graphloader/Loader";
import Judge from "../../../assets/images/Judge.png";
import President from "../../../assets/images/president.svg";
import JudgesPerformanceBarchart from "./PlotlyCharts/JudgesPerformanceBarchart";
import { FormControlLabel, Radio, RadioGroup, Button, Tooltip, Fade } from "@mui/material";
import { JudgesGroupedBarChart } from "./PlotlyCharts/JudgesGroupedBarChart";
import JudgeTable from "./PlotlyCharts/JudgeTable";
import { AnalyticsService } from "../../../services/AnalyticsService/AnalyticsService";
import { iconMap } from "../../../utils/CategoryIcons";

const JudgesDashboard = () => {
  const [selectedType, setSelectedType] = useState<number>(1);
  const [specificJudgeData, setSpecificJudgeData] = useState<any>([]);
  const [judgeData, setJudgeData] = useState<any>([]);
  const [courtroomData, setCourtroomData] = useState<any>([]);
  const [sectorwiseCaseData, setSectorwiseCaseData] = useState<any>([]);
  const [loadingSpecificData, setLoadingSpecificData] = useState<boolean>(false);
  const [judgeId, setJudgeId] = useState<string>("NULL");
  const [categoryId, setCategoryId] = useState<number>(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>("");

  const fetchCourtroomData = async () => {
    setLoadingSpecificData(true);
    const response = await AnalyticsService.getCourtroomData();
    if (response?.length > 0) {
      setCourtroomData(response);
    }
    setLoadingSpecificData(false);
  };

  const fetchJudgesData = async () => {
    const response = await AnalyticsService.getJudgeData();
    if (response?.length > 0) {
      setJudgeData(response);
    }
  };

  const fetchJudgesSectorData = async (id: string) => {
    setCategoryId(0);
    const data = await AnalyticsService.getJudgeDataBySector(id)
;
    if (data?.length > 0) {
      setSpecificJudgeData(data);
    }
  };

  const fetchJudgesSectorWiseCaseData = async (data: any) => {
    const result = await AnalyticsService.getJudgeSectorwiseCases(data);
    if (result?.length > 0) {
      setSectorwiseCaseData(result);
    }
  };

  const handleCommissionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(Number(event.target.value));
    setJudgeId("NULL");
    setCategoryId(0);
  };

  useEffect(() => {
    if (judgeId !== "NULL") {
      fetchJudgesSectorData(judgeId);
    }
  }, [judgeId]);

  useEffect(() => {
    if (judgeId !== "NULL" && categoryId !== 0) {
      fetchJudgesSectorWiseCaseData({ judgeid: judgeId, categoryid: categoryId });
    }
  }, [judgeId, categoryId]);

  useEffect(() => {
    fetchJudgesData();
    fetchCourtroomData();
    window.scrollTo(0, 0);
  }, []);

  const uniqueCategories = [...new Set(specificJudgeData.map((item: any) => item.category_name))];

  const renderCategoryButtons = () => {
    return (uniqueCategories as string[]).map((category: string) => (
      <Tooltip
      placement="top"
      title="Click here"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      >
      <Button
        key={category}
        variant="outlined"
        onClick={() => {
          const categoryData = specificJudgeData.find((item: any) => item.category_name === category);
          if (categoryData) {
            setCategoryId(categoryData.case_category_id);
            setSelectedCategoryName(category); // Set the selected category name here
          }
        }}
      >
        {uniqueCategories.length > 0 && (
          <img src={iconMap[category]} alt="cat" width={50} height={40} className="icon-white" />
        )}
        {category}
      </Button>
      </Tooltip>
    ));
  };

  const content = () => {
    if (selectedType === 1) {
      return (
        <div className="col-lg-6 m-auto margin-bottom-40">
          {loadingSpecificData ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <Loader />
            </div>
          ) : (
            <div className="graph-card">
              <JudgesPerformanceBarchart data={courtroomData} />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="row margin-bottom-40">
          <div className="col-lg-12 judgeName">
            {judgeData?.length > 0 &&
              judgeData.map(
                (judge: { judgeid:
                  React.SetStateAction<string>; 
                  designation: string; 
                  salutation: any; 
                  judges_name: any;
                 }, index: React.Key | null | undefined) => (
                  <div
                    key={index}
                    className={`judge-card ${judgeId === judge?.judgeid ? "active" : ""}`}
                    onClick={() => setJudgeId(judge?.judgeid)}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          src={judge.designation === "PRESIDENT" ? President : Judge}
                          className="width-50 height-50 margin-top-5"
                          alt="judge"
                        />
                      </div>
                      <div className={`designation ${judge.designation === "PRESIDENT" ? "president" : ""}`}>
                        <p>{`${judge.salutation} ${judge.judges_name}`}</p>
                        <p>
                          <i>{`${judge.designation}`}</i>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>

          <div className="col-lg-12 margin-top-30">
            {loadingSpecificData && (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <Loader />
              </div>
            )}

            {!loadingSpecificData && judgeId === "NULL" ? (
              <div className="graph-card">
                <JudgesGroupedBarChart data={judgeData} type="judge" />
              </div>
            ) : specificJudgeData?.length > 0 && judgeId !== "NULL" ? (
              <>
                <div className="graph-card">
                  <JudgesGroupedBarChart data={specificJudgeData} type="category" setCategoryId={setCategoryId} />
                </div>
                <br />
                <div className="buttons-category">
                  <div className="category-buttons">
                    <div className="icon-sector">
                      {renderCategoryButtons()}
                      </div>
                  </div>
                </div>
                <br/>
                {categoryId !== 0 && (
                  <JudgeTable data={sectorwiseCaseData} 
                  categoryName={selectedCategoryName} />
                )}
              </>
            ) : (
              judgeId === "" &&
              specificJudgeData?.length > 0 && (
                <div className="d-flex justify-content-center margin-top-20 h-100 w-100">
                  Please Select Judge
                </div>
              )
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="section margin-top-35">
      <div className="container-fluid">
        <div className="row">
          <div className="radio-button">
            <RadioGroup row value={selectedType} onChange={handleCommissionTypeChange}>
              <FormControlLabel value={1} control={<Radio size="medium" />} label="In Courtroom" />
              <FormControlLabel value={2} control={<Radio size="medium" />} label="Judges in Courtroom" />
            </RadioGroup>
          </div>
          {content()}
        </div>
      </div>
    </div>
  );
};

export default JudgesDashboard;