import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';

const NoDataFound = () => {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography variant="body2">
                {t("Label.NoDataFound")}
            </Typography>
        </Stack>
    )
}

export default NoDataFound