import { redirect } from "react-router-dom";

export default function NewsPressRelease() {

    const navigateToWebsite = (redirectLink: string) => {
        window.open(redirectLink, "_blank");
    };

    const newsCardDetails =
    {
        duration: "13 Feb 2024",
        image: "/images/Ai.png",
        details: `Artificial Intelligence (AI) integration in e-Jagriti will help in
                            reducing the pendency
                            of
                            consumer cases in the Consumer Commissions: Secretary, Department of Consumer Affairs,
                            GoI`
    }



    const dataPressRelease = [
        {
            icon: "/images/right-sideSecoundImage.png",
            source: "Press Information Bureau",
            redirectTo: "https://pib.gov.in/PressReleseDetail.aspx?PRID=1990081#",
            duration: "24 Dec 2023",
            details: `National Consumer Helpline 2.0 with new techlogies will address consumer grievance in a better way: Shri Piyush Goyal on National Consumer Day`
        },
        {
            icon: "/images/news3.jpg",
            source: "Press Information Bureau",
            redirectTo: "https://pib.gov.in/PressReleseDetail.aspx?PRID=2043744",
            duration: "09 Aug 2024",
            details: `2-day training programme on ‘Applications of AI/ML’ being conducted by National e-Governance Division`
        },
        {
            icon: "/images/news4.jpg",
            source: "Press Information Bureau",
            redirectTo: "https://pib.gov.in/PressReleseDetail.aspx?PRID=2039638",
            duration: "31 Jul 2024",
            details: `Government of India taking steps to encourage domestic manufacturing of semiconductors & promote country's digital transformation and self-reliance`
        }
    ]



    const PressReleaseTemplate = ({ redirectLink, imageUrl, source, duration, details, key }: { redirectLink: string, imageUrl: string, source: string, duration: string, details: string, key: any }) => {
        return (
            <div onClick={() => navigateToWebsite(redirectLink)} className="d-flex flex-column gap-2 bg-press-release px-2 py-3" key={key}>
                <div className="d-flex flex-row justify-content-between fw-semibold text-mute">
                    <small>{source}</small>
                    <small>{duration}</small>
                </div>
                <div className="d-flex flex-row gap-3 ">

                    <div className="size-56px">
                        <img className="object-fit-cover w-100 h-100" alt={source} src={imageUrl} />
                    </div>
                    <div>
                        <p className="ellipsis-2 ">{details}</p>
                    </div>

                </div>

            </div>
        )
    }



    return (
        <div className="news-spotlight-subgrid section-padding">
            {/* <h5 className="fw-bold text-white">Stay Informed, Stay Empowered!</h5> */}
            <h2 className="fw-bold text-white">News / <span className="theme-secondary">Press Release</span></h2>
            <div className="news-press-release-grid">
                <div onClick={() => navigateToWebsite("https://pib.gov.in/PressReleseDetail.aspx?PRID=2005604#")} className="card-width-responsive d-flex flex-column bg-white rounded">
                    <div className="content-card-size-responsive rounded-top">
                        <img className="object-fit-cover w-100 h-100" alt={"Press Release"} src={newsCardDetails.image} />
                    </div>
                    <div className="p-2 d-flex flex-column gap-2">
                        <div className="d-flex flex-row-reverse justify-content-between"><small>{newsCardDetails.duration}</small>
                        </div>
                        <p className="ellipsis-3 ">{newsCardDetails.details}</p>
                    </div>
                </div>
                <div className="d-flex flex-column gap-1 justify-content-between">
                    {
                        dataPressRelease.map((item, index) => (
                            <PressReleaseTemplate key={index} redirectLink={item?.redirectTo} source={item.source} imageUrl={item.icon} duration={item.duration} details={item.details} />
                        ))
                    }
                </div>

            </div>
            <div className="d-flex flex-row justify-content-center">
                <button className="btn btn-light">View all</button>
            </div>
        </div>
    )
}