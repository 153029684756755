import { Checkbox, TextField, Typography } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BasicCheckbox, ConfonetAutocomplete, Textbox } from "../../../components";
import { IDropdown } from "../../../models/common/IDropdown";
import { AddressDetailsModel } from "../../../models/forms/AddressDetailsModel";
import { RootState, useAppDispatch } from "../../../redux";
import { addAddressTypeList, addPostOfficeList } from "../../../redux/SpeechReducer/SpeechReducer";
import { MasterService } from "../../../services/MasterService/MasterService";
import { dropdownValidationSchema, makeDropdownOptions } from "../../../utils/Helper";
import { ValidationRegExp } from "../../../utils/ValidationRegExp";
import { ADD_ADVOCATE, ADVOCATE_LIST } from "../../../navigation/CONSTANTS";

export const addressValidationSchema = Yup.object(({
    addressType: dropdownValidationSchema("Address type is a required field")
        .required(),
    houseNumber: Yup.string()
        .max(60, "Only up to 60 characters are allowed")
        .matches(ValidationRegExp.ALPHABETS_SPACES_NUMBERS_AND_SPECIAL, "Only these special characters are allowed: ,-/.#"),
    landmark: Yup.string()
        .max(60, "Only up to 60 characters are allowed")
        .matches(ValidationRegExp.ALPHABETS_SPACES_NUMBERS_AND_SPECIAL, "Only these special characters are allowed: ,-/.#"),
    street: Yup.string()
        .max(60, "Only up to 60 characters are allowed")
        .matches(ValidationRegExp.ALPHABETS_SPACES_NUMBERS_AND_SPECIAL, "Only these special characters are allowed: ,-/.#"),
    pinCode: Yup.string()
        .required("Pincode is a required field")
        .length(6, "Pin code must have exactly 6 digits")
        .matches(ValidationRegExp.ONLY_NUMBER, "Please enter only numbers"),
    isNRI: Yup.boolean()
        .required("NRI status is required"),
    state: Yup.lazy((value, options) => {
        const { parent } = options;
        if (!parent.isNRI) {
            return dropdownValidationSchema("State is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
    district: Yup.lazy((value, options) => {
        const { parent } = options;
        if (!parent.isNRI) {
            return dropdownValidationSchema("District is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
    postOffice: Yup.lazy((value, options) => {
        const { parent } = options;
        if (!parent.isNRI) {
            return dropdownValidationSchema("Post ffice is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
    country: dropdownValidationSchema("Country is a required field").required(),
    stateOfNri: Yup.string()
        .when('isNRI', {
            is: true,
            then: (schema) => schema.required("State is a required field"),
            otherwise: (schema) => schema.notRequired()
        }),
    districtOfNri: Yup.string()
        .when('isNRI', {
            is: true,
            then: (schema) => schema.required("District is a required field"),
            otherwise: (schema) => schema.notRequired()
        })
}));

type AddressDetailsProps = {
    name: string,
    label?: string,
    values: AddressDetailsModel,
    touched?: FormikTouched<AddressDetailsModel>,
    errors?: string | FormikErrors<AddressDetailsModel>,
    handleBlur: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    },
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
    selectedAddressTypeOptions: IDropdown[],
    handleSelectAddressType?: () => void,
    isRespondent?: boolean
}

/** Renders the Address Details sub-form
 * 
 * @param AddressDetailsProps
 * 
 * ### Fields
 * * Address Type
 * * House Number
 * * Landmark
 * * Post Office
 * * Country
 * * State
 * * District
 * * Police Station
 * * Pin Code
 */
export const AddressDetails = (props: AddressDetailsProps) => {
    const {
        name,
        label,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        selectedAddressTypeOptions,
        handleSelectAddressType,
    } = props;

    const { userDetailsData } = useSelector((state: RootState) => state.user);
    const { state, country, addressType } = useSelector((state: RootState) => state.master);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [addressTypeOptions, setAddressTypeOptions] = useState<IDropdown[]>([])
    const [stateOptions, setStateOptions] = useState<IDropdown[]>([])
    const [districtOptions, setDistrictOptions] = useState<IDropdown[]>([]);
    const [postOfficeOptions, setPostOfficeOptions] = useState<IDropdown[]>([])
    const prefix = `${name}${name === "" ? "" : "."}`;
    const { t } = useTranslation();

    // Get all address types API calling
    const handleGetAllAddressTypes = useCallback(async () => {
        setAddressTypeOptions(addressType)
        dispatch(addAddressTypeList(addressType))
    }, [dispatch])

    // Get all states API calling
    const handleGetAllStates = useCallback(async () => {
        setStateOptions(state)
    }, [state]);

    // Get all districts of a specific state API calling
    const handleGetDistrictsById = async (stateId: number) => {
        const districts = await MasterService.getDistrictsByStateId(stateId)
        setDistrictOptions(makeDropdownOptions(districts, "districtNameEn", "districtId"))
    };

    useEffect(() => {
        if (!values.isNRI) {
            setFieldValue(`${prefix}country`, { label: "India", value: 76 });
        }
    }, [values.isNRI, setFieldValue, prefix]);
    // Get PinCode Details and set state, district and post office
    const handleGetPinCodeDetails = useCallback(async (pincode: string) => {
        if (pincode?.length === 6 && !Number.isNaN(pincode)) {
            const pinCodeDetailsResponse = await MasterService.getPincodeDetails(parseInt(pincode));
            if (pinCodeDetailsResponse?.length > 0) {
                const postOfficeList = makeDropdownOptions(pinCodeDetailsResponse, "postOfficeName", "masterPostOfficeId")
                setPostOfficeOptions(postOfficeList)
                dispatch(addPostOfficeList(postOfficeList))

                // set state according to pincode
                const pinCodeState: IDropdown = {
                    label: pinCodeDetailsResponse[0]?.stateNameEn,
                    value: pinCodeDetailsResponse[0]?.stateId
                }
                setFieldValue(`${prefix}state`, pinCodeState);
                setFieldTouched(`${prefix}state`, false);

                // set District according to pincode
                const pinCodeDistrict: IDropdown = {
                    label: pinCodeDetailsResponse[0].districtNameEn,
                    value: pinCodeDetailsResponse[0].districtId
                }
                setFieldValue(`${prefix}district`, pinCodeDistrict);
                setFieldTouched(`${prefix}district`, false);

                // set District according to pincode
                const pinCodePostOffice: IDropdown = {
                    label: pinCodeDetailsResponse[0].postOfficeName,
                    value: pinCodeDetailsResponse[0].masterPostOfficeId
                }
                setFieldValue(`${prefix}postOffice`, pinCodePostOffice);
                setFieldTouched(`${prefix}postOffice`, false);
            }
        }
        else {
            setPostOfficeOptions([]);
            setFieldValue(`${prefix}state`, { label: "", value: "" });
            setFieldValue(`${prefix}district`, { label: "", value: "" });
            setFieldValue(`${prefix}postOffice`, { label: "", value: "" });
        }
    }, [dispatch, prefix, setFieldValue, setFieldTouched]);

    // Handle API calling when component is mounted
    useEffect(() => {
        handleGetAllAddressTypes();
        handleGetAllStates();
    }, [handleGetAllAddressTypes, handleGetAllStates]);

    useEffect(() => {
        handleSelectAddressType?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (location.pathname.includes('edit-profile') && userDetailsData.userId !== 0) {

            // make addressType value for setting
            const addressTypeValue = addressTypeOptions.filter((type) => type.value === userDetailsData?.addressTypeId);

            setFieldValue(`${prefix}addressType`, addressTypeValue[0] ?? "");
            setFieldValue(`${prefix}houseNumber`, userDetailsData?.houseDoorFlatNumber ?? "");
            setFieldValue(`${prefix}street`, userDetailsData?.areaNameNumber ?? "");
            setFieldValue(`${prefix}landmark`, userDetailsData?.landmarkLocality ?? "");
            setFieldValue(`${prefix}pinCode`, userDetailsData?.postalPinCode ?? "");
            handleGetPinCodeDetails(userDetailsData?.postalPinCode?.toString())
        }
    }, [userDetailsData, location, addressTypeOptions, handleGetPinCodeDetails, prefix, setFieldValue])

    useEffect(() => {
        handleGetPinCodeDetails(values.pinCode)
    }, [values.pinCode, handleGetPinCodeDetails]);

    return (
        <>
            <div className="row">
                <div className="col-auto">
                    {label?.length ? <Typography className="margin-bottom-20" variant="h5">{label}</Typography> : ''}
                </div>{
                    location.pathname !== ADD_ADVOCATE && location.pathname !== ADVOCATE_LIST &&
                    <div className="col-auto">
                        <BasicCheckbox
                            control={<Checkbox />}
                            id="isNRI"
                            name={`${prefix}isNRI`}
                            label={t("Label.NRI")}
                            checked={values.isNRI}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                }

            </div>
            <div className="row">
                <div className="col-lg-4">
                    <ConfonetAutocomplete
                        id="AddressType"
                        className="uppercase"
                        value={values.addressType}
                        options={addressTypeOptions}
                        clearOnBlur
                        renderInput={(params) => (
                            <TextField
                                label={t("Label.AddressType") + "*"}
                                {...params}
                                className='select-form-control-element'
                                error={touched?.addressType && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.addressType : false)}
                                helperText={touched?.addressType?.label && errors ? (errors as FormikErrors<AddressDetailsModel>)?.addressType?.label : ''}
                                fullWidth
                            />
                        )}
                        getOptionDisabled={(option: IDropdown) => selectedAddressTypeOptions.some((disabledOption: IDropdown) => disabledOption.value === option.value)}
                        onChange={(_, newValue: IDropdown | null) => { setFieldValue(`${prefix}addressType`, newValue ?? { label: "", value: "" }) }}
                        onBlur={() => setFieldTouched(`${prefix}addressType.label`, true)}
                    />
                </div>
                <div className="col-lg-4">
                    <Textbox
                        id="HouseNumber"
                        name={`${prefix}houseNumber`}
                        className="uppercase"
                        label={t("Label.HouseNumber")}
                        value={values.houseNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 51 }}
                        error={touched?.houseNumber && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.houseNumber : false)}
                        helperText={touched?.houseNumber && errors ? (errors as FormikErrors<AddressDetailsModel>)?.houseNumber : ''}
                    />
                </div>
                <div className="col-lg-4">
                    <Textbox
                        id="street"
                        name={`${prefix}street`}
                        className="uppercase"
                        label={t("Label.Street")}
                        value={values.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 75 }}
                        error={touched?.street && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.street : false)}
                        helperText={touched?.street && errors ? (errors as FormikErrors<AddressDetailsModel>)?.street : ''}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Textbox
                        id="Landmark"
                        name={`${prefix}landmark`}
                        className="uppercase"
                        label={t("Label.Landmark")}
                        value={values.landmark}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 75 }}
                        error={touched?.landmark && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.landmark : false)}
                        helperText={touched?.landmark && errors ? (errors as FormikErrors<AddressDetailsModel>)?.landmark : ''}
                    />
                </div>
                <div className="col-lg-4">
                    <ConfonetAutocomplete
                        id="Country"
                        className="uppercase"
                        value={values.country}
                        disabled={!values.isNRI}
                        options={country}
                        clearOnBlur
                        renderInput={
                            (params) => (
                                <TextField
                                    label={t("Label.Country") + "*"}
                                    {...params}
                                    className='select-form-control-element'
                                    error={touched?.country && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.country : false)}
                                    helperText={touched?.country && errors ? (errors as FormikErrors<AddressDetailsModel>)?.country?.label : ''}
                                    fullWidth
                                />
                            )
                        }
                        onChange={(_, newValue: IDropdown | null) => { setFieldValue(`${prefix}country`, newValue ?? { label: "", value: "" }) }}
                        onBlur={() => { setFieldTouched(`${prefix}country.label`, true) }}
                    />
                </div>
                <div className="col-lg-4">
                    <Textbox
                        id="PinCode"
                        name={`${prefix}pinCode`}
                        className="uppercase"
                        label={t("Label.PinCode") + "*"}
                        value={values.pinCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched?.pinCode && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.pinCode : false)}
                        helperText={touched?.pinCode && errors ? (errors as FormikErrors<AddressDetailsModel>)?.pinCode : ''}
                    />
                </div>
            </div>
            <div className="row">
                {values.isNRI ? (
                    <>
                        <div className="col-lg-4">
                            <Textbox
                                id="State"
                                name={`${prefix}stateOfNri`}
                                className="uppercase"
                                label={t("Label.State")}
                                value={values.stateOfNri}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 75 }}
                                error={touched?.stateOfNri && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.stateOfNri : false)}
                                helperText={touched?.stateOfNri && errors ? (errors as FormikErrors<AddressDetailsModel>)?.stateOfNri : ''}
                            />
                        </div>
                        <div className="col-lg-4">
                            <Textbox
                                id="District"
                                name={`${prefix}districtOfNri`}
                                className="uppercase"
                                label={t("Label.District")}
                                value={values.districtOfNri}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 75 }}
                                error={touched?.districtOfNri && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.districtOfNri : false)}
                                helperText={touched?.districtOfNri && errors ? (errors as FormikErrors<AddressDetailsModel>)?.districtOfNri : ''}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-lg-4">
                            <ConfonetAutocomplete
                                id="State"
                                className="uppercase"
                                value={values.state}
                                options={stateOptions}
                                clearOnBlur
                                disabled
                                renderInput={
                                    (params) => (
                                        <TextField
                                            label={t("Label.State") + "*"}
                                            {...params}
                                            className='select-form-control-element'
                                            error={touched?.state && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.state : false)}
                                            helperText={touched?.state && errors ? (errors as FormikErrors<AddressDetailsModel>)?.state?.label : ''}
                                            fullWidth
                                        />
                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => {
                                    setFieldValue(`${prefix}state`, newValue ?? { label: "", value: "" })
                                    setFieldValue(`${prefix}district`, { label: "", value: "" })
                                    setFieldTouched(`${prefix}district`, false)
                                    if (newValue && newValue.value !== "") {
                                        handleGetDistrictsById(newValue.value)
                                    }
                                    else {
                                        setDistrictOptions([{ label: "", value: "" }])
                                    }
                                }}
                                onBlur={() => { setFieldTouched(`${prefix}state.label`, true) }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <ConfonetAutocomplete
                                id="District"
                                className="uppercase"
                                value={values.district}
                                options={districtOptions}
                                disabled
                                renderInput={
                                    (params) => (
                                        <TextField
                                            label={t("Label.District") + "*"}
                                            {...params}
                                            error={touched?.district && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.district : false)}
                                            helperText={touched?.district && errors ? (errors as FormikErrors<AddressDetailsModel>)?.district?.label : ''}
                                            fullWidth
                                        />
                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => { setFieldValue(`${prefix}district`, newValue ?? { label: "", value: "" }) }}
                                onBlur={() => { setFieldTouched(`${prefix}district.label`, true) }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <ConfonetAutocomplete
                                id="PostOffice"
                                className="uppercase"
                                value={values.postOffice}
                                options={postOfficeOptions}
                                renderInput={
                                    (params) => (
                                        <TextField
                                            label={t("Label.PostOffice") + "*"}
                                            {...params}
                                            error={touched?.postOffice && Boolean(errors ? (errors as FormikErrors<AddressDetailsModel>)?.postOffice : false)}
                                            helperText={touched?.postOffice && errors ? (errors as FormikErrors<AddressDetailsModel>)?.postOffice?.label : ''}
                                            fullWidth
                                        />
                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => { setFieldValue(`${prefix}postOffice`, newValue ?? { label: "", value: "" }) }}
                                onBlur={() => { setFieldTouched(`${prefix}postOffice.label`, true) }}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
};