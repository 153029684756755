export class UserModel {
  constructor(
    public userId: number,
    public userName: string,
    public mobileNumber: string,
    public email: string | null,
    public roles: Array<number>,
    public pinCode: number | null,
    public identityProofNumber: number | null,
    public alreadyActiveUser: boolean,
    public commissionId?: number | null,
  ) {
    this.userId = userId;
    this.userName = userName;
    this.mobileNumber = mobileNumber;
    this.email = email;
    this.roles = roles;
    this.commissionId = commissionId;
    this.pinCode = pinCode;
    this.identityProofNumber = identityProofNumber;
    this.alreadyActiveUser = alreadyActiveUser
  }

  static init = () => new UserModel(0, "", "", "", [], null, null, false);
}

export interface IRolesResponse {
  roleId: number;
  roleNameEnglish: string;
  activeStatus: number;
}

export interface IRolesResponse {
  roleId: number;
  roleNameEnglish: string;
  activeStatus: number;
}

export interface IUserStatus {
  userId: string;
  userLoginId: string;
  activeStatus: number;
  mobileNumber?: number;
  userLoginPassword?: string;
  userName?: string;
}

export interface IPasswordResetResponse {
  userId: number;
  userLoginId: string;
  userName: string;
  mobileNumber: number;
  createdOnDateTime: string;
}

export class OtpModel {
  constructor(public mobileNumber: string) {
    this.mobileNumber = mobileNumber;
  }
}

export class ResetPasswordModel {
  constructor(
    public emailOrMobile: string,
    public userLoginPassword: string,
    public otpText: string,
    public clientIpAddress: string
  ) {
    this.emailOrMobile = emailOrMobile;
    this.userLoginPassword = userLoginPassword;
    this.otpText = otpText;
    this.clientIpAddress = clientIpAddress;
  }
}

export class ChangePasswordModel {
  constructor(
    public userId: number,
    public oldPassword: string,
    public newPassword: string,
    public clientIpAddress: string
  ) {
    this.userId = userId;
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.clientIpAddress = clientIpAddress;
  }
}

export interface IChangePasswordResponse {
  success: boolean;
  message: string;
}

export class ChangeMobileNumberOrEmailModel {
  constructor(public userId: number, public mobileorEmail: string) {
    this.userId = userId;
    this.mobileorEmail = mobileorEmail;
  }
}

export class ChangeMobileNumberOrEmailOtpModel {
  constructor(
    public userId: number,
    // public newMobileOrEmail: string,
    public emailOrMobile: string,
    public otpText: string,
    public clientIpAddress: string
  ) {
    this.userId = userId;
    // this.newMobileOrEmail = newMobileOrEmail;
    this.emailOrMobile = emailOrMobile;
    this.otpText = otpText;
    this.clientIpAddress = clientIpAddress;
  }
}

export interface IChangeMobileNumberOrEmailResponse {
  userId: number;
  mobileorEmail: string;
  otpText: string;
}

export interface IGetUserComplinantDetailsResponse {
  userId: number;
  userName: string;
  emailId: string;
  mobileNumber: number;
  addressTypeId: number;
  addressTypeNameEn: string;
  districtId: number;
  districtNameEn: string;
  stateId: number;
  stateNameEn: string;
  countryId: string;
  countryNameEn: string;
  blockStreetMohalla: string;
  houseAppartmentBuilding: string;
  houseDoorFlatNumber: string;
  areaNameNumber: string;
  landmarkLocality: string;
  postOffice: number;
  postOfficeName: string;
  postalPinCode: number;
  activeStatus: number;
}
