import Carousel from "react-material-ui-carousel"
import Filecomplain from "./filecomplains/filecomplain"
import Footer from "./Footer/footer"
import Header from "./Header/Header"
import NewsAndSpotlight from "./NewsAndSpotlight/newsandspotlight"
import Onboarding from "./Onboarding/Onboarding"
import Ourvision from "./ourvision/ourvision"
import Searchcases from "./SearchCases/searchcases"
import Statics from "./Statics/statics"
import Trends from "./Trends/trends"
import Videos from "./videos/videos"
import Testimonials from "./testimonials/testimonials"
import NewNewSpotLight from "./NewsAndSpotlight/NewNewSpotLight"
import NewVideos from "./videos/NewVideos"


export const LandingPageHeader = ()=>{
    return <Header/>
}

export const LandingPageBody = ()=>{
    return (
        <>
        <Ourvision />
        <Onboarding />
        <Filecomplain />
        <Searchcases/>
        <NewsAndSpotlight />
        {/* <NewNewSpotLight/> */}
        <Trends />
        <Statics />
        {/* <Videos /> */}
        <Testimonials />
        <Carousel/>
        </>
    )
}

export const LandingPageFooter =() =>{
    return <Footer/>
}