import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import {
    COURT,
    COURT_EDITPROFILE,
    COURT_MASTER_DASHBOARD,
    COURT_COURTROOM_HEARING,
    COURT_DAILY_ORDER_ENTRY,
    COURT_COUSELIST,
    COURT_JUDGEMENT_UPLOAD,
    COURT_RESTORE_CASE,
    COURT_SUBSEQUENT_FILING,
    COURT__SUBSEQUENTDOCUMENTS,
    COURT__UPLOADSUBSEQUENTDOCUMENTS,
    COURT_COURTROOM_HEARING_DETAILS,
    COURT_DAILY_ORDER_ENTRY_UPLOAD,
    COURT_DAILY_ORDER_LIST
} from "../CONSTANTS";

const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const CourtroomHearingList = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomHearingList'));
const DailyOrderEntry = lazy(() => import('../../pages/Commissions/DailyOrderEntry'));
const CourtroomDashboard = lazy(() => import('../../pages/dashboard/components/CourtroomUser/CourtroomDashboard'))
const CauseList = lazy(() => import('../../pages/Commissions/CauseList'));
const JudgmentUpload = lazy(() => import('../../pages/Commissions/JudgmentUpload'));
const SubsequentFilingContainer = lazy(() => import('../../pages/Consumer/SubsequentFiling'));
const CourtroomHearingView = lazy(() => import('../../pages/Commissions/CourtroomHearing/component/CourtroomHearingView'));
const UploadTypeOrder  = lazy(() => import('../../pages/Commissions/DailyOrderEntry/component/UploadTypeOrder'))
const SubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/SubsequentDocuments'));
const UploadSubsequentDocuments = lazy(() => import('../../pages/Consumer/SubsequentFiling/component/UploadSubsequentDocuments'));

const courtMasterChildrenRoute: IRoute[] = [
    {
        path: COURT_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: COURT,
        element: <Dashboard />,
        children: [
            {
                path: COURT_MASTER_DASHBOARD,
                element: <CourtroomDashboard />
            }
        ]
    },
    {
        path: COURT_COURTROOM_HEARING,
        element: <CourtroomHearingList />,
    },
    {
        path: COURT_COURTROOM_HEARING_DETAILS,
        element: <CourtroomHearingView />
    },
    {
        path: COURT_DAILY_ORDER_ENTRY,
        element: <DailyOrderEntry />
    },
    {
        path: COURT_DAILY_ORDER_ENTRY_UPLOAD,
        element: < UploadTypeOrder />
    },
    {
        path: COURT_DAILY_ORDER_LIST,
        element: < DailyOrderEntry />
    },
    {
        path: COURT_COUSELIST,
        element: <CauseList />
    },
    {
        path: COURT_JUDGEMENT_UPLOAD,
        element: <JudgmentUpload />
    },
    {
        path: COURT_RESTORE_CASE,
        element: <></>
    },
    {
        path: COURT_SUBSEQUENT_FILING,
        element: <SubsequentFilingContainer />
    },
    {
        path: COURT__SUBSEQUENTDOCUMENTS,
        element: <SubsequentDocuments />
    },
    {
        path: COURT__UPLOADSUBSEQUENTDOCUMENTS,
        element: <UploadSubsequentDocuments />
    }
];

// list of commission Routes
export const courtMasterRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute
            requiredRoles={[ROLE.CourtMaster]}
        />,
        children: courtMasterChildrenRoute
    }
];