import { DateTime } from "luxon";
import { IDropdown } from "../../common/IDropdown";

export class CaseDetailFormViewModel {
  constructor(
    public claimConsideration: string,
    public paidConsideration: string,
    public dateOfCause: DateTime,
    public stateOfCauseOfAction: IDropdown,
    public districtOfCauseOfAction: IDropdown,
    public caseCategory: IDropdown,
    public subCategory: IDropdown,
    public subSubCategory: IDropdown,
    public caseDetails: string,
    public fillingReferenceNumber?: string,
    public caseTypeId?: number
  ) {
    this.claimConsideration = claimConsideration;
    this.paidConsideration = paidConsideration;
    this.dateOfCause = dateOfCause;
    this.stateOfCauseOfAction = stateOfCauseOfAction;
    this.districtOfCauseOfAction = districtOfCauseOfAction;
    this.caseCategory = caseCategory;
    this.subCategory = subCategory;
    this.subSubCategory = subSubCategory;
    this.caseDetails = caseDetails;
    this.fillingReferenceNumber = fillingReferenceNumber;
    this.caseTypeId = caseTypeId;
  }

  static init = () =>
    new CaseDetailFormViewModel(
      "",
      "",
      DateTime.now(),
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      { label: "", value: "" },
      ""
    );
}

export class CaseDetailModel {
  constructor(
    public caseTypeId: number,
    public paidAmount: string,
    public claimAmount: string,
    public dateOfCause: string,
    public caseStageId: number,
    public stateIdOfPlaceOfCause: number,
    public districtIdOfPlaceOfCause: number,
    public caseCategoryId: number,
    public remarks: string,
    public caseFiledBy: number,
    public filingReferenceNumber?: number,
    public clientIpAddress?: string
  ) {
    this.caseTypeId = caseTypeId;
    this.paidAmount = paidAmount;
    this.claimAmount = claimAmount;
    this.dateOfCause = dateOfCause;
    this.caseStageId = caseStageId;
    this.stateIdOfPlaceOfCause = stateIdOfPlaceOfCause;
    this.districtIdOfPlaceOfCause = districtIdOfPlaceOfCause;
    this.caseCategoryId = caseCategoryId;
    this.remarks = remarks;
    this.caseFiledBy = caseFiledBy;
    this.filingReferenceNumber = filingReferenceNumber;
    this.clientIpAddress = clientIpAddress;
  }
}
