import Timeline, {TimelineProps} from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export type ConfonetTimelineItemProps =  {
  color?:
  | "grey"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
  label?: string;
  cnumber?: string;
  title1?: string;
  title2?: string;
  description?: string;
  date?: string;
  link?: string;
  children?: React.ReactNode;
};

export interface ConfonetTimelineProps extends TimelineProps {
  items: Array<ConfonetTimelineItemProps>
}

/** Renders an Timeline Component
 * 
 * @param ConfonetTimelineProps
 */

const ConfonetTimeline: React.FC<ConfonetTimelineProps> = ({ items, position = "right" }) => {
  return (
    <Timeline sx={{
      [`& .${timelineItemClasses.root}:before`]: {
        flex: 0,
        padding: 0,
      },
      padding: '0',
    }}
    position={position}
    >
      <>
        {
          items.map(({ children, color }: ConfonetTimelineItemProps, index: number) => {
            return (
              <TimelineItem key={`timeline_item_${index}`} sx={{ alignItems: 'center', paddingBottom: '25px' }}>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: 'var(--white)', borderWidth: 'var(--size-4)', margin: '0px', zIndex: '1', }} variant='outlined' color={color} />
                  {index < items.length - 1 && (
                    <TimelineConnector sx={{
                      height: '100%',
                      position: 'absolute',
                      backgroundColor: 'var(--timeline-connector-color)',
                      padding: '0px'
                    }} />
                  )}
                </TimelineSeparator>
                <TimelineContent sx={{ padding: '0px' }}>
                  {children}
                </TimelineContent>
              </TimelineItem>
            );
          })
        }
      </>
    </Timeline>
  );
};

export { ConfonetTimeline };
