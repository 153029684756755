import { useState } from "react";
import { UserModel } from "../models/user/UserModel";
import { AuthenticationService } from "../services/AuthenticationService/authenticationService";
import { Login, LoginWithOtp } from "../models/user/LoginModel";
import { RootState, useAppDispatch } from "../redux";
import { clearFileCase } from "../redux/FileNewCaseReducer/FileNewCaseReducer";
import { clear } from "../redux/CaseReducer/CaseReducer";
import { clearUserData, saveUser } from "../redux/AuthReducer/AuthReducer";
import { useSelector } from "react-redux";
import { clearCourtRoomCaseStage } from "../redux/CommissionCaseReducer/CommissionCaseReducer";

const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const userDetails: UserModel = JSON.parse(
    sessionStorage.getItem("user") ?? "{}"
  );

	const {userDetailsData} = useSelector((state:RootState) => state.user);

  const [user, setUser] = useState<UserModel>(userDetails);

  const isAuthenticated = () => {
    return userDetailsData?.userId !== 0;
  };

  const signin = async (login: Login) => {
    const user = await AuthenticationService.login({ ...login });
    setUser(user.user);
    dispatch(saveUser(user.userDetails));
    sessionStorage.setItem("user", JSON.stringify(user.user));
    return user.user;
  };

  const signinWithOtp = async (login: LoginWithOtp) => {
    const user = await AuthenticationService.loginWithOtp({ ...login });
    setUser(user.user);
    dispatch(saveUser(user.userDetails));
    sessionStorage.setItem("user", JSON.stringify(user.user));
    return user.user;
  };

  const signOut = async () => {
    const status = await AuthenticationService.logoutUser(userDetailsData.userId);
    if (status === 200) {
      await sessionStorage.clear();
      await localStorage.clear();
      dispatch(clearFileCase());
      dispatch(clear());
      dispatch(clearUserData());
      dispatch(clearCourtRoomCaseStage());
      const user: UserModel = JSON.parse(sessionStorage.getItem("user") ?? "{}");
      setUser(user);
    }
    return user.userId === undefined;
  };

  return { isAuthenticated, signin, signinWithOtp, user, signOut };
};

export default useAuthentication;
