import React from "react";

const verticalBox: React.CSSProperties = {
  width: "5%",
  height: "100%",
  alignSelf: "flex-start",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  background: "#EB7202",
};

const HearingBox = () => {
  return (
    <div
      style={{
        ...verticalBox,
       
      }}
    >
      <div className="vertical-text">
        Hearing
      </div>
    </div>
  );
};

export default HearingBox;
