/**
 * View model for the complainant-respondent form
 */
export class AppealTypeComplainantRespondentViewModel {
  constructor(
    public selectedComplainant: number,
    public selectedRespondent: number,
    public selectedAdvocate: number,
    public switchComplainantRespondent: boolean
  ) {
    this.selectedComplainant = selectedComplainant;
    this.selectedRespondent = selectedRespondent;
    this.selectedAdvocate = selectedAdvocate;
    this.switchComplainantRespondent = switchComplainantRespondent;
  }

  static init = () =>
    new AppealTypeComplainantRespondentViewModel(-1, -1, -1, false);
}

export interface IGetCommissionsPayload {
  stateId?: number;
  districtId?: number;
  commissionTypeId: number;
  caseTypeId: number;
}
export interface IGetCommissionResponse {
  commissionId: number;
  commissionNameEn: string;
  commissionTypeId: number;
  locationType: string;
}

export interface IGetCommissionPayload {
  commissionId: number | undefined;
}
export interface IGetAppealTypeComplainantRespondentDraftPayload {
  prevFilingReferenceNo: number;
  currentFilingReferenceNo: number;
  clientIpAddress: string;
}
