import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";

type BasicButtonGroupProps = {
  text: Array<string>;
} & ButtonGroupProps;

/** Renders buttons group */
export function BasicButtonGroup({ text, ...rest }: BasicButtonGroupProps) {
  const [selectedBtn, setSelectedBtn] = React.useState(-1);
  return (
    <ButtonGroup disableRipple aria-label="outlined primary button group">
      {text.map((text: string, index: number) => (
        <Button className={selectedBtn === index ? "btn-active" : ""} onClick={()=>setSelectedBtn(index)} key={`{button_${index}}`}>{text}</Button>
      ))}
    </ButtonGroup>
  );
}
