import { calculatingTotalData, updateRowSpans, pendingMessage, transformData, convertNumber } from "../../ReportFunction/ReportBodyFunction";
import { reportColumnName } from "./ReportColumnName";
import { reportFooter } from "./ReportFooter";
import { reportInfoTable } from "./ReportInfoTable";


//@ type: commission_wise
//@ Report no: 1
export const commissionWiseReport = async(data:any) =>{
    data.push(reportFooter(data, "commission_wise"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[0]);
    const tableData1 = reportInfoTable[0]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 135, 135, 135, 135]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header 
    const MainReportHeader= "Commission Wise Disposal Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: categoryWiseReport
//@ Report no: 2
export const regularCategoryWiseReport = async(data:any) =>{ 
    data.push(reportFooter(data, "categoryWiseReport"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[1]);
    const tableData1 = reportInfoTable[1]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 135, 135, 135, 135]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader= "Category Wise Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: StateWisePendencyReport
//@ Report no: 3
export const stateWisePendencyReport = async(data:any) =>{
    data.push(reportFooter(data, "StateWisePendencyReport"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[2]);
    const tableData1 = reportInfoTable[2]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 135, 135, 135, 135]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="State-wise Including District Counts Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: StatePendencyForOneYearReport
//@ Report no: 4
export const statePendencyOneYearReport = async(data:any) =>{
    data.push(reportFooter(data, "StatePendencyForOneYearReport"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(...reportColumnName[3]) // table header for each page.
    const tableData1 = reportInfoTable[3]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,153,153, 90, 90, 90, 90]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="NCDRC, State And District Commissions Pendency Within 1 Year Status"
    const eachPageHeader = 3 // how many column take as header each page
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin,eachPageHeader}
}

//@ type: StateDistrictReport
//@ Report no: 5
export const stateDistrictReport = async(data:any) =>{
    data.push(reportFooter(data, "StateDistrictReport"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(reportColumnName[4]);
    const tableData1 = reportInfoTable[4]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,153,153, 90, 90, 90, 90]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="NCDRC, State And District Wise Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: RegionWiseReport
//@ Report no: 6
export const resionWiseReport = async(data:any) =>{
    data.push(reportFooter(data, "RegionWiseReport"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[5]);
    const tableData1 = reportInfoTable[5]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 135, 135, 135, 135]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="Region-wise Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: DisposalMoreThanOneYearReport
//@ Report no: 7
export const disposalMoreThenOneYearReport = async(data:any) =>{
    data.push(reportFooter(data, "DisposalMoreThanOneYearReport"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(...reportColumnName[6]) // table header for each page.
    const tableData1 = reportInfoTable[6]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,78, 78, 69, 69, 69, 69, 69, 68, 68]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="NCDRC, State And District Commissions Disposal More Than 1 Year Status"//11111
    const pendingMessage = ""
    const eachPageHeader = 3 // how many column take as header each page
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin, eachPageHeader}
}

//@ type: disposalWithinOneYearReport
//@ Report no: 8
export const disposalWithinOneYearReport = async(data:any) =>{
    data.push(reportFooter(data, "disposalWithinOneYearReport"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(...reportColumnName[7]) // table header for each page.
    const tableData1 = reportInfoTable[7]
    const tableData1_width=[100, 640]
    const tableData2_width=[57,90, 90, 90, 90, 90, 90, 90]
    const Page_Margin = [40, 140, 40, 60] // each page margin top after header
    const MainReportHeader="NCDRC, State And District Commissions Disposal Within 1 Year Status"
    const pendingMessage = ""
    const eachPageHeader = 3 // how many column take as header each page
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin, eachPageHeader}
}

//@ type: StateCommissionDurationWisePendency
//@ Report no: 9
export const stateCommissionDurationWisePendency = async(data:any) =>{
    data.push(reportFooter(data, "StateCommissionDurationWisePendency"));
    const tableData_number = convertNumber(data)
    let tableData2:any = tableData_number?.map((obj:any, index:any) => [{ text: index === tableData_number.length - 1 ? "" : (index + 1).toString(), alignment: 'center' },obj?.state_commission,{ text: obj?.casesfiled, alignment: 'right' },{ text: obj?.pend1to2yrs, alignment: 'right' },{ text: obj?.pend2to5yrs, alignment: 'right' },{ text: obj?.pend5to10yrs, alignment: 'right' },{ text: obj?.pend10to20yrs, alignment: 'right' },{ text: obj?.pend20to30yrs, alignment: 'right' },{ text: obj?.pendmorethan30yrs, alignment: 'right' }])
    tableData2.unshift(...reportColumnName[8]) // table header for each page.
    const tableData1 = reportInfoTable[8]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,82, 82, 80, 80, 80, 80, 80, 80]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="NCDRC And State Commission Duration Wise Pendency"
    const eachPageHeader = 3 // how many column take as header each page
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin, eachPageHeader}
}

//@ type: CaseType_Wise_Report
//@ Report no: 10
export const caseTypeWiseReport = async(data:any) =>{
    data.push(reportFooter(data, "CaseType_Wise_Report"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[9]);
    const tableData1 = reportInfoTable[9]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 135, 135, 135, 135]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="Case Type Wise Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}

//@ type: Registered_Users_Report
//@ Report no: 11
export const registeredUserReports = async(data:any) =>{
    data.push(reportFooter(data, "Registered_Users_Report"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(reportColumnName[10]);
    const tableData1 = reportInfoTable[10]
    const tableData1_width=[100, 640]
    const tableData2_width=[37,228, 228, 228]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="Registered Users"
    const pendingMessage=""
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}

//@ type: e-Jagriti_Case_Detail_Report
//@ Report no: 12
export const eJagritiCaseDetailsReports = async(data:any) =>{
    data.push(reportFooter(data, "e-Jagriti_Case_Detail_Report"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[11]);
    const tableData1 = reportInfoTable[11]
    const tableData1_width=[100, 640]
    const tableData2_width=[30,153,153, 90, 90, 90, 90]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="E-Jagriti Case Details"
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}
//@ type: StageWiseReport
//@ Report no: 13
export const stageWiseReports = async(data:any) =>{
    data.push(reportFooter(data, "StageWiseReport"));
    const tableData2 = transformData(convertNumber(data))
    tableData2.unshift([
        { text: "S.No", alignment: "center" },
        { text: "Stage", alignment: "center" },
        { text: "Case Count", alignment: "center" },
      ],);
    const tableData1 = reportInfoTable[12]
    const tableData1_width=[100, 640]
    const tableData2_width=[37,347, 348]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="Stage Wise"
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}

//@ type: StateStageWiseReport
//@ Report no: 14
export const stateStageWiseReport = async(data:any) =>{
    data.push(reportFooter(data, "StateStageWiseReport"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(reportColumnName[13]);
    const tableData1 = reportInfoTable[13]
    const tableData1_width=[100, 640]
    const tableData2_width = [37,228, 228, 228]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="State Stage Wise"
    const pendingMessage=""
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}

//@ type: State_District_Stage_wise_report
//@ Report no: 15
export const stateDistrictWiseStageReport = async(data:any) =>{
    data.push(reportFooter(data, "State_District_Stage_wise_report"));
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift(reportColumnName[14]);
    const tableData1 = reportInfoTable[14]
    const tableData1_width=[100, 640]
    const tableData2_width=[37,160, 160, 160, 160]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader="State District Stage Wise"
    const pendingMessage=""
    return{tableData1,tableData2,tableData1_width,tableData2_width,pendingMessage,MainReportHeader,Page_Margin}
}

//@ type: YearlyWiseCategoryReport
//@ Report no: 16
export const yearlyWiseCategoryReport = async(data:any) =>{
    const updatedData = data.map((curEle: any) =>{
        const {category_name, applicable_year, ...res} = curEle 
        return {applicable_year: String(curEle.applicable_year), ...res}
    })
    updatedData.push(reportFooter(data=updatedData, "YearlyWiseCategoryReport"));
    const tableData2:any = transformData(convertNumber(updatedData))
    tableData2.unshift(reportColumnName[15]);
    const tableData1 = reportInfoTable[15]
    const tableData1_width=[100, 640]
    const tableData2_width = [37, 140, 140, 140, 120, 125, 125]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header 
    const MainReportHeader= "Yearly Wise Category Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}

//@ type: RegionWiseEjagritiReport
//@ Report no: 17
export const regionWiseEjagritiReport = async(data:any) =>{
    data.push(reportFooter(data, "RegionWiseEjagritiReport"));
    const tableData2:any = transformData(convertNumber(data))
    tableData2.unshift(reportColumnName[16]);
    const tableData1 = reportInfoTable[16]
    const tableData1_width=[100, 640]
    const tableData2_width = [37,100, 80, 80, 90, 100, 100, 100]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header 
    const MainReportHeader= "Region Wise E-jagriti Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}


export const commissionWiseCategoryReport = async(data:any) =>{ 
    data.push({"commissionType":"All India","category_name": "","totalcasesfiled": calculatingTotalData(data,'totalcasesfiled'),"totalcasesdisposed": calculatingTotalData(data,'totalcasesdisposed'),"totalcasespending": calculatingTotalData(data,'totalcasespending'),"disposalrate": isNaN(calculatingTotalData(data,'totalcasesdisposed')/calculatingTotalData(data,'totalcasesfiled')*100) ? 0 : calculatingTotalData(data,'totalcasesdisposed')/calculatingTotalData(data,'totalcasesfiled')*100});
    const tableData2:any = updateRowSpans(transformData(convertNumber(data)))
    tableData2.unshift([{ text: 'S.No',alignment: 'center'},{ text: 'Commission',alignment: 'center'},{ text: 'Category',alignment: 'center'},{text:[{ text: 'Filed\n',alignment: 'center'},{ text: '(a)',alignment: 'center', color:'blue'}]},{text:[{ text: 'Disposed\n',alignment: 'center'},{ text: '(b)',alignment: 'center', color:'blue'}]},{text:[{ text: 'Pending',alignment: 'center'},{ text: '*',alignment: 'center', color:'red'}]},{text:[{ text: 'Disposal Rate\n',alignment: 'center'},{ text: 'f=(b/a)*100',alignment: 'center',color:'blue'}]},]);
    const tableData1 = [[{ text: 'Duration', bold: true,fontSize: 12, }, 'Since 1st April 1985'],[{ text: 'Case Types', bold: true,fontSize: 12, }, 'Consumer Case(CC), Revision Petition(RP), First Appeal(FA), Second Appeal(SA), CCPAA, Execution Revision Petition(ERP), Appeal Execution Appeal(AEA)'],];
    const tableData1_width=[100, 640]
    const tableData2_width=[30,135, 100, 100, 100, 100, 100]
    const Page_Margin = [40, 130, 40, 60] // each page margin top after header
    const MainReportHeader= "Category Wise Status"
    return{tableData1,tableData2,tableData1_width,tableData2_width,MainReportHeader,pendingMessage,Page_Margin}
}