import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { UserModel } from "../../models/user/UserModel";
import { UserService } from "../../services/CommonService/userService";

type AuthContextType = {
  user: UserModel | null;
  setUser: React.Dispatch<React.SetStateAction<UserModel | null>>;
  login: (username: string, password: string) => Promise<UserModel> | void;
  logout: () => void;
  isAuthorized: (requiredRoles: number[]) => boolean;
  isLogin: () => boolean;
  sidebar: boolean;
  setSidebarVal: (value: boolean) => void;
};

// Create a new context
export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  isAuthorized: () => false,
  isLogin: () => false,
  sidebar: true,
  setSidebarVal: () => {},
});

// Custom hook for accessing the AuthContext
export const useAuth = () => useContext(AuthContext);

// Define the type for AuthProvider props
type AuthProviderProps = {
  children: ReactNode;
};

// Create a context provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [sidebar, setSidebar] = useState<boolean>(true);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (userData) {
      setUser(userData);
    }
  }, []);

  const login = async (username: string, password: string) => {
    // Perform login logic, e.g., make API calls, validate credentials
    // Set the user state if login is successful
    // TODO: this will be replaced with actual logic
    const user = UserService.getUserDetail(2);
    await setUser(user);
    localStorage.setItem("userData", JSON.stringify(user));
    return user;
  };

  const logout = () => {
    // Perform logout logic, e.g., clear user data, invalidate tokens
    // Reset the user state
    localStorage.clear();
    setUser(null);
  };

  const isAuthorized = (requiredRoles: number[]) => {
    if (!user) {
      return false;
    }

    // Check if the user has the required roles for authorization
    if (requiredRoles.some((role) => user.roles.includes(role))) {
      return true;
    }

    return false;
  };

  const isLogin = () => {
    if (user && user.roles) {
      return true;
    }
    return false;
  };

  const setSidebarVal = (value: boolean) => {
    setSidebar(value);
  };

  // Provide the user state and authentication functions to the context
  const authContextValue: AuthContextType = {
    user,
    setUser,
    login,
    logout,
    isAuthorized,
    isLogin,
    sidebar,
    setSidebarVal,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
