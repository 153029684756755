import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
	DataGrid,
	DataGridProps,
	GridCell,
	GridCellProps,
	GridColDef,
	GridToolbarExport,
	GridToolbarQuickFilter
} from "@mui/x-data-grid";
import { useTranslation } from 'react-i18next';
import NoDataFound from "./NoDataFound";

type DataTableProps = DataGridProps & {
	columns: GridColDef[];
	rows: any;
	page?: number;
	pageSize?: number;
	pageSizeOptions?: Array<number>;
	checkboxSelection?: boolean;
	sortFieldName?: string;
	searchBar?: boolean;
	allowExport?: boolean;

};

const ConfonetToolbar = ({ rows, searchBar = true, allowExport = false, ...rest }: DataTableProps) => {
	return (
		<>
			{
				searchBar && (rows?.length > 0) &&
				<GridToolbarQuickFilter className="margin-bottom-10" />
			}
			{
				allowExport &&
				<div className="row margin-bottom-15">
					<div className="col-lg-12 text-end">
						{allowExport && <GridToolbarExport />}
					</div>
				</div>
			}
		</>
	)
}


export function DataTable({
	// rowHeight = 70,
	columns,
	rows,
	page = 0,
	pageSize = 5,
	pageSizeOptions = [5, 10],
	checkboxSelection = false,
	sortFieldName,
	initialState,
	searchBar = true,
	allowExport = false,
	...rest
}: DataTableProps) {
	const { t } = useTranslation();
	const CustomToolbar = (): JSX.Element => {
		return ConfonetToolbar({ rows, columns, searchBar, allowExport })
	};

	return (
		<>
			<DataGrid
				sx={{
					'.MuiDataGrid-columnSeparator': {
						display: 'none',
					},
					'& .MuiDataGrid-row:hover': {
						backgroundColor: "transparent",
					},
					'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
						backgroundColor: 'var(--primary-lighter)'
					},
					'& .MuiDataGrid-withBorderColor, &.MuiDataGrid-withBorderColor': {
						border: 'transparent',
					},
					'& .MuiDataGrid-columnHeaders': {
						backgroundColor: 'var(--primary-lighter)',
						borderRadius: 'var(--size-0)',
						borderTop: 'var(--size-1)',
						borderTopStyle: 'solid',
						borderColor: 'var(--gray-divider)',
						borderTopLeftRadius: 'var(--size-4)',
						borderTopRightRadius: 'var(--size-4)'
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						color: 'var(--gray-dark)',
						fontSize: 'var(--size-12)',
						fontWeight: 'var(--weight-700)',
						lineHeight: 'var(--size-18)',
					},
					'& .MuiDataGrid-cellContent, & .MuiTablePagination-root p': {
						color: 'var(--heading)',
						fontSize: 'var(--size-14)',
						fontWeight: 'var(--weight-400)',
						lineHeight: 'var(--size-21)',
					},
					'& .MuiTablePagination-root p': {
						margin: 'var(--size-0)',
					},
					'& .pending-status': {
						color: 'var(--secondary-coffee)',
						fontSize: 'var(--size-12)',
						fontWeight: 'var(--weight-700)',
						lineHeight: 'var(--size-18)',
						borderRadius: 'var(--size-4)',
						backgroundColor: 'rgba(212, 124, 66, 0.16)',
						padding: 'var(--size-4) var(--size-8)'
					},
					'& .MuiTextFieldQuickFilter': {
						padding: 'var(--size-0)',
						marginBottom: 'var(--size-20)'
					},
					'& .MuiDataGrid-cell': {
						borderTop: 'var(--size-1)',
						borderTopStyle: 'solid',
						borderColor: 'var(--gray-divider)'
					},
					'& .MuiTextFieldQuickFilter .MuiInputBase-formControl': {
						padding: 'var(--size-5) var(--size-10)',
						border: 'var(--size-2)',
						borderStyle: 'solid',
						borderColor: 'var(--gray-divider)',
						borderRadius: 'var(--size-4)'
					},
					'& .MuiTextFieldQuickFilter .MuiInputBase-formControl:before, & .MuiTextFieldQuickFilter .MuiInputBase-formControl.Mui-focused:after': {
						display: 'none'
					},
					'& .MuiDataGrid-cell.status-active .MuiDataGrid-cellContent': {
						color: 'var(--success)',
						fontWeight: 'var(--weight-700)',
					},
					'& .MuiDataGrid-cell.status-inactive .MuiDataGrid-cellContent': {
						color: 'var(--danger)',
						fontWeight: 'var(--weight-700)',
					},
					// '& .MuiDataGrid-main': {
					// 	position: 'relative'
					// },
					// '& .MuiDataGrid-main:after': {
					// 	content: "''",
					// 	border: 'var(--size-1)',
					// 	borderStyle: 'solid',
					// 	borderColor: 'var(--gray-divider)',
					// 	height: '100%',
					// 	position: 'absolute',
					// 	width: '100%',
					// 	borderRadius: 'var(--size-4)'
					// },
					'& .MuiDataGrid-virtualScroller': {
						zIndex: 100
					},
					'& .MuiDataGrid-cellContent': {
						whiteSpace: "normal",
						wordBreak: "break-word"
					}
				}}
				rowSelection={false}
				autoHeight={true}
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { page, pageSize },
					},
					sorting: {
						sortModel: sortFieldName ? [{ field: sortFieldName, sort: "desc" }] : []
					}
				}}
				// rowHeight={rowHeight}
				getRowHeight={() => "auto"}
				columnHeaderHeight={60}
				pageSizeOptions={pageSizeOptions}
				checkboxSelection={checkboxSelection}
				localeText={{
					columnHeaderSortIconLabel: t("Label.ColumnMenuLabel"),
					columnMenuLabel: t("Label.ColumnMenuLabel"),
					columnMenuShowColumns: t("Label.ColumnMenuShowColumns"),
					columnMenuManageColumns: t("Label.ColumnMenuManageColumns"),
					columnMenuFilter: t("Label.ColumnMenuFilter"),
					columnMenuHideColumn: t("Label.ColumnMenuHideColumn"),
					columnMenuUnsort: t("Label.ColumnMenuUnsort"),
					columnMenuSortAsc: t("Label.ColumnMenuSortAsc"),
					columnMenuSortDesc: t("Label.ColumnMenuSortDesc"),
					columnsPanelTextFieldLabel: t("Label.ColumnsPanelTextFieldLabel"),
					columnsPanelTextFieldPlaceholder: t("Label.ColumnsPanelTextFieldPlaceholder"),
					columnsPanelDragIconLabel: t("Label.ColumnsPanelDragIconLabel"),
					columnsPanelShowAllButton: t("Label.ColumnsPanelShowAllButton"),
					columnsPanelHideAllButton: t("Label.ColumnsPanelHideAllButton"),
					filterOperatorContains: t("Label.FilterOperatorContains"),
					filterOperatorEquals: t("Label.FilterOperatorEquals"),
					filterOperatorStartsWith: t("Label.FilterOperatorStartsWith"),
					filterOperatorEndsWith: t("Label.FilterOperatorEndsWith"),
					filterOperatorIs: t("Label.FilterOperatorIs"),
					filterOperatorNot: t("Label.FilterOperatorNot"),
					filterOperatorAfter: t("Label.FilterOperatorAfter"),
					filterOperatorOnOrAfter: t("Label.FilterOperatorOnOrAfter"),
					filterOperatorBefore: t("Label.FilterOperatorBefore"),
					filterOperatorOnOrBefore: t("Label.FilterOperatorOnOrBefore"),
					filterOperatorIsEmpty: t("Label.FilterOperatorIsEmpty"),
					filterOperatorIsNotEmpty: t("Label.FilterOperatorIsNotEmpty"),
					filterOperatorIsAnyOf: t("Label.FilterOperatorIsAnyOf"),

					filterPanelAddFilter: t("Label.FilterPanelAddFilter"),
					filterPanelRemoveAll: t("Label.FilterPanelRemoveAll"),
					filterPanelDeleteIconLabel: t("Label.FilterPanelDeleteIconLabel"),
					filterPanelLogicOperator: t("Label.FilterPanelLogicOperator"),
					filterPanelOperator: t("Label.FilterPanelOperator"),
					filterPanelOperatorAnd: t("Label.FilterPanelOperatorAnd"),
					filterPanelOperatorOr: t("Label.FilterPanelOperatorOr"),
					filterPanelColumns: t("Label.FilterPanelColumns"),
					filterPanelInputLabel: t("Label.FilterPanelInputLabel"),
					filterPanelInputPlaceholder: t("Label.FilterPanelInputPlaceholder"),
					MuiTablePagination: {
						labelDisplayedRows: ({ from, to, count }) =>
							`${from} - ${to} ${t('Label.Of')} ${count}`,
						labelRowsPerPage: t("Label.RowsPerPage"),
						getItemAriaLabel: (type) => {
							if (type === 'first') {
								return `${t("Label.GoToFirstPage")}`;
							}
							if (type === 'last') {
								return `${t("Label.GoToLastPage")}`;
							}
							if (type === 'next') {
								return `${t("Label.GoToNextPage")}`;
							}
							if (type === 'previous') {
								return `${t("Label.GoToPreviousPage")}`;
							}
							return "";
						},
					},
				}}
				slots={{
					noRowsOverlay: NoDataFound,
					noResultsOverlay: NoDataFound,
					toolbar: CustomToolbar,
					columnSortedDescendingIcon: ArrowDownward,
					columnSortedAscendingIcon: ArrowUpward,
					cell: CustomGridCell
				}}
				slotProps={{
					toolbar: {
						className: "MuiTextFieldQuickFilter",
					}
				}}

				{...rest}
			/>
		</>
	);
}


const CustomGridCell = ({ children, value, ...rest }: GridCellProps) => {
	return (
		<GridCell {...rest}>
			{
				value !== null ? children : <div className="fst-normal">N/A</div>
			}
		</GridCell>
	)
}