import { AxiosResponse } from "axios";
import { IAddAddvocateRequest } from "../../models/Advocate/IAdvocateDetail";
import {
    IGetAllAdmissionStatusResponse,
    IGetAllCaseStageeResponse,
    IGetAllCauselistCategoryResponse,
    IGetAllCourtResponse,
    IGetAllDisposalTypeResponse,
    IGetAllHearingModeResponse,
    IGetAllNoticeNumberResponse,
    IGetCourtDetailsResponse
} from "../../models/cases/commissionCase/CourtroomHearingFormViewModel";
import { IGetJudgesByCommissionIdResponse } from "../../models/cases/commissionCase/ICommissionCase";
import { IDefectTypeResponse } from "../../models/cases/commissionCase/ScrutinyModels";
import {
    IAppealAuthorityListResponse,
    IFile,
    IGetAdvocateResponse,
    IGetCaseCategory,
    IGetCaseTypeResponse,
    IGetFeeStructureResponse
} from "../../models/cases/fileNewCase/IFileNewCaseModel";
import { IAReasonsListResponse } from "../../models/cases/interlocutoryApplicationCase/InterlocutoryApplicationCaseDetailsModel";
import { ICommissionListResponse } from "../../models/cases/transferApplicationCase/TransferpplicationCaseDetailsModel";
import { IGetAddressTypeResponse, IGetAllCountryResponse, IGetAllStateResponse, IGetCommissionByIdResponse, IGetDistrictByIdResponse, IPinCodeResponse } from "../../models/common/IAddress";
import { IIdentityProofResponse } from "../../models/common/IIdentityProof";
import { IGetAllCaseCategoryResponse, IGetAllJusticeStatusResponse, IGetAllSeniorityResponse, IGetAllSurnameResponse, IGetAllUserStatusResponse, IGetHandicappedListResponse } from "../../models/common/IMaster";
import { IRolesResponse } from "../../models/user/UserModel";
import { MASTER_SERVICE } from "../CONSTANTS";
import { deleteCall, getCall, postCall, putCall } from "../api";


export class MasterService {

    /** Calls an API to get all available address types
 *
 * @returns Promise<IAddressType[]>
 */
    public static readonly getAllAddressTypes = async (): Promise<
        IGetAddressTypeResponse[]
    > => {
        const response: AxiosResponse<IGetAddressTypeResponse[]> = await getCall(
            `${MASTER_SERVICE}/addressType`
        );
        return response?.data;
    };

    /** Calls an API to get all states of India
 *
 * @returns Promise<IGetAllStateResponse>
 */
    public static readonly getAllStates = async (): Promise<IGetAllStateResponse[]> => {
        const response: AxiosResponse<IGetAllStateResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllState`
        );
        return response?.data;
    };

    /** Calls an API to get all states of India
     *
     * @returns Promise<IGetAllStateResponse>
     */
    public static readonly getAllCountry = async (): Promise<IGetAllCountryResponse[]> => {
        const response: AxiosResponse<IGetAllCountryResponse[]> = await getCall(
            `${MASTER_SERVICE}/getCountry`
        );
        return response?.data;
    };

    /** Calls an API to get districts of a single state
     *
     * @param stateId number
     * @returns Promise<IGetDistrictById[]>
     */
    public static readonly getDistrictsByStateId = async (stateId: number): Promise<IGetDistrictByIdResponse[]> => {
        const response: AxiosResponse<IGetDistrictByIdResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllDistrict`,
            { stateId }
        );
        return response?.data;
    };

    /**
 * Call an API to get the list of address details according to pincode
 */
    public static readonly getPincodeDetails = async (pinCode: number): Promise<IPinCodeResponse[]> => {
        const response: AxiosResponse<IPinCodeResponse[]> = await postCall(
            `${MASTER_SERVICE}/findByPinCode?pinCode=${pinCode}`,
            {}
        );
        return response?.data;
    }

    /**
     * Call an APT to perform get All Identity proof list
     */
    public static readonly getAllIdentityProof = async (): Promise<
        IIdentityProofResponse[]
    > => {
        const response: AxiosResponse<IIdentityProofResponse[]> = await getCall(
            `${MASTER_SERVICE}/identificationProof`
        );
        return response?.data;
    };

    /**
 * Call an API to perform Get Roles list
 */
    public static readonly getAllRoles: () => Promise<IRolesResponse[]> = async () => {
        const response: AxiosResponse<IRolesResponse[]> = await getCall(
            `${MASTER_SERVICE}/roles`
        );
        return response?.data;
    };

    /**
 * Call an API to get case types
 */
    public static readonly getCaseTypes: () => Promise<IGetCaseTypeResponse[]> = async () => {
        const response: AxiosResponse<IGetCaseTypeResponse[]> = await getCall(
            `${MASTER_SERVICE}/caseType`
        );
        return response?.data;
    };

    /**
* Call an API to get fee structure
*/
    public static readonly getFeeStructure = async (): Promise<IGetFeeStructureResponse> => {
        const response: AxiosResponse<IGetFeeStructureResponse> =
            await postCall(
                `${MASTER_SERVICE}/getFeeById?commissionTypeId=0`,
                {}
            );
        return response?.data;
    };

    /**
* Call an API to get Case Category types
*/
    public static readonly getCaseCategory = async (
        data: IGetCaseCategory
    ): Promise<any> => {
        const response: AxiosResponse<any> = await postCall(
            `${MASTER_SERVICE}/caseCategory`,
            data
        );

        return response?.data;
    };

    //     /** Call an API to get a list of all Advocates
    //  *
    //  * @returns Promise<IAdvocateResponse[]>
    //  */
    //     public static readonly getAllAdvocates: () => Promise<IGetAdvocateResponse[]> = async (pageNumber: number, pageSize: number): Promise<IGetAdvocateResponse[]> => {
    //         const params = { pageNumber, pageSize };
    //         const response: AxiosResponse<IGetAdvocateResponse[]> = await getCall(
    //             `${MASTER_SERVICE}/advocates`,
    //             params
    //         );
    //         return response?.data;
    //     };

    /** Call an API to get a list of all Advocates
*
* @returns Promise<IAdvocateResponse[]>
*/
    public static readonly getAllAdvocates = async (pageNumber: number, pageSize: number): Promise<any[]> => {
        const params = { pageNumber, pageSize };
        const response: AxiosResponse<any[]> = await getCall(
            `${MASTER_SERVICE}/advocates`,
            params
        );
        return response?.data;
    };

    /** Call an API to get a list of all Advocates
*
* @returns Promise<IAdvocateResponse[]>
*/
    public static readonly getAllAdvocatesbySearch = async (query: any): Promise<IGetAdvocateResponse[]> => {
        const response: AxiosResponse<IGetAdvocateResponse[]> = await getCall(
            `${MASTER_SERVICE}/findAdvocateBySearch?searchContent=${query}`
        );
        return response?.data;
    };
    /** Call an API to get a list of all Advocates
   *
   * @returns Promise<IAdvocateResponse[]>
   */
    public static readonly getAllAdvocatesbySearchPage = async (page: number, searchContent: string): Promise<any[]> => {
        const params = { page, searchContent };
        // const response: AxiosResponse<IGetAdvocateResponse[]> = await getCall(
        const response: AxiosResponse<any[]> = await getCall(
            `${MASTER_SERVICE}/getAdvocateWithPaginate`,
            params

        );
        return response?.data;
    };

    /** Call an API to get a list of all Advocates
  *
  * @returns Promise<IAdvocateResponse[]>
  */
    public static readonly getAllAdvocatesbyBarCouncil = async (activeStatus: boolean): Promise<any[]> => {
        const params = { activeStatus };
        const response: AxiosResponse<any> = await getCall(
            `${MASTER_SERVICE}/getBarcouncil`,
            params

        );
        return response?.data;
    };
    //  Call an API to add advocate

    public static readonly AddAdvocate = async (
        data: any
    ): Promise<any> => {
        const response: AxiosResponse<any> = await postCall(
            `${MASTER_SERVICE}/addAdvocate`,
            data
        );
        return response?.status;
    };

    //  Call an API to get Case Category types 

    public static readonly updateAdvocate = async (
        data: any
    ): Promise<any> => {
        const response: AxiosResponse<any> = await postCall(
            `${MASTER_SERVICE}/advocates`,
            data
        );

        return response?.data;
    };

    /** Call an API to get a list of all Advocates
*
* @returns Promise<IAdvocateResponse[]>
*/
    public static readonly deleteAdvocate = async (advocateId: number) => {
        // const params = { advocateId };
        const response: any = await deleteCall(
            `${MASTER_SERVICE}/deleteAdvocate?advocateId=${advocateId}`,
            {}
            // params
        );
        return response?.message;
    };

    /**
 * Call an API to get all document details
 *
 * @returns Promise<IDocumentDetail[]>
 */
    public static readonly getAllDocument = async (): Promise<IFile[]> => {
        try {
            const response: AxiosResponse<IFile[]> = await getCall(
                `${MASTER_SERVICE}/getAllDocument`
            );

            return response?.data;
        } catch (error) {
            console.error("Error fetching case filling document details:", error);
            throw error;
        }
    };

    /**
* Call an API to get all document details
*
* @returns Promise<IDocumentDetail[]>
*/
    public static readonly getAllDocumentByCaseTypeId = async (caseTypeId: number): Promise<IFile[]> => {
        try {
            const response: AxiosResponse<IFile[]> = await postCall(
                `${MASTER_SERVICE}/getDocumnetListByCaseTypeId`,
                { caseTypeId }
            );

            return response?.data;
        } catch (error) {
            console.error("Error fetching case filling document details:", error);
            throw error;
        }
    };

    /** Calls an API to get all surname
*
* @returns Promise<IGetAllSurnameResponse>
*/
    public static readonly getAllSurname = async (): Promise<IGetAllSurnameResponse[]> => {
        const response: AxiosResponse<IGetAllSurnameResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllState`
        );
        return response?.data;
    };

    /** Calls an API to get all Status
*
* @returns Promise<IGetAllUserStatusResponse>
*/
    public static readonly getAllStatus = async (): Promise<IGetAllUserStatusResponse[]> => {
        const response: AxiosResponse<IGetAllUserStatusResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllUserStatus`
        );
        return response?.data;
    };

    /** Calls an API to get all Justice Status
*
* @returns Promise<IGetAllJusticeStatusResponse>
*/
    public static readonly getAllJusticeStatus = async (): Promise<IGetAllJusticeStatusResponse[]> => {
        const response: AxiosResponse<IGetAllJusticeStatusResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllJusticeStatus`
        );
        return response?.data;
    };

    /** Calls an API to get all Seniority
*
* @returns Promise<IGetAllSeniorityResponse>
*/
    public static readonly getAllSeniority = async (): Promise<IGetAllSeniorityResponse[]> => {
        const response: AxiosResponse<IGetAllSeniorityResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllCourts`
        );
        return response?.data;
    };

    /** Calls an API to get all causelist category
*
* @returns Promise<IGetAllCauselistCategoryResponse>
*/
    public static readonly getAllCauselistCategory = async (): Promise<IGetAllCauselistCategoryResponse[]> => {
        const response: AxiosResponse<IGetAllCauselistCategoryResponse[]> = await getCall(
            `${MASTER_SERVICE}/causelist`
        );
        return response?.data;
    };

    /** Calls an API to get all court number
*
* @returns Promise<IGetAllCourtResponse>
*/
    public static readonly getAllCourtNumber = async (): Promise<IGetAllCourtResponse[]> => {
        const response: AxiosResponse<IGetAllCourtResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllCourts`
        );
        return response?.data;
    };

    /** Calls an API to get court number by commission ID 
*
* @returns Promise<IGetAllCourtResponse>
*/
    public static readonly getCourtNumberCommissionWise = async (commissionId: number | undefined): Promise<IGetAllCourtResponse[]> => {
        const response: AxiosResponse<IGetAllCourtResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllCourtsByComissionId`,
            { commissionId }
        );
        return response?.data;
    };

    /** Calls an API to get  court by commisionId
*
* @returns Promise<IGetAllCourtResponse>
*/
    public static readonly getCourtByCommissionId = async (commissionId: number | undefined): Promise<IGetCourtDetailsResponse> => {
        const response: AxiosResponse<IGetCourtDetailsResponse> = await getCall(
            `${MASTER_SERVICE}/getCourtByCommissionId`,
            { commissionId }
        );
        return response?.data;
    };

    /** Calls an API to get all case stage
 *
 * @returns Promise<IGetAllCaseStageeResponse>
 */
    public static readonly getAllCaseStage = async (): Promise<IGetAllCaseStageeResponse[]> => {
        const response: AxiosResponse<IGetAllCaseStageeResponse[]> = await getCall(
            `${MASTER_SERVICE}/caseStage`
        );
        return response?.data;
    };

    /** Calls an API to get date of service status
*
* @returns Promise<IGetAllAdmissionStatusResponse>
*/
    public static readonly getAllAdmissionStatus = async (): Promise<IGetAllAdmissionStatusResponse[]> => {
        const response: AxiosResponse<IGetAllAdmissionStatusResponse[]> = await getCall(
            `${MASTER_SERVICE}/admissionStatus`
        );
        return response?.data;
    };

    /** Calls an API to get all notice number
*
* @returns Promise<IGetAllNoticeNumberResponse>
*/
    public static readonly getAllNoticeType = async (caseTypeId: number): Promise<IGetAllNoticeNumberResponse[]> => {
        const response: AxiosResponse<IGetAllNoticeNumberResponse[]> = await getCall(
            `${MASTER_SERVICE}/noticeType`,
            { caseTypeId }
        );
        return response?.data;
    };

    /** Call an API to get judges list
* @param commissionId number
*/
    public static readonly getJudgesListByCommissionId = async (commissionId: number | null | undefined) => {
        const response: AxiosResponse<IGetJudgesByCommissionIdResponse[]> = await postCall(
            `${MASTER_SERVICE}/getJudgeByCommissionId?commissionId=${commissionId}`,
            {}
        );
        return response?.data;
    };

    /**
    * Call an API to perform Get Roles list
    */
    public static readonly getAllDefectType: () => Promise<IDefectTypeResponse[]> = async () => {
        const response: AxiosResponse<IDefectTypeResponse[]> = await getCall(
            `${MASTER_SERVICE}/defectType`
        );
        return response?.data;
    };

    /**
    * Call an API to get the List of Reasons for IA
    * @returns Promise<IAReasonsListResponse>
    */
    public static readonly getIAReasonsList = async (): Promise<IAReasonsListResponse[]> => {
        const response: AxiosResponse<IAReasonsListResponse[]> = await getCall(
            `${MASTER_SERVICE}/getInterlocutoryReasonlist`
        );

        return response?.data;
    }

    /**
    * Call an API to get the List of Reasons for IA
    * @returns Promise<IAReasonsListResponse>
    */
    public static readonly getAuthorityList = async (): Promise<IAppealAuthorityListResponse[]> => {
        const response: AxiosResponse<IAppealAuthorityListResponse[]> = await getCall(
            `${MASTER_SERVICE}/otherLegalBody`
        );

        return response?.data;
    }

    /**
    * Call an API to get the List of State or District Commission for TA
    * @returns Promise<ICommissionListResponse>
    */
    public static readonly getCommissionByCommissionId = async (commissionId?: number): Promise<ICommissionListResponse[]> => {
        if (commissionId) {
            const response: AxiosResponse<ICommissionListResponse[]> = await postCall(
                `${MASTER_SERVICE}/getCommissionByCommissionId?commissionId=${commissionId}`,
                {}
            );

            return response?.data

        } else {
            const response: AxiosResponse<ICommissionListResponse[]> = await postCall(
                `${MASTER_SERVICE}/getCommissionByCommissionId`,
                {}
            );

            return response?.data

        }
    }

    /** Calls an API to get commission of a single state
 *
 * @param stateId number
 * @returns Promise<IGetCommissionByIdResponse[]>
 */
    public static readonly getCommissionDetailsByStateId = async (stateId: number): Promise<IGetCommissionByIdResponse[]> => {
        const response: AxiosResponse<IGetCommissionByIdResponse[]> = await getCall(
            `${MASTER_SERVICE}/getCommissionDetailsByStateId`,
            { stateId }
        );
        return response?.data;
    };

    /** Calls an API to get commission of a single state
     * @returns Promise<IGetHandicappedListResponse[]>
     */
    public static readonly getHandicappedList = async (): Promise<IGetHandicappedListResponse[]> => {
        const response: AxiosResponse<IGetHandicappedListResponse[]> = await getCall(
            `${MASTER_SERVICE}/getHandicapType`
        );
        return response?.data;
    };


    /** Calls an API to get commission of a single state
     * @returns Promise<IGetAllCaseCategoryResponse[]>
     */
    public static readonly getAllCaseCategory = async (): Promise<IGetAllCaseCategoryResponse[]> => {
        const response: AxiosResponse<IGetAllCaseCategoryResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllCaseCategory`,
        );
        return response?.data;
    };

    /** Calls an API to get all hearing mode
*
* @returns Promise<IGetAllHearingModeResponse>
*/
    public static readonly getAllHearingMode = async (): Promise<IGetAllHearingModeResponse[]> => {
        const response: AxiosResponse<IGetAllHearingModeResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllHearingMode`
        );
        return response?.data;
    };

    /** Calls an API to get all disposal type
*
* @returns Promise<IGetAllDisposalTypeResponse>
*/
    public static readonly getAllDisposalType = async (): Promise<IGetAllDisposalTypeResponse[]> => {
        const response: AxiosResponse<IGetAllDisposalTypeResponse[]> = await getCall(
            `${MASTER_SERVICE}/getAllDisposalType`
        );
        return response?.data;
    };

}