import { ArrowForward } from "@mui/icons-material";
import { DialogActions, DialogContent } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BasicBreadcrumbs, BasicButton, Dialogbox, PreviewCard } from "../../../../../components";
import { IGetDailyOrderEntryListResponse } from "../../../../../models/cases/commissionCase/ICommissionCase";
import { COMMISSION_CASE_DEFINALIZE, COMMISSION_CASE_FINALIZE } from "../../../../../navigation/CONSTANTS";
import { RootState } from "../../../../../redux";
import { flattenArrayToIPreview, navigateToDashboardPath } from "../../../../../utils/Helper";
import { DailyOrderOrJudgementFinalizeDefinalizePayload, DailyOrderOrJudgementFinalizeDefinalizeResponse } from "../../../../../models/judgementUpload/JudgementUploadFormViewModel";
import { CourtroomService } from "../../../../../services/CaseService/CourtroomService/CourtroomService";


/**
 * Component for daily order finalized or de-finalized page view
 */
const DailyOrderFinalizedOrDefinalized = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetailsData } = useSelector((state: RootState) => state.user);

    const [dailyOrderData, setDailyOrderData] = useState<DailyOrderOrJudgementFinalizeDefinalizeResponse>();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const { path, dailyOrderResponse } = location.state;

    const handleShowDialog = () => {
        setShowDialog(true);
    }

    const handleConfirmFinalizeOrDefinalize = async () => {
        if (dailyOrderData) {
            setShowDialog(false);
            const data: DailyOrderOrJudgementFinalizeDefinalizePayload = {
                caseNumber: dailyOrderData?.caseNumber,
                isFinalize: path,
                orderTypeId: 1,
                dateOfHearing: dailyOrderData?.dateOfHearing,
            }

            const response = await CourtroomService.updateFinalizeAndDefinalizeData(data)
            if (response) {
                toast.success("Daily order updated successfully");
                navigate(path ? COMMISSION_CASE_FINALIZE : COMMISSION_CASE_DEFINALIZE);
            }
        }
    }

    useEffect(() => {
        if (dailyOrderResponse) {
            setDailyOrderData(dailyOrderResponse);
        }
    }, []);

    return (
        <>
            <Dialogbox
                title={path ? t("Label.DailyOrderFinalize") : t("Label.DailyOrderDefinalize")}
                open={showDialog}
                onClose={() => setShowDialog(false)}
            >
                <DialogContent>
                    <p>{path ? t("Label.ConfirmationFinalizeDailyOrder") : t("Label.ConfirmationDefinalizeDailyOrder")}</p>
                </DialogContent>
                <DialogActions>
                    <BasicButton
                        text={t("BtnText.No")}
                        onClick={() => setShowDialog(false)}
                        variant="outlined"
                    />
                    <BasicButton
                        text={t("BtnText.Yes")}
                        onClick={() => handleConfirmFinalizeOrDefinalize()}
                        variant="contained"
                        autoFocus
                    />
                </DialogActions>
            </Dialogbox>
            <section className="section margin-top-35">
                <div className="container-fluid padding-top-10">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12 margin-bottom-30">
                            <BasicBreadcrumbs
                                items={[
                                    {
                                        to: navigateToDashboardPath(userDetailsData.roleId),
                                        label: t("Breadcrumb.Dashboard"),
                                    },
                                    {
                                        to: path ? COMMISSION_CASE_FINALIZE : COMMISSION_CASE_DEFINALIZE,
                                        label: path ? t("Label.CaseFinalize") : t("Label.CaseDefinalize"),
                                    },
                                    {
                                        to: '',
                                        label: path ? t("Label.DailyOrderFinalize") : t("Label.DailyOrderDefinalize"),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row margin-bottom-25">
                        <div className="col-lg-12 margin-bottom-25">
                            {dailyOrderData && (
                                <PreviewCard
                                    heading={t("Label.CaseDetails")}
                                    items={flattenArrayToIPreview({
                                        caseNumber: dailyOrderData?.caseNumber,
                                        complainantName: dailyOrderData?.complainantName,
                                        respondantName: dailyOrderData?.respondantName,
                                        filingDate: dailyOrderData?.filingDate ? DateTime.fromFormat(dailyOrderData?.filingDate as string, "yyyy-MM-dd").toFormat("dd-MM-yyyy") : 'NA',
                                        dateOfHearing: dailyOrderData?.dateOfHearing ? DateTime.fromFormat(dailyOrderData.dateOfHearing, "yyyy-MM-dd").toFormat("dd-MM-yyyy") : 'NA',
                                    })}
                                />
                            )}
                        </div>
                    </div>

                    <div className="margin-top-25 clearfix">
                        <BasicButton
                            type="submit"
                            className="float-end"
                            variant="contained"
                            endIcon={<ArrowForward />}
                            text={path ? t("BtnText.Finalize") : t("BtnText.Definalize")}
                            onClick={handleShowDialog}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}


export default DailyOrderFinalizedOrDefinalized;