import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import { JUDGE, JUDGE_CASE_HISTORY, JUDGE_CAUSELIST, JUDGE_CONSOLIDATED_FILES, JUDGE_COURTROOM_HEARING, JUDGE_COURTROOM_HEARING_DETAILS, JUDGE_COURTROOM_MODIFICATION, JUDGE_DAILY_ORDER_ENTRY, JUDGE_DAILY_ORDER_VIEW, JUDGE_DASHBOARD, JUDGE_EDITPROFILE, JUDGE_FIND_CASEDETAILS, JUDGE_IMPORTANT_NOTES, JUDGE_JUDGMENT_PROCEEDING, JUDGE_JUDGMENT_UPLOAD, JUDGE_UPLOAD_TYPE_JUDGMENT, JUDGE_UPLOAD_TYPE_ORDER } from "../CONSTANTS";

// lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const CommissionDashboard = lazy(() => import('../../pages/dashboard/components/CommissionDashboard/CommissionDashboard'));
const CourtroomHearingList = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomHearingList'));
const CourtroomModification = lazy(() => import('../../pages/Commissions/CourtroomHearing/CourtroomModification'));
const CourtroomHearingView = lazy(() => import('../../pages/Commissions/CourtroomHearing/component/CourtroomHearingView'));
const FindCaseDeailsContainer = lazy(() => import('../../pages/Commissions/FindCaseDetails/FindCaseDeailsContainer'));
const JudgementSearchContainer = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const DailyOrderEntry = lazy(() => import('../../pages/Commissions/DailyOrderEntry'));
const UploadTypeOrder = lazy(() => import('../../pages/Commissions/DailyOrderEntry/component/UploadTypeOrder'));
const JudgmentUpload = lazy(() => import('../../pages/Commissions/JudgmentUpload'));
const UploadTypeJudgement = lazy(() => import('../../pages/Commissions/JudgmentUpload/component/UploadTypeJudgement'));
const JudgementProceeding = lazy(() => import('../../pages/Commissions/JudgementSearch'));
const ConsolidatedFilesContainer = lazy(() => import('../../pages/Commissions/ConsolidatedFiles'));
const ImportantNotesContainer = lazy(() => import('../../pages/Judge/ImportantNotes'));
const CauseList = lazy(() => import('../../pages/Commissions/CauseList'));
const CaseHistoryForJudgeContainer = lazy(() => import('../../pages/Judge/CaseHistoryForJudge'));

const presidentChildrenRoute: IRoute[] = [
    {
        path: JUDGE_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: JUDGE,
        element: <Dashboard />,
        children: [
            {
                path: JUDGE_DASHBOARD,
                element: <CommissionDashboard />
            }
        ]
    },
    {
        path: JUDGE_COURTROOM_HEARING,
        element: <CourtroomHearingList />
    },
    {
        path: JUDGE_COURTROOM_MODIFICATION,
        element: <CourtroomModification />
    },
    {
        path: JUDGE_COURTROOM_HEARING_DETAILS,
        element: <CourtroomHearingView />
    },
    {
        path: JUDGE_DAILY_ORDER_ENTRY,
        element: <DailyOrderEntry />
    },
    {
        path: JUDGE_DAILY_ORDER_VIEW,
        element: <DailyOrderEntry />
    },
    {
        path: JUDGE_UPLOAD_TYPE_ORDER,
        element: <UploadTypeOrder />
    },
    {
        path: JUDGE_JUDGMENT_UPLOAD,
        element: <JudgmentUpload />
    },
    {
        path: JUDGE_UPLOAD_TYPE_JUDGMENT,
        element: <UploadTypeJudgement />
    },
    {
        path: JUDGE_FIND_CASEDETAILS,
        element: <FindCaseDeailsContainer />
    },
    {
        path: JUDGE_JUDGMENT_PROCEEDING,
        element: <JudgementSearchContainer />
    },
    {
        path: JUDGE_JUDGMENT_PROCEEDING,
        element: <JudgementProceeding />
    },
    {
        path: JUDGE_CONSOLIDATED_FILES,
        element: <ConsolidatedFilesContainer />
    },
    {
        path: JUDGE_IMPORTANT_NOTES,
        element: <ImportantNotesContainer />
    },
    {
        path: JUDGE_CAUSELIST,
        element: <CauseList />
    },
    {
        path: JUDGE_CASE_HISTORY,
        element: <CaseHistoryForJudgeContainer />
    },
];

// list of commission Routes
export const presidentRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute
            requiredRoles={[ROLE.President]}
        />,
        children: presidentChildrenRoute
    }
];