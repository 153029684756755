import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./testimonials.scss";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { TiStarFullOutline } from "react-icons/ti";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const data = [
  {
    storyOf: 'Story from Assam(Tinsukia)',
    storyDiscription: `A consumer in Assam faced ongoing issues with a faulty electric scooter and received no resolution despite multiple complaints.
    Through Online Filing mode, the consumer filed a case on 07/02/2023, and after 13 months, the Consumer Commission ruled in favor. 
    The judgment awarded the consumer Rs. 1,50,000 in compensation for the faulty product, additional expenses, and 
    harassment, showcasing the power of consumer rights and justice.`
  },
  {
    storyOf: "Justice Delivered in Punjab (Moga)",
    storyDiscription: `A consumer who purchased a medical insurance policy was denied coverage when he fell ill, 
    forcing him to pay medical bills out of pocket. He filed a case on 27/09/2023, and within just 4 months,
    the Consumer Commission decided in his favor. The commission awarded the complainant Rs.35,415 plus Rs.12,000 
    in compensation and litigation expenses, ensuring justice and financial relief.`
  },
  {
    storyOf: "Villuppuram, TN Commission Ordered Compensation",
    storyDiscription: `In a case heard by the District Consumer Disputes Redressal Commission in Villuppuram, Tamil Nadu, 
the complainant faced significant losses in his construction business after purchasing a defective commercial vehicle.
 After filing a complaint on 28/04/2023, the commission ruled in favor, ordering the opposite parties to replace the vehicle and pay Rs. 1,10,000 as compensation within two months.`
  },

];

export default function Testimonials() {
  const carouselRef: any = useRef(null);

  const StarRating = ({ rating }: { rating: any }) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    return (
      <>
        <div className="bg-light d-flex align-items-center justify-content-center position-absolute rating-box rounded-pill py-2 ">
          {Array.from({ length: totalStars }, (v, i) => (
            <TiStarFullOutline key={i} className="" />
          ))}
        </div>

        <div className="p-2 ratings-circle d-flex align-items-center justify-content-center rounded-circle text-white text-sm font-weight-bold text-center position-absolute  ">
          {rating}
        </div>
      </>
    );
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  return (
    <section className="h-100 testimonial-sections  position-relative">
      {/* <div className="complain-bg w-100 h-100   "> */}
        {/* <img src="/testimonials/newbg.png" alt="" /> */}
      {/* </div> */}
      <div>
        <p className="text-black  text-center"> Our Testimonials</p>
        <h3 className="text-center">
          <span className="text-warning">Citizens</span> <span className="text-black">Share Their</span>
          <span className="text-warning"> Success</span> <span className="text-black">Stories</span>
        </h3>
      </div>
      <div className="my-5">
        <Carousel arrows={false} ref={carouselRef} responsive={responsive}>
          {data.map((element) => (
            <div className="bg-white bg-opacity-25 rounded sucess__Story__Card  p-3 md-p-4 d-flex gap-4 position-relative mr-2">
              <img
                className="position-absolute bottom-0 end-0 mb-4 me-4 pointer-events-none"
                src="/testimonials/testimonials.svg"
                alt=""
              />
              <div className="flex-grow-1 ">
                <div className="d-flex  ">
                  <div>
                    <img src="/testimonials/testimonials1.svg" alt="" />
                  </div>
                  <StarRating rating={3.5} />
                </div>
                <div className="text-dark text-lg font-normal mt-3">
                  <h5 className="text-black">{element.storyOf}</h5>
                  <p className="text-black">{element.storyDiscription}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="d-flex align-item-center justify-content-center my-4">
        <div
          className="carousel-button arrow-left"
          onClick={() => {
            handlePrev();
          }}
        >
          <IoIosArrowBack className="arrow-fill " />
        </div>
        <div
          className="carousel-button arrow-right"
          onClick={() => {
            handleNext();
          }}
        >
          <IoIosArrowForward className="arrow-fill " />
        </div>
      </div>
    </section>
  );
}
