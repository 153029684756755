// Role Enum
export enum ROLE {
	Consumer = 1,
	Advocate = 2,
	President = 3,
	Member = 4,
	DEO = 5,
	TSP = 6,
	DMA = 7,
	CourtMaster = 8,
	DealingAssistant = 9,
	NcdrcAdmin = 10,
	ScdrcAdmin = 11,
	DcdrcAdmin = 12,
	DocaAdmin = 13,
	DocaUser = 14,
	DIO = 15,
	NicStateCoordinator = 16,
	NicHq = 17,
	SuperAdmin = 18,
	Company = 19,
	GovtStakeHolder = 20,
	AnalyticsAdmin = 21,
}

// case file stepper form enum
export enum stepperForms {
	CaseDetails,
	ComplainantRespondent,
	AdditionalComplainant,
	AdditionalRespondent,
	DocumentUpload,
	FinalSubmit,
}

// case stage id enum
export enum caseStages {
	draft = 116,
	pendingForScrutiny = 117,
	revertedInScrutiny = 118,
	paymentPending = 119,
	pendingForApproval = 120,

}

// case stage id enum
export enum caseStagesId {
	disposedOff = 7

}

// Scrutiny Stepper form enum
export enum scrutinyStepperForms {
	CaseDetails,
	ComplainantRespondent,
	AdditionalComplainant,
	AdditionalRespondent,
	DocumentView,
	FinalAction,
}

export enum appealTypeScrutinyStepperForms {
	CaseDetails,
	ComplainantRespondent,
	AppealTypeDocumentView,
	FinalAction,
}

export enum interlocutoryTypeScruitnyStepperForms {
	CaseDetails,
	ComplainantRespondent,
	interlocutoryDocumentView,
	FinalAction,
}

// case Type Enum
export enum caseTypeEnum {
	ConsumerCase = 1,
	RevisionPetition = 2,
	TransferApplication = 3,
	ExecutionApplication = 4,
	ReviewApplication = 5,
	Appeal = 6,
	CaveatCases = 7,
	FirstAppeal = 8,
	MiscellaneousApplication = 9,
	InterlocutoryApplication = 10,
	ExecutionRevisionPetition = 12,
	AppealExecution = 14,
	SecondAppeal = 16,
	CCPAAppeal = 17,
}

// error code text
export enum ErrorCodeText {
	USER_ALREADY_EXIST = "USER_ALREADY_EXIST",
	ROLE_DOES_NOT_EXIST = "ROLE_DOES_NOT_EXIST",
	FIELD_ALREADY_EXIST = "FIELD_ALREADY_EXIST",
	INVALID_ARGUMENT = "INVALID_ARGUMENT",
	USER_NOT_FOUND = "USER_NOT_FOUND",
	ACCED_DENIED = "ACCED_DENIED",
	UNKNOWN = "UNKNOWN",
	EMPTY_REQUEST = "EMPTY_REQUEST",
	DATA_NOT_FOUND = "DATA_NOT_FOUND",
}

// Commision Type Enum

export enum commissionTypeEnum {
	DISTRICT_COMMISSION = 3,
	STATE_COMMISSION = 2,
	NCDRC_COMMISSION = 1,
}

// Hearing Mode Enum
export enum hearingModeEnum {
	NotAvailable = 0,
	Physical = 1,
	Virtual = 3,
	Hybrid = 4,
}

// Disposal Method Enum
export enum disposalMethodEnum {
	NotAvailable = 0,
	CourtRoom = 1,
	MediationCentre = 2,
	LokAdalat = 3,
}