import React, { useEffect, useRef, useState } from 'react';
import './SpinningWheel.css';
import { indian_number_system_converter } from '../../../utils/converter';
import { IDataEntry } from '../../../models/anaylticsDashboard/reponseData';
import { iconMap } from '../../../utils/CategoryIcons';
import { capsToTitleCase } from '../../../utils/Helper';



interface CategoryWheelProps {
    data: IDataEntry[];
}
const colors = [
    '#db1c50', '#dc2084', '#c758d0', '#9c46cf', '#8d6cef', '#8399ec', '#007ed5', '#60b7d3', '#98d9ff', '#7bdddc',
    '#26d7ad', '#2dcb76', '#1ba92f', '#52d726', '#d6f30b', '#d6f30b', '#ffec01', '#ffaf00', '#ff7300', '#ff1300'
];
const CategoryWheel: React.FC<CategoryWheelProps> = ({ data }) => {
    const [selectedCategory, setSelectedCategory] = useState<IDataEntry | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [radius, setRadius] = useState<number>(200); // Default radius, can be adjusted

    useEffect(() => {
        if (containerRef.current) {
            const size = containerRef.current.offsetWidth;
            setRadius(size / 2);
        }
    }, []);

    const handleCategoryClick = (category: IDataEntry) => {
        setSelectedCategory(category);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setSelectedCategory(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const segmentAngle = 360 / data?.length;
    // Adding padding around the wheel
    const padding = 10;
    const viewBoxSize = radius * 2 + padding * 2;

    return (
        <div className='d-flex'>
            <div className="wheel-container" ref={containerRef}>
                <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} width="100%" height="100%">
                    {/* Outer Circle */}
                    <circle
                        cx={radius + padding}
                        cy={radius + padding}
                        r={radius * 1.03}
                        stroke="#d5b1ff"
                        strokeWidth="8"
                        fill="none"
                    />

                    {data?.map((category, index) => {
                        const startAngle = index * segmentAngle;
                        const endAngle = startAngle + segmentAngle;
                        const textAngle = startAngle + segmentAngle / 2;

                        const x = radius + padding + (radius * 0.8) * Math.cos((Math.PI * textAngle) / 180);
                        const y = radius + padding + (radius * 0.8) * Math.sin((Math.PI * textAngle) / 180);
                        const rotateAngle = textAngle + 180;

                        return (
                            <g key={category.categoryid} onClick={() => handleCategoryClick(category)}>
                                <path stroke='white' strokeWidth='4'
                                    d={`M${radius + padding} ${radius + padding} 
                   L${radius + padding + radius * Math.cos((Math.PI * startAngle) / 180)} 
                     ${radius + padding + radius * Math.sin((Math.PI * startAngle) / 180)}
                   A${radius} ${radius} 0 0 1 
                   ${radius + padding + radius * Math.cos((Math.PI * endAngle) / 180)} 
                   ${radius + padding + radius * Math.sin((Math.PI * endAngle) / 180)}
                   Z`}
                                    fill={colors[index % colors.length]} // Assign different color from array
                                    className="wheel-segment"
                                />
                                <text
                                    x={x}
                                    y={y}
                                    textAnchor="start"
                                    alignmentBaseline="middle"
                                    transform={`rotate(${rotateAngle}, ${x}, ${y})`}
                                    className="wheel-label"
                                >
                                    {category.categoryname}
                                </text>
                            </g>
                        );
                    })}

                    {/* Inner Circles */}
                    <circle cx={radius + padding} cy={radius + padding} r={radius / 4} fill="rgba(227,219,219,0.6)" />
                    <circle cx={radius + padding} cy={radius + padding} r={radius / 5} fill="white" />
                    <text
                        x={radius + padding}
                        y={radius + padding}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        className="wheel-center-text"
                    >
                        Sectors
                    </text>
                </svg>
            </div>
            <div className='position-relative'>
                {selectedCategory && (
                    <div className="popup">
                        <div className='sector_popup_heading'>
                            <div className='icon-sector'><img src={iconMap[selectedCategory.categoryname]} alt='cat' width={40} height={35} className='icon-white' /></div>
                            <h5>{capsToTitleCase(selectedCategory.categoryname)}</h5>
                        </div>
                        <div className='margin-top-10'>
                            <p><b>Filed:</b> {indian_number_system_converter(selectedCategory.no_of_cases_filed)}</p>
                            <p><b>Disposed:</b>  {indian_number_system_converter(selectedCategory.no_of_cases_disposed)}</p>
                            <p><b>Pending:</b>  {indian_number_system_converter(selectedCategory.no_of_cases_pending)}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategoryWheel;
