import { lazy } from "react";
import { IRoute } from "../../models/common/IRoutes";
import { ROLE } from "../../utils/Enum";
import PrivateRoute from "../auth/PrivateRoute";
import {
    DEALINGASSISTANT,
    DEALING_ASSISTANT_EDITPROFILE,
    DEALING_ASSISTANT_DASHBOARD,
    DEALING_ASSISTANT_CASETRANSFER,
    DEALING_ASSISTANT_ADDREMOVEBUNCH,
    DEALING_ASSISTANT_ADDMODIFYCOMPLAINT,
    DEALING_ASSISTANT_SHIFTADJOURN,
    DEALING_ASSISTANT_ISSUEPRINT,
    DEALING_ASSISTANT_GENERATE_NOTICE,
    DEALING_ASSISTANT_EDIT_NOTICE
} from "../CONSTANTS";



// Lazy import of components
const EditProfile = lazy(() => import('../../pages/Common/EditProfile'));
const Dashboard = lazy(() => import('../../pages/dashboard'));
const DealingAssistantDashboard = lazy(() => import('../../pages/dashboard/components/DealingAssistant/DealingAssistantDashboard'));
const IssuePrintNoticeContainer = lazy(() => import('../../pages/Commissions/IssuePrintNotice'));
const AddRemoveBunchCases = lazy(() => import('../../pages/Commissions/AddRemoveBunchCases'));
const FindCaseDeailsContainer = lazy(() => import('../../pages/Commissions/FindCaseDetails/FindCaseDeailsContainer'))
const GenerateAndEditNoticeView = lazy(() => import('../../pages/Commissions/IssuePrintNotice/component/GenerateNotice/GenerateNoticeView'));

const dealingAssistantChildrenRoute: IRoute[] = [
    {
        path: DEALING_ASSISTANT_EDITPROFILE,
        element: <EditProfile />
    },
    {
        path: DEALINGASSISTANT,
        element: <Dashboard />,
        children: [
            {
                path: DEALING_ASSISTANT_DASHBOARD,
                element: <DealingAssistantDashboard />
            }
        ]
    },
    {
        path: DEALING_ASSISTANT_CASETRANSFER,
        element: <></>
    },
    {
        path: DEALING_ASSISTANT_ADDREMOVEBUNCH,
        element: <AddRemoveBunchCases />
    },
    {
        path: DEALING_ASSISTANT_ADDMODIFYCOMPLAINT,
        element: <FindCaseDeailsContainer />
    },
    {
        path: DEALING_ASSISTANT_SHIFTADJOURN,
        element: <></>
    },
    {
        path: DEALING_ASSISTANT_ISSUEPRINT,
        element: <IssuePrintNoticeContainer />
    },
    {
        path: DEALING_ASSISTANT_GENERATE_NOTICE,
        element: <GenerateAndEditNoticeView />
    },
    {
        path: DEALING_ASSISTANT_EDIT_NOTICE,
        element: <GenerateAndEditNoticeView />
    },
];

// List of dealing assistant Routes
export const dealingAssistantRoutes: IRoute[] = [
    {
        path: '',
        element: <PrivateRoute requiredRoles={[ROLE.DealingAssistant]} />,
        children: dealingAssistantChildrenRoute
    }
];
