import * as React from "react";
import { FormHelperText, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IDropdown } from "../../models/common/IDropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type DropdownProps = {
  id: string;
  label: string;
  error?: boolean;
  name?: string;
  value: any;
  helperText?: any;
  items: Array<IDropdown>;
  onDropDownChange?: (event: SelectChangeEvent<any>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  renderValue?: (value: any) => React.ReactNode;
} & FormControlProps;

/**
 * Dropdown Component
 *
 * Renders a dropdown/select input field with options.
 *
 * @param {DropdownProps} props - The props for the Dropdown component.
 * @returns {JSX.Element} - The rendered Dropdown component.
 */
export function Dropdown({
  id,
  helperText,
  error,
  value,
  items,
  label,
  name,
  onDropDownChange,
  onBlur,
  renderValue,
  ...rest
}: DropdownProps): JSX.Element {
  return (
    <div className="form-control-group">
      <FormControl
        error={error}
        className="select-form-control m-0 p-0"
        fullWidth
        {...rest}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          name={name}
          IconComponent={KeyboardArrowDownIcon}
          className="select-form-control-element"
          MenuProps={{
            classes: { paper: "select-form-control-dropdown" },
            disableScrollLock: true,
          }}
          id={id}
          value={value}
          renderValue={renderValue}
          onChange={onDropDownChange}
          onBlur={onBlur}
        >
          {items.map((item: IDropdown, index: number) => (
            <MenuItem key={`ddl_${index}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}
