import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDropdown } from "../../models/common/IDropdown";

const initialState: { [key: string]: IDropdown[] } = {
    stateList: [],
    districtList: [],
    caseCategoryList: [],
    subCategoryList: [],
    subSubCategoryList: [],
    addressTypeList: [],
    postOfficeList: [],
    advocateList: [],
    commissionList: []
}

export const speechSlice = createSlice({
    name: 'speech',
    initialState,
    reducers: {
        addStateList: (state, action: PayloadAction<IDropdown[]>) => {
            state.stateList = action.payload
        },
        addDistrictList: (state, action: PayloadAction<IDropdown[]>) => {
            state.districtList = action.payload
        },
        addCaseCategoryList: (state, action: PayloadAction<IDropdown[]>) => {
            state.caseCategoryList = action.payload
        },
        addSubCategoryList: (state, action: PayloadAction<IDropdown[]>) => {
            state.subCategoryList = action.payload
        },
        addSubSubCategoryList: (state, action: PayloadAction<IDropdown[]>) => {
            state.subSubCategoryList = action.payload
        },
        addAddressTypeList: (state, action: PayloadAction<IDropdown[]>) => {
            state.addressTypeList = action.payload
        },
        addPostOfficeList: (state, action: PayloadAction<IDropdown[]>) => {
            state.postOfficeList = action.payload
        },
        addAdvocateList: (state, action: PayloadAction<IDropdown[]>) => {
            state.advocateList = action.payload
        },
        addCommissionList: (state, action: PayloadAction<IDropdown[]>) => {
            state.commissionList = action.payload
        },
        clearSpeech: (state) => {
            state.stateList = [];
            state.districtList = [];
            state.caseCategoryList = [];
            state.subCategoryList = [];
            state.subSubCategoryList = [];
            state.addressTypeList = [];
            state.postOfficeList = [];
            state.advocateList = [];
            state.commissionList = [];
            localStorage.removeItem('persist:root');
        }
    }
});

export const {
    addStateList,
    addDistrictList,
    addCaseCategoryList,
    addSubCategoryList,
    addSubSubCategoryList,
    addAddressTypeList,
    addAdvocateList,
    addCommissionList,
    addPostOfficeList,
    clearSpeech
} = speechSlice.actions;

export default speechSlice.reducer