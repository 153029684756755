import React from "react";

type ConfonetCardProps = {
  children: React.ReactNode;
  className?: string;
};

/** Renders the card
 * @param CardsProps
 */
export function ConfonetCard({
  children,
  className = "card card-primary h-100",
}: ConfonetCardProps) {
  return <div className={className}>{children}</div>;
}

type CardHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

export const ConfonetCardHeader = ({
  children,
  className = "card-header card-primary-header",
}: CardHeaderProps) => <div className={className}>{children}</div>;

type ConfonetCardBodyProps = {
  value?: string;
  children: React.ReactNode;
  className?: string;
};

export const ConfonetCardBody = ({
  children,
  className = "card-body card-primary-body",
}: ConfonetCardBodyProps) => <div className={className}>{children}</div>;
