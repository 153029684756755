export const reportColumnName = [
  //@ type: commission_wise
  //@ Report no: 1
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission", alignment: "center" },
    {
      text: [
        { text: "File\n", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],

  //@ type: categoryWiseReport
  //@ Report no: 2

  [
    { text: "S.No", alignment: "center" },
    { text: "Category", alignment: "center" },
    {
      text: [
        { text: "Filed\n", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],

  //@ type: StateWisePendencyReport
  //@ Report no: 3
  [
    { text: "S.No", alignment: "center" },
    { text: "State", alignment: "center" },
    {
      text: [
        { text: "Filed\n", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", color: "blue", alignment: "center" },
      ],
    },
  ],

  //@ type: StatePendencyForOneYearReport
  //@ Report no: 4
  [
    [
      { text: "S.No", rowSpan: 3, alignment: "center" },
      { text: "State", rowSpan: 3, alignment: "center" },
      {
        rowSpan: 3,
        text: [{ text: "District", rowSpan: 2, alignment: "center" }],
      },
      {
        rowSpan: 3,
        text: [
          { text: "Total Filed\n", alignment: "center" },
          { text: "(a)", alignment: "center", color: "blue" },
        ],
      },
      {
        colSpan: 3,
        text: [
          { text: "Pending", alignment: "center" },
          { text: "*", alignment: "center", color: "red" },
        ],
      },
      {},
      {},
    ],
    [
      {},
      {},
      {},
      {},
      { rowSpan: 2, bold:true, text: "0 to 3 Months", alignment: "center" },
      { rowSpan: 2, bold:true, text: "3 to 6 Months", alignment: "center" },
      { rowSpan: 2, bold:true, text: "6 to 12 Months", alignment: "center" },
    ],
    [{}, {}, {}, {}, {}, {}, {}],
  ],

  //@ type: StateDistrictReport
  //@ Report no: 5
  [
    { text: "S.No", alignment: "center" },
    { text: "State", alignment: "center" },
    { text: "District", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", blue: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "(f=(a-b)/a)*100", color: "blue", alignment: "center" },
      ],
    },
  ],

  //@ type: RegionWiseReport
  //@ Report no: 6
  [
    { text: "S.No", alignment: "center" },
    { text: "Region", alignment: "center" },
    {
      text: [
        { text: "Filed\n", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],

  //@ type: DisposalMoreThanOneYearReport
  //@ Report no: 7
  [
    [
      { text: "S.No", rowSpan: 3, alignment: "center" },
      { text: "State", rowSpan: 3, alignment: "center" },
      {
        rowSpan: 3,
        text: [{ text: "District", rowSpan: 2, alignment: "center" }],
      },
      {
        colSpan: 7,
        text: "Disposed", alignment: "center" 
      },
      {},
      {},
      {},
      {},
      {},
      {},
    ],

    [
      {},
      {},
      {},
      { rowSpan: 2, bold: true, text: "0 - 1 year", alignment: "center" },
      { rowSpan: 2, bold: true, text: "1 - 2 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "2 - 5 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "5 - 10 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "10 - 20 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "20 - 30 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "More than 30 years", alignment: "center" },
    ],

    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
  ],
  //@ type: disposalWithinOneYearReport
  //@ Report no: 8
  [
    [
      { text: "S.No", rowSpan: 3, alignment: "center" },
      { text: "State", rowSpan: 3, alignment: "center" },
      {
        rowSpan: 3,
        text: [{ text: "District", rowSpan: 2, alignment: "center" }],
      },
      {
        rowSpan: 3, text: "Filed", alignment: "center" 
      },
      {
        rowSpan: 3, text: "Disposed", alignment: "center" 
      },
      {
        colSpan: 3, text: "Disposal", alignment: "center" 
      },
      {},
      {},
    ],
    [
      {},
      {},
      {},
      {},
      {},
      { rowSpan: 2, bold:true, text: "0 to 3 Months", alignment: "center" },
      { rowSpan: 2, bold:true, text: "3 to 6 Months", alignment: "center" },
      { rowSpan: 2, bold:true, text: "6 to 12 Months", alignment: "center" },
    ],
    [{}, {}, {},{}, {}, {}, {}, {}],
          
  ],

  //@ type: StateCommissionDurationWisePendency
  //@ Report no: 9
  [
    [
      { text: "S.No", rowSpan: 3, alignment: "center" },
      { text: "State", rowSpan: 3, alignment: "center" },
      {
        rowSpan: 3,
        text: [{ text: "Filed", rowSpan: 2, alignment: "center" }],
      },
      {
        colSpan: 6,
        text: [
          { text: "Pending", alignment: "center" },
          { text: "*", alignment: "center", color: "red" },
        ],
      },
      {},
      {},
      {},
      {},
      {},
    ],

    [
      {},
      {},
      {},
      { rowSpan: 2, bold: true, text: "0 - 1 year", alignment: "center" },
      { rowSpan: 2, bold: true, text: "2 - 5 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "5 - 10 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "10 - 20 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "20 - 30 years", alignment: "center" },
      { rowSpan: 2, bold: true, text: "More than 30 years", alignment: "center" },
    ],

    [{}, {}, {}, {}, {}, {}, {}, {}, {}],
  ],

  //@ type: CaseType_Wise_Report
  //@ Report no: 10
  [
    { text: "S.No", alignment: "center" },
    { text: "Category", alignment: "center" },
    {
      text: [
        { text: "Filed\n", alignment: "center" },
        { text: "(a)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Disposed\n", alignment: "center" },
        { text: "(b)", alignment: "center", color: "blue" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate\n", alignment: "center" },
        { text: "f=(b/a)*100", alignment: "center", color: "blue" },
      ],
    },
  ],

  //@ type: Registered_Users_Report
  //@ Report no: 11
  [
    { text: "S.No", alignment: "center" },
    { text: "State Name", alignment: "center" },
    { text: "District Name", alignment: "center" },
    { text: "Registered Users", alignment: "center" },
  ],

  //@ type: e-Jagriti_Case_Detail_Report
  //@ Report no: 12
  [
    { text: "S.No", alignment: "center" },
    { text: "Commission Type", alignment: "center" },
    { text: "Filed", alignment: "center" },
    { text: "Reverted", alignment: "center" },
    { text: "Admitted", alignment: "center" },
    { text: "Disposed", alignment: "center" },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", alignment: "center", color: "red" },
      ],
    },
  ],

  //@ type: StageWiseReport
  //@ Report no: 13
  [
    { text: "S.No", alignment: "center" },
    { text: "Stage Name", alignment: "center" },
    { text: "Case Count", alignment: "center" },
  ],

  //@ type: StateStageWiseReport
  //@ Report no: 14
  [
    { text: "S.No", alignment: "center" },
    { text: "State", alignment: "center" },
    { text: "Stage", alignment: "center" },
    { text: "Case Count", alignment: "center" },
  ],

  //@ type: State_District_Stage_wise_report
  //@ Report no: 15
  [
    { text: "S.No", alignment: "center" },
    { text: "State", alignment: "center" },
    { text: "District", alignment: "center" },
    { text: "Stage", alignment: "center" },
    { text: "Case Count", alignment: "center" },
  ],

  //@ type: YearlyWiseCategoryReport
  //@ Report no: 16
  [
    { text: "S.No", alignment: "center" },
    { text: "Year", alignment: "center" },
    {
      text: [
        { text: "Filed", alignment: "center" },
        { text: "\n(a)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Disposed", alignment: "center" },
        { text: "\n(b)", color: "blue", alignment: "center" },
      ],
    },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
    {
      text: [
        { text: "Disposal Rate", alignment: "center" },
        { text: "\n f=(b/a)*100", color: "blue", alignment: "center" },
      ],
    },
  ],

  //@ type: RegionWiseEjagritiReport
  //@ Report no: 17
  [
    { text: "S.No", alignment: "center" },
    { text: "Region", alignment: "center" },
    { text: "Filed", alignment: "center" },
    { text: "Reverted", alignment: "center" },
    { text: "Payment Pending", alignment: "center" },
    { text: "Admitted", alignment: "center" },
    { text: "Disposed", alignment: "center" },
    {
      text: [
        { text: "Pending", alignment: "center" },
        { text: "*", color: "red" },
      ],
    },
  ],
];
