import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
	CourtroomHearingModel,
	IGetCourtRoomDateOfHearingResponse,
	IGetCourtRoomModificationPayload,
	IGetCourtroomDetailsByIdResponse,
} from "../../../models/cases/commissionCase/CourtroomHearingFormViewModel";
import { CourtroomHearingListPayload, CourtroomProceedingTextPayload, CourtroomProceedingTextResponse, DailyOrderDataPayload, DailyOrderEntryListPayload, ICaseHearingResponse, IGetCourtroomHearingListResponse, IGetDailyOrderEntryListResponse, IGetDailyOrderResponseCode, IStatusResponse } from "../../../models/cases/commissionCase/ICommissionCase";
import { CauselistFormPayload, CauselistResponse, JudgeCourtMappingListPayload, JudgeCourtMappingListResponse } from "../../../models/causelist/CauselistModel";
import { AddDisplayBoardPayload, DisplayBoardResponse } from "../../../models/displayBoard/DisplayBoardModel";
import { DailyOrderOrJudgementFinalizeDefinalizePayload, DailyOrderOrJudgementFinalizeDefinalizeResponse, IGetDailyOrderPdfResponse, IGetJudgementDataResponse, IGetJudgementSearchCaseResponse, JudgementOrDailyOrderSearchCasePayload } from "../../../models/judgementUpload/JudgementUploadFormViewModel";
import { CourtRoomHearingSubmitModel } from "../../../pages/Commissions/CourtroomHearing/component/CaseDetails/CourtroomHearingCaseDetailsFormModel";
import { COURTROOM_SERVICE, ORDER_SERVICE } from "../../CONSTANTS";
import { getCall, postCall, putCall, putCallFile } from "../../api";

export class CourtroomService {
	/** Call an API to perform save courtroom details
* @param data string
*/
	public static readonly saveCourtRoomCase = async (data: CourtroomHearingModel) => {
		try {
			const response = await postCall(
				`${COURTROOM_SERVICE}/saveCourtRoomCase`,
				data
			);
			return response?.data;
		} catch (error) {
			toast.error(error as string);
		}
	};

	/** Calls an API to get courtroom details by reference number
*
* @returns Promise<IGetCourtroomDetailsByIdResponse>
*/
	public static readonly getCourtroomDetailsById = async (fillingReferenceNo: number): Promise<IGetCourtroomDetailsByIdResponse[]> => {
		const params = { fillingReferenceNo };
		const response: AxiosResponse<IGetCourtroomDetailsByIdResponse[]> = await getCall(
			`${COURTROOM_SERVICE}/getCourtRoomCaseDetails`,
			params
		)
		return response?.data
	}

	/** Calls an API to get courtroom details by reference number
*
* @returns Promise<IGetCourtroomDetailsByIdResponse>
*/
	public static readonly getCourtroomDetailsByReferenceNumber = async (filingReferenceNo: number): Promise<IGetCourtroomDetailsByIdResponse> => {
		const params = { filingReferenceNo };
		const response: AxiosResponse<IGetCourtroomDetailsByIdResponse> = await getCall(
			`${COURTROOM_SERVICE}/getLatestCourtRoomHearing`,
			params
		)
		return response?.data
	}

	/** Call an API to get courtroom hearing list
* @param data string
*/
	public static readonly getCourtRoomHearingList = async (data: CourtroomHearingListPayload) => {
		const response: AxiosResponse<IGetCourtroomHearingListResponse[]> = await postCall(
			`${COURTROOM_SERVICE}/getListOfCourtRoomHearing`,
			data
		);
		return response?.data;
	};

	/** Call an API to get daily order entry list
* @param data string
*/
	public static readonly getDailyOrderEntryList = async (data: DailyOrderEntryListPayload) => {
		const response: AxiosResponse<IGetDailyOrderEntryListResponse[]> = await postCall(
			`${ORDER_SERVICE}/getListOfDailyOrder`,
			data
		);
		return response?.data;
	};

	/** Call an API to get judgement upload list
* @param data string
*/
	public static readonly getJudgementUploadList = async (data: DailyOrderEntryListPayload) => {
		const response: AxiosResponse<IGetDailyOrderEntryListResponse[]> = await postCall(
			`${COURTROOM_SERVICE}/getListOfDailyOrderOrJudgement`,
			data
		);
		return response?.data;
	};

	/** Call an API to get Courtroom Proceeding Text
* @param data
*/
	public static readonly getCourtroomProceedingTextById = async (data: CourtroomProceedingTextPayload) => {
		const response: AxiosResponse<CourtroomProceedingTextResponse> = await getCall(
			`${COURTROOM_SERVICE}/getProceedingText`,
			data
		);
		return response?.data;
	};

	/**
 * Call an API to Daily Order Entry Submit
 * @param data 
 * @returns 
 */
	public static readonly dailyOrderEntrySubmit = async (data: any): Promise<boolean> => {
		const response: AxiosResponse<boolean> = await putCall(
			`${ORDER_SERVICE}/uploadCourtRoomHearingOrderDetails`,
			data
		);
		return response?.data;
	};

	/**
* Call an API to search case details
* @param data 
* @returns 
*/
	public static readonly searchCaseDetails = async (data: JudgementOrDailyOrderSearchCasePayload): Promise<IGetJudgementSearchCaseResponse> => {
		const response: AxiosResponse<IGetJudgementSearchCaseResponse> = await getCall(
			`${ORDER_SERVICE}/getJudgementDetail`,
			data
		)
		return response?.data;
	}

	/**
 * Call an API to final action submit
 * @param data 
 * @returns 
 */
	public static readonly judgementUploadSubmit = async (data: any): Promise<any> => {
		const response: AxiosResponse<any> = await putCallFile(
			`${ORDER_SERVICE}/uploadCourtRoomHearingOrderDetails`,
			data
		);
		return response?.data;
	};

	/** Call an API to Generate Daily Order Data 
* @param data string
*/
	public static readonly DailyOrderData = async (data: DailyOrderDataPayload) => {
		const response: AxiosResponse<IGetDailyOrderResponseCode> = await postCall(
			`${ORDER_SERVICE}/generateDailyOrderData`,
			data
		);
		return response?.data.responseData[0];
	};


	/**
	 * Call API to get daily order data (PDF)
	 * @param params JudgementOrDailyOrderSearchCasePayload
	 * @returns Promis<IGetDailyOrderPdfResponse>
	 */
	public static readonly getDailyOrderPdf = async (params: JudgementOrDailyOrderSearchCasePayload): Promise<IGetDailyOrderPdfResponse> => {
		const response: AxiosResponse<IGetDailyOrderPdfResponse> = await getCall(
			`${ORDER_SERVICE}/getDailyOrderPdf`,
			params
		)
		return response?.data
	}

	/**
	 * Call API to get judgement data (PDF)
	 * @param params JudgementOrDailyOrderSearchCasePayload
	 * @returns Promis<IGetJudgementDataResponse>
	 */
	public static readonly getJudgementPdf = async (params: JudgementOrDailyOrderSearchCasePayload): Promise<IGetJudgementDataResponse> => {
		const response: AxiosResponse<IGetJudgementDataResponse> = await getCall(
			`${ORDER_SERVICE}/getJudgementPdf`,
			params
		)
		return response?.data
	}

	/**
 * Call an API to get case hearing date
 * @param caseNumber: string
 * @returns Promise<IGetCourtRoomDateOfHearingResponse>
 */
	public static readonly getCaseHearingDetailsByDate = async (caseNumber: string, commissionId: number): Promise<IGetCourtRoomDateOfHearingResponse[]> => {
		const response: AxiosResponse<IGetCourtRoomDateOfHearingResponse[]> = await getCall(
			`${COURTROOM_SERVICE}/getCaseHearingDetailsByDate?caseNumber=${caseNumber}&commissionId=${commissionId}`
		);
		return response?.data;
	};

	/**
* Call an API to get case hearing date
* @param data IGetCourtRoomModificationPayload
* @returns Promise<CourtRoomHearingSubmitModel>
*/
	public static readonly getCourtroomModificationDetails = async (data: IGetCourtRoomModificationPayload): Promise<CourtRoomHearingSubmitModel> => {
		const response: AxiosResponse<CourtRoomHearingSubmitModel> = await postCall(
			`${COURTROOM_SERVICE}/courtRoomHearingModification`,
			data
		);
		return response?.data;
	};

	/**
* Call an API to submit courtroom hearing details
* @param data 
* @returns 
*/
	public static readonly submitCourtRoomHearingDetails = async (data: CourtRoomHearingSubmitModel): Promise<ICaseHearingResponse> => {
		const response: AxiosResponse<ICaseHearingResponse> = await postCall(
			`${COURTROOM_SERVICE}/saveCourtRoomCaseDetails`,
			data
		)
		return response?.data;
	}

	/**
* Call an API to update courtroom hearing details
* @param data 
* @returns 
*/
	public static readonly updateCourtRoomCaseDetails = async (data: CourtRoomHearingSubmitModel): Promise<IStatusResponse> => {
		const response: AxiosResponse<IStatusResponse> = await postCall(
			`${COURTROOM_SERVICE}/updateCourtRoomCaseDetails`,
			data
		)
		return response?.data;
	}

	/** Call an API to get causelist data
* @param data string
*/
	public static readonly getCauseListData = async (data: CauselistFormPayload): Promise<CauselistResponse[]> => {
		const response: AxiosResponse<CauselistResponse[]> = await postCall(
			`${ORDER_SERVICE}/getDataForCauseList`,
			data
		)
		return response?.data;
	}

	/** Call an API to get causelist data
* @param data string
*/
	public static readonly UploadJudgementCaseDetailTA = async (data: any) => {
		const response: AxiosResponse<any> = await postCall(
			`${COURTROOM_SERVICE}/getJudgementUploadCaseDetails?caseNumber=${data}`,
			data
		)
		return response?.data;
	}

	/** Call an API to get causelist judge data
* @param data string
*/
	public static readonly getJudgeCourtMappingList = async (data: JudgeCourtMappingListPayload): Promise<JudgeCourtMappingListResponse> => {
		const response: AxiosResponse<JudgeCourtMappingListResponse> = await postCall(
			`${COURTROOM_SERVICE}/getJudgeCourtMappingList`,
			data
		)
		return response?.data;
	}

	public static readonly updateDisplayBoard = async (data: AddDisplayBoardPayload): Promise<IStatusResponse> => {
		const response: AxiosResponse<IStatusResponse> = await postCall(
			`${COURTROOM_SERVICE}/saveOrUpdateCourtRoomHearingDisplayBoardDetails`,
			data
		)
		return response?.data;
	}

	/**
	 * Call an API to get display board
	 * @param commissionId: string
	 * @returns Promise<DisplayBoardResponse[]>
	 */
	public static readonly getDisplayBoardByCommissionId = async (commissionId: string): Promise<DisplayBoardResponse[]> => {
		const response: AxiosResponse<DisplayBoardResponse[]> = await getCall(
			`${COURTROOM_SERVICE}/getDisplayBoardByCommissionId`,
			{ commissionId }
		);
		return response?.data;
	};

	/**
	* Call an API to search finalize and de-finalize data
	* @param data 
	* @returns 
	*/
	public static readonly getFinalizeAndDefinalizeData = async (data: DailyOrderOrJudgementFinalizeDefinalizePayload): Promise<DailyOrderOrJudgementFinalizeDefinalizeResponse> => {
		const response: AxiosResponse<DailyOrderOrJudgementFinalizeDefinalizeResponse> = await postCall(
			`${ORDER_SERVICE}/getFinalizeAndDefinalize`,
			data
		)
		return response?.data;
	}

	/**
	* Call an API to update finalize and de-finalize data
	* @param data 
	* @returns 
	*/
	public static readonly updateFinalizeAndDefinalizeData = async (data: DailyOrderOrJudgementFinalizeDefinalizePayload): Promise<string> => {
		const response: AxiosResponse<string> = await putCall(
			`${ORDER_SERVICE}/UpdationForFinalizeAndDefinalize`,
			data
		)
		return response?.data;
	}
}

