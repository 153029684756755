import { useTheme } from "@mui/material";
import React from "react";

const verticalBox: React.CSSProperties = {
  width: "5%",
  height: "100%",
  background: "#00A14B",
  alignSelf: "flex-start",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const FiledBox = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        ...verticalBox,
        background: theme.palette.primary.main,
      }}
    >
      <div className="vertical-text">
        Filed
      </div>
    </div>
  );
};

export default FiledBox;
