import { Step, StepLabel, Stepper, StepperProps } from "@mui/material";
import * as React from "react";


interface StepPanelProps {
  children?: React.ReactNode;
  index: number;
  stepvalue: number;
}

export type StepProps = {
  label: string;
  children?: React.ReactNode;
  stepvalue: number;
};

type BasicStepperProps = StepperProps & {
  steps: Array<StepProps>;
  stepvalue: number;
}

function StepPanel(props: StepPanelProps) {
  const { children, stepvalue, index } = props;
  return (
    <div hidden={stepvalue !== index}>
      {stepvalue === index && (
        <div className="step-content">{children}</div>
      )}
    </div>
  );
}


export function BasicStepper({ steps, stepvalue, ...rest }: BasicStepperProps) {

  return (
    <>
      <Stepper activeStep={stepvalue} {...rest}>
        {
          steps.map(({ label }: StepProps, index: number) => {
            return (
              <Step key={`step_${label}`}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })
        }
      </Stepper >
      <>
        {
          steps.map(({ children }: StepProps, index: number) => {
            return (
              <StepPanel key={`step_panel_${stepvalue + index}`} stepvalue={stepvalue} index={index}>
                {children}
              </StepPanel>
            );
          })
        }
      </>
    </>
  );
}
