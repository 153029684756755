import AddIcon from "@mui/icons-material/Add";
import { Checkbox, TextField, Typography } from "@mui/material";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
    AddressDetails,
    BasicButton,
    BasicCheckbox,
    ConfonetAutocomplete,
    ConfonetCard,
    ConfonetCardBody,
    ConfonetCardHeader,
    Textbox,
    addressValidationSchema
} from "../..";
import { IDropdown } from "../../../models/common/IDropdown";
import { AddressDetailsModel } from "../../../models/forms/AddressDetailsModel";
import PersonalDetailsModel from "../../../models/forms/PersonalDetailsModel";
import { RootState } from "../../../redux";
import { MasterService } from "../../../services/MasterService/MasterService";
import { dropdownValidationSchema } from "../../../utils/Helper";
import { ValidationRegExp } from "../../../utils/ValidationRegExp";
export const complainantPersonalValidationSchema = Yup.object({
    name: Yup.string()
        .required("Name is a required field")
        .max(99, "Only up to 99 characters are allowed")
        .matches(ValidationRegExp.NAMES, "Remove any special characters, numbers or extra spaces"),
    mobileNumber: Yup.string()
        .required("Mobile number is a required field")
        .matches(ValidationRegExp.ONLY_MOBILE_NUM, "Mobile number must be valid")
        //.min(10, "Mobile number must have 10 digits")
        .max(10, "Mobile number must have 10 digits"),
    email: Yup.string()
        .matches(ValidationRegExp.ONLY_EMAIL, "Email must be valid")
        .max(50, "Only up to 50 characters are allowed"),
    isSeniorCitezen: Yup.boolean(),
    isWidow: Yup.boolean(),
    isDifferentlyAbled: Yup.boolean(),
    isHandicapped: Yup.boolean(),
    address: Yup.array()
        .min(1, "At least one address needed")
        .of(addressValidationSchema),
    handicapped: Yup.lazy((value, options) => {
        const { parent } = options;
        if (parent.isHandicapped) {
            return dropdownValidationSchema("Handicapped is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
})

export const respondentPersonalValidationSchema = Yup.object({
    name: Yup.string()
        .max(250, "Only up to 250 characters are allowed")
        .required("Name is a required field")
        .matches(ValidationRegExp.NAMES, "Remove any special characters, numbers or extra spaces"),
    mobileNumber: Yup.string()
        .required("Mobile number is a required field")
        .matches(ValidationRegExp.ONLY_MOBILE_NUM, "Mobile number must be valid")
        .max(10, "Mobile number must have 10 digits"),
    email: Yup.string()
        .matches(ValidationRegExp.ONLY_EMAIL, "Email must be valid")
        .max(50, "Only up to 50 characters are allowed"),
    isSeniorCitezen: Yup.boolean(),
    isWidow: Yup.boolean(),
    isDifferentlyAbled: Yup.boolean(),
    isHandicapped: Yup.boolean(),
    address: Yup.array()
        .of(addressValidationSchema),
    handicapped: Yup.lazy((value, options) => {
        const { parent } = options;
        if (parent.isHandicapped) {
            return dropdownValidationSchema("Handicapped is a required field").required();
        } else {
            return dropdownValidationSchema().notRequired();
        }
    }),
})

type PersonalDetailsProps = {
    name: string,
    label: string,
    values: PersonalDetailsModel,
    touched?: FormikTouched<PersonalDetailsModel>,
    errors?: string | FormikErrors<PersonalDetailsModel>,
    singleAddress?: boolean,
    handleBlur: {
        (e: FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    },
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
    advocateList?: IDropdown[],
    isRespondent?: boolean,
}

/** Renders the Personal Details sub-form
 * @param PersonalDetailsProps
 * 
 * ### Fields
 * * Name
 * * Mobile Number
 * * Email
 * * Is Widow
 * * Is Senior Citizen
 * * Is Diffrently Abled
 * * Address
 */
export const PersonalDetails = ({ name, label, values, touched, errors, singleAddress = false, handleBlur, handleChange, setFieldValue, setFieldTouched, advocateList, isRespondent }: PersonalDetailsProps) => {
    const { t } = useTranslation();
    const { handiCapType, addressType } = useSelector((state: RootState) => state.master);
    const [addressMaxCount, setAddressMaxCount] = useState<number>(1)
    const [selectedAddressTypeOptions, setSelectedAddressTypeOptions] = useState<IDropdown[]>([]);
    const prefix = `${name}${name === "" ? "" : "."}`;

    const handleAddressCount = async () => {
        setAddressMaxCount(addressType.length);
    };

    const handleSelectAddressType = () => {
        setSelectedAddressTypeOptions(values.address.reduce(
            (acc: IDropdown[], item: AddressDetailsModel, index: number) => {
                if (item.addressType !== null) {
                    acc.push(item.addressType);
                }
                return acc;
            }, [])
        )
    }


    // Add new address to the end of the array
    const addAddress = () => {
        const addressCopy = Array.from(values.address);
        addressCopy.push(AddressDetailsModel.init());
        setFieldValue(`${prefix}address`, addressCopy)
    }

    // Delete address with given from array
    const deleteAddress = (index: number) => {
        const addressCopy = Array.from(values.address);
        addressCopy.splice(index, 1);
        setFieldValue(`${prefix}address`, addressCopy)
        setFieldTouched(`${prefix}address[${index}]`, false)
    }

    useEffect(() => {
        handleAddressCount();
    }, []);

    return (
        <ConfonetCard>
            <ConfonetCardHeader>
                <div className="card-title card-primary-header-title">{label}</div>
            </ConfonetCardHeader>
            <ConfonetCardBody>
                <div className="row">
                    <div className="col-lg-4">
                        <Textbox
                            id="Name"
                            name={`${prefix}name`}
                            className="uppercase"
                            label={t("Label.Name") + "*"}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 251 }}
                            error={touched?.name && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.name : false)}
                            helperText={touched?.name && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.name : ""}
                        />
                    </div>
                    <div className="col-lg-4">
                        <Textbox
                            id="MobileNumber"
                            name={`${prefix}mobileNumber`}
                            className="uppercase"
                            label={t("Label.MobileNumber") + "*"}
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 10 }}
                            error={touched?.mobileNumber && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.mobileNumber : false)}
                            helperText={touched?.mobileNumber && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.mobileNumber : ""}
                        />
                    </div>
                    <div className="col-lg-4">
                        <Textbox
                            id="Email"
                            name={`${prefix}email`}
                            className="uppercase"
                            label={t("Label.Email")}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 51 }}
                            error={touched?.email && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>)?.email : false)}
                            helperText={touched?.email && errors ? (errors as FormikErrors<PersonalDetailsModel>)?.email : ""}
                        />
                    </div>
                </div>
                {values.isDifferentlyAbled && handiCapType && (
                    <div className="row">
                        <div className="col-lg-4">
                            <ConfonetAutocomplete
                                id="handicapped"
                                className="uppercase"
                                value={values.handicapped}
                                options={handiCapType}
                                clearOnBlur
                                renderInput={
                                    (params) => (
                                        <TextField
                                            {...params}
                                            label={t("Label.HandicappedType")}
                                            error={touched?.handicapped && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>).handicapped : false)}
                                            helperText={touched?.handicapped && errors ? (errors as FormikErrors<PersonalDetailsModel>).handicapped?.label : ""}
                                            onChange={(event) => setFieldValue(`${prefix}advocateText`, event.target.value)}
                                            fullWidth
                                        />
                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => {
                                    setFieldValue(`${prefix}handicapped`, newValue ?? { label: "", value: "" })
                                }}
                                onBlur={() => {
                                    setFieldTouched(`${prefix}handicapped.label`, true)
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-auto">
                        <BasicCheckbox
                            control={<Checkbox />}
                            id="isSeniorCitizen"
                            name={`${prefix}isSeniorCitizen`}
                            label={t("Label.SeniorCitizen")}
                            checked={values.isSeniorCitizen}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-auto">
                        <BasicCheckbox
                            control={<Checkbox />}
                            id="isWidow"
                            name={`${prefix}isWidow`}
                            label={t("Label.Widow")}
                            checked={values.isWidow}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-auto">
                        <BasicCheckbox
                            control={<Checkbox />}
                            id="isDifferentlyAbled"
                            name={`${prefix}isDifferentlyAbled`}
                            label={t("Label.DifferentlyAbled")}
                            checked={values.isDifferentlyAbled}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                {
                    values.address.length > 0 &&
                    values.address.map((_, index: number) => {
                        return (
                            <div className={`card-text-info-block ${values.address.length > 1 ? "margin-bottom-20" : "margin-top-10"}`} key={index}>
                                {
                                    values.address.length > 1 &&
                                    <BasicButton
                                        className="btn-custom-delete min-width-auto"
                                        text=""
                                        variant="text"
                                        type="button"
                                        onClick={() => {
                                            deleteAddress(index);
                                            handleSelectAddressType()
                                        }}
                                    />
                                }
                                <AddressDetails
                                    name={`${prefix}address[${index}]`}
                                    label={`${t("Label.Address")} ${index + 1}`}
                                    values={values.address[index]}
                                    touched={touched?.address?.[index]}
                                    errors={errors ? (errors as FormikErrors<PersonalDetailsModel>).address?.[index] : ""}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    selectedAddressTypeOptions={selectedAddressTypeOptions}
                                    handleSelectAddressType={handleSelectAddressType}
                                    isRespondent={isRespondent}
                                />
                            </div>
                        )
                    })

                }
                {
                    ((!singleAddress || values.address.length < 1) && (values.address.length < addressMaxCount)) &&
                    <BasicButton
                        className="btn-custom btn-custom-secondary mt-lg-3"
                        type="button"
                        color="secondary"
                        text={t("Label.AddAddress")}
                        startIcon={<AddIcon />}
                        variant="text"
                        onClick={addAddress}
                    />
                }

                {advocateList && values.advocate && !isRespondent &&
                    <div className="row margin-top-25">
                        <div className="col-lg-12">
                            <Typography className="margin-bottom-15" variant="h5">{t("Label.ComplainantAdvocateDetails")}</Typography>
                        </div>
                        <div className="col-lg-12">
                            <ConfonetAutocomplete
                                id="advocate"
                                className="uppercase"
                                value={values.advocate}
                                options={advocateList}
                                clearOnBlur

                                renderInput={
                                    (params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                label={t("Label.Name")}
                                                error={touched?.advocate && Boolean(errors ? (errors as FormikErrors<PersonalDetailsModel>).advocate : false)}
                                                helperText={touched?.advocate && errors ? (errors as FormikErrors<PersonalDetailsModel>).advocate?.label : ""}
                                                onChange={(event) => setFieldValue(`${prefix}advocateText`, event.target.value)}
                                                fullWidth
                                            />
                                        </>

                                    )
                                }
                                onChange={(_, newValue: IDropdown | null) => {
                                    setFieldValue(`${prefix}advocate`, newValue ?? { label: "", value: "" })
                                }}
                                onBlur={() => {
                                    setFieldTouched(`${prefix}advocate.label`, true)
                                }}

                            />
                        </div>
                    </div>
                }
            </ConfonetCardBody>
        </ConfonetCard>

    )
};


interface IPersonalDetailsFormProps {
    formik: FormikProps<PersonalDetailsModel>,
    advocateOptions: IDropdown[],
    CRsequenceNumber: number,
    complainantLabel: string,
    respondentLabel: string,
    isRespondent?: boolean
}
export const PersonalDetailsForm = ({ formik, CRsequenceNumber, advocateOptions, complainantLabel, respondentLabel, isRespondent }: IPersonalDetailsFormProps) => {
    const { t } = useTranslation();
    const label = (CRsequenceNumber > 0 ? t("Label.Additional") + " " : "") +
        (isRespondent ? respondentLabel : complainantLabel)

    return (
        <PersonalDetails
            name=""
            label={label}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            advocateList={advocateOptions}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            isRespondent={isRespondent}
            singleAddress={CRsequenceNumber > 0}
        />
    )
}