import React from 'react'
import "./footer.scss"
import "../landingPageGloble.scss"
import { Link } from 'react-router-dom'
import { DISCLAIMER, FAQ, FEEDBACK, HELP, TERMS_AND_CONDITIONS, WEBSITE_POLICIES } from '../../navigation/CONSTANTS'


const Footer = () => {
    return (
        <footer className='container-fluid p-0'>
            <div className="container-fluid footer-section-inner px-2 ">
                <div className="row gap-2 gap-md-5 footer-section-inner-row">
                    <div className="col-md p-3">
                        <div className='d-flex d-flex-wrap justify-content-evenly'>
                            <a href="https://www.nic.in/">
                                <img src="/images/NIC.png" alt="NIC" className="mb-2 footer-section-left-image" />
                            </a>
                            <a href="https://digitalindia.gov.in/">
                                <img
                                    src="/images/digi.png"
                                    alt="Digital India Logo"
                                    className="mb-2 footer-section-left-image"
                                />
                            </a>
                        </div>
                        <p className="fw-bold mb-2 my-4 footer-section-left-text">
                            <p className='text-white'>© E-Jagriti Platform Is Developed, Designed And Maintained By{" "}</p>
                            <span className="fw-normal footer-section-left-text-highlight ">
                                National Informatics Centre, <br />
                                Ministry Of Electronics &Information Technology, Government Of India.
                            </span>
                        </p>
                    </div>
                    <div className="col-md col-sm-6 p-3">
                        <div className='text-center footer-section-mid-text-t'>
                            <p>Other Links</p>
                        </div>
                        {/* <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='/#/website-policies'>WebSite Policies</a></p>
                            <p><a href='/#/disclaimer'>Disclaimer</a></p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='/#/disclaimer'>Help</a></p>
                            <p><a href='/#/terms-and-conditions'>Terms & Conditions</a></p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-between fw-bold text-capitalize footer-section-mid-text-b'>
                            <p><a href='/#/faq'>FAQ</a></p>
                            <p><a href="/#/feedback">Feedback</a></p>
                        </div> */}

                        <div className='footerOtherLinks'>
                            <div>
                                <Link to={WEBSITE_POLICIES} className='text-decoration-none'><p>WebSite Policies</p></Link>
                                <Link to={DISCLAIMER} className='text-decoration-none'><p>Disclaimer</p></Link>
                                <Link to={HELP} className='text-decoration-none'><p>Help</p></Link>
                            </div>
                            <div>
                                <Link to={TERMS_AND_CONDITIONS} className='text-decoration-none'><p>Terms & Conditions</p></Link>
                                <Link to={FAQ} className='text-decoration-none'><p>FAQ</p></Link>
                                <Link to={FEEDBACK} className='text-decoration-none'><p>Feedback</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md p-3">
                        <div className='text-center footer-section-mid-text-t'>
                            <p className=''>Follow Us</p>
                        </div>
                        <div className='text-center fw-bold text-capitalize footer-section-right-text-b'>
                            <p className='text-white'>Stay connect with us through out social media platform</p>
                        </div>
                        <div className='d-flex d-flex-wrap justify-content-around'>
                            <a href="#">
                                <img
                                    src="/images/facebook.png"
                                    alt="Facebook"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/youtube.png"
                                    alt="Youtube"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/instagram.png"
                                    alt="Instagram"
                                    className="footer-section-right-icon"
                                />
                            </a>
                            <a href="#">
                                <img
                                    src="/images/twitter.png"
                                    alt="Twitter"
                                    className="footer-section-right-icon"
                                />
                            </a>


                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer