import React from 'react';
import Plot from 'react-plotly.js';
import { findMaxValue, formatNumber, indian_number_system_converter } from '../../../../utils/converter';

interface JudgeGroupedData {
  judgeid: string;
  commission_id?: string;
  salutation?: string;
  judges_name?: string;
  designation?: string;
  no_of_cases: string;
  no_of_hearings: string;
  orders: string;
  judgement_given: string;
}

interface CategoryGroupedData {
  judgeid: string;
  case_category_id: string;
  category_name: string;
  no_of_cases: string;
  no_of_hearings: string;
  orders: string;
  judgement_given: string;
}

interface ChartProps {
  data: JudgeGroupedData[] | CategoryGroupedData[];
  type: 'judge' | 'category';
  setCategoryId?: any;
}

export const JudgesGroupedBarChart = ({ data, type, setCategoryId }: ChartProps) => {
  const isJudgeData = type === 'judge';

  const xValues = isJudgeData
    ? (data as JudgeGroupedData[]).map(d => d.judges_name ?? `Judge ${d.judgeid}`)
    : (data as CategoryGroupedData[]).map(d => d.category_name);

  const cases = data.map(d => d.no_of_cases);
  const hearings = data.map(d => d.no_of_hearings);
  const orders = data.map(d => d.orders);
  const judgments = data.map(d => d.judgement_given);
  const formatCases = cases?.map((item) =>
    indian_number_system_converter(item)
  );
  const formatHearing = hearings?.map((item) =>
    indian_number_system_converter(item)
  );
  const formatProceedings = orders?.map((item) =>
    indian_number_system_converter(item)
  );
  const formatJudgment = judgments?.map((item) =>
    indian_number_system_converter(item)
  );

  const max = findMaxValue(
    data.flatMap((category) => [
      parseInt(category.no_of_cases),
      parseInt(category.no_of_hearings),
      parseInt(category.orders),
      parseInt(category.judgement_given),
    ])
  );

  const color = isJudgeData ? ['#6D3580', '#CC4165', '#E4734F', '#FFE26F'] : ['#114232', '#87A922', '#FFCC29', '#F58634']

  const handleBarClick = (event: any) => {
    if (!isJudgeData) {
      const pointIndex = event.points[0].pointIndex;
      const clickedData = data[pointIndex];
      const categoryId = (clickedData as CategoryGroupedData).case_category_id;
      setCategoryId(categoryId);
    }
  };

  return (
    <Plot
      className='plotlyChart'
      data={[
        {
          x: xValues,
          y: cases,
          type: 'bar',
          name: 'Cases',
          hovertemplate: formatCases?.map((value) => `${value}`),
          transforms: [{
            type: 'aggregate',
            groups: xValues,
            aggregations: [
              { target: 'y', func: 'sum', enabled: true }
            ]
          }]
        },
        {
          x: xValues,
          y: hearings,
          type: 'bar',
          name: 'Hearings',
          hovertemplate: formatHearing?.map((value) => `${value}`),
          transforms: [{
            type: 'aggregate',
            groups: xValues,
            aggregations: [
              { target: 'y', func: 'sum', enabled: true }
            ]
          }]
        },
        {
          x: xValues,
          y: orders,
          type: 'bar',
          name: 'Orders',
          hovertemplate: formatProceedings?.map((value) => `${value}`),
          transforms: [{
            type: 'aggregate',
            groups: xValues,
            aggregations: [
              { target: 'y', func: 'sum', enabled: true }
            ]
          }]
        },
        {
          x: xValues,
          y: judgments,
          type: 'bar',
          name: 'Judgments',
          hovertemplate: formatJudgment?.map((value) => `${value}`),
          transforms: [{
            type: 'aggregate',
            groups: xValues,
            aggregations: [
              { target: 'y', func: 'sum', enabled: true }
            ]
          }]
        },
      ]}
      layout={{
        barmode: 'group',
        colorway: color,
        title: {
          text: isJudgeData ? "<b>Insights on Hearings and Adjudication</b>" : "<b>Sectoral Activity Summary Breakdown</b>",
          font: {
            size: 18,
            family: "'Inter', sans-serif",
            color: "#4B5563",
          },
          x: 0.04,
          y: 0.94,
        },
        xaxis: { automargin: true },
        yaxis: {
          type: 'log',
          showgrid: false,
          // visible: false
          // range: [0, max * 1.1],
          tickformat: ",.0f",
          // zeroline: false,
          // tickvals: [0, max / 4, max / 2, (3 * max) / 4, max],
          // ticktext: [
          //   "0",
          //   formatNumber(max / 4),
          //   formatNumber(max / 2),
          //   formatNumber((3 * max) / 4),
          //   formatNumber(max),
          // ],
        },
        legend: {
          x: 1,
          orientation: "h",
          xanchor: "right",
          y: 1.12,
        },
        hovermode: "x unified"
      }}
      config={{ displaylogo: false }}
      onClick={handleBarClick}
    />
  );
};
