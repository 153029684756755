/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Plot from "react-plotly.js";
import { Button } from "@mui/material";
import {
  findMaxValue,
  formatNumber,
  indian_number_system_converter,
} from "../../../../utils/converter";

interface YearlyDataPoint {
  yearflag: string;
  filed: string;
  disposed: string;
  pending: string;
}

interface MonthlyDataPoint {
  yearflag: string;
  monthflag: string;
  monthname: string;
  filed: string;
  disposed: string;
  pending: string;
}

type AnyDataPoint = YearlyDataPoint | MonthlyDataPoint;

interface FilledAreaAnimationProps {
  yearlyData: YearlyDataPoint[];
  monthlyData: MonthlyDataPoint[];
}

interface CustomLayout extends Partial<Plotly.Layout> {
  data: {
    mode: string;
    x: string[];
    y: string[];
    fill?: string;
    fillcolor?: string;
    line: {
      color: string;
    };
    text?: string[];
    hovertemplate?: string[];
  }[];
}

const getSelectedX = (
  trace: string,
  monthlyData: MonthlyDataPoint[] | undefined
): string => {
  if (trace === "yearly") {
    return "yearflag";
  } else if (trace === "monthly" && monthlyData) {
    return "monthname" in monthlyData[0] ? "monthname" : "monthflag";
  } else {
    return "";
  }
};

const getFormattedData = (
  data: AnyDataPoint[],
  animationFrame: number,
  selectedX: string
) => {
  return data.slice(0, animationFrame + 1).map((point: AnyDataPoint) =>
    selectedX === "monthly" && "monthname" in point
      ? point.monthname.toString()
      : point[selectedX as keyof AnyDataPoint].toString().trimEnd()
  );
};

const getYData = (
  data: AnyDataPoint[],
  field: keyof AnyDataPoint,
  animationFrame: number
) => {
  return data.slice(0, animationFrame + 1).map((point) => point[field]);
};

const FilledAreaAnimation: React.FC<FilledAreaAnimationProps> = ({
  yearlyData,
  monthlyData,
}) => {
  const [animationFrame, setAnimationFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedTrace, setSelectedTrace] = useState<"yearly" | "monthly">(
    "yearly"
  );
  const [selectedYear, setSelectedYear] = useState<string | undefined>(
    monthlyData?.length
      ? monthlyData[monthlyData.length - 1]?.yearflag
      : undefined
  );

  const [layout, setLayout] = useState<CustomLayout>({
    title: {
      text: "<b>Time Period Analysis</b>",
      font: {
        size: 18,
        family: "'Inter', sans-serif",
        color: "#4B5563",
      },
      x: 0.04,
      y: 0.99,
    },
    data: [],
    autosize: true,
    hovermode: "x",
    plot_bgcolor: "rgba(0,0,0,0)",
    paper_bgcolor: "rgba(0,0,0,0)",
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIsPlaying(true);
    }
  }, [yearlyData, monthlyData, selectedYear]);

  useEffect(() => {
    if (isPlaying) {
      setAnimationFrame(0);
    }
  }, [selectedTrace, isPlaying]);

  useLayoutEffect(() => {
    let interval: NodeJS.Timeout;

    if (
      isPlaying &&
      animationFrame < Math.max(yearlyData?.length, monthlyData?.length) - 1
    ) {
      interval = setInterval(() => {
        setAnimationFrame((frame) =>
          Math.min(
            frame + 1,
            Math.max(yearlyData?.length, monthlyData?.length) - 1
          )
        );

        updateLayout();
      }, 100);
    } else {
      setAnimationFrame(0);
      setIsPlaying(false);
    }

    return () => clearInterval(interval);
  }, [yearlyData, monthlyData, animationFrame, isPlaying, selectedTrace, selectedYear,]);

  useEffect(() => {
    if (selectedTrace === "monthly" && monthlyData?.length) {
      setSelectedYear(monthlyData[monthlyData.length - 1]?.yearflag);
    }
  }, [monthlyData, selectedTrace]);

  useEffect(() => {
    setIsPlaying(true);
  }, [selectedTrace]);

  useEffect(() => {
    if (selectedTrace === "monthly") {
      setIsPlaying(true);
    }
  }, [monthlyData]);

  useEffect(() => {
    if (selectedTrace === "yearly") {
      setIsPlaying(true);
    }
  }, [yearlyData]);

  useEffect(() => {
    if (selectedTrace === "monthly" && selectedYear) {
      setIsPlaying(true);
      setAnimationFrame(0);
    }
  }, [selectedYear]);

  const updateLayout = () => {
    const selectedData =
      selectedTrace === "yearly"
        ? yearlyData
        : monthlyData?.filter((item) => item.yearflag == selectedYear);

    const selectedX = getSelectedX(selectedTrace, monthlyData);

    const x = getFormattedData(selectedData, animationFrame, selectedX);

    const filedNum = selectedData.map((item) =>
      parseInt(item.filed)
    );
    const disposedNum = selectedData.map((item) =>
      parseInt(item.disposed)
    );
    const pendingNum = selectedData.map((item) =>
      parseInt(item.pending)
    );

    const maxFiled = findMaxValue(filedNum);
    const maxDisposed = findMaxValue(disposedNum);
    const maxPending = findMaxValue(pendingNum);

    const formattedFiled = filedNum.map((value) =>
      indian_number_system_converter(String(value))
    );
    const formattedDisposed = disposedNum.map((value) =>
      indian_number_system_converter(String(value))
    );

    setLayout((prevLayout) => ({
      ...prevLayout,
      data: [
        {
          mode: "lines",
          x: x,
          y: getYData(selectedData, "disposed", animationFrame),
          // fill: "tonexty",
          // fillcolor: "#08c",
          line: { color: "#00c3d0", width: 2, shape: "spline" },
          text: formattedDisposed,
          hovertemplate: formattedDisposed.map((val) => `Disposed: %{text}`),
          name: "Disposed",
          xaxis: { type: selectedX === "monthly" ? "category" : undefined },
        },
        {
          mode: "lines",
          x: x,
          y: getYData(selectedData, "filed", animationFrame),
          line: { color: "#b472ee", width: 2, shape: 'spline' },
          text: formattedFiled,
          // fill: "tonexty",
          hovertemplate: formattedFiled.map((val) => `Filed: %{text}`),
          name: "Filed",
          xaxis: { type: selectedX === "monthly" ? "category" : undefined },
        },

      ],
      yaxis: {
        title: {
          text: "Case Counts",
          font: {
            size: 14,
            family: "'Inter', sans-serif",
            color: "#4B5563",
          },
          standoff: 10
        },
        range: [0, Math.max(maxFiled, maxDisposed, maxPending) * 1.1],
        automargin: true,
        tickformat: ",.0f",
        showgrid: false,
        tickvals: [
          0,
          Math.max(maxFiled, maxDisposed, maxPending) / 25,
          Math.max(maxFiled, maxDisposed, maxPending) / 4,
          Math.max(maxFiled, maxDisposed, maxPending) / 2,
          (3 * Math.max(maxFiled, maxDisposed, maxPending)) / 4,
          Math.max(maxFiled, maxDisposed, maxPending),
        ],
        ticktext: [
          "0",
          formatNumber(Math.max(maxFiled, maxDisposed, maxPending) / 25),
          formatNumber(Math.max(maxFiled, maxDisposed, maxPending) / 4),
          formatNumber(Math.max(maxFiled, maxDisposed, maxPending) / 2),
          formatNumber((3 * Math.max(maxFiled, maxDisposed, maxPending)) / 4),
          formatNumber(Math.max(maxFiled, maxDisposed, maxPending)),
        ],
      },
      xaxis: {
        type: selectedX === "monthly" ? "category" : undefined,
        tickvals: x,
        ticktext: x,
        showgrid: false
      },
      legend: {
        traceorder: "normal",
        xanchor: "right",
        orientation: "h",
        x: 1,
        y: 1.12,
      },
      margin: { t: 70 },
    }));
  };

  const handleSelectYearly = () => setSelectedTrace("yearly");
  const handleSelectMonthly = () => setSelectedTrace("monthly");

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  return (
    <div>
      <div className="d-flex">
        <Button
          onClick={handleSelectYearly}
          className={`button-yearly-monthly ${selectedTrace === "yearly" ? "button-selected" : ""}`}
        >
          Yearly
        </Button>
        <Button
          onClick={handleSelectMonthly}
          className={`button-yearly-monthly ${selectedTrace === "monthly" ? "button-selected" : ""}`}
        >
          Monthly
        </Button>
        {selectedTrace === "monthly" && (
          <select value={selectedYear} onChange={handleYearChange}>
            {yearlyData?.map((item, index) => (
              <option key={index} value={item.yearflag}>
                {item.yearflag}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="margin-top-20">
        <Plot
          className="plotlyChart"
          data={layout.data as any}
          layout={{
            ...layout, plot_bgcolor: "rgba(0,0,0,0)",
            paper_bgcolor: "rgba(0,0,0,0)",
          }}
          useResizeHandler
          config={{ displaylogo: false }}
        />
      </div>
    </div>
  );
};

export default FilledAreaAnimation;
