import React from "react";

function Unauthorized() {
  return (
    <div className="d-flex flex-column w-100 justify-content-center align-items-center">
      <div>
        <h1>401</h1>
      </div>
      <div>
        <h1>Unauthorized</h1>
      </div>
    </div>
  );
}

export default Unauthorized;