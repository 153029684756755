import { AxiosResponse } from "axios";
import { getCall } from "../api";
import { REPORT_SERVICE } from "../CONSTANTS";
import { ICategoryWiseReportResponse, ICommissionWiseReportResponse, IDisposalMoreThanOneYearResponse, IPendencyWithInYearResponse, IRegionWiseReportResponse, IStateWiseReportResponse, IDisposalWithin1YearReport, IYearWiseReport, IStateCommissionDurationWisePendency, IcategoryWiseFilter, INCDRCCourtwiseHearingDetailsParams, NCDRCJudgeswiseReport } from "../../models/reports/ReportModel";

export class ReportService {

    /**
    * Call an API to perform Get summary report
    */
    public static getCommissionWiseReport: () => Promise<ICommissionWiseReportResponse[]> = async () => {
        const response: ICommissionWiseReportResponse[] = await getCall(
            `${REPORT_SERVICE}/summary-report`
        );
        return response;
    };

    /**
    * Call an API to perform Get category report
    */
    public static getCategoryReport: () => Promise<ICategoryWiseReportResponse[]> = async () => {
        const response: AxiosResponse<ICategoryWiseReportResponse[]> = await getCall(
            `${REPORT_SERVICE}/category-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform Get state report
    */
    public static getStateWiseReport: () => Promise<IStateWiseReportResponse[]> = async () => {
        const response: AxiosResponse<IStateWiseReportResponse[]> = await getCall(
            `${REPORT_SERVICE}/state-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform Get state pendency for one year report
    */
    public static getStatePendencyForOneYearReport: () => Promise<IPendencyWithInYearResponse[]> = async () => {
        const response: AxiosResponse<IPendencyWithInYearResponse[]> = await getCall(
            `${REPORT_SERVICE}/statependency1year-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform Get state district report
    */
    public static getStatesDistrictReport: () => Promise<IStateWiseReportResponse[]> = async () => {
        const response: AxiosResponse<IStateWiseReportResponse[]> = await getCall(
            `${REPORT_SERVICE}/statedistrict-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform get region wise report
    */
    public static getRegionWiseReport: () => Promise<IRegionWiseReportResponse[]> = async () => {
        const response: AxiosResponse<IRegionWiseReportResponse[]> = await getCall(
            `${REPORT_SERVICE}/regionwise-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform get disposal more than 1 year report
    */
    public static getDisposalMoreThanOneYearReport: () => Promise<IDisposalMoreThanOneYearResponse[]> = async () => {
        const response: AxiosResponse<IDisposalMoreThanOneYearResponse[]> = await getCall(
            `${REPORT_SERVICE}/disposalmorethan1year-report`
        );
        return response?.data;
    };

// ---------------API CALL AFTER 7TH REPORTS------------------------
 /**
    * Call an API to perform get disposal within 1 year report.
    */
 public static getDisposalWithin1YearReport: () => Promise<IDisposalWithin1YearReport[]> = async () => {
    const response: AxiosResponse<IDisposalWithin1YearReport[]> = await getCall(
        `${REPORT_SERVICE}/disposalwithin1year-report`
    );
    return response?.data;
};

    /**
    * Call an API to perform get State Commission Duration Wise Pendency.
    */
    public static getStateCommissionDurationWisePendency: () => Promise<IStateCommissionDurationWisePendency[]> = async () => {
        const response: AxiosResponse<IStateCommissionDurationWisePendency[]> = await getCall(
            `${REPORT_SERVICE}/statecommissionwisependency-report`
        );
        return response?.data;  
    };
    
    /**
    * Call an API to perform get year wise report.
    */
    public static getYearWiseReport: () => Promise<IYearWiseReport[]> = async () => {
        const response: AxiosResponse<IYearWiseReport[]> = await getCall(
            `${REPORT_SERVICE}/yearwise-report`
        );
        return response?.data;
    };

    /**
    * call an API for getting Category wise report
    */
    public static getStateDistrictCategory = async (Catogery_props: string): Promise<IcategoryWiseFilter[]> => {
        const response = await getCall(`${window.location.origin+Catogery_props}`);
        return response
    };

    /**
    * Call an API to perform get Case Type Wise report.
    */

    public static getCaseTypeWiseReport: () => Promise<IYearWiseReport[]> = async () => {
        const response: AxiosResponse<IYearWiseReport[]> = await getCall(
            `${REPORT_SERVICE}/casetype-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform get Registered Users Report Wise report.
    */

    public static Registered_Users_Report: () => Promise<IYearWiseReport[]> = async () => {
        const response: AxiosResponse<IYearWiseReport[]> = await getCall(
            `${REPORT_SERVICE}/userdetailcount-report`
        );
        return response?.data;
    };

    /**
    * Call an API to perform e-Jagriti Case Detail report.
    */

    public static e_Jagriti_Case_Detail_report: () => Promise<IYearWiseReport[]> = async () => {
        const response: AxiosResponse<IYearWiseReport[]> = await getCall(
            `${REPORT_SERVICE}/ejagriticasedetail-report`
        );
        return response?.data;
    };
    
    /**
    * Call an API to perform Stage Wise report.
    */

    public static Stage_Wise_Report: () => Promise<IYearWiseReport[]> = async () => {
        const response: AxiosResponse<IYearWiseReport[]> = await getCall(
            `${REPORT_SERVICE}/stagewise-report`
        );
        return response?.data;
    };

    /**
    * Dynamic Report API.
    */
    public static DynamicReportAPI = async (URL: string): Promise<any[]> => {
        const response = await getCall(`${window.location.origin+URL}`);
        return response
    };

    /**
    * NCDRC Courtwise Hearing Details.
    */
    public static NCDRCCourtwiseHearingDetails = async ({selectedCourtId, fromDateParams, toDateParams}:INCDRCCourtwiseHearingDetailsParams): Promise<any[]> => {
        const response = await getCall(`${window.location.origin}+"/report/getncdrchearingcourt?dateOfNextHearing1=${fromDateParams}&dateOfNextHearing2=${toDateParams}&courtId=${selectedCourtId}`);
        return response
    };

    /***
     * NCDRC Judgeswise Report
     * 
     */
    public static NCDRCJudgeWiseReports = async ({allSelectedJudgesId, fromDateParams, toDateParams}: NCDRCJudgeswiseReport): Promise<any[]> =>{
        const response = await getCall(`${window.location.origin}/report/getncdrcjudgehearing?dateOfNextHearing1=${fromDateParams}&dateOfNextHearing2=${toDateParams}&judgeId=${allSelectedJudgesId.toString()}`);
        return response
    }


     /**
    * Details of Judges Cases Details Since Inception
    */
     public static getDetailsofJudgesCasesDetails = async (): Promise<any[]> => {
        const response = await getCall(`${window.location.origin}/report/gettotalncdrccasesheared`);
        return response
    };

     /**
    * Details of Judge Information
    */
     public static getjudgeinformation = async (): Promise<any[]> => {
        const response = await getCall(`${window.location.origin}/report/getjudgeinformation`);
        return response
    };
    
}