import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Handle console logs
import "./i18n";
import "./utils/dropConsole";
import { CssBaseline, Theme, ThemeProvider, createTheme } from "@mui/material";
import "./App.scss";
import { ConfonetLoader } from "./components";
import { themeOptions } from "./layout/theme/customTheme";
import { ProvideAuth } from "./navigation/auth/ProvideAuth";
import ErrorFallback from "./navigation/ErrorFallback";
// import { RouterConfig } from "./navigation/RouterConfig";
import { loaderService } from "./services/CommonService/loaderService";
import { RoutingConfig } from "./navigation/RoutingConfig";

function App() {
  const theme: Theme = createTheme(themeOptions);
  const [loaders, setLoaders] = useState<Array<number>>([]);

  useEffect(() => {
    const loaderSubscription = loaderService
      .getLoading()
      .subscribe((isLoading) => {
        //Loader is going to be shown for multiple API calls and IF on a screen more than one API call triggers, the one
        //which finishes early makes the isLoading flag to false therefore the API is in the progress doesn't get the loader
        //on the screen. To resolve this problem we are maintaining the array where on every API call we push the new element
        //in the array and removes one element once API call finishes so at end when all call finishes the array becomes empty and not loader
        //is shown on to the screen.
        if (isLoading)
          setLoaders((prevState: Array<number>) => [...prevState, 1]);
        else
          setLoaders((prevState: Array<number>) =>
            prevState.slice(0, prevState.length - 1)
          );
      });

    // cleanup activity
    return () => {
      loaderSubscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        theme="colored"
        className="custom-toast"
      />
      <div className="wrapper">
        <div className={loaders.length === 0 ? "d-none" : ""}>
          <ConfonetLoader />
        </div>
        <ProvideAuth>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {/* <RouterConfig /> */}
              <RoutingConfig />
            </ErrorBoundary>
          </ThemeProvider>
        </ProvideAuth>
      </div>
    </>
  );
}

export default App;
